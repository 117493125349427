import React from 'react';
import { useContentSpaceId } from '_lib/auth';
import { createFailedUpdateMessage, createSuccessfulUpdateMessage, useSetMessage } from '_lib/messages';
import { ValidatedEntity } from 'domain/api/common/types';
import { getContentEntriesToAddAndRemove } from 'domain/api/common/transforms';
import { ImageLink, ImageLinkInput } from '../types';
import { useAddImageLinkMappingsMutation } from '../addMappings';
import { useRemoveImageLinkMappingsMutation } from '../removeMappings';
import { useUpdateOneImageLinkInfo } from '../updateInfo';
import { useCreateImageLinkAsset } from '../createOne/useCreateImageLinkAsset';

export const useUpdateOneImageLink = (imageLink: ImageLink) => {
  const contentSpaceId = useContentSpaceId();
  const setMessage = useSetMessage();
  const [loading, setLoading] = React.useState(false);
  const [result, setResult] = React.useState<ValidatedEntity<ImageLink> | undefined>();
  const addMappings = useAddImageLinkMappingsMutation();
  const removeMappings = useRemoveImageLinkMappingsMutation();
  const updateInfo = useUpdateOneImageLinkInfo();
  const createImageLinkAsset = useCreateImageLinkAsset();

  const execute = (input: ImageLinkInput & { id: string }): Promise<void> => {
    return (async () => {
      try {
        if (!contentSpaceId) throw new Error('Failed to update');
        setLoading(true);

        const file = input.files && input.files.length > 0 ? input.files[0] : undefined;
        const { toAdd, toRemove } = getContentEntriesToAddAndRemove(imageLink, input);
        const updateInfoResult = await updateInfo.execute({ ...input, contentSpaceId });

        if (!updateInfoResult) throw new Error('Failed to update');
        setResult(updateInfoResult);

        const addMappingsResult =
          toAdd.length > 0
            ? await addMappings.execute({
              contentSpaceId,
              imageLinkId: input.id,
              contentEntries: toAdd,
            })
            : undefined;

        if (!addMappingsResult && toAdd.length > 0) throw new Error('Failed to update');
        setResult(addMappingsResult);

        const removeMappingsResult =
          toRemove.length > 0
            ? await removeMappings.execute({
              contentSpaceId,
              imageLinkId: input.id,
              contentEntries: toRemove,
            })
            : undefined;

        if (!removeMappingsResult && toRemove.length > 0) throw new Error('Failed to update');
        setResult(removeMappingsResult);

        const createAssetResult = file
          ? await createImageLinkAsset.execute(imageLink, file)
          : undefined;

        if (file && !createAssetResult) throw new Error('Failed to update');

        setMessage(createSuccessfulUpdateMessage(''));
        setLoading(false);
        return;
      } catch (error) {
        setMessage(createFailedUpdateMessage('', ''));
        setLoading(false);
      }
    })();
  };

  return {
    execute,
    result,
    loading,
    called: addMappings.called || removeMappings.called || updateInfo.called,
    error: addMappings.error || removeMappings.error || updateInfo.error,
  };
};
