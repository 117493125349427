import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { AuthGate, AuthProvider } from '_lib/auth';
import { MessagesDisplay } from '_lib/messages';
import { LoadingBlind, SystemLoadingDoneMarker } from '_lib/loading';
import { apolloClient } from './api';
import { ConfigProvider } from './config';
import { AuthLayout } from './view/containers/AuthLayout';
import { Routes } from './routes/Routes';
import { ApiProvider } from './api/ApiProvider/ApiProvider';
import { EntitiesProvider } from './entities/EntitiesProvider';

function App() {
  if (!process.env.REACT_APP_BASE_GRAPHQL_URL) {
    throw new Error('Missing environment variable REACT_APP_BASE_GRAPHQL_URL');
  }
  if (!process.env.REACT_APP_BASE_ASSETS_URL) {
    throw new Error('Missing environment variable REACT_APP_BASE_ASSETS_URL');
  }
  if (!process.env.REACT_APP_AUTH_DOMAIN) {
    throw new Error('Missing environment variable REACT_APP_AUTH_DOMAIN');
  }
  if (!process.env.REACT_APP_AUTH_CLIENT_ID) {
    throw new Error('Missing environment variable REACT_APP_AUTH_CLIENT_ID');
  }
  if (!process.env.REACT_APP_AUTH_REDIRECT_URI) {
    throw new Error('Missing environment variable REACT_APP_AUTH_REDIRECT_URI');
  }
  if (!process.env.REACT_APP_BASE_CONFIG_URL) {
    throw new Error('Missing environment variable REACT_APP_BASE_CONFIG_URL');
  }

  return (
    <div className="App">
      <ApolloProvider client={apolloClient}>
        <LoadingBlind>
          <AuthProvider
            clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
            domain={process.env.REACT_APP_AUTH_DOMAIN}
            redirectUri={process.env.REACT_APP_AUTH_REDIRECT_URI}
          >
            <AuthGate>
              <ConfigProvider>
                <ApiProvider>
                  <EntitiesProvider>
                    <BrowserRouter>
                      <SystemLoadingDoneMarker />
                      <AuthLayout>
                        <Routes />
                      </AuthLayout>
                      <MessagesDisplay />
                    </BrowserRouter>
                  </EntitiesProvider>
                </ApiProvider>
              </ConfigProvider>
            </AuthGate>
          </AuthProvider>
        </LoadingBlind>
      </ApolloProvider>
    </div>
  );
}

export default App;
