import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { ListPageResource } from 'system/routes/types';
import { ResourceConstraint } from 'lib/resource';
import { Status } from 'common/types';
import { useContentSpaceId } from '_lib/auth';
import { Table } from '_lib/components';
import { Spinner } from '_lib/loading';
import { TopbarLayout } from 'domain/view/components/layout/topbar/TopbarLayout';
import { ListPageTopbar } from 'domain/view/components/layout/topbar/ListPageTopbar';

export interface ListPageProps<
  TResource extends ResourceConstraint & { publishedStatus?: Status },
  Error
> {
  resourceName: ListPageResource;

  get: (contentSpaceId: string) => void;
  data: TResource[] | null | undefined;
  noDataMessage: string;
  actions?: {
    publish?: (id: TResource['id']) => void;
    unpublish?: (id: TResource['id']) => void;
    delete?: (id: TResource['id']) => void;
    edit?: (data: TResource) => JSX.Element;
  };
  error: Error | undefined;
  loading: boolean;
  actionLoading?: boolean;
  hasData: boolean;
  called: boolean;
  topbar: {
    title: string;
    createAction?: JSX.Element;
    editAction?: (values: TResource) => JSX.Element;
  };
}

export function ListPage<T extends ResourceConstraint & { publishedStatus?: Status }, C>({
  resourceName,
  get,
  called,
  data,
  noDataMessage,
  loading,
  topbar,
  actions,
  actionLoading,
}: ListPageProps<T, C>) {
  const classes = useStyles();
  const contentSpaceId = useContentSpaceId();
  const resourceRef = React.useRef<string | null>(null);
  React.useEffect(() => {
    if (resourceRef.current !== resourceName) {
      resourceRef.current = resourceName;
      if (!called) get(contentSpaceId || '');
    }
    // This is intentionally disabled as it created unexpected behaviour
    // when include in the useEffect dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceName, get, called]);

  if (loading) return <Spinner />;

  const tableKey = data?.length;
  return (
    <TopbarLayout
      topbar={<ListPageTopbar title={topbar.title} actions={topbar.createAction} />}
    >
      <div className={classes.pagePadding}>
        <Table
          key={`${resourceRef.current}-list-page-table-${tableKey}`}
          resourceName={resourceName}
          data={data || ([] as any)}
          noDataMessage={noDataMessage}
          loading={actionLoading}
          // Need to fix issues with union types here
          actions={actions || ({} as any)}
        />
      </div>
    </TopbarLayout>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  pagePadding: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(3),
    paddingTop: 0,
    boxSizing: 'border-box',
  },
}));
