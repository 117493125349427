import { useHistory } from 'react-router';
import { useIdInputMutation } from 'domain/api/common/adapters/useIdInputMutation';
import { updateCacheOnDeleteOne } from 'domain/api/common/cache';
import { createFailedDeleteMessage, createSuccessfulDeleteMessage, useSetMessage } from '_lib/messages';
import { DeleteOneAdapterAction } from 'domain/api/common/adapters/types';
import { DELETE_ONE_COMMUNITY } from './gql';

type Response = {
  deleteCommunity: {
    deletedId: string | null | undefined;
  };
};

export const useDeleteOneCommunity = (redirectPath?: string): DeleteOneAdapterAction => {
  const history = useHistory();
  const setMessage = useSetMessage();

  return useIdInputMutation<Response, string | undefined>(
    DELETE_ONE_COMMUNITY,
    (data) => data?.deleteCommunity.deletedId || undefined,
    {
      updateCache: (cache, data) =>
        updateCacheOnDeleteOne(cache, 'communities', data?.deleteCommunity.deletedId),
      onSuccess: () => {
        setMessage(createSuccessfulDeleteMessage(''));
        if (redirectPath) history.push(`/${redirectPath}`);
      },
      onError: (err) => setMessage(createFailedDeleteMessage('', err)),
    }
  );
};
