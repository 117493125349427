import { EntityActions, EntityFields } from 'domain/entities/types';
import { atom, selectorFamily } from 'recoil';
import { Config } from './types';

export const configState = atom<Config | undefined>({
  key: 'ConfigState',
  default: undefined,
});

export const entityFieldsSelectorFamily = selectorFamily<EntityFields, keyof Config['resources'] & string>({
  key: 'EntityFieldsSelectorFamily',
  get: (param) => ({ get }) => {
    const entityConfig = get(configState)?.resources[param];
    if (!entityConfig) return { disabledFields: [], allDisabled: true };

    return {
      allDisabled: entityConfig.fields.allAutomatic,
      disabledFields: entityConfig.fields.automatic || [],
    };
  },
});

export const entityActionsSelectorFamily = selectorFamily<EntityActions, keyof Config['resources'] & string>({
  key: 'EntityActionsSelectorFamily',
  get: (param) => ({ get }) => {
    const entityConfig = get(configState)?.resources[param];

    if (!entityConfig) return { disabledActions: [], allDisabled: true };

    return {
      allDisabled: entityConfig.actions.allAutomatic,
      disabledActions: entityConfig.actions.automatic || [],
    };
  },
});


