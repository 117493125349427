import React from 'react';
import { Typography } from '@material-ui/core';

interface OwnProps {
  value?: number | null;
  prefix?: string;
  suffix?: string;
}

type Props = OwnProps;

export const NumberFieldReadonly = ({ value, prefix, suffix }: Props) => {
  const label = value === null ? 'N/A' : `${prefix ? `${prefix} ` : ''}${value}${suffix ? ` ${suffix}` : ''}` ;
  return (
    <Typography style={{ whiteSpace: 'pre' }}>
      {label}
    </Typography>
  );
};
