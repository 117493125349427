import { useLazyQuery } from '@apollo/client';
import { GetManyAdapterAction } from 'domain/api/common/adapters/types';
import {
  GetManyVariables,
  ResponseWithContentSpace,
  ValidatedEntity,
} from 'domain/api/common/types';
import { validateApiResponseEntities } from 'domain/api/common/validation';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { useContentSpaceId } from '_lib/auth';
import { Embedding } from '../types';
import { filterEmbeddingsByType, transformApiEmbeddingsToEmbeddings } from '../transforms';
import { GET_MANY_EMBEDDINGS } from './gql';

type Response = ResponseWithContentSpace<{ embeddings: Embedding[] }>;
type Variables = GetManyVariables;

export const useGetManyEmbeddings = (
  isVideo?: boolean
): GetManyAdapterAction<ValidatedEntity<Embedding>> => {
  const schemas = useApiSchemas();
  const contentSpaceId = useContentSpaceId();
  const [query, queryResult] = useLazyQuery<Response, Variables>(GET_MANY_EMBEDDINGS);

  const execute = () => {
    contentSpaceId && query({ variables: { contentSpaceId } });
  };

  const validated = schemas?.embedding.apiSchema
    ? validateApiResponseEntities(
      queryResult.data?.contentSpace.embeddings,
      schemas.embedding.apiSchema
    )
    : undefined;

  const transformed = transformApiEmbeddingsToEmbeddings(validated || []);

  const filtered = filterEmbeddingsByType(transformed, isVideo);

  return {
    execute,
    result: filtered,
    loading: queryResult.loading,
    called: queryResult.called,
    error: queryResult.error?.message,
  };
};
