import React from 'react';
import { throttle } from 'lodash';

const SCROLL_HEADSPACE = 25;

export const useSectionScrolling = (containerId: string, sectionIds: string[]) => {
  const [activeId, setActiveId] = React.useState<string | null>(null);
  const container = document.getElementById(containerId);

  React.useEffect(() => {
    if (!activeId && sectionIds.length > 0) setActiveId(sectionIds[0]);
    // Throttling for performance
    const handleScroll = throttle(
      () => {
        sectionIds.forEach(id => {
          const section = document.getElementById(id);
          if (section && container) {
            const sectionOffset = section.offsetTop - container.offsetTop - SCROLL_HEADSPACE;
            if (container.scrollTop >= sectionOffset) setActiveId(id);
          }
        });
      },
      50,
      { leading: false }
    );

    if (container) container.addEventListener('scroll', handleScroll);

    return () => {
      if (container) container.removeEventListener('scroll', handleScroll);
    };
  }, [container, sectionIds, activeId, setActiveId]);

  return {
    activeSection: activeId,
  };
};
