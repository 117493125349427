import React from 'react';
import {
  Grid,
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Community } from 'domain/api/community/types';
import { FormLayout } from '_lib/form/formSection/components/FormLayout';
import { useFormState } from '_lib/multiFormSemaphore';
import { useUiText } from 'domain/uiText';
import { NumberField, SelectField, TextField } from 'lib';
import { DisabledFormOverlay } from '_lib/form/formSection/components/DisabledFormOverlay';
import {
  CommunityPricingMapping,
  ICommunityPricingProps,
} from './types';
import { useCommunityPricingErrors, useCommunityPricingState } from './hooks';

const makeCommunityOptions = (items: Partial<Community>[]) => {
  return items.map((item) => ({ label: item.displayName || '', value: item.id || '' }));
};

export const CommunityPricingForm = ({
  id,
  icon,
  title,
  helpText,
  communities,
  communityPricingMappings,
  startingPrices,
  onSubmit,
  type,
}: ICommunityPricingProps) => {
  const { formId, editing, enabled, openForm, closeForm } = useFormState(id);
  const classes = useStyles();
  const uiText = useUiText();
  const communityOptions = makeCommunityOptions(communities || []);

  const {
    state,
    newItem,
    communityMappings,
    setState,
    makeInitialState,
  } = useCommunityPricingState(type, communityPricingMappings, startingPrices);

  const {
    formError,
    communityFieldError,
    priceFieldError,
  } = useCommunityPricingErrors(state, newItem);

  const handleAddItem = () => {
    setState((prev) => [...prev, newItem]);
  };

  const handleRemoveItem = (index: number) => () =>
    setState((prev) => [...prev.slice(0, index), ...prev.slice(index + 1, prev.length)]);

  function handleChange<TName extends 'id' | 'price'>(index: number) {
    return (name: TName, value: CommunityPricingMapping[TName] | string[] | null) =>
      setState((prev) => {
        const items = [...prev];
        items[index] = { ...items[index], [name]: value };
        return items;
      });
  }

  const handleEdit = () => {
    openForm();
  };

  const handleCancel = () => {
    setState(makeInitialState(communityMappings || []));
    closeForm();
  };

  const handleSubmit = () => {
    onSubmit(state);
    closeForm();
  };

  const renderItem = (item: CommunityPricingMapping, index: number) => {
    return (
      <TableRow key={`community-pricing-item-${index}`}>
        <TableCell variant="body">
          {type === 'communityPricing' && (
            <SelectField
              name="id"
              value={item.id}
              options={communityOptions}
              readonly={!editing}
              onChange={handleChange(index)}
              errors={communityFieldError(item.id)}
              required
            />
          )}
          {type === 'communityStartingPrice' && (
            <TextField
              name="id"
              value={item.id}
              readonly={!editing}
              onChange={handleChange(index)}
              errors={communityFieldError(item.id)}
              required
            />
          )}
        </TableCell>
        <TableCell variant="body">
          <NumberField
            name="price"
            value={item.price}
            prefix="$"
            readonly={!editing}
            onChange={handleChange(index)}
            errors={priceFieldError(item.price)}
            required
          />
        </TableCell>
        {editing && (
          <TableCell variant="body" align="right">
            <IconButton size="small" onClick={handleRemoveItem(index)}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    );
  };

  return (
    <FormLayout
      editing={editing}
      formDisabled={!enabled}
      hasErrors={formError}
      submitDisabled={formError}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSubmit={handleSubmit}
      formId={formId}
      icon={icon}
      title={title}
      helpText={helpText}
      flat
    >
      <Paper elevation={editing ? 0 : undefined}>
        <Table>
          <colgroup>
            <col style={{ width: '40%' }} />
            <col style={{ width: '50%' }} />
            {editing && <col style={{ width: '10%' }} />}
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell variant="head">
                {type === 'communityPricing' ? uiText.COMMUNITY_SINGULAR : uiText.LABEL_LABEL}
              </TableCell>
              <TableCell variant="head">{uiText.LABEL_PRICE}</TableCell>
              {editing && <TableCell variant="head" />}
            </TableRow>
          </TableHead>
          <TableBody>{state.map(renderItem)}</TableBody>
        </Table>
        {state.length <= 0 && (
          <Grid
            className={classes.noResults}
            container={true}
            justify="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item={true}>
              <Typography color="inherit" variant="subtitle1">
                {uiText.NO_COMMUNITY_PRICING}
              </Typography>
            </Grid>
          </Grid>
        )}
        {editing && (
          <Grid
            container={true}
            justify="center"
            alignItems="center"
            spacing={1}
            style={{ padding: 10 }}
          >
            <Button
              className={classes.addButton}
              size="small"
              onClick={handleAddItem}
              color="primary"
              variant="outlined"
              fullWidth={true}
              endIcon={<AddIcon />}
            >
              {uiText.ACTION_ADD_LABEL}
            </Button>
          </Grid>
        )}
      </Paper>
      {!enabled && <DisabledFormOverlay />}
    </FormLayout>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  noResults: {
    width: '100%',
    padding: `${theme.spacing(2)}px 0`,
    color: theme.palette.text.secondary,
  },
  addNew: {
    backgroundColor: theme.palette.grey[200],
  },
  addButton: {
    border: `solid 1px ${theme.palette.grey[200]}`,
  },
}));
