import React from 'react';
import { Community } from 'domain/api/community/types';
import { correctShowHomeAndInventoryContentEntries, generateContentEntrySelectOptions } from 'utilities/helpers';
import { validateEmbeddingUrlWithVendorRegex } from 'system/resources/utilities/fieldModifiers';
import { FloorPlan } from 'domain/api/floorPlan/types';
import { Inventory } from 'domain/api/inventory/types';
import { ShowHome } from 'domain/api/showhome/types';
import { Embedding } from 'domain/api/embedding/types';
import { useUpdateOneEmbedding } from 'domain/api/embedding';
import { EntityDialogActionForm } from 'domain/view/components/form/EntityDialogActionForm';
import { useUiText } from 'domain/uiText';
import { LayoutRenderer } from 'domain/view/components/layout/layoutRenderer/LayoutRenderer';

type Props = {
  floorPlans: FloorPlan[];
  communities: Community[];
  inventories: Inventory[];
  showHomes: ShowHome[];
  embedding: Embedding;
};

export const EditThirdPartyWidgetForm = ({
  embedding,
  floorPlans,
  communities,
  inventories,
  showHomes,
}: Props) => {
  const uiText = useUiText();
  const updateThirdPartyWidget = useUpdateOneEmbedding(embedding);

  /**
   * @todo Delete this when BE is updated to support showHome contentEntries
   */
  const correctedData = correctShowHomeAndInventoryContentEntries(
    showHomes,
    embedding,
  );

  /**
   * @todo Consolidate this functionality
   */
  const communityOptions = generateContentEntrySelectOptions(communities);
  const inventoryOptions = generateContentEntrySelectOptions(inventories);
  const floorPlanOptions = generateContentEntrySelectOptions(floorPlans);
  const showHomeOptions = generateContentEntrySelectOptions(showHomes);

  const handleSubmit = (values: Embedding, close: () => void) => {
    updateThirdPartyWidget.execute({ ...values, id: embedding.id })
      .then(() => close());
  };
  return (
    <EntityDialogActionForm
      resourceName="embedding"
      onSubmit={handleSubmit}
      title={uiText.ACTION_EDIT_THIRD_PARTY_PLUGIN_LABEL}
      action={{
        type: 'edit',
        label: uiText.ACTION_EDIT_LABEL,
        isIcon: true,
        isSmall: true,
      }}
      initialValues={correctedData}
      submitting={updateThirdPartyWidget.loading}
      fieldModifiers={{
        iframeUrl: (fields, errors) =>
          validateEmbeddingUrlWithVendorRegex(fields, errors, embedding.vendor.allowedRegex),
      }}
      includeUntouchedFields={true}
    >
      {(renderField) => (
        <LayoutRenderer
          items={[
            {
              container: true,
              direction: 'column',
              spacing: 2,
              items: [
                {
                  renderContent: () => renderField('displayName'),
                },
                {
                  renderContent: () =>
                    renderField('iframeUrl', {
                      helperText: embedding.vendor.urlHelperText,
                    }),
                },
                {
                  renderContent: () =>
                    renderField('floorPlans', {
                      options: floorPlanOptions.options,
                      additionalAction: {
                        value: floorPlanOptions.publishedIds,
                        label: uiText.LABEL_ALL_PUBLISHED,
                      },
                    }),
                },
                {
                  renderContent: () =>
                    renderField('communities', {
                      options: communityOptions.options,
                      additionalAction: {
                        value: communityOptions.publishedIds,
                        label: uiText.LABEL_ALL_PUBLISHED,
                      },
                    }),
                },
                {
                  renderContent: () =>
                    renderField('inventories', {
                      options: inventoryOptions.options,
                      additionalAction: {
                        value: inventoryOptions.publishedIds,
                        label: uiText.LABEL_ALL_PUBLISHED,
                      },
                    }),
                },
                {
                  renderContent: () =>
                    renderField('showHomes', {
                      options: showHomeOptions.options,
                      additionalAction: {
                        value: showHomeOptions.publishedIds,
                        label: uiText.LABEL_ALL_PUBLISHED,
                      },
                    }),
                },
              ],
            },
          ]}
        />
      )}
    </EntityDialogActionForm>
  );
};
