import * as yup from 'yup';
import { UiText } from 'domain/uiText/uiText';
import { ApiSalesCentre, ApiSalesCentreInput } from './types';
import { apiContactValidationSchema } from '../contact/validation';

const sharedSchemas = (uiText: UiText) => ({
  name: yup.string().defined().required().default('').label('Name'),
  phone: yup.string().defined().default('').label('Phone Number'),
  address: yup.string().defined().default('').label('Address'),
  email: yup.string().defined().default('').label('Email'),
});

export const apiSalesCentreInputValidationSchema = (
  uiText: UiText,
) => yup.object<ApiSalesCentreInput, object>({
  ...sharedSchemas(uiText),
  hours: yup.array().of(yup.string().defined()).defined(),
  community: yup.string().defined(),
}).defined();

export const apiSalesCentreValidationSchema = (
  uiText: UiText,
) => yup.object<ApiSalesCentre, object>({
  id: yup.string().required(),
  ...sharedSchemas(uiText),
  community: yup.object({
    id: yup.string().required(),
  }).required(),
  contacts: yup.array().of(apiContactValidationSchema(uiText)).defined().default([]),
  hours: yup.array().of(yup.string().defined()).defined().default([]),
}).defined();
