import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton, Tooltip } from '@material-ui/core';
import { useBaseUiText } from '_lib/uiText';

interface Props {
  onDelete?: () => void;
  disabled?: boolean;
  tooltip?: string;
  small?: boolean;
}

export const DeleteIconButton = React.memo(({
  disabled,
  tooltip,
  onDelete,
  small,
}: Props) => {
  const uiText = useBaseUiText();
  return (
    <Tooltip title={tooltip || uiText.ACTION_DELETE_LABEL}>
      <div>
        <IconButton
          onClick={onDelete}
          disabled={disabled}
          size={small ? 'small' : undefined}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
});
