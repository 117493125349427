import React from 'react';
import { RegistrationHook } from './registrationState';

export const useBlockerRegistration = () => {
  const [blockers, setBlockers] = React.useState(new Set<symbol>());

  const registerLoadingBlocker = ( blockerKey: symbol ) => {
    setBlockers(oldState => {
      const state = new Set(oldState);
      state.add(blockerKey);
      return state;
    });
  };

  const unregisterLoadingBlocker = ( blockerKey: symbol ) => {
    setBlockers(oldState => {
      const state = new Set(oldState);
      state.delete(blockerKey);
      return state;
    });
  };

  const useRegistration: RegistrationHook = (key: symbol) => {
    const useLoadingBlind = (loading: boolean) => {
      React.useEffect(() => {
        if (loading) {
          registerLoadingBlocker(key);
        } else {
          unregisterLoadingBlocker(key);
        }
      }, [loading]);
    };

    return {
      isRegistered: true,
      useLoadingBlind,
    };
  };

  return {
    isBlocked: !!blockers.size,
    registrationHook: useRegistration,
  };
};
