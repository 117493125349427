import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FormLayout } from '_lib/form/formSection/components/FormLayout';
import { useFormState } from '_lib/multiFormSemaphore';
import { useUiText } from 'domain/uiText';
import { Contact } from 'domain/api/contact';
import { SalesCentre } from 'domain/api/salesCentre';
import { CreateSalesCentreDialog } from './dialogs';
import { CommunitySalesCentre } from './CommunitySalesCentre';

interface Props {
  entityContentId: string;
  contacts: Contact[];
  salesCentres: SalesCentre[];
  id: string;
  title: string;
  icon: JSX.Element;
}

export const CommunitySalesCentresForm = ({
  contacts,
  entityContentId,
  salesCentres = [],
  id,
  title,
  icon,
}: Props) => {
  const uiText = useUiText();
  const { editing, enabled, openForm, closeForm } = useFormState(id);

  const stortedSalesCentres = [...salesCentres].sort((a, b) => {
    const aName = a.name.toLowerCase(); // case-insensitive compare
    const bName = b.name.toLowerCase();
    if (aName > bName) return 1;
    if (aName < bName) return -1;
    return 0;
  });

  const renderSalesCentre = () => {
    return (
      <Grid item={true} container={true} direction="column" spacing={2}>
        {stortedSalesCentres.map((salesCentre) => (
          <CommunitySalesCentre
            key={`community-${entityContentId}-sales-centre-${salesCentre.id}`}
            salesCentre={salesCentre}
            contacts={contacts}
            openForm={openForm}
            closeForm={closeForm}
            entityContentId={entityContentId}
          />
        ))}
        {stortedSalesCentres.length <= 0 && (
          <Grid item={true} container={true} justify="center">
            <Grid item={true}>
              <Typography color="textSecondary">
                {uiText.NO_SALES_CENTRE}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const actions = (
    <React.Fragment>
      <Grid item={true}>
        <CreateSalesCentreDialog entityContentId={entityContentId} />
      </Grid>
    </React.Fragment>
  );

  return (
    <Grid item={true} id={id} style={{ position: 'relative' }}>
      <FormLayout
        editing={editing}
        formDisabled={!enabled}
        editDisabled={!enabled}
        submitDisabled={false}
        title={title}
        formId={id}
        icon={icon}
        flat={true}
        actions={actions}
      >
        {renderSalesCentre()}
      </FormLayout>
    </Grid>
  );
};
