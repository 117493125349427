import {
  createFailedUpdateMessage,
  createSuccessfulUpdateMessage,
  useSetMessage,
} from '_lib/messages';
import { ValidatedEntity, WithClearList } from 'domain/api/common/types';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { useCreateOrUpdateOne } from 'domain/api/common/adapters/useCreateOrUpdateOne';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { generateCreateOrUpdateString } from 'domain/api/common/fragments';
import { transformClearableFieldsToClearList } from 'domain/api/common/transforms';
import {
  ApiFeatureTag,
  ApiFeatureTagInput,
  CLEARABLE_FEATURE_TAG_FIELDS,
  FeatureTag,
} from '../types';
import { FeatureTagInfo } from '../fragments';
import { transformApiFeatureTagToFeatureTag } from '../transforms';

const UPDATE_ONE_FEATURE_TAG = generateCreateOrUpdateString('update', 'featureTag', FeatureTagInfo);

type Response = {
  updateFeatureTag: {
    featureTag: ApiFeatureTag | undefined;
  };
};

type Input<T> = T & {
  id: string;
  contentSpaceId: string;
};

export const useUpdateOneFeatureTag = () => {
  const setMessage = useSetMessage();
  const schemas = useApiSchemas();

  const { execute: executeMutation, ...rest } = useCreateOrUpdateOne<
    WithClearList<ApiFeatureTagInput, Input<ApiFeatureTagInput>>,
    Response,
    ValidatedEntity<FeatureTag> | undefined
  >(
    UPDATE_ONE_FEATURE_TAG,
    (data) =>
      schemas?.featureTag.apiSchema
        ? transformApiFeatureTagToFeatureTag(
            validateApiResponseEntity(
              data?.updateFeatureTag.featureTag,
              schemas.featureTag.apiSchema
            )
          )
        : undefined,
    {
      onSuccess: () => setMessage(createSuccessfulUpdateMessage('')),
      onError: (err) => setMessage(createFailedUpdateMessage('', err)),
    }
  );

  const execute = ({
    id,
    contentSpaceId,
    ...restData
  }: Input<ApiFeatureTagInput> & { id: string }) => {
    executeMutation({
      id,
      contentSpaceId,
      ...restData,
      clear: transformClearableFieldsToClearList(restData, CLEARABLE_FEATURE_TAG_FIELDS),
    });
  };

  return { execute, ...rest };
};
