import { createFailedUpdateMessage, createSuccessfulUpdateMessage, useSetMessage } from '_lib/messages';
import { ValidatedEntity, WithClearList } from 'domain/api/common/types';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { useCreateOrUpdateOne } from 'domain/api/common/adapters/useCreateOrUpdateOne';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { generateCreateOrUpdateString } from 'domain/api/common/fragments';
import { transformClearableFieldsToClearList } from 'domain/api/common/transforms';
import { ApiContact, ApiContactInput, CLEARABLE_CONTACT_FIELDS, Contact } from '../types';
import { transformApiContactToContact, transformContactToApiContactInput } from '../transforms';
import { AllContactInfo } from '../fragments';

const UPDATE_ONE_CONTACT = generateCreateOrUpdateString('update', 'contact', AllContactInfo);

type Response = {
  updateContact: {
    contact: ApiContact | undefined;
  }
};

type Input<T> = T & {
  id: string;
};

export const useUpdateOneContact = () => {
  const setMessage = useSetMessage();
  const schemas = useApiSchemas();

  const { execute: executeMutation, ...rest } = useCreateOrUpdateOne<
  WithClearList<ApiContactInput, Input<ApiContactInput>>,
  Response,
  ValidatedEntity<Contact> | undefined
  >(
    UPDATE_ONE_CONTACT,
    (data) => schemas?.contact.apiSchema ? transformApiContactToContact(
      validateApiResponseEntity(
        data?.updateContact.contact,
        schemas.contact.apiSchema,
      ),
    ) : undefined,
    {
      onSuccess: () => setMessage(createSuccessfulUpdateMessage('')),
      onError: (err) => setMessage(createFailedUpdateMessage('', err)),
    },
  );

  const execute = ({ id, ...restData }: Input<Partial<Contact>> & { id: string }) => {
    const transformed = transformContactToApiContactInput(restData);
    executeMutation({
      id,
      ...transformed,
      clear: transformClearableFieldsToClearList(transformed, CLEARABLE_CONTACT_FIELDS),
    });
  };

  return { execute, ...rest };
};
