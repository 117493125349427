import React from 'react';
import { ResourcesContextValue } from 'system/resources/types';
import { SystemContext } from './SystemContext';

export const useSystemContext = () => React.useContext(SystemContext);
export const useResources = () => useSystemContext().resources;
export const useContentSpaceId = () => useSystemContext().contentSpaceId;

/**
 * @param resource The name of the resource(example: floorPlan)
 */
export function useResource<TResourceName extends keyof ResourcesContextValue>(
  resource: TResourceName
) {
  const { contentSpaceId, resources } = useSystemContext();
  return {
    contentSpaceId,
    ...resources[resource],
  };
}
