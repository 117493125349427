import {
  createFailedDeleteMessage,
  createSuccessfulDeleteMessage,
  useSetMessage,
} from '_lib/messages';
import { useIdInputMutation } from 'domain/api/common/adapters/useIdInputMutation';
import { updateCacheOnDeleteOne } from 'domain/api/common/cache';
import { DeleteOneAdapterAction } from 'domain/api/common/adapters/types';
import { DELETE_ONE_SALES_CENTRE } from './gql';

type Response = {
  deleteSalesCentre: {
    deletedId: string | null | undefined;
  };
};

export const useDeleteOneSalesCentre = (communityId: string): DeleteOneAdapterAction => {
  const setMessage = useSetMessage();

  return useIdInputMutation<Response, string | undefined>(
    DELETE_ONE_SALES_CENTRE,
    (data) => data?.deleteSalesCentre.deletedId || undefined,
    {
      updateCache: (cache, data) => {
        const deletedId = data?.deleteSalesCentre.deletedId;
        updateCacheOnDeleteOne(cache, 'salesCentres', deletedId);
        cache.modify({
          id: `Community:${communityId}`,
          fields: {
            salesCentres(existing = [], { readField }) {
              return existing.filter((ref: any) => readField('id', ref) !== deletedId);
            }
          }
        });
      },
      onSuccess: () => {
        setMessage(createSuccessfulDeleteMessage(''));
      },
      onError: (err) => setMessage(createFailedDeleteMessage('', err)),
      omitContentSpaceId: true,
    }
  );
};
