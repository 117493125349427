import React from 'react';
import { useDeleteOneAsset } from 'domain/api/asset';
import { useContentSpaceId } from '_lib/auth';
import { AssetFormItem } from '../../components/AssetFormItem';
import { useAssetFormContext } from '../../AssetFormContext';

type Props = {
  index: number;
  url: string;
  id: string;
  startUpdate?: boolean;
  selected: boolean;
  onSelect: () => void;
  onSuccess: () => void;
  onError: () => void;
  failed?: boolean;
  displayName?: string;
  showCheckeredBackground?: boolean;
};

export const AssetFormDeletingItem = (props: Props) => {
  const contentSpaceId = useContentSpaceId();
  const [called, setCalled] = React.useState(false);
  const { entity, isMultiple, isPdf } = useAssetFormContext();
  const [deleteAssetMutation, result] = useDeleteOneAsset(entity);


  React.useEffect(() => {
    if (props.startUpdate && !called && !result.loading && contentSpaceId) {
      setCalled(true);
      deleteAssetMutation({
        variables: {
          input: {
            contentSpaceId,
            id: props.id,
          },
        },
      })
        .then(res => {
          setCalled(false);
          if (!res.errors && res.data) {
            props.onSuccess();
          } else {
            props.onError();
          }
        })
        .catch(() => {
          props.onError();
          setCalled(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.startUpdate]);

  return (
    <React.Fragment>
      <AssetFormItem
        index={props.index}
        url={props.url}
        id={props.id}
        isReadonly={false}
        selected={!isMultiple ? undefined : props.selected}
        onSelect={!isMultiple ? undefined : props.onSelect}
        loading={result.loading}
        isPdf={isPdf}
        displayName={props.displayName}
        error={props.failed ? 'Failed to delete' : undefined}
        showCheckeredBackground={props.showCheckeredBackground}
      />
    </React.Fragment>
  );
};
