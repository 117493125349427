import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useMultiWindowLogout, useMultiWindowLogoutEffect } from './useMultiWindowAuth';

type Props = {
  children: React.ReactNode;
};

export const AuthGate = ({ children }: Props) => {
  const auth0 = useAuth0();
  useMultiWindowLogoutEffect(auth0.logout);
  const { setMultiWindowAuthKey, triggerMultiWindowLogout } = useMultiWindowLogout();

  React.useEffect(() => {
    if (!auth0.isLoading) {
      if (auth0.isAuthenticated) {
        setMultiWindowAuthKey();
      } else {
        auth0.getAccessTokenSilently()
          .then(setMultiWindowAuthKey)
          .catch(e => {
            if (e.error === 'login_required') {
              auth0.loginWithRedirect();
            } else if (e.error === 'invalid_grant') {
              triggerMultiWindowLogout();
              auth0.logout();
            } else {
              throw new Error(`An error occured during authentication: ${e.error}`);
            }
          });
      } 
    }
  }, [auth0, setMultiWindowAuthKey, triggerMultiWindowLogout]);

  if (auth0.error) throw new Error(`An error occured during authentication: ${auth0.error}`);

  if (auth0.isLoading || !auth0.user) {
    return null;
  }
  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};
