import { compact } from 'lodash';
import { ApiFeatureTag, ApiFeatureTagInput, FeatureTag } from './types';

export const transformApiFeatureTagToFeatureTag = (
  data?: ApiFeatureTag
): FeatureTag | undefined => data ? ({
  id: data.id,
  name: data.name,
  description: data.description,
  showFloorPlanViewer: data.showFloorPlanViewer,
  category: data.featureTagCategory,
  featureTagCategory: data.featureTagCategory.displayName,
}) : undefined;

export const transformApiFeatureTagsToFeatureTags = (
  data?: ApiFeatureTag[],
  ) => data ? compact(data.map(el => transformApiFeatureTagToFeatureTag(el))) : [];

export const transformFeatureTagToApiFeatureTagInput = (
  data: Partial<FeatureTag> & { featureTagCategory: string; id: string }
): ApiFeatureTagInput & { id: string } => {
  const { featureTagCategory, ...rest } = data;
  return {
    ...rest,
    featureTagCategoryId: featureTagCategory,
  };
};
