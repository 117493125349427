import { gql } from '@apollo/client';
import { generateCreateOrUpdateString } from 'domain/api/common/fragments';
import { AllImageLinkInfo } from '../fragments';

export const CREATE_ONE_IMAGE_LINK = generateCreateOrUpdateString('create', 'imageLink', AllImageLinkInfo);

export const NEW_IMAGE_LINK_FRAGMENT = gql`
  fragment NewAsset on Asset {
    id
    url
    displayOrder
    description
    attributeName
  }
`;
