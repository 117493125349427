import React from 'react';
import {
  Grid,
  Typography,
  Button,
  makeStyles,
  createStyles,
  Theme,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import { Link } from 'react-router-dom';

type Props = {
  resource: Array<{ published: boolean }>;
  title: string;
  icon: JSX.Element;
  description: string;
  link: string;
};

export const LandingPageItem = (props: Props) => {
  const { resource, title, icon, description, link } = props;
  const published = resource.filter((r) => r.published).length;
  const draft = resource.length - resource.filter((r) => r.published).length;
  const classes = useStyles();

  return (
    <Grid item xs={3}>
      <Card className={classes.card} elevation={2}>
        <CardHeader
          className={classes.cardHeader}
          title={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {icon}
              <Typography align="left" variant="h6" style={{ margin: '0 0 0 10px' }}>
                {title}
              </Typography>
            </span>
          }
        />
        <CardContent className={classes.cardContent}>
          <Typography variant="body2" color="textSecondary" component="p" align="left">
            {description}
          </Typography>
          <Typography align="left" style={{ margin: '10px 0 0 0' }}>
            Draft: {draft}
          </Typography>
          <Typography align="left" style={{ margin: 0 }}>
            Published: {published}
          </Typography>
          <Link to={link} style={{ textDecoration: 'none', alignSelf: 'flex-start' }}>
            <Button variant="outlined" endIcon={<ArrowIcon />} className={classes.linkButton}>
              Go to {title}
            </Button>
          </Link>
        </CardContent>
      </Card>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    cardHeader: {
      padding: 0,
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    linkButton: {
      marginTop: 10,
      fontWeight: 500,
      textTransform: 'capitalize',
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      '&:disabled': {
        backgroundColor: theme.palette.grey[300],
        borderColor: theme.palette.grey[500],
        color: theme.palette.grey[500],
      },
    },
  })
);
