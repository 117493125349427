import { ExternalResourceConfig, InternalResourceConfig, makeResource } from 'lib/resource';
import {
  defaultVendor, defaultVendorFields,
  Vendor, VendorFields, vendorFields,
} from './types';
import { defaultExternalResourceConfig } from '../shared';
import { vendorFieldsSchema } from './schemas';

export const vendorInternalResourceConfig: InternalResourceConfig<
Vendor,
VendorFields
> = {
  enabledPages: [],
  defaultResource: defaultVendor,
  defaultResourceFields: defaultVendorFields,
  fields: vendorFields,
  fieldsValidationSchema: vendorFieldsSchema,
  dataDisplay: {
    fields: {},
  },
};

export const defaultVendorExternalResourceConfig: ExternalResourceConfig = {
  ...defaultExternalResourceConfig,
  aliases: {
    path: 'vendors',
    plural: 'Vendors',
    singular: 'Vendor',
  },
};

export const defaultVendorResource = makeResource({
  internalConfig: vendorInternalResourceConfig,
  externalConfig: defaultVendorExternalResourceConfig,
});
