import React from 'react';
import { FormDialog } from '_lib/form/formDialog/FormDialog';
import { useResourceFields } from 'system/resources/hooks/useResourceFields';
import { Contact, useUpdateOneContact } from 'domain/api/contact';
import { useToggle } from 'common/hooks/useToggle';
import { useUiText } from 'domain/uiText';
import { EditIconButton } from '_lib/components';
import { useResetableAdapterAction } from 'domain/api/common/hooks';

type Props = {
  contact: Contact;
  salesCentreId: string;
};

export const EditContactDialog = ({ contact }: Props) => {
  const uiText = useUiText();
  const dialogState = useToggle();
  const updateContact = useResetableAdapterAction(useUpdateOneContact);

  const form = useResourceFields('contact', {
    hasReadonly: false,
    initialValues: contact,
  });

  const handleSubmit = () => {
    form.onSubmit(
      () => {},
      (values) => updateContact.execute({ ...values, id: contact.id })
    );
  };
  React.useEffect(() => {
    if (updateContact.called && !updateContact.loading) {
      dialogState.onClose();
    }
  }, [updateContact, dialogState]);
  return (
    <React.Fragment>
      <EditIconButton small onEdit={dialogState.onOpen} />
      <FormDialog
        title={uiText.ACTION_EDIT_CONTACT_LABEL}
        open={dialogState.open}
        submitDisabled={!form.canSubmit}
        onClose={dialogState.onClose}
        onSubmit={handleSubmit}
        submitting={updateContact.loading}
      >
        {form.renderField('name')}
        {form.renderField('title')}
        {form.renderField('phone')}
        {form.renderField('email')}
      </FormDialog>
    </React.Fragment>
  );
};
