import React from 'react';
import _ from 'lodash';
import { Button, Grid, makeStyles, Typography, Theme, useTheme } from '@material-ui/core';
import Select, { ValueType } from 'react-select';
import { isKeyOf } from '_lib/common/helpers';

import { SelectFieldControlProps, SelectOption } from './types';
import { FieldErrors } from '../shared/FieldErrors/FieldErrors';

const isArray = (value?: any): value is unknown[] => {
  return !!value && Array.isArray(value);
};

const isSelectOption = (option: any): option is SelectOption => {
  return typeof option === 'object' && isKeyOf(option, 'label') && isKeyOf(option, 'value');
};

export function SelectFieldControl<TName extends string>({
  required,
  value,
  name,
  label,
  disabled,
  errors,
  onChange,
  options = [],
  isMulti,
  additionalAction,
  helperText,
  isFullWidth,
}: SelectFieldControlProps<TName>) {
  const classes = useStyles();
  const theme = useTheme();

  const handleChange = (_value: ValueType<SelectOption, boolean>) => {
    if (isArray(value) && _value === null) {
      onChange(name, []);
    } else {
      const newValue = isArray(_value)
        ? _.compact(_value.map((el) => isSelectOption(el) && el.value))
        : _value && isSelectOption(_value) && _value.value;

      onChange(name, newValue || null);
    }
  };

  const selectedOptions = options.filter((option) => {
    if (isArray(value)) {
      return value.includes(option.value);
    }
    return option.value === value;
  });

  const renderAdditionaAction = () => {
    if (additionalAction?.value) {
      const actionValue = additionalAction?.value;
      const actionLabel = additionalAction?.label;

      const isDisabled =
        (typeof actionValue === 'string' && actionValue.length <= 0) ||
        (Array.isArray(actionValue) && actionValue.length <= 0);
      return (
        <Grid item={true}>
          <Button
            disabled={isDisabled}
            onClick={() => onChange(name, additionalAction?.value)}
            variant="outlined"
            size="small"
          >
            {actionLabel}
            {Array.isArray(actionValue) && <span>({actionValue.length})</span>}
          </Button>
        </Grid>
      );
    }
    return null;
  };
  return (
    <React.Fragment>
      {label && (
        <Typography variant="caption" style={{ fontStyle: 'normal', fontWeight: 'normal' }}>
          {label}{required && ' *'}
        </Typography>
      )}
      <Grid container={true} wrap="nowrap" alignItems="center" spacing={2}>
        <Grid item={true} style={{ flexGrow: 2 }}>
          <Select
            className={classes.selectInputError}
            isMulti={isMulti}
            onChange={handleChange}
            options={options.sort()}
            value={selectedOptions}
            isDisabled={disabled}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            menuPlacement="bottom"
            isClearable={!required}
            styles={{
              container: (styles) => {
                return {
                  ...styles,
                  maxWidth: !isFullWidth ? 400 : undefined,
                };
              },
              control: (styles) => {
                return {
                  ...styles,
                  border: !errors || (errors && errors.length <= 0) ? '' : `2px solid ${theme.palette.error.main}`,
                };
              },
              menuPortal: (base) => ({ ...base, zIndex: 99999 }),
              valueContainer: (styles) => {
                return {
                  ...styles,
                  maxHeight: 96,
                  overflow: 'auto',
                };
              },
            }}
          />
          {helperText && <Typography variant="caption">{_.capitalize(helperText)}</Typography>}
          {errors && (
            <Typography className={classes.error} variant="caption">
              <FieldErrors errors={errors} />
            </Typography>
          )}
        </Grid>
        {renderAdditionaAction()}
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    color: theme.palette.error.main,
  },
  selectInputError: {
    borderColor: theme.palette.error.main,
  },
}));
