import React from 'react';
import * as yup from 'yup';
import { FormControlLabel, GridProps, Switch } from '@material-ui/core';
import { LayoutRenderer } from 'lib/layout/LayoutRenderer';
import { useFieldRenderer } from 'lib';
import { LayoutItem } from 'lib/layout/types';
import { FormLayout } from '_lib/form/formSection/components/FormLayout';
import { useFormState } from '_lib/multiFormSemaphore';
import { Map } from './Map';

type Location = {
  lat: number | null;
  lng: number | null;
};

interface Props {
  location: Location;
  onSubmit: (values: Location) => void;
  id: string;
  title: string;
  icon: JSX.Element;
}

const regex = new RegExp(/@(?<lat>-?[\d.]*),(?<lng>-?[\d.]*)/);

const parseGoogleMapsUrl = (str: string): Location => {
  const match = str.match(regex);
  if (match && match.length > 2) {
    return {
      lat: parseFloat(match[1]) || null,
      lng: parseFloat(match[2]) || null,
    };
  }
  return {
    lat: null,
    lng: null,
  };
};

const validation = yup.object({
  lat: yup.number().max(90).min(-90).defined().default(null).label('Latitude'),
  lng: yup.number().max(180).min(-180).defined().default(null).label('Longitude'),
  url: yup.string().defined().default(''),
});

const getLatLngError = (value: number | null, type: 'lat' | 'lng') => {
  const hasError = value === null && value !== null ;
  switch (type) {
    case 'lat':
      return hasError ? ['Latitude must be set'] : undefined;
    case 'lng':
    default:
      return hasError ? ['Longitude must be set'] : undefined;
  }
};

export const LocationForm = ({ location, onSubmit, id, title, icon }: Props) => {
  const form = useFieldRenderer({
    fields: {
      lat: { type: 'number', value: location.lat },
      lng: { type: 'number', value: location.lng },
      url: { type: 'text', value: '' },
    },
    validation,
    fieldModifiers: {
      lat: (fields, errors) => {
        const latError = getLatLngError(fields.lat.value, 'lat') || errors.lat;
        const lngError = getLatLngError(fields.lng.value, 'lng') || errors.lng;
        return {
          fields,
          errors: {
            ...errors,
            lat: latError,
            lng: !latError && !!lngError ? undefined : lngError,
          },
        };
      },
      lng: (fields, errors) => {
        const latError = getLatLngError(fields.lat.value, 'lat') || errors.lat;
        const lngError = getLatLngError(fields.lng.value, 'lng') || errors.lng;
        return {
          fields,
          errors: {
            ...errors,
            lat: !lngError && !!latError ? undefined : latError,
            lng: lngError,
          },
        };
      },
      url: (fields, errors) => {
        const parsedLocation = parseGoogleMapsUrl(fields.url.value);
        return {
          fields: {
            ...fields,
            lat: {
              ...fields.lat,
              value: parsedLocation.lat,
            },
            lng: {
              ...fields.lng,
              value: parsedLocation.lng,
            },
          },
        };
      },
    },
    includeUntouchedFields: true,
  });

  const [generateLatLon, setGenerateLatLon] = React.useState<boolean>(false);
  React.useEffect(() => {
    setGenerateLatLon(false)
  }, [form.isEditing])

  const { editing, enabled, openForm, closeForm } = useFormState(id);

  const handleEdit = () => {
    form.onEdit();
    openForm();
  };

  const handleCancel = () => {
    form.reset();
    closeForm();
  };

  const handleSubmit = () => {
    form.onSubmit(
      () => {},
      (values) => {
        onSubmit({ lat: values.lat, lng: values.lng });
        closeForm();
      }
    );
  };

  const handleGenerateLatLon = () => {
    setGenerateLatLon(!generateLatLon);
  };

  const editDisabled = !enabled;

  const fieldLayout: Partial<GridProps> = form.isEditing
    ? { xl: 4, lg: 4, md: 6, sm: 6, xs: 12 }
    : { xl: 6, lg: 6, md: 6, sm: 6, xs: 12 };

  const fields: LayoutItem[] = [
    {
      item: true,
      ...fieldLayout,
      renderContent: () =>
        form.renderField('lat', {
          label: 'Latitude',
          readonly: !form.isEditing || generateLatLon,
          nullable: true,
        }),
    },
    {
      item: true,
      ...fieldLayout,
      renderContent: () =>
        form.renderField('lng', {
          label: 'Longitude',
          readonly: !form.isEditing || generateLatLon,
          nullable: true,
        }),
    },
  ];

  if (form.isEditing) {
    fields.push({
      item: true,
      ...fieldLayout,
      renderContent: () => (
        <FormControlLabel
          style={{ marginTop: 20 }}
          control={<Switch size="medium" onChange={handleGenerateLatLon} />}
          label="Generate from Google Maps URL"
          labelPlacement="end"
        />
      ),
    });
  }

  const urlField: LayoutItem[] =
    form.isEditing && generateLatLon
      ? [
        {
          item: true,
          ...fieldLayout,
          renderContent: () =>
            form.renderField('url', {
              label: 'Google Maps URL',
              readonly: !form.isEditing,
              helperText: 'Example: https://www.google.ca/maps/@<latitude>,<longitude>',
            }),
        },
      ]
      : [];

  return (
    <FormLayout
      editing={editing}
      editDisabled={editDisabled}
      formDisabled={editDisabled}
      submitDisabled={form.hasErrors}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSubmit={handleSubmit}
      icon={icon}
      title={title}
      formId={id}
      hasErrors={form.hasErrors}
    >
      <LayoutRenderer
        items={[
          {
            container: true,
            direction: 'column',
            spacing: 2,
            items: [
              {
                container: true,
                item: true,
                spacing: 2,
                items: fields,
              },
              {
                container: true,
                item: true,
                spacing: 1,
                items: urlField,
              },
              {
                container: true,
                item: true,
                spacing: 2,
                items: [
                  {
                    renderContent: () => (
                      <Map
                        lat={form.fields.lat.value}
                        lng={form.fields.lng.value}
                        disableInteractions={!form.isEditing}
                      />
                    ),
                  },
                ],
              },
            ],
          },
        ]}
      />
    </FormLayout>
  );
};
