import { gql, useMutation } from '@apollo/client';
import { createFailedUpdateMessage, createSuccessfulUpdateMessage, useSetMessage } from '_lib/messages';
import { apolloClient } from 'domain/api';
import { SalesCentre } from 'system/resources/salesCentre';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { AllSalesCentreInfo } from '../fragments';
import { ADD_SALES_CENTRE_CONTACTS } from './gql';

type Input = {
  input: {
    salesCentreId: string;
    contacts: string[];
  };
};

type Response = {
  addSalesCentreContacts: {
    salesCentre: SalesCentre;
  }
};

export const useAddContactsToSalesCentre = () => {
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();
  const [mutation, mutationResult] = useMutation<Response, Input>(
    ADD_SALES_CENTRE_CONTACTS,
  );

  const execute = ({ id, input }: { id: string, input: string[] }) => {
    mutation({
      variables: {
        input: {
          salesCentreId: id,
          contacts: input,
        },
      },
    })
      .then(res => {
        const newSalesCentre = res.data?.addSalesCentreContacts?.salesCentre;
        if (newSalesCentre) {
          const community = apolloClient.readFragment({
            id: `Community:${newSalesCentre.community.id}`,
            fragment: gql`
              fragment CommunitySalesCentres on Community {
                id
                salesCentres {
                  ${AllSalesCentreInfo}
                }
              }
            `,
          });
          apolloClient.cache.writeFragment({
            id: `Community:${newSalesCentre.community.id}`,
            fragment: gql`
              fragment UpdatedSalesCentre on Community {
                id
                salesCentres {
                  ${AllSalesCentreInfo}
                }
              }
            `,
            data: {
              __typename: 'Community',
              salesCentres: [
                ...community.salesCentres.filter((el: any) => el.id !== newSalesCentre.id),
                newSalesCentre,
              ],
            },
          });
          setMessage(createSuccessfulUpdateMessage(''));
        }
      })
      .catch(err => setMessage(createFailedUpdateMessage('', err)));
  };

  const validated = schemas?.salesCentre.apiSchema ? validateApiResponseEntity(
    mutationResult.data?.addSalesCentreContacts?.salesCentre,
    schemas.salesCentre.apiSchema,
  ) : undefined;

  return {
    execute,
    result: validated,
    loading: mutationResult.loading,
    called: mutationResult.called,
    error: mutationResult.error?.message,
  };
};
