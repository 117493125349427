import React from 'react';
import { LayoutRenderer } from 'lib';
import { Community } from 'domain/api/community/types';
import { Vendor } from 'system/resources/vendor';
import { generateContentEntrySelectOptions } from 'utilities/helpers';
import { validateEmbeddingUrlWithVendorRegex } from 'system/resources/utilities/fieldModifiers';
import { FloorPlan } from 'domain/api/floorPlan/types';
import { Inventory } from 'domain/api/inventory/types';
import { ShowHome } from 'domain/api/showhome/types';
import { EmbeddingInput } from 'domain/api/embedding/types';
import { EntityDialogActionForm } from 'domain/view/components/form/EntityDialogActionForm';
import { useUiText } from 'domain/uiText';

type Props = {
  onSubmit: (values: EmbeddingInput) => void;
  submitting: boolean;
  submitted: boolean;
  vendors: Vendor[];
  floorPlans: FloorPlan[];
  communities: Community[];
  inventories: Inventory[];
  showHomes: ShowHome[];
};

export const CreateVideoForm = ({
  vendors,
  floorPlans,
  communities,
  inventories,
  showHomes,
  onSubmit,
  submitting,
  submitted,
}: Props) => {
  const uiText = useUiText();
  /**
   * @todo Consolidate this functionality
   */
  const communityOptions = generateContentEntrySelectOptions(communities);
  const inventoryOptions = generateContentEntrySelectOptions(inventories);
  const floorPlanOptions = generateContentEntrySelectOptions(floorPlans);
  const showHomeOptions = generateContentEntrySelectOptions(showHomes);

  return (
    <EntityDialogActionForm
      resourceName="video"
      onSubmit={onSubmit}
      title={uiText.ACTION_NEW_VIDEO_LABEL}
      action={{
        type: 'create',
        label: uiText.ACTION_NEW_VIDEO_LABEL,
      }}
      submitted={submitted}
      submitting={submitting}
      fieldModifiers={{
        iframeUrl: (fields, errors) =>
          validateEmbeddingUrlWithVendorRegex(
            fields,
            errors,
            vendors.find((el) => el.id === fields.vendorId.value)?.allowedRegex
          ),
      }}
      includeUntouchedFields
    >
      {(renderField, fields) => {
        return (
          <LayoutRenderer
            items={[
              {
                container: true,
                direction: 'column',
                spacing: 2,
                items: [
                  {
                    item: true,
                    renderContent: () => renderField('displayName'),
                  },
                  {
                    item: true,
                    renderContent: () =>
                      renderField('vendorId', {
                        options: vendors.map((vendor) => ({
                          label: vendor.displayName,
                          value: vendor.id,
                        })),
                      }),
                  },
                  {
                    item: true,
                    renderContent: () =>
                      renderField('iframeUrl', {
                        helperText:
                          vendors.find((vendor) => vendor.id === fields.vendorId.value.toString())
                            ?.urlHelperText || '',
                      }),
                  },
                  {
                    item: true,
                    renderContent: () =>
                      renderField('floorPlans', {
                        options: floorPlanOptions.options,
                        additionalAction: {
                          value: floorPlanOptions.publishedIds,
                          label: uiText.LABEL_ALL_PUBLISHED,
                        },
                      }),
                  },
                  {
                    item: true,
                    renderContent: () =>
                      renderField('communities', {
                        options: communityOptions.options,
                        additionalAction: {
                          value: communityOptions.publishedIds,
                          label: uiText.LABEL_ALL_PUBLISHED,
                        },
                      }),
                  },
                  {
                    item: true,
                    renderContent: () =>
                      renderField('inventories', {
                        options: inventoryOptions.options,
                        additionalAction: {
                          value: inventoryOptions.publishedIds,
                          label: uiText.LABEL_ALL_PUBLISHED,
                        },
                      }),
                  },
                  {
                    item: true,
                    renderContent: () =>
                      renderField('showHomes', {
                        options: showHomeOptions.options,
                        additionalAction: {
                          value: showHomeOptions.publishedIds,
                          label: uiText.LABEL_ALL_PUBLISHED,
                        },
                      }),
                  },
                ],
              },
            ]}
          />
        );
      }}
    </EntityDialogActionForm>
  );
};
