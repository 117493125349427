import { AllAssetInfo } from '../asset/fragments';
import { PublishProblemsFragment } from '../common/fragments';

export const AllImageLinkInfo = `
  id
  published
  displayName
  url
  isLarge
  ${PublishProblemsFragment}
  contentEntries {
    id
    builderId
    published
  }
  assets {
    ${AllAssetInfo}
  }
`;
