import { useUiText } from 'domain/uiText';
import React from 'react';
import { StartingPrice } from 'system/resources/community';
import {
  CommunityPricingMapping,
  defaultCommunityPricingItem,
  ICommunityPricingState,
} from './types';

export const useCommunityPricingErrors = (
  communityPricing: CommunityPricingMapping[],
  newItem: CommunityPricingMapping
) => {
  const [formError, setFormError] = React.useState(false);
  const uiText = useUiText();

  React.useEffect(() => {
    const error =
      communityPricing &&
      communityPricing
        .map((c) => {
          if (!c.id) return true;
          if (!c.price && c.price !== 0) return true;
          return false;
        })
        .some((el) => el);
    setFormError(error);
  }, [communityPricing]);

  const communityFieldErrorMessage = [`${uiText.COMMUNITY_SINGULAR} is required`];
  const priceFieldErrorMessage = [`${uiText.LABEL_PRICE} is required`];

  const communityFieldError = (id: string) => (!id ? communityFieldErrorMessage : undefined);
  const priceFieldError = (price: number | null) =>
    !price && price !== 0 ? priceFieldErrorMessage : undefined;

  return {
    formError,
    communityFieldError,
    priceFieldError,
  };
};

export const useCommunityPricingState = (
  type: 'communityPricing' | 'communityStartingPrice' | undefined,
  communityPricingMappings?: CommunityPricingMapping[],
  startingPrices?: StartingPrice[]
) => {
  const convertToCommunityMappings = (items: StartingPrice[]): CommunityPricingMapping[] => {
    return items.map((item: any) => ({
      id: item.name ? item.name : '',
      displayName: item.name,
      price: item.price,
    }));
  };

  const communityMappings =
    type === 'communityPricing'
      ? communityPricingMappings
      : convertToCommunityMappings(startingPrices || []);

  const makeInitialState = (items: CommunityPricingMapping[]): CommunityPricingMapping[] => {
    return [...items]
      .sort((a, b) => {
        const aName = a.displayName.toLowerCase(); // case-insensitive compare
        const bName = b.displayName.toLowerCase();
        if (aName > bName) return 1;
        if (aName < bName) return -1;
        return 0;
      })
      .map((item) => ({
        id: item.id ? item.id : '',
        displayName: item.displayName,
        price: item.price,
      }));
  };

  const [state, setState] = React.useState<ICommunityPricingState>(
    makeInitialState(communityMappings || [])
  );
  const [newItem, setNewItem] = React.useState(defaultCommunityPricingItem);

  return {
    state,
    newItem,
    communityMappings,
    setState,
    setNewItem,
    makeInitialState,
  };
};
