import React from 'react';
import { LayoutRenderer } from 'lib';
import { Button } from '@material-ui/core';
import { Vendor } from 'system/resources/vendor';
import { validateEmbeddingUrlWithVendorRegex } from 'system/resources/utilities/fieldModifiers';
import { ResourcesContextValue } from 'system/resources/types';
import { EmbeddingInput } from 'domain/api/embedding/types';
import { useUiText } from 'domain/uiText';
import { EntityDialogActionForm } from '../EntityDialogActionForm';

type Props = {
  onSubmit: (values: EmbeddingInput) => void;
  submitting: boolean;
  submitted: boolean;
  disabled: boolean;
  vendors: Vendor[];
  resourceName: Extract<
  keyof Pick<ResourcesContextValue, 'embedding' | 'video'>, string
  >;
};

export const CreateEmbeddingMappingForm = ({
  onSubmit,
  submitted,
  submitting,
  disabled,
  vendors,
  resourceName,
}: Props) => {
  const uiText = useUiText();
  const newLabel = resourceName === 'embedding'
    ? uiText.ACTION_NEW_THIRD_PARTY_PLUGIN_LABEL
    : uiText.ACTION_NEW_VIDEO_LABEL;

  return (
    <EntityDialogActionForm
      resourceName="embedding"
      onSubmit={onSubmit}
      title={newLabel}
      action={(onClick) => (
        <Button
          size="small"
          variant="outlined"
          style={{ textTransform: 'capitalize', marginRight: 8 }}
          onClick={onClick}
          disabled={disabled}
        >
          {newLabel}
        </Button>
      )}
      submitting={submitting}
      submitted={submitted}
      fieldModifiers={{
        iframeUrl: (fields, errors) => validateEmbeddingUrlWithVendorRegex(
          fields,
          errors,
          vendors.find(el => el.id === fields.vendorId.value)?.allowedRegex,
        ),
      }}
      includeUntouchedFields
    >
      {(renderField, fields) => (
        <LayoutRenderer
          items={[
            {
              container: true, direction: 'column', spacing: 2,
              items: [
                {
                  renderContent: () => renderField('displayName'),
                },
                {
                  item: true,
                  renderContent: () =>
                    renderField('vendorId', {
                      options: vendors.map((vendor) => ({
                        label: vendor.displayName,
                        value: vendor.id,
                      })),
                    }),
                },
                {
                  item: true,
                  renderContent: () =>
                    renderField('iframeUrl', {
                      helperText: vendors.find(
                        (vendor) => vendor.id === fields.vendorId.value.toString()
                      )?.urlHelperText || ''
                    }),
                },
              ],
            },
          ]}
        />
      )}
    </EntityDialogActionForm>
  );
};
