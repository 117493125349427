import { defaultUiText, UiText } from 'domain/uiText';
import { ExternalResourceConfig, InternalResourceConfig, makeResource } from 'lib/resource';
import {
  defaultContact,
  defaultContactFields,
  Contact,
  ContactFields,
  contactFields,
  contactDataDisplayFields,
} from './types';
import { contactFieldsSchema } from './schemas';
import { defaultExternalResourceConfig } from '../shared';

export const contactInternalResourceConfig = (uiText: UiText): InternalResourceConfig<
Contact,
ContactFields
> => ({
  enabledPages: ['list'],
  defaultResource: defaultContact,
  defaultResourceFields: defaultContactFields,
  fields: contactFields(uiText),
  fieldsValidationSchema: contactFieldsSchema(uiText),
  dataDisplay: contactDataDisplayFields(uiText),
});

export const defaultContactExternalResourceConfig: ExternalResourceConfig = {
  ...defaultExternalResourceConfig,
  aliases: {
    path: 'contacts',
    plural: 'Contacts',
    singular: 'Contact',
  },
};

export const defaultContactResource = makeResource({
  internalConfig: contactInternalResourceConfig(defaultUiText),
  externalConfig: defaultContactExternalResourceConfig,
});
