import React from 'react';
import { LayoutRenderer } from 'lib/layout/LayoutRenderer';
import { EntitySectionForm } from 'domain/view/components/form/EntitySectionForm';
import { ShowHome, ShowHomeInput } from 'domain/api/showhome';
import { PageSectionOptions } from '_lib/sectionedPageLayout';

type Props = PageSectionOptions & {
  initialValues: ShowHome;
  onSubmit: (values: ShowHomeInput) => void;
};

export const ShowHomeAddressForm = ({ initialValues, onSubmit, ...rest }: Props) => {
  return (
    <EntitySectionForm
      resourceName="showHome"
      initialValues={initialValues}
      onUpdate={onSubmit}
      {...rest}
    >
      {(renderField) => (
        <LayoutRenderer
          items={[
            {
              container: true,
              direction: 'column',
              spacing: 2,
              items: [
                {
                  container: true,
                  item: true,
                  spacing: 2,
                  items: [
                    {
                      item: true,
                      xl: 4,
                      md: 6,
                      renderContent: () => renderField('country'),
                    },
                    {
                      item: true,
                      xl: 4,
                      md: 6,
                      renderContent: () => renderField('city'),
                    },
                    {
                      item: true,
                      xl: 4,
                      md: 6,
                      renderContent: () => renderField('countrySubDivisionCode'),
                    },
                    {
                      item: true,
                      xl: 6,
                      renderContent: () => renderField('address'),
                    },
                    {
                      item: true,
                      xl: 6,
                      renderContent: () => renderField('postalCode'),
                    },
                  ],
                },
              ],
            },
          ]}
        />
      )}
    </EntitySectionForm>
  );
};
