import React from 'react';
import { Button, IconButton, makeStyles, Theme, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Action } from 'common/types';
import { useBaseUiText } from '../../../../uiText';

interface OwnProps {}

type Props = OwnProps & Omit<Action, 'type'>;

export const EditAction = (props: Props) => {
  const { label, onClick, disabled, isSmall } = props;
  const uiText = useBaseUiText();
  const classes = useStyles();
  const handleClick = (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    onClick && onClick();
  };
  if (props.isIcon) {
    return (
      <Tooltip title={uiText.ACTION_EDIT_LABEL}>
        <IconButton size={isSmall ? 'small' : undefined} disabled={disabled} onClick={handleClick}>
          <EditIcon fontSize={isSmall ? 'small' : undefined} />
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <Button
      disabled={disabled}
      variant="outlined"
      className={classes.root}
      onClick={handleClick}
      endIcon={<EditIcon fontSize="small" />}
      size={isSmall ? 'small' : undefined}
    >
      {label}
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontWeight: 500,
    textTransform: 'capitalize',
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[300],
      borderColor: theme.palette.grey[500],
      color: theme.palette.grey[500],
    },
  },
}));
