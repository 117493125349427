import React from 'react';
import { AdapterAction } from 'domain/api/common/adapters/types';

// Allows us to 'reset' an adapter so we can use the initial values of
// things like called.
// This is an issue with ApolloClient and there is PR up with a proposed
// solution for this issue.
// https://github.com/apollographql/apollo-feature-requests/issues/170
export function useResetableAdapterAction<TVariables, TResult, TResponse extends any = void>(
  _adapter: (
    args: any
  ) => AdapterAction<TVariables, TResult, TResponse> & {
    execute: (variables: TVariables, options?: any) => void;
  },
  args?: any
) {
  const [called, setCalled] = React.useState<boolean>(false);
  const adapter = _adapter(args);

  const execute: (variables: TVariables, options?: any) => void = (variables, options?: any) => {
    adapter.execute(variables, options);
    setCalled(true);
  };

  React.useEffect(() => {
    if (adapter.called && !adapter.loading && (!!adapter.result || !!adapter.error)) {
      setCalled(false);
    }
  }, [adapter]);
  return {
    ...adapter,
    called,
    execute,
  };
}
