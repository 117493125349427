import { useHistory } from 'react-router';
import {
  createFailedDeleteMessage,
  createSuccessfulDeleteMessage,
  useSetMessage,
} from '_lib/messages';
import { useIdInputMutation } from 'domain/api/common/adapters/useIdInputMutation';
import { updateCacheOnDeleteOne } from 'domain/api/common/cache';
import { DeleteOneAdapterAction } from 'domain/api/common/adapters/types';
import { DELETE_ONE_SHOW_HOME } from './gql';

type Response = {
  deleteShowHome: {
    deletedId: string | null | undefined;
  };
};

export const useDeleteOneShowHome = (redirectPath?: string): DeleteOneAdapterAction => {
  const history = useHistory();
  const setMessage = useSetMessage();

  return useIdInputMutation<Response, string | undefined>(
    DELETE_ONE_SHOW_HOME,
    (data) => data?.deleteShowHome.deletedId || undefined,
    {
      updateCache: (cache, data) => updateCacheOnDeleteOne(
        cache, 'showHomes', data?.deleteShowHome.deletedId,
      ),
      onSuccess: () => {
        setMessage(createSuccessfulDeleteMessage(''));
        if (redirectPath) history.push(`/${redirectPath}`);
      },
      onError: (err) => setMessage(createFailedDeleteMessage('', err)),
    }
  );
};
