import { atom, useRecoilState, useSetRecoilState } from 'recoil';
import { Message } from './types';

const messageState = atom<Message | undefined>({
  key: 'MessagesState',
  default: undefined,
});

export const useSetMessage = () => {
  const setMessage = useSetRecoilState(messageState);
  return setMessage;
};

export const useMessagesState = () => useRecoilState(messageState);
