import React from 'react';
import yup from 'yup';
import { YUP_VALIDATION_ERROR_NAME } from '../common/constants';
import { useFetchConfig } from './useFetchConfig';

export type ConfigProviderProps<C extends object> = {
  url: string;
  schema: yup.ObjectSchema<C, object>;
  setConfig: (config: C | undefined) => void;
  children?: React.ReactNode;
};

export function ConfigProvider<C extends object>({
  schema,
  url,
  setConfig,
  children,
}: ConfigProviderProps<C>) {
  const config = useFetchConfig<C>(url);
  if (config) {
    try {
      const validated = schema.validateSync(config, { abortEarly: false });
      setConfig(validated);
    } catch (err) {
      if (err && err.name && err.name === YUP_VALIDATION_ERROR_NAME) {
        throw new Error('Invalid configuration');
      }
      throw err;
    }
  }
  if (config) {
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }
  return null;
}
