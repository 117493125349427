import React from 'react';
import classNames from 'classnames';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { SaveButton } from '_lib/components/buttons/SaveButton';
import { useUiText } from 'domain/uiText';
import { useQueuedJobs } from 'common/hooks/useQueuedJobs';
import { FileUpload } from '_lib/form/fileUpload/FileUpload';
import { useSetMessage } from '_lib/messages';
import { useAssetFormContext } from '../../AssetFormContext';
import { AssetFormUploadingItem } from './AssetFormUploadingItem';
import { useImageHelp } from '../../helpers';

export const AssetFormUploading = () => {
  const classes = useStyles();
  const uiText = useUiText();
  const setMessage = useSetMessage();
  const {
    attributeName,
    entityContentId,
    updateContext,
    isMultiple,
    sectionConfig,
    assets,
    isPdf,
    isImage,
    onUploadCompleted,
  } = useAssetFormContext();
  const {
    isCurrentJob,
    addSelected,
    start,
    onJobSuccess,
    onJobFailed,
    hasSelected,
    selected,
    isRunning,
    hasFailedJobs,
  } = useQueuedJobs({
    onCompleted: () => {
      updateContext({
        step: isMultiple && assets.length > 1 ? 'reordering' : 'readonly',
      });
      onUploadCompleted?.();
    },
    delayMs: 150,
  });

  const handleSubmit = () => start();

  const handleClose = () => updateContext({ step: 'readonly' });

  const handleSuccess = React.useCallback((tempId: string) => () => (
    onJobSuccess(tempId)
  ), [onJobSuccess]);

  const handleError = React.useCallback((tempId: string, fileName: string) => (err: string)=> {
    onJobFailed(tempId);
    setMessage({ severity: 'error', content: `Failed to upload ${fileName}` });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onJobFailed]);

  const handleSelectFiles = (files: File[]) => {
    files.forEach((file) => {
      const tempId = Math.random().toString(32);

      addSelected(tempId, {
        tempId,
        file,
        displayOrder: null,
        displayName: file.name,
        attributeName,
      });
    });
  };

  return (
    <React.Fragment>
      <DialogTitle>
        <Typography variant="h4">{sectionConfig.title}</Typography>
        <Typography color="textSecondary" variant="caption">
          {useImageHelp(attributeName)}
        </Typography>
      </DialogTitle>
      <DialogContent className={classNames({ [classes.selectingContainer]: !hasSelected })}>
        {!hasSelected && (
          <FileUpload
            isPdf={!!isPdf}
            isImage={!!isImage}
            isMultiple={!!isMultiple}
            selectFiles={handleSelectFiles}
          />
        )}
        {hasSelected && (
          <Grid container={true} spacing={2}>
            {selected.map(({ key, data }) => (
              <AssetFormUploadingItem
                file={data.file}
                displayOrder={data.displayOrder}
                attributeName={data.attributeName}
                key={`${key}-readonly-${entityContentId}-${attributeName}`}
                onSuccess={handleSuccess(key)}
                onError={handleError(key, data.displayName)}
                startUpload={isCurrentJob(key)}
              />
            ))}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} disabled={isRunning}>
          {uiText.ACTION_CANCEL_LABEL}
        </Button>
        <SaveButton
          disabled={isRunning || !hasSelected || hasFailedJobs}
          onSave={handleSubmit}
        />
      </DialogActions>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  selectingContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
