import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Typography } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import { useUiText } from 'domain/uiText';
import { FeatureTag } from 'domain/api/featureTag';
import { useAllFeatureTags } from './hooks';
import { CreateFeatureTagDialog } from './CreateFeatureTagDialog';

interface Props {
  excludeOptions: FeatureTag[];
  onAddTag: (tag: FeatureTag) => void;
}

export const FeatureTagsComboBox = ({ excludeOptions, onAddTag }: Props) => {
  const uiText = useUiText();
  const { allFeatureTags } = useAllFeatureTags();
  const [newTagName, setNewTagName] = React.useState<string | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const excludeIds = excludeOptions.map(opt => opt.id);
  const allFeatureNames = allFeatureTags.map(tag => tag.name);
  let options: (FeatureTag | string)[] = allFeatureTags
    .filter(opt => excludeIds.indexOf(opt.id) < 0)
    .sort((a, b) => a.name.localeCompare(b.name));
  if (inputValue !== '' && allFeatureNames.indexOf(inputValue) < 0) {
    options = [...options, inputValue];
  }

  return (
    <>
      <Autocomplete
        value={null}
        onChange={(_, val: FeatureTag | string | null) => {
          if (typeof val === 'string') {
            setNewTagName(val);
          } else if (val) {
            onAddTag(val);
          }
          setInputValue('');
        }}
        inputValue={inputValue}
        onInputChange={(_, val: string) => setInputValue(val)}
        options={options}
        getOptionLabel={tag => {
          if (typeof tag === 'string') {
            return `${uiText.ACTION_CREATE_LABEL} "${tag}" ${uiText.FEATURE_TAG_SINGLULAR.toLowerCase()}`;
          }
          return tag.name;
        }}
        renderOption={tag => {
          if (typeof tag === 'string') {
            return (<>
              <AddCircleOutline fontSize='small' style={{ color: 'green', paddingRight: 4 }} />
              <span style={{ color: 'green' }}>
                {`${uiText.ACTION_CREATE_LABEL} "${tag}" ${uiText.FEATURE_TAG_SINGLULAR.toLowerCase()}`}
              </span>
            </>)
          }
          return <span>{tag.name} <Typography variant="caption">{tag.description}</Typography></span>;
        }}
        noOptionsText={uiText.HELP_NO_FEATURE_TAGS}
        autoHighlight
        openOnFocus
        handleHomeEndKeys
        renderInput={
          (params) => <TextField {...params} label={uiText.ACTION_ADD_FEATURE_TAG} />
        }
      />
      <CreateFeatureTagDialog
        newTagName={newTagName}
        onAddTag={onAddTag}
        onClose={() => setNewTagName(null)}
      />
    </>
  );
};
