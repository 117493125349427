import React from 'react';
import { AssetFormItem } from '../../components/AssetFormItem';
import { useAssetFormContext } from '../../AssetFormContext';
import { AssetFormPageSectionItems } from '../../components/AssetFormPageSectionItems';
import { AssetFormPageSectionHeader } from '../../components/AssetFormPageSectionHeader';
import { isPdf as checkIsPdf } from '../../helpers';

interface Props {
  showCheckeredBackground?: boolean;
}

export const AssetFormReadonly = ({ showCheckeredBackground }: Props) => {
  const {
    assets, attributeName, entityContentId, isPdf,
  } = useAssetFormContext();

  return (
    <React.Fragment>
      <AssetFormPageSectionHeader />
      <AssetFormPageSectionItems>
        {(renderAssetItem) => (
          <React.Fragment>
            {assets.map((asset, i) => renderAssetItem(
              <AssetFormItem
                index={i}
                url={asset.url}
                id={asset.id}
                displayName={asset.displayName}
                showDescription={true}
                showCheckeredBackground={showCheckeredBackground}
                isReadonly={true}
                description={asset.description}
                isPdf={checkIsPdf(asset.url) || isPdf}
                key={`${asset.id}-readonly-${entityContentId}-${attributeName}`}
              />,
              asset.id,
            ))}
          </React.Fragment>
        )}
      </AssetFormPageSectionItems>
    </React.Fragment>
  );
};
