import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import Close from '@material-ui/icons/Close';
import {
  makeStyles,
  IconButton,
  InputBase,
  Typography,
} from '@material-ui/core';

export const bulletInputId = (index: number) => `text-list-bullet-field-input-${index}`;

type Props = {
  _index: number;
  value: string;
  readonly?: boolean;
  disabled?: boolean;
  onChange: (value: string, index: number) => void;
  onDelete: (index: number) => void;
  focusPrev: (index: number) => void;
  focusNext: (index: number) => void;
  mergePrev: (index: number) => void;
  mergeNext: (index: number) => void;
};

export const TextListFieldBullet = ({
  _index,
  value,
  readonly,
  disabled,
  onChange,
  onDelete,
  focusPrev,
  focusNext,
  mergePrev,
  mergeNext,
}: Props) => {
  const classes = useStyles();
  const handleChange = React.useCallback((e: React.BaseSyntheticEvent) => {
    onChange(e.target.value, _index);
  }, [_index, onChange]);

  const handleDelete = React.useCallback(() => onDelete(_index), [onDelete, _index]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const { selectionStart, selectionEnd } = e.target as HTMLInputElement;
    if (selectionStart === 0 && selectionEnd === 0) {
      if (e.key === 'ArrowLeft') {
        focusPrev(_index);
        e.preventDefault();
      }
      else if (e.key === 'Backspace') {
        mergePrev(_index);
        e.preventDefault();
      }
    } else if (selectionStart === value.length && selectionEnd === value.length) {
      if (e.key === 'ArrowRight') {
        focusNext(_index);
        e.preventDefault();
      }
      else if (e.key === 'Delete') {
        mergeNext(_index);
        e.preventDefault();
      }
    }
  };

  return (
    <div className={classes.container}>
      {!readonly && (
        <div className={classes.drag}>
          <IconButton edge="end" size="small" disabled={disabled}>
            <DragIndicatorIcon fontSize="small" />
          </IconButton>
        </div>
      )}
      <div className={classes.input}>
        {readonly ? (
          <Typography className={classes.readonly}>{value || ' '}</Typography>
        ): (
          <InputBase
            id={bulletInputId(_index)}
            fullWidth={true}
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            multiline={true}
            disabled={disabled}
          />
        )}
      </div>
      {!readonly && (
        <div className={classes.delete}>
          <IconButton edge="end" size="small" onClick={handleDelete} disabled={disabled}>
            <Close fontSize="small" />
          </IconButton>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    border: `solid 1px ${theme.palette.grey[200]}`,
    borderRadius: 4,
    padding: `0px ${theme.spacing(1)}px`,
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
    borderLeft: `solid 3px ${theme.palette.primary.main}`,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  drag: {
    borderRight: `solid 1px ${theme.palette.grey[200]}`,
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  input: {
    flexGrow: 1,
  },
  readonly: {
    whiteSpace: 'pre-wrap',
    padding: `${theme.spacing(.5)}px ${theme.spacing(1)}px`,
  },
  delete: {
    borderLeft: `solid 1px ${theme.palette.grey[200]}`,
    paddingLeft: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

export const SortableTextListFieldBullet = SortableElement(TextListFieldBullet);
