import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Contact } from 'domain/api/contact';
import { useUiText } from 'domain/uiText';
import { SalesCentre } from 'domain/api/salesCentre';
import { FormHeader } from '_lib/form/layout';
import { CommunitySalesCentreContact } from './CommunitySalesCentreContact';
import { CreateContactDialog, AddExistingContactsDialog } from './dialogs';

type Props = {
  salesCentre: SalesCentre;
  allContacts: Contact[];
  entityContentId: string;
};

export const CommunitySalesCentreContacts = ({
  salesCentre,
  allContacts,
  entityContentId,
}: Props) => {
  const uiText = useUiText();
  const availableContacts = React.useMemo(() => {
    return allContacts.filter(
      (contact) => !salesCentre.contacts.find((_contact) => _contact.id === contact.id)
    );
  }, [salesCentre, allContacts]);
  const renderContact = (contact: Contact) => {
    return (
      <Grid
        item={true}
        key={`sales-centre-contact-${contact.id}`}
        xs={12}
        sm={12}
        md={6}
        lg={4}
        xl={4}
      >
        <CommunitySalesCentreContact contact={contact} salesCentreId={salesCentre.id} />
      </Grid>
    );
  };

  return (
    <Grid item={true} style={{ flexGrow: 2 }}>
      <FormHeader
        title={uiText.CONTACT_PLURAL}
        noPadding={true}
        actions={
          <React.Fragment>
            <Grid item={true}>
              <AddExistingContactsDialog
                contacts={availableContacts}
                salesCentreId={salesCentre.id}
              />
            </Grid>
            <Grid item={true}>
              <CreateContactDialog salesCentre={salesCentre} entityContentId={entityContentId} />
            </Grid>
          </React.Fragment>
        }
      />
      <Grid container={true} spacing={2}>
        {salesCentre.contacts.length > 0 ? (
          salesCentre.contacts.map(renderContact)
        ) : (
          <Grid item={true} container={true} justify="center">
            <Grid item={true}>
              <Typography color="textSecondary">{uiText.NO_CONTACTS_ON_SALES_CENTRE}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
