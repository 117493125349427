import React from 'react';
import { Chip, makeStyles, Theme, Tooltip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import classNames from 'classnames';
import { Status } from 'common/types';
import { useStatusLabel, useStatusToolTip } from './status';

interface PublishedStatusProps {
  publishedStatus: Status;
}

export const PublishedStatusChip = (props: PublishedStatusProps) => {
  const { publishedStatus } = props;
  const classes = useStyles();

  return (
    <Tooltip title={useStatusToolTip(publishedStatus)}>
      <Chip
        variant="outlined"
        size="small"
        icon={
          publishedStatus === 'error' ? (
            <WarningIcon className={classes.error} fontSize="small" />
          ) : undefined
        }
        label={useStatusLabel(publishedStatus)}
        className={classNames(
          {
            [classes.published]: publishedStatus === 'published',
            [classes.draft]: publishedStatus === 'draft',
            [classes.error]: publishedStatus === 'error',
          },
          classes.publishedStatus
        )}
      />
    </Tooltip>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  publishedStatus: {
    borderWidth: 2,
    fontWeight: 'bold',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    borderRadius: 4,
  },
  published: {
    backgroundColor: 'transparent',
    borderColor: theme.palette.success.dark,
    color: theme.palette.success.dark,
  },
  draft: {
    backgroundColor: 'transparent',
    borderColor: theme.palette.info.main,
    color: theme.palette.info.main,
  },
  error: {
    backgroundColor: 'transparent',
    borderColor: theme.palette.warning.dark,
    color: theme.palette.warning.dark,
  },
  errorIcon: {
    color: theme.palette.warning.dark,
  },
}));
