import React from 'react';
import { useUiText } from 'domain/uiText';
import { useGetManyFloorPlans } from 'domain/api/floorPlan';
import { useGetManyCommunities } from 'domain/api/community';
import { useGetManyInventories } from 'domain/api/inventory';
import {
  useCreateOneEmbedding,
  useDeleteOneEmbedding,
  useGetManyEmbeddings,
  usePublishOneEmbedding,
  useUnpublishOneEmbedding,
} from 'domain/api/embedding';
import { useResetableAdapterAction } from 'domain/api/common/hooks';
import { useGetManyShowHomes } from 'domain/api/showhome';
import { useGetManyVendors } from 'domain/api/vendor';
import { EmbeddingInput } from 'domain/api/embedding/types';
import { ListPage } from 'domain/view/pages/ListPage/ListPage';
import { CreateThirdPartyWidgetForm, EditThirdPartyWidgetForm } from '../../forms';

export const ThirdPartyWidgetsListPageContainer = () => {
  const uiText = useUiText();
  const getEmbeddings = useGetManyEmbeddings();
  const getCommunities = useGetManyCommunities();
  const getFloorPlans = useGetManyFloorPlans();
  const getInventories = useGetManyInventories();
  const getShowHomes = useGetManyShowHomes();
  const createEmbedding = useResetableAdapterAction(useCreateOneEmbedding);
  const getVendors = useGetManyVendors();
  const publishEmbedding = usePublishOneEmbedding();
  const unpublishEmbedding = useUnpublishOneEmbedding();
  const deleteEmbedding = useDeleteOneEmbedding();
  const vendorsFilteredByType = getVendors.result.filter((vendor) => vendor.type !== 'video');

  const get = () => {
    getEmbeddings.execute();
    getCommunities.execute();
    getInventories.execute();
    getFloorPlans.execute();
    getVendors.execute();
    getShowHomes.execute();
  };

  const handleCreateEmbedding = (thirdPartyWidget: EmbeddingInput) => {
    createEmbedding.execute(thirdPartyWidget);
  };

  return (
    <ListPage
      resourceName="embedding"
      get={get}
      actions={{
        publish: (id: string) => publishEmbedding.execute({ id }),
        unpublish: (id: string) => unpublishEmbedding.execute({ id }),
        delete: (id: string) => deleteEmbedding.execute({ id }),
        edit: (data) => (
          <EditThirdPartyWidgetForm
            embedding={data}
            floorPlans={getFloorPlans.result}
            communities={getCommunities.result}
            inventories={getInventories.result}
            showHomes={getShowHomes.result}
          />
        ),
      }}
      loading={getEmbeddings.loading || createEmbedding.loading}
      actionLoading={
        deleteEmbedding.loading || publishEmbedding.loading || unpublishEmbedding.loading
      }
      error={undefined}
      data={getEmbeddings.result}
      called={getEmbeddings.called}
      hasData={getEmbeddings.result.length > 0}
      noDataMessage={uiText.NO_THIRD_PARTY_PLUGIN}
      topbar={{
        title: uiText.THIRD_PARTY_PLUGIN_PLURAL,
        createAction: (
          <CreateThirdPartyWidgetForm
            onSubmit={handleCreateEmbedding}
            submitting={createEmbedding.loading}
            submitted={!createEmbedding.loading && createEmbedding.called}
            vendors={vendorsFilteredByType}
            floorPlans={getFloorPlans.result}
            communities={getCommunities.result}
            inventories={getInventories.result}
            showHomes={getShowHomes.result}
          />
        ),
      }}
    />
  );
};
