import React from 'react';
import Dropzone from 'react-dropzone';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { useUiText } from 'domain/uiText';
import { generateAcceptedFileTypes } from 'domain/view/components/form/AssetForm/helpers';
import { MAX_UPLOAD_SIZE } from 'domain/view/components/form/AssetForm/types';
import { FileUploadProps } from './types';
import { useUploadErrors } from './useUploadErrors';

export function FileUpload({ selectFiles, isMultiple, isImage, isPdf }: FileUploadProps) {
  const classes = useStyles();
  const uiText = useUiText();
  const { renderUploadErrors, fileRejections, setFileRejections } = useUploadErrors();

  const handleSelect = (files: File[]) => selectFiles(files);

  return (
    <Dropzone
      onDrop={handleSelect}
      minSize={0}
      maxSize={MAX_UPLOAD_SIZE}
      onDropRejected={(rejections) => setFileRejections(rejections)}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} className={classes.container}>
          <input
            {...getInputProps()}
            multiple={isMultiple}
            accept={generateAcceptedFileTypes(isImage, isPdf)}
          />
          <CloudUpload fontSize="large" />
          <Typography gutterBottom={true}>
            {isMultiple
              ? uiText.HELP_UPLOAD_MULTIPLE
              : uiText.HELP_UPLOAD_SINGLE}
          </Typography>
          <Typography gutterBottom={true}>or</Typography>
          <Button variant="contained">{isMultiple ? 'Select Files' : 'Select a File'}</Button>
          {!!fileRejections.length && renderUploadErrors()}
        </div>
      )}
    </Dropzone>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: `${theme.spacing(2)}px 0px`,
    border: `dashed 2px ${theme.palette.grey[400]}`,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    flexGrow: 2,
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  error: {
    marginTop: '20px',
  },
}));
