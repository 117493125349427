import React from 'react';
import { useResource } from 'system/hooks';
import { useResourceFields } from 'system/resources/hooks/useResourceFields';
import { ResourcesContextValue } from 'system/resources/types';
import { ResourceFields } from 'lib/resource';
import { FieldErrors, FieldModifiers } from 'lib/form/types';
import { Action } from 'common/types';
import { ActionDialogForm } from './ActionDialogForm';

type ResourceFieldsConstraint<TResourceName extends keyof ResourcesContextValue> = ResourceFields<
ResourcesContextValue[TResourceName]['defaultResourceFields']
>;

type FieldNameConstraint<
  TResourceName extends keyof ResourcesContextValue
> = keyof ResourceFieldsConstraint<TResourceName>;

type ActionProps = Pick<Action, 'type' | 'label' | 'isSmall' | 'isIcon'>;

type Props<
  TResourceName extends keyof ResourcesContextValue,
  TResourceInput extends Record<string, any>,
  TInitialValues extends Record<string, any>
> = {
  resourceName: TResourceName;
  onSubmit: (values: TResourceInput, close: () => void, alsoPublish?: boolean) => void;
  children(
    renderField: <TFieldName extends FieldNameConstraint<TResourceName> & string>(
      field: TFieldName,
      fieldConfig?: Partial<ResourceFieldsConstraint<TResourceName>[TFieldName]['fieldConfig']>
    ) => JSX.Element,
    fields: {
      [Key in keyof ResourceFieldsConstraint<TResourceName>]: {
        type: ResourceFieldsConstraint<TResourceName>[Key]['type'];
        value: ResourceFieldsConstraint<TResourceName>[Key]['value'];
      };
    }
  ): React.ReactNode;
  fieldModifiers?: Partial<FieldModifiers<any>>;
  externalFieldErrors?: Partial<FieldErrors<TInitialValues>>;
  title: string;
  action: ActionProps | ((onClick: () => void) => JSX.Element);
  initialValues?: TInitialValues;
  omittedFields?: (FieldNameConstraint<TResourceName> & string)[];
  submitting?: boolean;
  submitted?: boolean;
  submitDisabled?: boolean;
  onClose?: () => void;
  includeUntouchedFields?: boolean;
  showCreateAndPublishAction?: boolean;
};

export function EntityDialogActionForm<
  TResourceName extends keyof ResourcesContextValue,
  TResourceInput extends Record<string, any>,
  TInitialValues extends Record<string, any>
>({
  resourceName,
  onSubmit,
  children,
  fieldModifiers,
  title,
  action,
  initialValues,
  submitted,
  submitting,
  omittedFields,
  externalFieldErrors,
  submitDisabled,
  onClose,
  includeUntouchedFields,
  showCreateAndPublishAction,
}: Props<TResourceName, TResourceInput, TInitialValues>) {
  const { helpers } = useResource(resourceName);
  const form = useResourceFields(resourceName, {
    hasReadonly: false,
    fieldModifiers,
    initialValues,
    omittedFields,
    includeUntouchedFields,
  });

  const handleSubmit = (close: () => void, alsoPublish?: boolean) => {
    form.onSubmit(
      (errors) => {},
      (values) => {
        onSubmit(values as TResourceInput, close, alsoPublish);
        onClose && onClose();
      }
    );
  };

  const handleCancel = () => {
    form.reset();
    onClose && onClose();
  };

  const generateAction = () => {
    if (typeof action === 'function') {
      return (onClick: () => void) => action(onClick);
    }
    return {
      ...action,
      disabled: helpers.isActionDisabled(action.type === 'edit' ? 'update' : action.type),
    };
  };

  React.useEffect(() => {
    if (externalFieldErrors) form.setErrorsExternally(externalFieldErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalFieldErrors]);

  return (
    <ActionDialogForm
      title={title}
      submitDisabled={!!submitting || !form.canSubmit || !!submitDisabled}
      submitted={submitted}
      submitting={submitting}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      action={generateAction()}
      hasErrors={form.hasErrors && form.attemptedSubmit}
      showCreateAndPublishAction={showCreateAndPublishAction}
    >
      {children(form.renderField, form.fields)}
    </ActionDialogForm>
  );
}
