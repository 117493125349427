import { compact } from 'lodash';
import { createPublishedStatus } from '../common/transforms';
import { ApiImageLink, ApiImageLinkInput, ImageLink } from './types';

export const transformImageLinkToApiImageLinkInput = (
  data: Partial<ImageLink> & { id: string },
): ApiImageLinkInput => ({
  displayName: data.displayName,
  url: data.url,
});

export const transformApiImageLinkToImageLink = (
  data?: ApiImageLink,
): ImageLink | undefined => data ? ({
  id: data.id,
  published: data.published,
  displayName: data.displayName,
  url: data.url,
  assets: data.assets,
  publishedStatus: createPublishedStatus(data),
  publishProblems: data.publishProblems,
  isLarge: data.isLarge,
  contentEntries: (data.contentEntries || []).map(el => el.id),
  communities: (data.contentEntries || []).filter(el => el.__typename === 'Community').map(el => el.id),
  floorPlans: (data.contentEntries || []).filter(el => el.__typename === 'FloorPlan').map(el => el.id),
  inventories: (data.contentEntries || []).filter(el => el.__typename === 'Inventory').map(el => el.id),
  showHomes: (data.contentEntries || []).filter(el => el.__typename === 'ShowHome').map(el => el.id),
}) : undefined;

export const transformApiImageLinksToImageLinks = (
  data?: ApiImageLink[],
) => data ? compact(data.map(el => transformApiImageLinkToImageLink(el))) : [];

export const filterImageLinksBySize = (imageLinks: ImageLink[], isLarge?: boolean, ) => {
  return imageLinks.filter(el => isLarge ? el.isLarge : !el.isLarge);
};
