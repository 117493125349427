import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const systemLoadingState = atom({
  key: 'SystemLoading',
  default: true,
});

export const useClearSystemLoading = () => useSetRecoilState(systemLoadingState)(false);

export const useSytemLoading = () => ({ isLoading: useRecoilValue(systemLoadingState) });
