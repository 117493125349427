import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { GoogleApiWrapper, GoogleAPI } from 'google-maps-react';
import { googleApiWrapperOptions } from 'googleApiWrapperOptions';
import { Spinner } from '_lib/loading';
import { useUiText } from 'domain/uiText';
import { GeoboundaryPath } from './types';

interface Props {
  boundary: GeoboundaryPath | null | undefined;
  draftBoundary: GeoboundaryPath | undefined;
  setDraftBoundary: (boundary: GeoboundaryPath) => void;
  drawing: boolean;
  calculating: boolean;
  google: GoogleAPI;
  loaded?: boolean;
  center: google.maps.LatLngLiteral,
}

const HEIGHT = 500;
const MAP_ID = 'demostats-boundary-map';

const DemostatsMap = ({
  boundary,
  draftBoundary,
  setDraftBoundary,
  drawing,
  calculating,
  google,
  loaded,
  center,
}: Props) => {
  const classes = useStyles();
  const uiText = useUiText();

  const [map, setMap] = React.useState<google.maps.Map | undefined>(undefined);
  const [polygon, setPolygon] = React.useState<google.maps.Polygon | undefined>(undefined);

  // Set up map
  React.useEffect(() => {
    setMap(new google.maps.Map(
      document.getElementById(MAP_ID) as HTMLElement,
      {
        // https://developers.google.com/maps/documentation/javascript/interaction
        gestureHandling: 'cooperative',
        // https://developers.google.com/maps/documentation/javascript/controls
        mapTypeControl: true,
        streetViewControl: false,
        fullscreenControl: false,
        scaleControl: false,
        rotateControl: false,
        zoomControl: true,
      }
    ));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Frame map
  React.useEffect(() => {
    if (map) {
      if (drawing && draftBoundary) {
        const bounds = new google.maps.LatLngBounds();
        draftBoundary.forEach(point => {
          bounds.extend(point);
        });
        map.fitBounds(bounds);
      } else if (boundary) {
        const bounds = new google.maps.LatLngBounds();
        boundary.forEach(point => {
          bounds.extend(point);
        });
        map.fitBounds(bounds);
      } else if (!boundary) {
        map.setOptions({
          zoom: 11,
          center,
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawing, boundary === undefined, boundary === null, !!draftBoundary]);

  // Draw polygon
  React.useEffect(() => {
    if (map) {
      polygon && polygon.setMap(null);
      if (drawing && draftBoundary) {
        const newPoly = new google.maps.Polygon({
          paths: draftBoundary,
          map,
          strokeColor: 'grey',
          strokeOpacity: 0.9,
          strokeWeight: 1,
          fillColor: 'grey',
          fillOpacity: 0.3,
          editable: true,
        });
        const newPath = newPoly.getPath();
        newPoly.addListener('contextmenu', (event) => {
          if (event.vertex && newPath.getLength() > 3) {
            newPath.removeAt(event.vertex);
            setDraftBoundary(
              newPath.getArray().map(point => ({ lng: point.lng(), lat: point.lat() }))
            );
          }
        });
        newPath.addListener('insert_at', () => {
          setDraftBoundary(
            newPath.getArray().map(point => ({ lng: point.lng(), lat: point.lat() }))
          );
        });
        newPath.addListener('set_at', () => {
          setDraftBoundary(
            newPath.getArray().map(point => ({ lng: point.lng(), lat: point.lat() }))
          );
        });
        setPolygon(newPoly);
      } else if (!drawing && boundary) {
        setPolygon(new google.maps.Polygon({
          paths: boundary,
          map,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
          editable: false,
        }));
      } else {
        setPolygon(undefined);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawing, !!boundary, !!draftBoundary]);

  // Ensure a starter square
  React.useEffect(() => {
    if (!draftBoundary) {
      setDraftBoundary(defaultBoundary(google, center));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!draftBoundary]);

  return (
    <div className={classes.mapBox}>
      {(!loaded || boundary === undefined) && <Skeleton variant="rect" height={HEIGHT} />}
      {boundary === null && !drawing &&
        <Typography className={classes.emptyNote}>{uiText.NO_BOUNDARY}</Typography>}
      {calculating && <Spinner withContent />}
      <div id={MAP_ID} className={classes.map} />
    </div>
  );
};

export const DemostatsBoundaryMap = GoogleApiWrapper(googleApiWrapperOptions)(DemostatsMap);

const useStyles = makeStyles((theme: Theme) => ({
  mapBox: {
    height: HEIGHT,
    position: 'relative',
  },
  map: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  emptyNote: {
    position: 'absolute',
    top: 10,
    right: 10,
    height: 40,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    boxSizing: 'border-box',
    backgroundColor: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
    borderRadius: 2,
    zIndex: 2,
  },
}));

const defaultBoundary = (
  google: GoogleAPI,
  startPoint: google.maps.LatLngLiteral
): GeoboundaryPath => {
  const DISTANCE_FROM_CENTER_M = 1414;  // makes for a 2km x 2km square
  const origin = new google.maps.LatLng(startPoint);
  return [45, 135, 225, 315].map(
    bearing => google.maps.geometry.spherical.computeOffset(
      origin, DISTANCE_FROM_CENTER_M, bearing,
    ).toJSON()
  );
};
