import React from 'react';
import { ScrollMenuPageLayout } from '../components/ScrollMenuPageLayout';
import { PageSectionRegistrarContext } from '../PageSectionRegisrarContext';
import { usePageSectioning } from '../usePageSectioning';
import { ScrollingPageSectionMenu } from './ScrollingPageSectionMenu';

const SECTIONED_PAGE_SCROLL_CONTAINER_ID = 'sectioned-page-scroll-container';

interface Props {
  children: React.ReactNode;
}

export const SectionedPageLayout = ({ children }: Props) => {
  const { sections, useRegisterPageSection } = usePageSectioning();
  return (
    <ScrollMenuPageLayout
      menu={
        <ScrollingPageSectionMenu
          sections={sections}
          scrollContainerId={SECTIONED_PAGE_SCROLL_CONTAINER_ID}
        />
      }
      scrollContainerId={SECTIONED_PAGE_SCROLL_CONTAINER_ID}
    >
      <PageSectionRegistrarContext.Provider value={useRegisterPageSection}>
        {children}
      </PageSectionRegistrarContext.Provider>
    </ScrollMenuPageLayout>
  );
};
