import { createFailedUpdateMessage, createSuccessfulUpdateMessage, useSetMessage } from '_lib/messages';
import { ValidatedEntity, WithClearList } from 'domain/api/common/types';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { useCreateOrUpdateOne } from 'domain/api/common/adapters/useCreateOrUpdateOne';
import { generateCreateOrUpdateString } from 'domain/api/common/fragments';
import { transformClearableFieldsToClearList } from 'domain/api/common/transforms';
import { ApiSalesCentre, ApiSalesCentreInput, CLEARABLE_SALES_CENTRE_FIELDS, SalesCentre } from '../types';
import { transformApiSalesCentreToSalesCentre, transformSalesCentreToApiSalesCentreInput } from '../transforms';
import { AllSalesCentreInfo } from '../fragments';

const UPDATE_ONE_SALES_CENTRE = generateCreateOrUpdateString('update', 'salesCentre', AllSalesCentreInfo);

type Response = {
  updateSalesCentre: {
    salesCentre: ApiSalesCentre | undefined;
  }
};

type Input<T> = T & {
  id: string;
  contentSpaceId: string;
};

export const useUpdateOneSalesCentre = () => {
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  const { execute: executeMutation, ...rest } = useCreateOrUpdateOne<
  WithClearList<ApiSalesCentreInput, Input<ApiSalesCentreInput>>,
  Response,
  ValidatedEntity<SalesCentre> | undefined
  >(
    UPDATE_ONE_SALES_CENTRE,
    (data) => schemas?.salesCentre.apiSchema ? transformApiSalesCentreToSalesCentre(
      validateApiResponseEntity(
        data?.updateSalesCentre.salesCentre,
        schemas.salesCentre.apiSchema,
      ),
    ) : undefined,
    {
      onSuccess: () => setMessage(createSuccessfulUpdateMessage('')),
      onError: (err) => setMessage(createFailedUpdateMessage('', err)),
    },
  );

  const execute = ({
    id,
    ...restData
  }: Input<Partial<SalesCentre>> & { contentSpaceId: string }) => {
    const transformed = transformSalesCentreToApiSalesCentreInput(restData);
    executeMutation({
      id,
      contentSpaceId: restData.contentSpaceId,
      ...transformed,
      clear: transformClearableFieldsToClearList(transformed, CLEARABLE_SALES_CENTRE_FIELDS),
    });
  };

  return { execute, ...rest };
};
