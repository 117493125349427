import * as yup from 'yup';
import { UiText } from 'domain/uiText';

import { CommunityFields } from './types';

// Schemas used in both the resource and fields
const sharedSchemas = (uiText: UiText) => ({
  builderId: yup.string().defined().required().default('').label('ID'),
  displayName: yup.string().defined().required().default('').label('Name'),
  displayPriority: yup.number().defined().default(null).label('Display Priority'),
  communityType:
    yup.string().defined().label(`${uiText.COMMUNITY_SINGULAR} Type`).default(null),
  showCommunityStats:
    yup.boolean().defined().default(false).label(`${uiText.COMMUNITY_SINGULAR} Statistics`),
  showCommuteCalculation: yup.boolean().defined().default(true).label('Commute Calculator'),
  lotmapLink: yup.string().defined().default(null).label('Lotmap Link'),
  lotmapText: yup.string().defined().default(null).label('Lotmap Text'),

  seoTitleTag: yup.string().defined().default('').label('SEO Title'),
  seoMetaKeywords: yup.array().of(yup.string().defined()).defined().compact().default([]).label('SEO Keywords'),
});

export const communityFieldsSchema = (uiText: UiText) => yup.object<CommunityFields>({
  ...sharedSchemas(uiText),
  communityGroup:
    yup.string().defined().default(null).label(`${uiText.COMMUNITY_SINGULAR} Group`),
  lat: yup.number().defined().default(0).min(-90).max(90).label('Latitude'),
  lng: yup.number().defined().default(0).min(-180).max(180).label('Longitude'),
  seoDescriptionBody: yup.string().defined().default('').label('Description Body Text'),
  seoDescriptionBullets:
    yup.array().of(yup.string().defined()).defined().compact().default([]).label('Description Bullet Points'),
}).defined();
