import React from 'react';
import { Action } from 'common/types';
import { assertUnreachable } from 'utilities/errors';
import { CreateAction } from './CreateAction/CreateAction';
import { DeleteAction } from './DeleteAction/DeleteAction';
import { EditAction } from './EditAction/EditAction';
import { PublishAction } from './PublishAction/PublishAction';
import { UnpublishAction } from './UnpublishAction/UnpublishAction';

type Props = Action;

// eslint-disable-next-line consistent-return
export const ActionButton = (props: Props) => {
  const { type, ...rest } = props;
  switch (type) {
    case 'delete':
      return <DeleteAction {...rest} />;
    case 'publish':
      return <PublishAction {...rest} />;
    case 'unpublish':
      return <UnpublishAction {...rest} />;
    case 'create':
      return <CreateAction {...rest} />;
    case 'edit':
      return <EditAction {...rest} />;
    default:
      assertUnreachable(type);
  }
};
