import { AllVendorInfo } from 'domain/api/vendor/fragments';
import { PublishProblemsFragment } from '../common/fragments';

export const AllEmbeddingInfo = `
  id
  published
  displayName
  iframeUrl
  ${PublishProblemsFragment}
  vendor {
    ${AllVendorInfo}
  }
  contentEntries {
    id
    builderId
    published
  }
`;
