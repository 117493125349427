import React from 'react';
import { Typography } from '@material-ui/core';

interface OwnProps {
  value?: boolean;
  checkedLabel?: string;
  unCheckedLabel?: string
}

type Props = OwnProps;

export const CheckboxFieldReadonly = ({
  value,
  checkedLabel = 'Enabled',
  unCheckedLabel = 'Not Enabled',
}: Props) => (
  <Typography style={{ whiteSpace: 'pre' }}>{value ? checkedLabel : unCheckedLabel}</Typography>
);
