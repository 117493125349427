import React from 'react';
import { apiAssetValidationSchema } from 'domain/api/asset/validation';

export const useDescriptionValidation = (value: string) => {
  const [error, setError] = React.useState<string | undefined>(undefined);
  React.useEffect(() => {
    apiAssetValidationSchema.fields.description
      .validate(value)
      .then(() => error && setError(undefined))
      .catch((err) => setError(err.errors[0]));
  });

  const setDescriptionFieldError = (err: string | undefined) => {
    setError(err);
  };

  return {
    descriptionFieldError: error,
    setDescriptionFieldError,
  };
};
