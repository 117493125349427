import { defaultUiText, UiText } from 'domain/uiText';
import { ExternalResourceConfig, InternalResourceConfig, makeResource } from 'lib/resource';
import {
  defaultInventory,
  defaultInventoryFields,
  Inventory,
  InventoryFields,
  inventoryFields,
  inventoryDataDisplayFields,
} from './types';
import { inventoryFieldsSchema } from './schemas';
import { defaultExternalResourceConfig } from '../shared';

export const inventoryInternalResourceConfig = (
  uiText: UiText
): InternalResourceConfig<Inventory, InventoryFields> => ({
  enabledPages: ['list', 'single'],
  defaultResource: defaultInventory,
  defaultResourceFields: defaultInventoryFields,
  fields: inventoryFields(uiText),
  fieldsValidationSchema: inventoryFieldsSchema(uiText),
  dataDisplay: inventoryDataDisplayFields(uiText),
});

export const defaultInventoryExternalResourceConfig: ExternalResourceConfig = {
  ...defaultExternalResourceConfig,
  aliases: {
    path: 'spec-homes',
    plural: 'Spec Homes',
    singular: 'Spec Home',
  },
};

export const defaultInventoryResource = makeResource({
  internalConfig: inventoryInternalResourceConfig(defaultUiText),
  externalConfig: defaultInventoryExternalResourceConfig,
});
