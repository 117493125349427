export const AllContactInfo = `
  id
  name
  email
  title
  phone
  salesCentres {
    id
    name
  }
`;
