import React from 'react';
import { Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

type Props = {
  hasExisting?: boolean;
  onReplace?: () => void;
  onUseOriginal?: () => void;
};

export const FileFieldControlsAndLabel = ({
  hasExisting,
  onReplace,
  onUseOriginal
}: Props) => {
  const classes = useStyles();

  return (
    <Grid
      container={true}
      wrap="nowrap"
      alignItems="center"
      className={classes.topContainer}
      spacing={1}
    >
      <Grid item={true} style={{ flexGrow: 2 }}>
        <Typography variant="caption">Image</Typography>
      </Grid>
      {onUseOriginal && (
        <Grid item={true}>
          <Button
            size="small"
            variant="outlined"
            onClick={onUseOriginal}
            className={classes.button}
          >
            Use Original Image
          </Button>
        </Grid>
      )}
      {onReplace && (
        <Grid item={true}>
          <Button
            size="small"
            variant="outlined"
            onClick={onReplace}
            className={classes.button}
          >
            {hasExisting ? 'Replace Image' : 'Select a Different Image'}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topContainer: {
    paddingBottom: theme.spacing(1),
  },
  button: {
    textTransform: 'capitalize',
  }
}));
