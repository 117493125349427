import { isKeyOf } from '_lib/common/helpers';
import { useEntityActionsConfig, useEntityFieldsConfig } from 'domain/config/hooks';
import { Config } from 'domain/config/types';
import { ResourceAction } from 'lib/resource';

export const useEntityFields = (entity: keyof Config['resources'] & string) => {
  const { disabledFields, allDisabled } = useEntityFieldsConfig(entity);
  function removeDisabledFields<T extends Record<string, any>>(values: T) {
    const newValues: Partial<{ [K in keyof T & string]: T[K] }>= {};

    if (allDisabled) return newValues;

    Object.keys(values).forEach(field => {
      if (isKeyOf(values, field) && !disabledFields.includes(field)) {
        newValues[field] = values[field];
      }
    });

    return newValues as Partial<T>;
  }

  return {
    removeDisabledFields,
  };
};

export const useEntityActions = (entity: keyof Config['resources'] & string) => {
  const { disabledActions, allDisabled } = useEntityActionsConfig(entity);

  const isActionDisabled = (action: ResourceAction) => {
    if (allDisabled) return true;
    return disabledActions.includes(action);
  };

  return {
    isActionDisabled,
  };
};
