import React from 'react';
import { Grid } from '@material-ui/core';
import { ResourcesContextValue } from 'system/resources/types';
import { ResourceConstraint } from 'lib/resource';
import { DeleteIconButton, Table } from '_lib/components';
import { useUiText } from 'domain/uiText';
import { FormLayout } from '_lib/form/formSection/components/FormLayout';
import { AddMappingsDialog } from './AddMappingsDialog';

export type Props<
TTarget extends ResourceConstraint,
TTargetsName extends keyof ResourcesContextValue,
> = {
  id: string;
  title: string;
  addMappingDialogTitle: string;
  icon?: JSX.Element;
  targetsName: TTargetsName;
  mappedTargets: TTarget[];
  unmappedTargets: TTarget[];
  addMappings: {
    execute: (ids: string[]) => void;
    loading: boolean;
    called: boolean;
  };
  removeMappings: {
    execute: (ids: string[]) => void;
    loading: boolean;
    called: boolean;
  };
  createAction: JSX.Element;
  publishAction?: (id: string) => void;
  unpublishAction?: (id: string) => void;
  loading: boolean;
  enabled: boolean;

};

export function RelationMappingForm<
TTarget extends ResourceConstraint,
TTargetsName extends keyof ResourcesContextValue,
>({
  id,
  title,
  addMappingDialogTitle,
  icon,
  targetsName,
  mappedTargets,
  unmappedTargets,
  addMappings,
  removeMappings,
  createAction,
  publishAction,
  unpublishAction,
  loading,
  enabled,
}: Props<TTarget, TTargetsName>) {
  const [selectedToDelete, setSelectedToDelete] = React.useState<string[]>([]);
  const uiText = useUiText();

  const handleSelect = (ids: string[]) => setSelectedToDelete(() => ids);

  const handleDelete = () => {
    selectedToDelete.length > 0 && removeMappings.execute(selectedToDelete);
  };
  const deleteAction = (
    <Grid item={true}>
      <DeleteIconButton
        onDelete={handleDelete}
        disabled={
          selectedToDelete.length <= 0 ||
          mappedTargets.length <= 0 ||
          removeMappings.loading ||
          !enabled
        }
        tooltip={uiText.SELECT_ENTRIES_TO_REMOVE}
      />
    </Grid>
  );

  const addAction = (
    <AddMappingsDialog
      title={addMappingDialogTitle}
      targetsName={targetsName}
      targets={unmappedTargets}
      disabled={addMappings.loading || !enabled}
      called={addMappings.called}
      onAddMappings={addMappings.execute}
      loading={loading}
    />
  );

  const actions = (
    <React.Fragment>
      {deleteAction}
      {addAction}
      {createAction}
    </React.Fragment>
  );

  React.useEffect(() => {
    if (mappedTargets.length !== selectedToDelete.length) {
      setSelectedToDelete(() => []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedTargets]);

  return (
    <Grid item={true} id={id} style={{ position: 'relative' }}>
      <FormLayout
        title={title}
        icon={icon as JSX.Element}
        actions={actions}
        formDisabled={!enabled}
        flat
      >
        <Table
          searchable={false}
          key={`relation-mappings-table-${targetsName}-${unmappedTargets.length + mappedTargets.length}`}
          resourceName={targetsName}
          /**
           * @todo Fix this
           */
          data={mappedTargets as any}
          noDataMessage="No data"
          actions={{
            publish: publishAction,
            unpublish: unpublishAction,
          }}
          onSelect={handleSelect}
          selectedRows={selectedToDelete}
          loading={removeMappings.loading || loading}
        />
      </FormLayout>
    </Grid>
  );
}
