import React from 'react';
import { useFormSemaphore } from './useFormSemaphore';

export const useFormState = (id: string) => {
  const [editing, setEditing] = React.useState(false);
  const openForm = () => setEditing(true);
  const closeForm = () => setEditing(false);
  const formId = `${id}-form`;
  const { enabled } = useFormSemaphore(formId, editing);
  return { editing, enabled, formId, openForm, closeForm };
};
