import React from 'react';
import { Typography } from '@material-ui/core';
import { Alert, AlertProps } from '@material-ui/lab';

type Props = {
  hasCommunity: boolean;
};

type LandingPageNotificationType = {
  severity: AlertProps['severity'];
  title?: string;
  subTitle?: string;
  bullets?: string[];
};

export const LandingPageNotification = (props: Props) => {
  const { hasCommunity } = props;

  const notifications: { [name: string]: LandingPageNotificationType } = {
    gettingStarted: {
      severity: 'info',
      title: 'Getting Started',
      subTitle: 'To get started why not try creating your first Community',
      bullets: [
        'Head to the Communities page.',
        'Click the "New Community" button in the top right.',
        'Fill in the required information then click "Submit".',
      ],
    },
    generic: {
      severity: 'info',
      subTitle: 'Check here for tips and useful information regarding new features.',
    },
  };

  const currentNotification = hasCommunity ? notifications.generic : notifications.gettingStarted;

  return (
    <Alert severity={currentNotification.severity}>
      <Typography align="left" variant="h6" style={{ margin: 0 }}>
        {currentNotification.title}
      </Typography>
      <Typography variant="subtitle2">{currentNotification.subTitle}</Typography>
      {currentNotification.bullets && (
        <ul style={{ textAlign: 'left' }}>
          {currentNotification.bullets.map((b, i) => (
            <li key={b}>{b}</li>
          ))}
        </ul>
      )}
    </Alert>
  );
};
