import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { IconButton, InputBase, makeStyles, Typography } from '@material-ui/core';

export const chipInputId = (index: number) => `text-list-chip-field-input-${index}`;

type Props = {
  className?: string;
  disabled?: boolean;
  index: number;
  value: string;
  placeholder?: string;
  readonly?: boolean;
  autoFocus?: boolean;
  onChange: (value: string, index: number) => void;
  onDelete?: (index: number) => void;
  onEnterPressed: () => void;
};

const TEXT_SCALE_FACTOR = .9;

export const TextListFieldChip = ({
  className,
  index,
  disabled,
  placeholder,
  value,
  readonly,
  autoFocus,
  onChange,
  onDelete,
  onEnterPressed,
}: Props) => {
  const classes = useStyles();
  // Used to apply styles to the container when the input is focused
  const [isFocused, setIsFocused] = React.useState(false);

  const handleChange = (e: React.BaseSyntheticEvent) => {
    onChange(e.target.value, index);
  };

  const handleDelete = () => onDelete && onDelete(index);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') onEnterPressed();
  };

  const containerClassNames = classNames(
    classes.container,
    className,
    {
      [classes.containerHover]: !readonly && !disabled,
      [classes.focused]: isFocused && !disabled,
    }
  );

  return (
    <div className={containerClassNames}>
      {readonly ? (
        <Typography className={classes.readonly}>{value}</Typography>
      ) : (
        <React.Fragment>
          <InputBase
            id={chipInputId(index)}
            className={classes.input}
            style={{ width: `${(placeholder || value).length * TEXT_SCALE_FACTOR}ch` }}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onKeyPress={handleKeyPress}
            autoFocus={autoFocus && !disabled}
            disabled={disabled}
          />
          {onDelete && (
            <IconButton
              className={classes.deleteButton}
              size="small"
              onClick={handleDelete}
              disabled={disabled}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(1),
    paddingRight: 4,
    backgroundColor: theme.palette.grey[300],
    borderRadius: Number.MAX_SAFE_INTEGER,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    border: 'solid 1px transparent',
    maxWidth: '100%',
  },
  containerHover: {
    '&:hover': {
      border: `solid 1px ${theme.palette.primary.main}`,
    },
  },
  focused: {
    border: `solid 1px ${theme.palette.primary.main}`,
  },
  readonly: {
    fontSize: '0.85rem',
    height: theme.spacing(3),
    lineHeight: `${theme.spacing(3)}px`,
  },
  input: {
    fontSize: '0.85rem',
    height: theme.spacing(3),
  },
  deleteButton: {
    backgroundColor: theme.palette.grey[400],
    fontSize: theme.typography.caption.fontSize,
    '&:hover': {
      backgroundColor: theme.palette.grey[500],
    },
  }
}));
