import * as yup from 'yup';
import { UiText } from 'domain/uiText';
import { ContactFields } from './types';

export const contactFieldsSchema = (uiText: UiText) =>
  yup
    .object<ContactFields>({
      name: yup.string().defined().required().default('').label('Name'),
      email: yup.string().defined().default('').label('Email'),
      title: yup.string().defined().default('').label('Job Title'),
      phone: yup.string().defined().default('').label('Phone Number'),
    })
    .defined();
