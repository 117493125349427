import { createFailedUnpublishMessage, createSuccessfulUnpublishMessage, useSetMessage } from '_lib/messages';
import { useIdInputMutation } from 'domain/api/common/adapters/useIdInputMutation';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { ValidatedEntity } from 'domain/api/common/types';
import { UNPUBLISH_ONE_INVENTORY } from './gql';
import { ApiInventory, Inventory } from '../types';
import { transformApiInventoryToInventory } from '../transforms';

type Response = {
  unpublishInventory: {
    inventory?: ApiInventory;
  };
};

export const useUnpublishOneInventory = () => {
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  return useIdInputMutation<Response, ValidatedEntity<Inventory> | undefined>(
    UNPUBLISH_ONE_INVENTORY,
    (data) => schemas?.inventory.apiSchema ? transformApiInventoryToInventory(
      validateApiResponseEntity(
        data?.unpublishInventory.inventory,
        schemas.inventory.apiSchema,
      ),
    ) : undefined,
    {
      onSuccess: () => setMessage(createSuccessfulUnpublishMessage('')),
      onError: (err) => setMessage(createFailedUnpublishMessage('', err)),
    }
  );
};
