import { useMutation } from '@apollo/client';
import { AssetFile } from 'domain/api/asset';
import { CREATE_ONE_ASSET } from 'domain/api/asset/createOne/gql';
import { CreateAssetInput, CreateAssetResponse } from 'domain/api/asset/createOne/hook';
import { apolloClient } from 'domain/api/_apollo/client';
import { useContentSpaceId } from '_lib/auth';
import { pollAsset } from 'domain/api/asset/pollAsset/pollAsset';
import { GetOneVariables, ResponseWithContentSpace } from 'domain/api/common/types';
import { ApiImageLink } from '../types';
import { GET_ONE_IMAGE_LINK } from '../getOne/gql';

type GetOneImageLinkResponse = ResponseWithContentSpace<{ imageLink: ApiImageLink }>;

export const useCreateImageLinkAsset = () => {
  const contentSpaceId = useContentSpaceId();
  const [mutation, mutationResult] = useMutation<CreateAssetResponse, CreateAssetInput>(
    CREATE_ONE_ASSET,
  );

  const execute = async (
    imageLink: { id: string },
    file: AssetFile,
  ): Promise<ApiImageLink> => {
    try {
      if (!contentSpaceId) throw new Error('Missing contentSpaceId');

      const createAssetResult = await mutation({
        variables: {
          input: {
            file,
            contentSpaceId,
            contentId: imageLink.id,
            attributeName: 'displayImageNormal',
            displayOrder: null,
          },
        },
      });

      const asset = createAssetResult.data?.createAsset.asset;

      if (!asset?.id) throw new Error('Failed to create asset');

      // Start polling for the new asset
      const createdAsset = await pollAsset(asset.id, contentSpaceId);
      if (!createdAsset) throw new Error('Failed to retrieve asset');

      // Refetch the updated imageLink
      const refetchedImageLink = await apolloClient.query<
      GetOneImageLinkResponse,
      GetOneVariables
      >({
        query: GET_ONE_IMAGE_LINK,
        variables: { id: imageLink.id, contentSpaceId },
        fetchPolicy: 'network-only',
      });

      return refetchedImageLink.data.contentSpace.imageLink;
    } catch (error) {
      return error;
    }
  };

  return {
    execute,
    ...mutationResult,
    result: mutationResult.data?.createAsset.asset,
  };
};
