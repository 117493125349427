import React from 'react';
import { autoGenerateBuilderIdFromDisplayName } from 'system/resources/utilities/fieldModifiers';
import { useDisplayNameAvailable } from 'system/resources/hooks/useDisplayNameAvailable';
import { Community, useCheckCommunityDisplayName } from 'domain/api/community';
import { LayoutRenderer } from 'domain/view/components/layout/layoutRenderer/LayoutRenderer';
import { useUiText } from 'domain/uiText';
import { EntityDialogActionForm } from 'domain/view/components/form/EntityDialogActionForm';

type Props = {
  onSubmit: (values: Community) => void;
  submitting: boolean;
};

export const CreateCommunityForm = ({ onSubmit, submitting }: Props) => {
  const uiText = useUiText();
  const { error, success, onChange, loading, reset } = useDisplayNameAvailable(
    useCheckCommunityDisplayName
  );
  return (
    <EntityDialogActionForm
      resourceName="community"
      onSubmit={onSubmit}
      externalFieldErrors={{
        displayName: error ? [error] : [],
      }}
      fieldModifiers={{
        displayName: autoGenerateBuilderIdFromDisplayName,
      }}
      title={uiText.ACTION_NEW_COMMUNITY_LABEL}
      action={{
        type: 'create',
        label: uiText.ACTION_NEW_COMMUNITY_LABEL,
      }}
      onClose={reset}
      submitting={submitting}
      includeUntouchedFields={true}
    >
      {(renderField) => (
        <LayoutRenderer
          items={[
            {
              container: true,
              direction: 'column',
              spacing: 2,
              items: [
                {
                  container: true,
                  item: true,
                  spacing: 2,
                  items: [
                    {
                      item: true,
                      xl: 6,
                      renderContent: () =>
                        renderField('displayName', {
                          onChangeCallback: onChange,
                          loading,
                          successText: success,
                        }),
                    },
                    {
                      item: true,
                      xl: 6,
                      renderContent: () => renderField('builderId'),
                    },
                    {
                      item: true,
                      xl: 6,
                      renderContent: () => renderField('communityType'),
                    },
                    {
                      item: true,
                      xl: 6,
                      renderContent: () => renderField('communityGroup'),
                    },
                  ],
                },
              ],
            },
          ]}
        />
      )}
    </EntityDialogActionForm>
  );
};
