import React from 'react';
import { useGetManyEmbeddings } from 'domain/api/embedding';
import { useGetManyVendors } from 'domain/api/vendor';
import { ResourcesContextValue } from 'system/resources/types';
import { Embedding } from 'domain/api/embedding/types';

type Props = {
  sourceId: string;
  mapped: Embedding[];
  resourceName: Extract<
  keyof Pick<ResourcesContextValue, 'embedding' | 'video'>, string
  >;
};

const filterEmbeddingsByVendor = (
  embeddings: Embedding[],
  isVideo?: boolean,
) => {
  return embeddings.filter((embedding) => {
    if (!isVideo && embedding.vendor.type === 'video') return false;
    if (isVideo && embedding.vendor.type !== 'video') return false;
    return true;
  });
};

const embeddingsWithVendorNameAndStatus = (
  embeddings: Embedding[],
) => embeddings.map(el => ({
  ...el,
  vendorName: el.vendor.displayName,
  publishedStatus: el.publishedStatus,
}));

export const useEmbeddingMappingsData = ({
  sourceId,
  mapped,
  resourceName,
}: Props) => {
  const isVideo = resourceName === 'video';
  const getEmbeddings = useGetManyEmbeddings(isVideo);
  const getVendors = useGetManyVendors();

  React.useEffect(() => {
    if (!getEmbeddings.called) {
      getEmbeddings.execute();
    }
    if (!getVendors.called) {
      getVendors.execute();
    }
  }, [getEmbeddings, getVendors]);

  const mappedValidatedAndTransformed = React.useMemo(() => {
    return embeddingsWithVendorNameAndStatus(
      filterEmbeddingsByVendor(mapped, isVideo),
    );
  }, [mapped, isVideo]);

  const unmappedTransformed = React.useMemo(() => {
    return embeddingsWithVendorNameAndStatus(
      filterEmbeddingsByVendor(
        getEmbeddings.result.filter(el => (
          !el.contentEntries.find((entry) => entry === sourceId))
        ),
        isVideo,
      ),
    );
  }, [isVideo, getEmbeddings.result, sourceId]);

  const vendorsTransformed = React.useMemo(() => (
    getVendors.result
      .filter(el => (
        (isVideo && el.type === 'video') || (!isVideo && el.type !== 'video'))
      )
  ), [isVideo, getVendors.result]);

  return {
    mapped: mappedValidatedAndTransformed,
    unmapped: unmappedTransformed,
    vendors: vendorsTransformed,
  };
};
