import React from 'react';
import { useResetableAdapterAction } from 'domain/api/common/hooks';
import { FeatureTag, useCreateOneFeatureTag, useGetManyFeatureTags } from 'domain/api/featureTag';
import { FeatureTagCategory, useGetManyFeatureTagCategories } from 'domain/api/featureTagCategory';

export const useAllFeatureTags: () => { allFeatureTags: FeatureTag[] } = () => {
  const { execute, result } = useGetManyFeatureTags();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => { execute(); }, []);

  return { allFeatureTags: result };
};

export const useAllFeatureTagCategories: () => { allCategories: FeatureTagCategory[] } = () => {
  const { execute, result } = useGetManyFeatureTagCategories();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => { execute(); }, []);

  const sortedResult = [...result].sort((a, b) => a.displayOrder - b.displayOrder)
  return {
    allCategories: sortedResult
   };
}

export const useCreateFeatureTagAndAdd = (addTag: (tag: FeatureTag) => void) => {
  const { execute, result, called } = useResetableAdapterAction(useCreateOneFeatureTag);
  const createFeatureTagAndAdd = (
    name: string,
    description: string,
    categoryId: string,
    showFloorPlan: boolean
  ) => execute({
    name,
    description,
    featureTagCategoryId: categoryId,
    showFloorPlanViewer: showFloorPlan
  });

  React.useEffect(() => {
    if (result && called) {
      addTag(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, called]);

  return {
    createFeatureTagAndAdd,
  };
}
