import { defaultUiText } from 'domain/uiText';
import { ExternalResourceConfig, makeResource } from 'lib/resource';
import { defaultEmbeddingExternalResourceConfig, embeddingInternalResourceConfig } from './embeddingResource';

export const defaultVideoExternalResourceConfig: ExternalResourceConfig = {
  ...defaultEmbeddingExternalResourceConfig,
  aliases: {
    path: 'videos',
    plural: 'Videos',
    singular: 'Video',
  },
};

export const defaultVideoResource = makeResource({
  internalConfig: embeddingInternalResourceConfig(defaultUiText),
  externalConfig: defaultVideoExternalResourceConfig,
});
