import { DocumentNode, useLazyQuery } from '@apollo/client';
import { AdapterAction } from 'domain/api/common/adapters/types';
import { useContentSpaceId } from '_lib/auth';

type CheckDisplayNameVariables = {
  contentSpaceId: string;
  displayName: string;
};

/**
 * @description
 * A hook that checks if the provided displayName is available.
 */
export function useCheckDisplayName<TResponse>(
  gqlQuery: DocumentNode,
  responseTransformer: (response?: TResponse) => boolean | undefined,
): AdapterAction<string, boolean | undefined> {
  const contentSpaceId = useContentSpaceId();
  const [query, queryResult] = useLazyQuery<TResponse, CheckDisplayNameVariables>(
    gqlQuery,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  const execute = (displayName: string) => {
    if (!contentSpaceId) return;
    query({ variables: { contentSpaceId, displayName } });
  };

  return {
    execute,
    result: responseTransformer(queryResult.data),
    loading: queryResult.loading,
    called: queryResult.called,
    error: queryResult.error?.message,
  };
}
