import { useHistory } from 'react-router';
import {
  createFailedCreationMessage,
  createSuccessfulCreationMessage,
  useSetMessage,
} from '_lib/messages';
import { ValidatedEntity } from 'domain/api/common/types';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { updateCacheOnCreateOne } from 'domain/api/common/cache';
import { useCreateOrUpdateOne } from 'domain/api/common/adapters/useCreateOrUpdateOne';
import { generateCreateOrUpdateString } from 'domain/api/common/fragments';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { ApiCommunity, ApiCommunityInput, Community } from '../types';
import { AllCommunityInfo } from '../fragments';
import {
  transformApiCommunityToCommunity,
  transformCommunityToApiCommunityInput,
} from '../transforms';

const CREATE_ONE_COMMUNITY = generateCreateOrUpdateString('create', 'community', AllCommunityInfo);

type Response = {
  createCommunity: {
    community: ApiCommunity | undefined;
  };
};

type Input<T> = {
  contentSpaceId: string;
  builderId: string;
  community: T;
};

export const useCreateOneCommunity = (redirectPath?: string) => {
  const history = useHistory();
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  const { execute: executeMutation, ...rest } = useCreateOrUpdateOne<
  Input<ApiCommunityInput>,
  Response,
  ValidatedEntity<Community> | undefined
  >(
    CREATE_ONE_COMMUNITY,
    (data) =>
      schemas?.community.apiSchema
        ? transformApiCommunityToCommunity(
          validateApiResponseEntity(data?.createCommunity.community, schemas.community.apiSchema)
        )
        : undefined,
    {
      onSuccess: (result) => {
        setMessage(createSuccessfulCreationMessage(''));
        if (redirectPath && result) history.push(`/${redirectPath}/${result.id}`);
      },
      onError: (err) => setMessage(createFailedCreationMessage('', err)),
      updateCache: (cache, data) =>
        updateCacheOnCreateOne(
          cache,
          'communities',
          data?.createCommunity.community,
          `fragment AllCommunityInfo on Community {
            ${AllCommunityInfo}
          }`
        ),
    }
  );

  const execute = (input: Input<Partial<Community>>) =>
    executeMutation({
      ...input,
      community: transformCommunityToApiCommunityInput(input.community),
    });

  return { execute, ...rest };
};
