import { gql } from '@apollo/client';
import { AllSalesCentreInfo } from '../fragments';

export const ADD_SALES_CENTRE_CONTACTS = gql`
fragment AllSalesCentreInfo on SalesCentre {
  ${AllSalesCentreInfo}
}
mutation addSalesCentreContacts($input: AddSalesCentreContactsInput) {
  addSalesCentreContacts(input: $input) {
    salesCentre {
      ...AllSalesCentreInfo
    }
  }
}
`;
