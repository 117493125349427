import { makeStyles } from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';
import LogoPin from '../../assets/logo-pin.svg';
import LogoChevronLeft from '../../assets/logo-chevron-left.svg';
import LogoChevronRight from '../../assets/logo-chevron-right.svg';

export const FullScreenLoader = React.memo(() => {
  const classes = useStyles();
  return (
    <div className={classes.loadingFrame}>
      <div className={classes.loadingLogo}>
        <img className={classNames(classes.logoImg, classes.loadingLogoChevronLeft)} src={LogoChevronLeft} alt="" />
        <img className={classNames(classes.logoImg, classes.loadingLogoChevronRight)} src={LogoChevronRight} alt="" />
        <img className={classNames(classes.logoImg, classes.loadingLogoPin)} src={LogoPin} alt="OpenHouse.AI is loading" />
      </div>
    </div>
  );
});

const useStyles = makeStyles(() => ({
  loadingFrame: {
    backgroundColor: '#ffffff',
    position: 'absolute',
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999999
  },
  loadingLogo: {
    position: 'relative',
    width: '20rem',
    height: '20rem',
  },
  logoImg: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  '@keyframes pinDrop': {
    '0%': { bottom: '5rem', opacity: 0, },
    '50%': { bottom: '-1.5rem', opacity: 1, },
    '100%': { bottom: 0, },
  },
  loadingLogoPin: {
    animation: '$pinDrop 1s ease 0.25s backwards',
  },
  '@keyframes chevronSlideLeft': {
    '0%': { left: '4rem', opacity: 0, transform: 'scale(0.5)', },
    '100%': { left: 0, opacity: 1, },
  },
  '@keyframes chevronPulseLeft': {
    '0%': { left: 0 },
    '50%': { left: '1rem' },
    '100%': { left: 0 },
  },
  loadingLogoChevronLeft: {
    transformOrigin: '50% 90%',
    animation: '$chevronSlideLeft 0.325s ease-out 0.825s backwards, $chevronPulseLeft 2s ease 3s infinite',
  },
  '@keyframes chevronSlideRight': {
    '0%': { right: '4rem', opacity: 0, transform: 'scale(0.5)', },
    '100%': { right: 0, opacity: 1, },
  },
  '@keyframes chevronPulseRight': {
    '0%': { right: 0 },
    '50%': { right: '1rem' },
    '100%': { right: 0 },
  },
  loadingLogoChevronRight: {
    transformOrigin: '50% 90%',
    animation: '$chevronSlideRight 0.325s ease-out 0.825s backwards, $chevronPulseRight 2s ease 3s infinite',
  }
}));
