import * as yup from 'yup';
import { UiText } from 'domain/uiText/uiText';
import { pickCommonApiValidationSchemas } from '../common/validation';
import { ApiFloorPlanLayout } from './types';
import { apiAssetValidationSchema } from '../asset/validation';

export const apiFloorPlanLayoutValidationSchema = (uiText: UiText) =>
  yup
    .object<ApiFloorPlanLayout, object>({
      ...pickCommonApiValidationSchemas(uiText)([
        'id',
        'bedrooms',
        'bathrooms',
        'halfBaths',
        'floorSize',
        'displayName',
      ]),
      description: yup.string().defined().default(null),
      displayOrder: yup.number().defined(),
      assets: yup.array().of(apiAssetValidationSchema).defined().default([]),
    })
    .defined();
