import React from 'react';
import { createFailedCreationMessage, createSuccessfulCreationMessage, useSetMessage } from '_lib/messages';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { ApiImageLink, ImageLinkInput } from '../types';
import { useCreateImageLink } from './useCreateImageLink';
import { transformApiImageLinkToImageLink } from '../transforms';
import { usePublishOneImageLink } from '../publishOne';

export const useCreateOneImageLink = (
  entity?: { id: string, __typename: string, [x: string]: any },
  isLarge?: boolean,
) => {
  const setMessage = useSetMessage();
  const schema = useApiSchemas()?.imageLink;
  const createImageLink = useCreateImageLink(entity, isLarge);
  const [apiResult, setApiResult] = React.useState<ApiImageLink | undefined>();
  const publishOneImageLink = usePublishOneImageLink();

  const transformAndValidate = (apiImageLink?: ApiImageLink) => {
    if (!schema || !apiImageLink) return undefined;

    return transformApiImageLinkToImageLink(
      validateApiResponseEntity({ ...apiImageLink }, schema.apiSchema),
    );
  };

  const execute = (input: ImageLinkInput, alsoPublish?: boolean ) => {
    createImageLink.execute(input)
      .then(res => {
        setApiResult(res);
        setMessage(createSuccessfulCreationMessage(''));
        if (alsoPublish) publishOneImageLink.execute({ id: res.id });
      })
      .catch(err => {
        setMessage(createFailedCreationMessage('', err));
      });
  };

  return {
    execute,
    result: transformAndValidate(apiResult),
    loading: createImageLink.loading,
    called: createImageLink.called,
    error: createImageLink.error?.message,
  };
};
