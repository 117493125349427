import React from 'react';
import { LayoutRenderer } from 'lib';
import { correctShowHomeAndInventoryContentEntries, generateContentEntrySelectOptions } from 'utilities/helpers';
import { Community } from 'domain/api/community/types';
import { FloorPlan } from 'domain/api/floorPlan/types';
import { Inventory } from 'domain/api/inventory/types';
import { ShowHome } from 'domain/api/showhome/types';
import { ImageLink, ImageLinkInput } from 'domain/api/imageLink/types';
import { useUpdateOneImageLink } from 'domain/api/imageLink';
import { EntityDialogActionForm } from 'domain/view/components/form/EntityDialogActionForm';
import { useUiText } from 'domain/uiText';

type Props = {
  floorPlans: FloorPlan[];
  communities: Community[];
  inventories: Inventory[];
  showHomes: ShowHome[];
  imageLink: ImageLink;
};

export const EditBannerForm = ({
  imageLink,
  floorPlans,
  communities,
  inventories,
  showHomes,
}: Props) => {
  const uiText = useUiText();
  const updateImageLink = useUpdateOneImageLink(imageLink);

  /**
   * @todo Delete this when BE is updated to support showHome contentEntries
   */
  const correctedData = correctShowHomeAndInventoryContentEntries(
    showHomes,
    imageLink,
  );

  const communityOptions = generateContentEntrySelectOptions(communities);
  const inventoryOptions = generateContentEntrySelectOptions(inventories);
  const floorPlanOptions = generateContentEntrySelectOptions(floorPlans);
  const showHomeOptions = generateContentEntrySelectOptions(showHomes);

  const handleSubmit = (
    values: ImageLinkInput & { id: string },
    close: () => void,
  ) => {
    updateImageLink.execute({
      ...values,
      id: imageLink.id,
    })
      .then(() => close());
  };

  return (
    <EntityDialogActionForm
      resourceName="banner"
      onSubmit={handleSubmit}
      title={uiText.ACTION_EDIT_BANNER_LABEL}
      action={{
        type: 'edit',
        label: uiText.ACTION_EDIT_LABEL,
        isIcon: true,
        isSmall: true,
      }}
      submitting={updateImageLink.loading}
      initialValues={correctedData}
      includeUntouchedFields={true}
    >
      {(renderField) => (
        <LayoutRenderer
          items={[
            {
              container: true, direction: 'column', spacing: 2,
              items: [
                {
                  renderContent: () => renderField('displayName'),
                },
                {
                  renderContent: () => renderField('url'),
                },
                {
                  container: true,
                  xl: 12,
                  renderContentGridProps: { style: { width: '100%' } },
                  renderContent: () => renderField('files', {
                    existingUrl: imageLink.assets.length > 0 ? imageLink.assets[0].url : undefined
                  }),
                },
                {
                  renderContent: () =>
                    renderField('floorPlans', {
                      options: floorPlanOptions.options,
                      additionalAction: {
                        value: floorPlanOptions.publishedIds,
                        label: uiText.LABEL_ALL_PUBLISHED,
                      },
                    }),
                },
                {
                  renderContent: () =>
                    renderField('communities', {
                      options: communityOptions.options,
                      additionalAction: {
                        value: communityOptions.publishedIds,
                        label: uiText.LABEL_ALL_PUBLISHED,
                      },
                    }),
                },
                {
                  renderContent: () =>
                    renderField('inventories', {
                      options: inventoryOptions.options,
                      additionalAction: {
                        value: inventoryOptions.publishedIds,
                        label: uiText.LABEL_ALL_PUBLISHED,
                      },
                    }),
                },
                {
                  renderContent: () =>
                    renderField('showHomes', {
                      options: showHomeOptions.options,
                      additionalAction: {
                        value: showHomeOptions.publishedIds,
                        label: uiText.LABEL_ALL_PUBLISHED,
                      },
                    }),
                },
              ],
            },
          ]}
        />
      )}
    </EntityDialogActionForm>
  );
};
