import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Drawer,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import SidebarBackground from 'assets/sidebar-background.svg';
import { APP_SIDEBAR_WIDTH, APP_TOPBAR_HEIGHT } from '../../../constants';

type Props = {
  children: React.ReactNode;
  footer: React.ReactNode;
};

export const AppSidebar = React.memo(({ children, footer }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        PaperProps={{ elevation: 4 }}
        classes={{ paper: classes.paper }}
      >
        <Grid container direction="column" wrap="nowrap" className={classes.gridContainer}>
          <Grid item className={classes.topbarSection}>
            <RouterLink to="/" style={{ display: 'block' }}>
              <img
                className={classes.logo}
                alt="OpenHouse.ai Logo"
                src="http://storage.googleapis.com/production_assets/openhouse_apps/openhouse_logo.png"
              />
            </RouterLink>
          </Grid>
          <Grid item className={classes.mainList}>
            {children}
          </Grid>
          <Grid
            className={classes.bottomContent}
            item
            container
            direction="column"
            alignItems="center"
            justify="flex-end"
          >
            <img className={classes.background} src={SidebarBackground} alt="" />
            <Grid item>
              {footer}
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'relative',
  },
  drawer: {
    width: APP_SIDEBAR_WIDTH,
    flexShrink: 0,
    height: '100vh',
    border: 'none',
  },
  paper: {
    width: APP_SIDEBAR_WIDTH,
    border: 'none',
    position: 'relative',
    overflow: 'hidden',
  },
  gridContainer: {
    height: '100%',
    position: 'relative',
  },
  topbarSection: {
    height: APP_TOPBAR_HEIGHT,
    padding: theme.spacing(3),
    paddingBottom: 0,
    paddingTop: theme.spacing(2),
    width: '100%',
    position: 'relative',
  },
  logo: {
    maxWidth: `calc(${APP_SIDEBAR_WIDTH}px - ${theme.spacing(3) * 2}px)`,
  },
  mainList: {
    flexGrow: 2,
    overflowY: 'auto',
  },
  activeLinkContainer: {
    borderLeft: `5px solid ${theme.palette.primary.main}`,
  },
  activeLink: {
    color: theme.palette.primary.main,
  },
  bottomContent: {
    padding: theme.spacing(2),
    flexBasis: 180,
    flexShrink: 10,
    position: 'relative',
    zIndex: 2,
  },
  background: {
    width: APP_SIDEBAR_WIDTH,
    position: 'absolute',
    top: -30,
    left: 0,
    zIndex: -1,
  },
}));
