import React from 'react';
import { Checkbox, Grid, Tooltip } from '@material-ui/core';
import { DeleteButton } from '_lib/components';
import { useQueuedJobs } from 'common/hooks/useQueuedJobs';
import { useAssetFormContext } from '../../AssetFormContext';
import { AssetFormPageSectionItems } from '../../components/AssetFormPageSectionItems';
import { AssetFormPageSectionHeader } from '../../components/AssetFormPageSectionHeader';
import { AssetFormDeletingItem } from './AssetFormDeletingItem';

interface Props {
  showCheckeredBackground?: boolean;
}

export const AssetFormDeleting = ({ showCheckeredBackground }: Props) => {
  const {
    assets, attributeName, entityContentId, updateContext, isMultiple, isPdf,
  } = useAssetFormContext();
  const {
    isCurrentJob, addSelected, removeSelected, start,
    onJobSuccess, onJobFailed, selected, isSelected, isRunning,
    isFailedJob, hasFailedJobs, reset,
  } = useQueuedJobs({
    onCompleted: () => {
      if (!hasFailedJobs) {
        updateContext({ step: 'readonly' });
      } else {
        reset();
      }
    },
    delayMs: 300,
  });

  const handleSelectAsset = (id: string) => () => {
    if (isSelected(id)) {
      removeSelected(id);
    } else {
      addSelected(id, id);
    }
  };

  const handleSelectAll = () => {
    if (selected.length === assets.length) {
      assets.forEach(asset => {
        removeSelected(asset.id);
      });
    } else {
      assets.forEach(asset => {
        addSelected(asset.id, asset.id);
      });
    }
  };

  const handleSubmit = () => start();

  const handleSuccess = (id: string) => onJobSuccess(id);

  React.useEffect(() => {
    if (!isMultiple && assets.length === 1 && !isSelected(assets[0].id)) {
      addSelected(assets[0].id, assets[0].id);
    }
  }, [assets, addSelected, isSelected, isMultiple]);

  return (
    <React.Fragment>
      <AssetFormPageSectionHeader
        actionsDisabled={isRunning}
        additionalActions={(
          <React.Fragment>
            {isMultiple && (
              <Grid item={true}>
                <Tooltip title="Select all">
                  <span>
                    <Checkbox
                      color="primary"
                      onClick={handleSelectAll}
                      checked={selected.length === assets.length}
                      disabled={isRunning}
                    />
                  </span>
                </Tooltip>
              </Grid>
            )}
            <Grid item={true}>
              <DeleteButton
                onDelete={handleSubmit}
                disabled={isRunning}
              />
            </Grid>
          </React.Fragment>
        )}
      />
      <AssetFormPageSectionItems>
        {(renderAssetItem) => (
          <React.Fragment>
            {assets.map((asset, i) => renderAssetItem(
              <AssetFormDeletingItem
                index={i}
                url={asset.url}
                id={asset.id}
                key={`${asset.id}-deleting-${entityContentId}-${attributeName}`}
                onSuccess={() => handleSuccess(asset.id)}
                onError={() => onJobFailed(asset.id)}
                startUpdate={isCurrentJob(asset.id)}
                selected={isSelected(asset.id)}
                failed={isFailedJob(asset.id)}
                onSelect={handleSelectAsset(asset.id)}
                displayName={isPdf ? asset.displayName : undefined}
                showCheckeredBackground={showCheckeredBackground}
              />,
              asset.id,
            ))}
          </React.Fragment>
        )}
      </AssetFormPageSectionItems>
    </React.Fragment>
  );
};
