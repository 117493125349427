import React from 'react';
import { ReadonlyField } from '../shared/ReadonlyField/ReadonlyField';
import { SelectFieldControl } from './SelectFieldControl';
import { SelectFieldReadonly } from './SelectFieldReadonly';
import { SelectFieldProps, SelectOption } from './types';

export const getSelectedOption = (
  value: string | string[] | null,
  options: SelectOption[] = [],
) => {
  if (!value) return undefined;
  return options.filter(option => {
    if (Array.isArray(value)) return value.includes(option.value);
    return option.value === value;
  });
};

export function SelectField<TName extends string>(props: SelectFieldProps<TName>) {
  const { readonly, options, ...rest } = props;

  const sortedOptions = [...options].sort((a, b) => {
    const labelA = a.label.toLowerCase();  // case-insensitive compare
    const labelB = b.label.toLowerCase();
    if (labelA > labelB) return 1;
    if (labelA < labelB) return -1;
    return 0;
  });

  if (readonly) {
    return (
      <ReadonlyField errors={rest.errors} label={rest.label}>
        <SelectFieldReadonly value={getSelectedOption(rest.value, sortedOptions)} />
      </ReadonlyField>
    );
  }
  return <SelectFieldControl options={sortedOptions} {...rest} />;
}
