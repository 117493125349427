import { createFailedUpdateMessage, createSuccessfulUpdateMessage, useSetMessage } from '_lib/messages';
import { IdType, ValidatedEntity, WithClearList } from 'domain/api/common/types';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { useCreateOrUpdateOne } from 'domain/api/common/adapters/useCreateOrUpdateOne';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { generateCreateOrUpdateString } from 'domain/api/common/fragments';
import { transformClearableFieldsToClearList } from 'domain/api/common/transforms';
import { ApiInventory, ApiInventoryInput, CLEARABLE_INVENTORY_FIELDS, Inventory } from '../types';
import { transformApiInventoryToInventory, transformInventoryToApiInventoryInput } from '../transforms';
import { AllInventoryInfo } from '../fragments';

const UPDATE_ONE_INVENTORY = generateCreateOrUpdateString('update', 'inventory', AllInventoryInfo);

type Response = {
  updateInventory: {
    inventory: ApiInventory | undefined;
  }
};

type Input<T> = {
  contentSpaceId: string;
  id: string;
  idType: IdType;
  inventory: T;
};

export const useUpdateOneInventory = () => {
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  const { execute: executeMutation, ...rest } = useCreateOrUpdateOne<
  WithClearList<ApiInventoryInput, Input<ApiInventoryInput>>,
  Response,
  ValidatedEntity<Inventory> | undefined
  >(
    UPDATE_ONE_INVENTORY,
    (data) => schemas?.inventory.apiSchema ? transformApiInventoryToInventory(
      validateApiResponseEntity(
        data?.updateInventory.inventory,
        schemas.inventory.apiSchema,
      ),
    ) : undefined,
    {
      onSuccess: () => setMessage(createSuccessfulUpdateMessage('')),
      onError: (err) => setMessage(createFailedUpdateMessage('', err)),
    },
  );

  const execute = (input: Input<Partial<Inventory>>) => {
    const transformed = transformInventoryToApiInventoryInput(input.inventory);
    executeMutation({
      ...input,
      inventory: transformed,
      clear: transformClearableFieldsToClearList(transformed, CLEARABLE_INVENTORY_FIELDS)
        // Temporary work around for inconsistent field names in clearList
        .map(el => el === 'possessionDateSec' ? 'possessionDate' as any : el),
    });
  };

  return { execute, ...rest };
};
