import { UiText } from 'domain/uiText';
import { DataDisplay, Resource, ResourceFields } from 'lib/resource';
import { imageCellRenderer } from '_lib/components/table/CellRenderers';
import { Asset, AssetFile } from 'domain/api/asset/types';

export type ImageLinkContentEntry = {
  id: string;
  builderId: string;
  published: boolean;
  __typename: string;
};

export type ImageLink = {
  id: string;
  published: boolean;
  displayName: string;
  url: string;
  isLarge: boolean;
  contentEntries: ImageLinkContentEntry[];
  assets: Asset[];
};
export const defaultImageLink: ImageLink = {
  id: '',
  published: false,
  displayName: '',
  url: '',
  isLarge: false,
  contentEntries: [],
  assets: [],
};

export type ImageLinkFields =  {
  displayName: string;
  url: string;
  files: AssetFile[];
  contentEntries?: string[],
  floorPlans: string[];
  communities: string[];
  inventories: string[];
  showHomes: string[];
};

export type ImageLinkResource = Resource<
ImageLink,
ImageLinkFields
>;


export const defaultImageLinkFields: ImageLinkFields = {
  displayName: '',
  url: '',
  files: [],
  contentEntries: [],
  floorPlans: [],
  communities: [],
  inventories: [],
  showHomes: [],
};

export const imageLinkFields = (
  uiText: UiText,
): ResourceFields<Omit<ImageLinkFields, 'contentEntries'>> => ({
  displayName: {
    value: '',
    name: 'displayName',
    type: 'text',
    fieldConfig: {
      label: 'Name',
      required: true,
    }
  },
  url: {
    value: '',
    name: 'url',
    type: 'text',
    fieldConfig: {
      label: 'URL',
      nullable: true,
    }
  },
  files: {
    value: [],
    name: 'files',
    type: 'file',
    fieldConfig: {
      label: 'File',
      required: true,
    }
  },
  floorPlans: {
    value: [],
    name: 'floorPlans',
    type: 'select',
    fieldConfig: {
      label: uiText.FLOOR_PLAN_PLURAL,
      isMulti: true,
    }
  },
  communities: {
    value: [],
    name: 'communities',
    type: 'select',
    fieldConfig: {
      label: uiText.COMMUNITY_PLURAL,
      isMulti: true,
    }
  },
  inventories: {
    value: [],
    name: 'inventories',
    type: 'select',
    fieldConfig: {
      label: uiText.INVENTORY_PLURAL,
      isMulti: true,
    }
  },
  showHomes: {
    value: [],
    name: 'showHomes',
    type: 'select',
    fieldConfig: {
      label: uiText.SHOWHOME_PLURAL,
      isMulti: true,
    }
  },
});

export const imageLinkDataDisplayFields = (
  uiText: UiText,
): DataDisplay<ImageLink> => ({
  fields: {
    assets: {
      dataKey: 'assets',
      renderer: (assets: Asset[]) =>
        imageCellRenderer(assets.length > 0 ? assets[0].url : undefined),
    },
    displayName: {
      dataKey: 'displayName',
      label: 'Name',
    },
    url: {
      dataKey: 'url',
      label: 'URL',
    },
  },
});
