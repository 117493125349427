import React from 'react';
import {
  createFailedUpdateMessage,
  createSuccessfulUpdateMessage,
  useSetMessage,
} from '_lib/messages';
import { IdType, ValidatedEntity, WithClearList } from 'domain/api/common/types';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { useCreateOrUpdateOne } from 'domain/api/common/adapters/useCreateOrUpdateOne';
import { generateCreateOrUpdateString } from 'domain/api/common/fragments';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { transformClearableFieldsToClearList } from 'domain/api/common/transforms';
import {
  AllFloorPlanLayoutInfo,
  ApiFloorPlanLayout,
  ApiFloorPlanLayoutInput,
  transformApiFloorPlanLayoutToFloorPlanAttributes,
} from 'domain/api/floorPlanLayouts';
import { ApiFloorPlan, ApiFloorPlanInput, CLEARABLE_FLOOR_PLAN_FIELDS, FloorPlan } from '../types';
import {
  transformApiFloorPlanToFloorPlan,
  transformFloorPlanToApiFloorPlanInput,
} from '../transforms';
import { AllFloorPlanInfo } from '../fragments';

const UPDATE_ONE_FLOOR_PLAN = generateCreateOrUpdateString('update', 'floorPlan', AllFloorPlanInfo);

type UpdateFloorPlanResponse = {
  updateFloorPlan: {
    floorPlan: ApiFloorPlan | undefined;
  };
};

type UpdateFloorPlanInput<T> = {
  contentSpaceId: string;
  id: string;
  idType: IdType;
  floorPlan: T;
};

// TODO: Remove when implementing full FloorPlanLayout support
const UPDATE_ONE_FLOOR_PLAN_LAYOUT = generateCreateOrUpdateString(
  'update',
  'floorPlanLayout',
  AllFloorPlanLayoutInfo
);

// TODO: Remove when implementing full FloorPlanLayout support
type UpdateFloorPlanLayoutResponse = {
  updateFloorPlanLayout: {
    floorPlanLayout: ApiFloorPlanLayout | undefined;
  };
};

// TODO: Remove when implementing full FloorPlanLayout support
type UpdateFloorPlanLayoutInput<T> = {
  contentSpaceId: string;
  id: string;
  idType: IdType;
  floorPlanLayout: T;
};

export const useUpdateOneFloorPlan = () => {
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  // TODO: Remove when implementing full FloorPlanLayout support
  const mutationInputRef = React.useRef<UpdateFloorPlanInput<Partial<FloorPlan>> | null>(null);
  const updateFloorPlanLayoutMutation = useCreateOrUpdateOne<
    UpdateFloorPlanLayoutInput<Partial<ApiFloorPlanLayoutInput>>,
    UpdateFloorPlanLayoutResponse,
    ApiFloorPlanLayout
  >(UPDATE_ONE_FLOOR_PLAN_LAYOUT, (data) => data?.updateFloorPlanLayout.floorPlanLayout);

  const updateFloorPlanMutation = useCreateOrUpdateOne<
    WithClearList<ApiFloorPlanInput, UpdateFloorPlanInput<ApiFloorPlanInput>>,
    UpdateFloorPlanResponse,
    ValidatedEntity<FloorPlan> | undefined
  >(
    UPDATE_ONE_FLOOR_PLAN,
    (data) =>
      schemas?.floorPlan.apiSchema
        ? transformApiFloorPlanToFloorPlan(
            validateApiResponseEntity(data?.updateFloorPlan.floorPlan, schemas.floorPlan.apiSchema)
          )
        : undefined,
    {
      onSuccess: () => setMessage(createSuccessfulUpdateMessage('')),
      onError: (err) => setMessage(createFailedUpdateMessage('', err)),
    }
  );

  // TODO: Remove when implementing full FloorPlanLayout support
  React.useEffect(() => {
    const input = mutationInputRef.current;
    const floorPlanLayout =
      updateFloorPlanMutation.response?.updateFloorPlan.floorPlan?.floorPlanLayouts[0];
    if (floorPlanLayout && input) {
      const { bedrooms, bathrooms, halfBaths, floorSize } = input.floorPlan;
      const floorPlanLayoutAttributes = Object.fromEntries(
        [
          ['bedrooms', bedrooms],
          ['bathrooms', bathrooms],
          ['halfBaths', halfBaths],
          ['floorSize', floorSize],
        ].filter((attribute) => typeof attribute !== 'undefined')
      );
      updateFloorPlanLayoutMutation.execute({
        id: floorPlanLayout.id,
        contentSpaceId: input.contentSpaceId,
        idType: 'CONTENT_ID',
        floorPlanLayout: {
          ...floorPlanLayoutAttributes,
          floorPlan: {
            id: input.id,
            idType: 'CONTENT_ID',
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateFloorPlanMutation.response]);

  const execute = (input: UpdateFloorPlanInput<Partial<FloorPlan>>) => {
    // TODO: Remove when implementing full FloorPlanLayout support
    mutationInputRef.current = input;
    const transformed = transformFloorPlanToApiFloorPlanInput(input.floorPlan);
    updateFloorPlanMutation.execute({
      ...input,
      floorPlan: transformed,
      clear: transformClearableFieldsToClearList(transformed, CLEARABLE_FLOOR_PLAN_FIELDS),
    });
  };

  return {
    execute,
    loading: updateFloorPlanMutation.loading || updateFloorPlanLayoutMutation.loading,
    error: updateFloorPlanMutation.error || updateFloorPlanLayoutMutation.error,
    called: updateFloorPlanMutation.called && updateFloorPlanLayoutMutation.called,
    result: {
      ...updateFloorPlanMutation.result,
      ...transformApiFloorPlanLayoutToFloorPlanAttributes(updateFloorPlanLayoutMutation.result),
    },
  };
};
