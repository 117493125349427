import React from 'react';
import { InputAdornment, TextField as MaterialTextField } from '@material-ui/core';
import { NumberFieldControlProps } from './types';
import { FieldErrors } from '../shared/FieldErrors/FieldErrors';

export function NumberFieldControl<TName extends string>({
  required,
  value,
  name,
  label,
  disabled,
  errors,
  onChange,
  onBlur,
  prefix,
  suffix,
}: NumberFieldControlProps<TName>) {
  const handleChange = (e: React.BaseSyntheticEvent) => {
    e.persist();
    const testValue = e.currentTarget.value;
    const isValid = typeof testValue === 'string' && testValue.length > 0;
    onChange(name, isValid ? testValue : null);
  };

  const handleBlur = (e: React.BaseSyntheticEvent) => {
    e.persist();
    const testValue = e.currentTarget.value;
    const isValid = typeof testValue === 'string' && testValue.length > 0;
    onBlur && onBlur(name, isValid ? testValue : null);
  };

  const InputProps = {
    startAdornment: prefix ? <InputAdornment position="start">{prefix}</InputAdornment> : undefined,
    endAdornment: suffix ? <InputAdornment position="end">{suffix}</InputAdornment> : undefined,
  };

  return (
    <MaterialTextField
      type="number"
      name={name}
      value={value === null ? '' : value}
      label={label}
      disabled={disabled}
      required={required}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors && errors.length > 0}
      fullWidth={true}
      variant="standard"
      helperText={<FieldErrors errors={errors} />}
      InputProps={InputProps}
    />
  );
}
