import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { IconButton, Tooltip } from '@material-ui/core';
import { useBaseUiText } from '_lib/uiText';

interface Props {
  tooltip?: string;
  onAdd?: () => void;
  disabled?: boolean;
}

export const AddIconButton = React.memo(({ tooltip, disabled, onAdd }: Props) => {
  const uiText = useBaseUiText();
  return (
    <Tooltip title={tooltip || uiText.ACTION_NEW_LABEL}>
      <div>
        <IconButton disabled={disabled} onClick={onAdd}>
          <AddIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
});
