import React from 'react';
import { FileUpload } from '_lib/form/fileUpload/FileUpload';
import { FileFieldControlProps } from './types';

export function FileFieldUploader<TName extends string>({
  name,
  isMultiple,
  onChange,
}: FileFieldControlProps<TName>) {
  const handleChange = (files: File[]) => onChange(name, files);

  return (
    <FileUpload selectFiles={handleChange} isMultiple={isMultiple} isImage isPdf={false} />
  );
}
