import { ExternalResourceConfig, InternalResourceConfig, makeResource } from 'lib/resource';
import { defaultUiText, UiText } from 'domain/uiText';
import {
  defaultFloorPlan,
  defaultFloorPlanFields,
  FloorPlan,
  floorPlanDataDisplayFields,
  FloorPlanFields,
  floorPlanFields,
} from './types';
import { defaultExternalResourceConfig } from '../shared';
import { floorPlanFieldsSchema } from './schemas';

export const floorPlanInternalResourceConfig = (uiText: UiText): InternalResourceConfig<
FloorPlan,
FloorPlanFields
> => ({
  enabledPages: ['list', 'single'],
  defaultResource: defaultFloorPlan,
  defaultResourceFields: defaultFloorPlanFields,
  fields: floorPlanFields(uiText),
  fieldsValidationSchema: floorPlanFieldsSchema(uiText),
  dataDisplay: floorPlanDataDisplayFields(uiText),
});

export const defaultFloorPlanExternalResourceConfig: ExternalResourceConfig = {
  ...defaultExternalResourceConfig,
  aliases: {
    path: 'floor-plans',
    plural: 'Floor Plans',
    singular: 'Floor Plan',
  },
};

export const defaultFloorPlanResource = makeResource({
  internalConfig: floorPlanInternalResourceConfig(defaultUiText),
  externalConfig: defaultFloorPlanExternalResourceConfig,
});
