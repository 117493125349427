import React from 'react';

export const useLoadOnce = (isBlocked: boolean) => {
  const [hasBeenBlocked, setHasBeenBlocked] = React.useState(false);
  React.useEffect(() => {
    if (isBlocked) {
      setHasBeenBlocked(true);
    }
  }, [isBlocked]);
  return {
    hasFinished: hasBeenBlocked && !isBlocked,
  };
};
