import { UiText } from 'domain/uiText';
import { DataDisplay, Resource, ResourceFields } from 'lib/resource';

export type Contact = {
  id: string;
  name: string;
  email: string | null;
  title: string | null;
  phone: string | null;
};

export const defaultContact: Contact = {
  id: '',
  name: '',
  email: null,
  title: null,
  phone: null,
};

export type ContactFields = Partial<{
  name: string;
  email: string | null;
  title: string | null;
  phone: string | null;
  salesCentres: string[];
}>;

export type ContactResource = Resource<Contact, ContactFields>;

export const defaultContactFields: ContactFields = {
  name: '',
  email: '',
  title: '',
  phone: '',
};

export const contactFields = (
  uiText: UiText
): ResourceFields<Omit<ContactFields, 'salesCentres'>> => ({
  name: {
    value: '',
    name: 'name',
    type: 'text',
    fieldConfig: {
      label: 'Name',
      required: true,
    },
  },
  email: {
    value: '',
    name: 'email',
    type: 'text',
    fieldConfig: {
      label: 'Email',
      nullable: true,
    },
  },
  title: {
    value: '',
    name: 'title',
    type: 'text',
    fieldConfig: {
      label: 'Job Title',
      nullable: true,
    },
  },
  phone: {
    value: '',
    name: 'phone',
    type: 'text',
    fieldConfig: {
      label: 'Phone Number',
      nullable: true,
    },
  },
});

export const contactDataDisplayFields = (uiText: UiText): DataDisplay<Contact> => ({
  fields: {
    name: {
      dataKey: 'name',
      label: 'Name',
    },
    title: {
      dataKey: 'title',
      label: 'Job Title',
    },
    email: {
      dataKey: 'email',
      label: 'Email',
    },
    phone: {
      dataKey: 'phone',
      label: 'Phone Number',
    },
  },
});
