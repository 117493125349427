import React from 'react';
import { Route } from 'react-router';
import { useContentSpaceId } from '_lib/auth';
import { useRoutes } from './useRoutes';

export const Routes = () => {
  const contentSpaceId = useContentSpaceId();
  const routes = useRoutes();
  if (!contentSpaceId) return null;
  return (
    <React.Fragment>
      {Object.entries(routes).map(([key, route]) => (
        <Route key={key} exact path={route.path}>
          {route.component}
        </Route>
      ))}
    </React.Fragment>
  );
};
