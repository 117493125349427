import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { baseUiText } from '_lib/uiText/baseUiText';

interface DeleteDialogProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const DeleteDialog = (props: DeleteDialogProps) => {
  const classes = useStyles();
  const uiText = baseUiText;

  const handleClose = (e: React.BaseSyntheticEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
    props.onCancel();
  };

  const handleConfirm = (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    props.onConfirm();
  };

  const handleCancel = (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    props.onCancel();
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="delete-dialog-title"
      PaperProps={{
        className: classes.dialog,
      }}
    >
      <DialogTitle id="delete-dialog-title">{uiText.ACTION_DELETE_DIALOG_TITLE}</DialogTitle>
      <DialogContent>
        <DialogContentText>{uiText.ACTION_DELETE_DIALOG_DESCRIPTION}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={classes.cancel} variant="outlined" onClick={handleCancel}>
          {uiText.ACTION_CANCEL_LABEL}
        </Button>
        <Button className={classes.confirm} onClick={handleConfirm}>
          {uiText.ACTION_DELETE_DIALOG_CONFIRM}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    borderTop: `solid 4px ${theme.palette.error.main}`,
  },
  confirm: {
    backgroundColor: theme.palette.error.main,
    borderColor: theme.palette.error.dark,
    color: theme.palette.getContrastText(theme.palette.error.main),
    '&:hover': {
      backgroundColor: theme.palette.error.light,
      borderColor: theme.palette.error.main,
    },
  },
  cancel: {
    backgroundColor: theme.palette.grey[200],
    borderColor: theme.palette.grey[400],
    color: theme.palette.getContrastText(theme.palette.grey[500]),
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
      borderColor: theme.palette.grey[800],
    },
  },
}));
