import * as yup from 'yup';
import { UiText } from 'domain/uiText/uiText';
import {
  latitudeValidationSchema,
  longitudeValidationSchema,
  pickCommonApiValidationSchemas,
  publishProblemsSchema,
} from '../common/validation';
import { ApiInventory, ApiInventoryInput } from './types';
import { apiEmbeddingValidationSchema } from '../embedding/validation';
import { apiAssetValidationSchema } from '../asset/validation';
import { apiImageLinkValidationSchema } from '../imageLink/validation';
import { apiFeatureTagValidationSchema } from '../featureTag/validation';

export const apiInventoryInputValidationSchema = (uiText: UiText) => {
  return yup.object<ApiInventoryInput, object>({
    ...pickCommonApiValidationSchemas(uiText)([
      'displayName',
      'seoTitleTag',
      'seoMetaKeywords',
      'seoMetaDescription',
      'displayPriority',
      'halfBaths',
      'homeType',
      'bathrooms',
      'bedrooms',
      'floorSize',
      'showMonthlyPaymentCalculator',
      'showCommuteCalculation',
      'address',
      'city',
      'countrySubDivisionCode',
      'country',
      'postalCode',
    ]),
    possessionDateSec: yup.number().nullable().defined().default(null),
    price: yup.number().defined().default(null).label('Price'),
    priceReduced: yup.boolean().defined().default(false).label('Price Reduced'),
    priceReducedFrom: yup
      .number()
      .defined()
      .nullable()
      .when(['price', 'priceReduced'], (price: number = 0, priceReduced: boolean, schema: yup.NumberSchema<number, object>) => {
        if (!priceReduced) return schema;
        return schema.min(price).default(0);
      })
      .label('Price Reduced From'),
    floorPlan: yup.object({
      id: yup.string().defined().nullable().default(null),
    }).defined().nullable().default(null),
    community: yup.object({
      id: yup.string().defined().nullable().default(null),
    }).defined().nullable().default(null),
    locationVisualizerLat: latitudeValidationSchema,
    locationVisualizerLon: longitudeValidationSchema,
  }).defined();
};

export const apiInventoryValidationSchema = (uiText: UiText) => yup.object<ApiInventory, object>({
  ...pickCommonApiValidationSchemas(uiText)([
    'id',
    'displayName',
    'seoTitleTag',
    'seoMetaKeywords',
    'seoMetaDescription',
    'displayPriority',
    'halfBaths',
    'homeType',
    'bathrooms',
    'bedrooms',
    'floorSize',
    'showMonthlyPaymentCalculator',
    'showCommuteCalculation',
  ]),
  address: yup.object({
    ...pickCommonApiValidationSchemas(uiText)([
      'address',
      'city',
      'countrySubDivisionCode',
      'country',
      'postalCode',
    ]),
  }).defined().nullable().default(null),
  locationVisualizerLat: latitudeValidationSchema,
  locationVisualizerLon: longitudeValidationSchema,
  status: yup.string().defined().nullable().default(null),
  possessionDate: yup.number().nullable().defined().default(null),
  price: yup.number().defined().default(null).label('Price'),
  priceReduced: yup.boolean().defined().default(false).label('Price Reduced'),
  priceReducedFrom: yup
    .number()
    .defined()
    .nullable()
    .when(['price', 'priceReduced'], (price: number = 0, priceReduced: boolean, schema: yup.NumberSchema<number, object>) => {
      if (!priceReduced) return schema;
      return schema.min(price).required().default(0);
    })
    .label('Price Reduced From'),
  inventoryCategory: yup.object({
    id: yup.string().defined().default(''),
    name: yup.string().defined().default(''),
    priority: yup.number().defined().default(0),
  }).defined(),
  floorPlan: yup.object({
    id: yup.string().defined().required().default(''),
    publishProblems: publishProblemsSchema,
  }).defined().nullable().default(null).label(uiText.FLOOR_PLAN_SINGULAR),
  community: yup.object({
    id: yup.string().defined().default(''),
    communityGeoPointLat:
      yup.number().defined().min(-90).max(90).required().label('Latitude'),
    communityGeoPointLon:
      yup.number().defined().min(-180).max(180).required().label('Longitude'),
  }).defined().nullable().default(null).label(uiText.COMMUNITY_SINGULAR),
  assets: yup.array()
    .of(apiAssetValidationSchema)
    .defined()
    .default([]),
  imageLinks: yup.array().of(apiImageLinkValidationSchema(uiText, true)).defined().default([]),
  embeddings: yup.array().of(apiEmbeddingValidationSchema(uiText)).defined().default([]),
  featureTags: yup.array().of(apiFeatureTagValidationSchema).defined().default([]),
}).defined().test(
  'hasMainImage',
  `Main image is required. Select a ${uiText.FLOOR_PLAN_SINGULAR} with a main image or upload one.`,
  (values) => {
    // Important
    // First we must check if this entry has been updated with a listingMainImage
    // in the apollo cache(because creating an asset doesn't refetch the entry it was created for).
    // If no listingMainImage is found in this entries assets we will use the publishProblems
    // instead(since this handles checking the mapped floorPlans assets as well).
    if (values?.assets.find(el => el.attributeName === 'listingMainImage')) return true;
    if (values?.publishProblems?.find(el => el.field === 'listingMainImage')) return false;
    return true;
  }).test(
  'hasLatLon',
  `Longitude & latitude are required. Choose a ${uiText.COMMUNITY_SINGULAR} to use its location or manually enter one`,
  (values) => {
    const {
      locationVisualizerLat = null, locationVisualizerLon = null,
    } = values || {};
    const {
      communityGeoPointLat = null, communityGeoPointLon = null,
    } = values?.community || {};
    if (locationVisualizerLat === null && locationVisualizerLon === null &&
      communityGeoPointLat === null && communityGeoPointLon === null
    ) {
      return false;
    }
    return true;
  });
