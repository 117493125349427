import {
  createFailedDeleteMessage,
  createSuccessfulDeleteMessage,
  useSetMessage,
} from '_lib/messages';
import { useIdInputMutation } from 'domain/api/common/adapters/useIdInputMutation';
import { updateCacheOnDeleteOne } from 'domain/api/common/cache';
import { DeleteOneAdapterAction } from 'domain/api/common/adapters/types';
import { DELETE_ONE_FEATURE_TAG } from './gql';

type Response = {
  deleteFeatureTag: {
    deletedId: string | null | undefined;
    contentSpaceId: string;
  };
};

export const useDeleteOneFeatureTag = (): DeleteOneAdapterAction => {
  const setMessage = useSetMessage();

  return useIdInputMutation<Response, string | undefined>(
    DELETE_ONE_FEATURE_TAG,
    (data) => {
      return data?.deleteFeatureTag.deletedId || undefined;
    },
    {
      updateCache: (cache, data) => {
        updateCacheOnDeleteOne(cache, 'featureTags', data?.deleteFeatureTag.deletedId);
      },
      onSuccess: () => {
        setMessage(createSuccessfulDeleteMessage(''));
      },
      onError: (err) => setMessage(createFailedDeleteMessage('', err)),
      omitContentSpaceId: false,
    }
  );
};
