import { Asset } from 'domain/api/asset/types';
import { ApiImageLink, ImageLink } from 'domain/api/imageLink/types';
import { CommonApiFields, PublishableApiEntity, PublishedStatus } from '../common/types';
import { ApiCommunity } from '../community/types';
import { ApiEmbedding, Embedding } from '../embedding/types';
import { ApiFeatureTag, FeatureTag } from '../featureTag/types';
import { ApiFloorPlan } from '../floorPlan/types';

export type ApiShowHome = PublishableApiEntity<Pick<CommonApiFields,
'id' |
'builderId' |
'displayName' |
'seoMetaDescription' |
'seoMetaKeywords' |
'seoTitleTag' |
'showMonthlyPaymentCalculator' |
'showCommuteCalculation' |
'homeType' |
'bedrooms' |
'bathrooms' |
'halfBaths' |
'floorSize' |
'garage'
> & {
  locationVisualizerLat: number | null;
  locationVisualizerLon: number | null;

  address: Pick<CommonApiFields,
  'fullAddress' |
  'address' |
  'city' |
  'countrySubDivisionCode' |
  'country' |
  'postalCode'
  > | null;

  floorPlan: Pick<ApiFloorPlan, 'id' | 'publishProblems'> | null,
  community: Pick<ApiCommunity, 'id' | 'communityGeoPointLat' | 'communityGeoPointLon'> | null,
  assets: Asset[];
  embeddings: ApiEmbedding[];
  imageLinks: ApiImageLink[];
  featureTags: ApiFeatureTag[];
}>;

export type ApiShowHomeInput = Partial<Pick<ApiShowHome,
'displayName' |
'seoMetaDescription' |
'seoMetaKeywords' |
'seoTitleTag' |
'showMonthlyPaymentCalculator' |
'showCommuteCalculation' |
'homeType' |
'bedrooms' |
'bathrooms' |
'halfBaths' |
'floorSize' |
'garage' |
'locationVisualizerLat' |
'locationVisualizerLon'
> & {
  floorPlan?: { id: string | null } | null;
  community?: { id: string | null } | null;
  featureTags?: string[] | null;
}> & Partial<Pick<CommonApiFields,
'address' |
'city' |
'countrySubDivisionCode' |
'country' |
'postalCode'
>>;

export const CLEARABLE_SHOW_HOME_FIELDS: (keyof ApiShowHomeInput & string)[] = [
  'homeType',
  'bedrooms',
  'bathrooms',
  'halfBaths',
  'floorSize',
  'garage',
  'locationVisualizerLat',
  'locationVisualizerLon',
];

export type ShowHome = Pick<ApiShowHome,
'id' |
'published' |
'builderId' |
'displayName' |
'seoMetaKeywords' |
'seoTitleTag' |
'showMonthlyPaymentCalculator' |
'showCommuteCalculation' |
'homeType' |
'bedrooms' |
'bathrooms' |
'halfBaths' |
'floorSize' |
'garage' |
'assets'
> & {
  publishedStatus: PublishedStatus;
  seoDescriptionBody: string;
  seoDescriptionBullets: string[];
  fullAddress: string | null;

  lat: number | null;
  lng: number | null;

  embeddings: Embedding[];
  imageLinks: ImageLink[];
  featureTags: FeatureTag[];
  floorPlan: Pick<ApiFloorPlan, 'id' | 'publishProblems'> | null,
  community: Pick<ApiCommunity, 'id' | 'communityGeoPointLat' | 'communityGeoPointLon'> | null,
} & Pick<CommonApiFields,
'address' |
'city' |
'countrySubDivisionCode' |
'country' |
'postalCode'
>;

export type ShowHomeInput = Partial<Omit<ShowHome, 'floorPlan' | 'community'> & {
  floorPlanId: string | null;
  communityId: string | null;
}>;
