import React from 'react';
import { PureQueryOptions } from '@apollo/client';
import { validateApiResponseEntities } from 'domain/api/common/validation';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { useBatchMutation } from 'domain/api/common/hooks';
import { ApiEmbedding } from '../types';
import { REMOVE_EMBEDDING_CONTENT_ENTRIES } from './gql';
import { transformApiEmbeddingsToEmbeddings } from '../transforms';

type Input = {
  input: {
    embeddingId: string;
    contentSpaceId: string;
    contentEntries: string[];
  };
};

type Response = {
  addEmbeddingContentEntries: {
    embedding: ApiEmbedding;
  };
};

export const useRemoveMultipleEmbeddingMappingsMutation = (refetchQueries?: PureQueryOptions[]) => {
  const schemas = useApiSchemas();
  const { result, ...rest } = useBatchMutation<Response, Input>(
    REMOVE_EMBEDDING_CONTENT_ENTRIES,
    undefined,
    refetchQueries,
  );

  const validated = React.useMemo(() => {
    return schemas?.embedding.apiSchema ? transformApiEmbeddingsToEmbeddings(
      validateApiResponseEntities(
        result,
        schemas.embedding.apiSchema,
      )
    ) : undefined;
  }, [schemas, result]);

  return {
    result: validated,
    ...rest,
  };
};
