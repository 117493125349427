import { UiText } from 'domain/uiText';
import { DataDisplay, Resource, ResourceFields } from 'lib/resource';
import { imageCellRenderer } from '_lib/components/table/CellRenderers';
import { defaultVendor, Vendor } from '../vendor';

export type EmbeddingContentEntry = {
  __typename: string;
  id: string;
  builderId: string;
  published: boolean;
};

export type Embedding = {
  id: string;
  published: boolean;
  displayName: string;
  iframeUrl: string;
  vendor: Vendor;
  contentEntries: EmbeddingContentEntry[];
};

export const defaultEmbedding: Embedding = {
  id: '',
  published: false,
  displayName: '',
  iframeUrl: '',
  vendor: defaultVendor,
  contentEntries: [],
};

export type EmbeddingFields = {
  displayName: string;
  iframeUrl: string;
  vendorId: string;
  contentEntries?: string[];
  floorPlans: string[];
  communities: string[];
  inventories: string[];
  showHomes: string[];
};

export type EmbeddingResource = Resource<Embedding, EmbeddingFields>;

export const defaultEmbeddingFields: EmbeddingFields = {
  displayName: '',
  iframeUrl: '',
  vendorId: '',
  floorPlans: [],
  communities: [],
  inventories: [],
  showHomes: [],
};

export const embeddingFields = (
  uiText: UiText,
): ResourceFields<Omit<EmbeddingFields, 'contentEntries'>> => ({
  displayName: {
    value: '',
    name: 'displayName',
    type: 'text',
    fieldConfig: {
      label: 'Name',
      required: true,
    },
  },
  iframeUrl: {
    value: '',
    name: 'iframeUrl',
    type: 'text',
    fieldConfig: {
      label: 'URL',
      required: true,
    },
  },
  vendorId: {
    value: '',
    name: 'vendorId',
    type: 'select',
    fieldConfig: {
      label: 'Vendor',
      required: true,
    },
  },
  floorPlans: {
    value: [],
    name: 'floorPlans',
    type: 'select',
    fieldConfig: {
      label: uiText.FLOOR_PLAN_PLURAL,
      isMulti: true,
    },
  },
  communities: {
    value: [],
    name: 'communities',
    type: 'select',
    fieldConfig: {
      label: uiText.COMMUNITY_PLURAL,
      isMulti: true,
    },
  },
  inventories: {
    value: [],
    name: 'inventories',
    type: 'select',
    fieldConfig: {
      label: uiText.INVENTORY_PLURAL,
      isMulti: true,
    },
  },
  showHomes: {
    value: [],
    name: 'showHomes',
    type: 'select',
    fieldConfig: {
      label: uiText.SHOWHOME_PLURAL,
      isMulti: true,
    }
  },
});

export type EmbeddingDataDisplayFields = Embedding & { vendorName: string };

export const embeddingDataDisplayFields = (
  uiText: UiText,
): DataDisplay<EmbeddingDataDisplayFields> => ({
  fields: {
    vendor: {
      dataKey: 'vendor',
      renderer: (data) => imageCellRenderer(data.icon),
    },
    vendorName: {
      dataKey: 'vendorName',
      label: 'Vendor',
    },
    displayName: {
      dataKey: 'displayName',
      label: 'Name',
    },
    iframeUrl: {
      dataKey: 'iframeUrl',
      label: 'URL',
    },
  },
});
