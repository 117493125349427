import React from 'react';
import { makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import classNames from 'classnames';

interface OwnProps {
  label?: string;
  errors?: string[];
  children?: React.ReactNode;
}

type Props = OwnProps;

export const ReadonlyField = (props: Props) => {
  const { label, children } = props;
  const classes = useStyles();

  const errors = props.errors ? props.errors : [];
  return (
    <Tooltip
      title={errors.length > 0 ? errors.join('\n') : ''}
    >
      <div className={classNames({ [classes.readonlyError]: errors.length > 0 })}>
        <div className={classes.readonlyLabel}>
          <Typography variant="caption">{label}</Typography>
          {errors.length > 0 && <WarningIcon className={classes.readonlyErrorIcon} />}
        </div>
        {children}
      </div>
    </Tooltip>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  readonlyError: {
    borderLeft: `solid 4px ${theme.palette.warning.dark}`,
    paddingLeft: theme.spacing(1),
  },
  readonlyErrorIcon: {
    color: theme.palette.warning.dark,
    fontSize: 12,
    marginBottom: 2,
    marginLeft: theme.spacing(.5),
  },
  readonlyLabel: {
    display: 'flex',
    alignItems: 'center',
  },
}));
