import React from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { millisecondsToSeconds, secondsToMilliseconds } from 'utilities/helpers';
import { DateFieldControlProps } from './types';
import { FieldErrors } from '../shared/FieldErrors/FieldErrors';

/**
 *
 * @description
 * This field expects and returns SECONDS
 */
export function DateFieldControl<TName extends string>({
  required,
  value,
  name,
  label,
  disabled,
  errors,
  onChange,
}: DateFieldControlProps<TName>) {
  const today = React.useMemo(() => new Date(), []);
  const handleChange = (date: Date | null) => {
    onChange(name, date ? millisecondsToSeconds(date.valueOf()) : null);
  };

  return (
    /**
     * @note
     * MuiPickerUtilsProvider is currently only compatible with
     * 1.* versions of @date-io/date-fns.
     * The latest versions of @material-ui/lab include a date picker component,
     * so we should consider using that in the future.
     */
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        disablePast
        variant="inline"
        format="yyyy-MM-dd"
        margin="none"
        label={label}
        required={required}
        disabled={disabled}
        minDate={today}
        error={errors && errors.length > 0}
        helperText={<FieldErrors errors={errors} />}
        value={!value ? null : new Date(secondsToMilliseconds(value))}
        onChange={handleChange}
        fullWidth={true}
        InputProps={{
          placeholder: 'YYYY-MM-DD',
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
