import React from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { Action } from 'common/types';
import { ActionButton } from './actionButtons/ActionButton';

export interface ActionsProps {
  actions: Action[];
}

type Props = ActionsProps;

const renderAction = (action: Action) => (
  <Grid item={true} key={action.label}>
    <ActionButton {...action} />
  </Grid>
);

export const Actions = (props: Props) => {
  const { actions } = props;
  const classes = useStyles();
  return (
    <Grid container={true} spacing={2} className={classes.container}>
      {actions.map(renderAction)}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: 0,
  },
}));
