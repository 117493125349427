// eslint-disable-next-line import/no-extraneous-dependencies
import { Feature, Polygon } from 'geojson';
import { Demostats, GeoboundaryPath } from './types';

export const fetchBoundary = async (
  contentSpaceId: string,
  id: string,
  version?: string,
): Promise<GeoboundaryPath | null> => {
  if (!process.env.REACT_APP_BASE_SOURCE_GIS_URL) {
    throw new Error('Missing environment variable REACT_APP_BASE_SOURCE_GIS_URL');
  }
  const url = version
    ? `${process.env.REACT_APP_BASE_SOURCE_GIS_URL}/${id}/v/${version}?contentSpaceId=${contentSpaceId}`
    : `${process.env.REACT_APP_BASE_SOURCE_GIS_URL}/${id}?contentSpaceId=${contentSpaceId}`;
  try {
    const response = await fetch(url);
    if (response.ok) {
      return transformPolygonFeatureToGeoboundaryData(await response.json());
    }
    if (response.status === 404) {
      return null;
    }
    throw new Error();
  } catch (err) {
    throw new Error('An error occurred when fetching demostats');
  }
};

export const fetchDemostats = async (
  contentSpaceId: string,
  id: string,
  version?: string,
): Promise<Demostats | null> => {
  if (!process.env.REACT_APP_BASE_PROJECT_DEMOSTATS_URL) {
    throw new Error('Missing environment variable REACT_APP_BASE_PROJECT_DEMOSTATS_URL');
  }
  const url = version
    ? `${process.env.REACT_APP_BASE_PROJECT_DEMOSTATS_URL}/${id}/v/${version}?contentSpaceId=${contentSpaceId}`
    : `${process.env.REACT_APP_BASE_PROJECT_DEMOSTATS_URL}/${id}?contentSpaceId=${contentSpaceId}`;
  try {
    const response = await fetch(url);
    if (response.ok) {
      return await response.json();
    }
    if (response.status === 404) {
      return null;
    }
    throw new Error();
  } catch (err) {
    throw new Error('An error occurred when fetching demostats');
  }
};

export const submitBoundaryDraft = async (
  contentSpaceId: string,
  id: string,
  boundary: GeoboundaryPath,
): Promise<string> => {
  if (!process.env.REACT_APP_BASE_PROJECT_DEMOSTATS_ETL_URL) {
    throw new Error('Missing environment variable REACT_APP_BASE_PROJECT_DEMOSTATS_ETL_URL');
  }
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_PROJECT_DEMOSTATS_ETL_URL}/${id}?contentSpaceId=${contentSpaceId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(transformGeoboundaryDataToPolygonFeature(boundary)),
      }
    );
    if (response.ok) {
      const result = await response.json();
      if (typeof result.version !== 'string') throw new Error();
      return result.version;
    }
    throw new Error();
  } catch (err) {
    throw new Error('An error occurred when submitting boundary');
  }
};

export const publishDemostats = async (
  contentSpaceId: string,
  id: string,
  version: string,
): Promise<void> => {
  if (!process.env.REACT_APP_BASE_PROJECT_DEMOSTATS_ETL_URL) {
    throw new Error('Missing environment variable REACT_APP_BASE_PROJECT_DEMOSTATS_ETL_URL');
  }
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_PROJECT_DEMOSTATS_ETL_URL}/${id}/v/${version}/publish?contentSpaceId=${contentSpaceId}`,
      {
        method: 'PUT',
      }
    );
    if (response.ok) {
      return;
    }
    throw new Error();
  } catch (err) {
    throw new Error('An error occurred when submitting boundary');
  }
};

export const pollDemostatsETL = async (
  contentSpaceId: string,
  id: string,
  version: string,
): Promise<void> => {
  if (!process.env.REACT_APP_BASE_PROJECT_DEMOSTATS_ETL_URL) {
    throw new Error('Missing environment variable REACT_APP_BASE_PROJECT_DEMOSTATS_ETL_URL');
  }
  const url = `${process.env.REACT_APP_BASE_PROJECT_DEMOSTATS_ETL_URL}/${id}/v/${version}?contentSpaceId=${contentSpaceId}`;
  try {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      // eslint-disable-next-line no-await-in-loop
      await sleep(100);
      // eslint-disable-next-line no-await-in-loop
      const response = await fetch(url);
      if (response.ok) {
        // eslint-disable-next-line no-await-in-loop
        const result = await response.json();
        if (result.done) {
          if (result.errors) throw new Error();
          return;
        }
      } else {
        throw new Error();
      }
    }
  } catch (err) {
    throw new Error('An error occurred when processing demostats');
  }
};

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const transformPolygonFeatureToGeoboundaryData = (
  feature: Feature<Polygon, {}>,
): GeoboundaryPath => {
  const polygon = feature.geometry;
  polygon.coordinates[0].pop(); // last coord is duplicate as the first one, so we remove it
  return polygon.coordinates[0].map(coord => { return { lat: coord[1], lng: coord[0] }; });
};

const transformGeoboundaryDataToPolygonFeature = (
  geometry: GeoboundaryPath,
): Feature<Polygon, {}> => {
  const coordinates = geometry.map(coord => [coord.lng, coord.lat]);
  // In polygon coordinates, we need to add the first coord to the end
  coordinates.push(coordinates[0]);
  return {
    'type': 'Feature',
    'properties': {},
    'geometry': {
      type: 'Polygon',
      coordinates: [coordinates],
    },
  };
};
