import React from 'react';
import { useContentSpaceId } from '_lib/auth';
import { fetchBoundary, fetchDemostats, pollDemostatsETL, publishDemostats, submitBoundaryDraft } from './api';
import { Demostats, GeoboundaryPath } from './types';

export const useFetchBoundary = (
  id: string,
  version?: string,
): GeoboundaryPath | undefined | null => {
  const contentSpaceId = useContentSpaceId();
  const [boundary, setBoundary] = React.useState<GeoboundaryPath | undefined | null>(undefined);
  React.useEffect(() => {
    setBoundary(undefined);
    contentSpaceId && fetchBoundary(contentSpaceId, id, version)
      .then((path) => setBoundary(path));
  }, [contentSpaceId, id, version]);
  return boundary;
};

export const useFetchDemostats = (id: string, version?: string): Demostats | undefined | null => {
  const contentSpaceId = useContentSpaceId();
  const [demostats, setDemostats] = React.useState<Demostats | undefined | null>(undefined);
  React.useEffect(() => {
    setDemostats(undefined);
    contentSpaceId && fetchDemostats(contentSpaceId, id, version)
      .then((stats) => setDemostats(stats));
  }, [contentSpaceId, id, version]);
  return demostats;
};

export const useDemostatsPreview = (id: string, editing: boolean) => {
  const contentSpaceId = useContentSpaceId();
  const [previewing, setPreviewing] = React.useState(false);
  const [previewVersion, setPreviewVersion] = React.useState<string | undefined>(undefined);
  const [calculating, setCalculating] = React.useState(false);
  const [calculatingVersion, setCalculatingVersion] = React.useState<string | undefined>(undefined);

  const previewDemostats = (draftBoundary: GeoboundaryPath) => {
    if (!contentSpaceId) return;
    setCalculating(true);
    submitBoundaryDraft(contentSpaceId, id, draftBoundary)
      .then(version => setCalculatingVersion(version));
  };
  React.useEffect(() => {
    if (editing && contentSpaceId && calculating && calculatingVersion) {
      pollDemostatsETL(contentSpaceId, id, calculatingVersion).then(() => setCalculating(false));
    } else if (!calculating && calculatingVersion) {
      setCalculatingVersion(undefined);
      if (editing) {
        setPreviewVersion(calculatingVersion);
        setPreviewing(true);
      }
    }
  }, [calculating, calculatingVersion, contentSpaceId, editing, id]);
  const clearPreview = () => {
    setCalculating(false);
    setPreviewing(false);
  };
  const clearDraftVersion = () => {
    setPreviewVersion(undefined);
  };
  const publishPreviewVersion = async () => {
    if (!contentSpaceId || !previewVersion || !previewing)
      throw new Error('Tried to publish in invalid state');
    await publishDemostats(contentSpaceId, id, previewVersion);
    setCalculating(false);
    setPreviewing(false);
    setPreviewVersion(undefined);
    setCalculatingVersion(undefined);
  };

  return {
    previewVersion,
    calculating,
    previewing,
    previewDemostats,
    clearPreview,
    clearDraftVersion,
    publishPreviewVersion,
  };
};

export const useDraftBoundary = (
  editing: boolean,
  boundary: GeoboundaryPath | null | undefined,
) => {
  const [draftBoundary, setDraftBoundary] = React.useState<GeoboundaryPath | undefined>(undefined);
  React.useEffect(() => {
    setDraftBoundary(boundary || undefined);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing, boundary]);

  return {
    draftBoundary,
    setDraftBoundary,
    clearDraftBoundary: () => setDraftBoundary(undefined),
  };
};

export const useDemostatsState = (
  communityId: string,
  editing: boolean,
  closeForm: () => void,
) => {
  const {
    calculating,
    previewVersion,
    previewing,
    previewDemostats,
    clearPreview,
    clearDraftVersion,
    publishPreviewVersion,
  } = useDemostatsPreview(communityId, editing);
  const boundary = useFetchBoundary(communityId, previewVersion);
  const demostats = useFetchDemostats(communityId, previewVersion);
  const {
    draftBoundary,
    setDraftBoundary,
    clearDraftBoundary,
  } = useDraftBoundary(editing, boundary);

  const onCancel = () => {
    clearPreview();
    clearDraftVersion();
    closeForm();
  };
  const onPreview = () => {
    draftBoundary && previewDemostats(draftBoundary);
  };
  const onSave = () => {
    publishPreviewVersion()
      .then(() => {
        closeForm();
      });
  };

  return {
    calculating,
    previewing,
    boundary,
    draftBoundary,
    demostats,
    setDraftBoundary,
    onCancel,
    onPreview,
    clearPreview,
    clearDraftBoundary,
    onSave,
  };
};
