import {
  createFailedUpdateMessage,
  createSuccessfulUpdateMessage,
  useSetMessage,
} from '_lib/messages';
import { IdType, ValidatedEntity, WithClearList } from 'domain/api/common/types';
import { generateCreateOrUpdateString } from 'domain/api/common/fragments';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { useCreateOrUpdateOne } from 'domain/api/common/adapters/useCreateOrUpdateOne';
import { transformClearableFieldsToClearList } from 'domain/api/common/transforms';
import { ApiCommunity, ApiCommunityInput, CLEARABLE_COMMUNITY_FIELDS, Community } from '../types';
import {
  transformApiCommunityToCommunity,
  transformCommunityToApiCommunityInput,
} from '../transforms';
import { AllCommunityInfo } from '../fragments';

const UPDATE_ONE_COMMUNITY = generateCreateOrUpdateString('update', 'community', AllCommunityInfo);

type Response = {
  updateCommunity: {
    community: ApiCommunity | undefined;
  };
};

type Input<T> = {
  contentSpaceId: string;
  id: string;
  idType: IdType;
  community: T;
};

export const useUpdateOneCommunity = () => {
  const setMessage = useSetMessage();
  const schemas = useApiSchemas();

  const { execute: executeMutation, ...rest } = useCreateOrUpdateOne<
  WithClearList<ApiCommunityInput, Input<ApiCommunityInput>>,
  Response,
  ValidatedEntity<Community> | undefined
  >(
    UPDATE_ONE_COMMUNITY,
    (data) =>
      schemas?.community.apiSchema
        ? transformApiCommunityToCommunity(
          validateApiResponseEntity(data?.updateCommunity.community, schemas.community.apiSchema)
        )
        : undefined,
    {
      onSuccess: () => setMessage(createSuccessfulUpdateMessage('')),
      onError: (err) => setMessage(createFailedUpdateMessage('', err)),
    }
  );

  const execute = (input: Input<Partial<Community>>) => {
    const transformed = transformCommunityToApiCommunityInput(input.community);
    executeMutation({
      ...input,
      community: transformed,
      clear: transformClearableFieldsToClearList(transformed, CLEARABLE_COMMUNITY_FIELDS),
    });
  };

  return { execute, ...rest };
};
