import React from 'react';
import { LayoutRenderer } from 'lib';
import { Community } from 'domain/api/community/types';
import { generateContentEntrySelectOptions } from 'utilities/helpers';
import { FloorPlan } from 'domain/api/floorPlan/types';
import { Inventory } from 'domain/api/inventory/types';
import { ShowHome } from 'domain/api/showhome/types';
import { ImageLink } from 'domain/api/imageLink/types';
import { AssetFile } from 'domain/api/asset/types';
import { EntityDialogActionForm } from 'domain/view/components/form/EntityDialogActionForm';
import { useUiText } from 'domain/uiText';

type Props = {
  floorPlans: FloorPlan[];
  communities: Community[];
  inventories: Inventory[];
  showHomes: ShowHome[];
  onSubmit: (
    values: ImageLink & { files: AssetFile[] },
    onClose: () => void,
    alsoPublish?: boolean
  ) => void;
  submitting: boolean;
  submitted: boolean;
};

export const CreateImageLinkForm = ({
  floorPlans,
  communities,
  inventories,
  showHomes,
  onSubmit,
  submitted,
  submitting,
}: Props) => {
  const uiText = useUiText();
  const communityOptions = generateContentEntrySelectOptions(communities);
  const inventoryOptions = generateContentEntrySelectOptions(inventories);
  const floorPlanOptions = generateContentEntrySelectOptions(floorPlans);
  const showHomeOptions = generateContentEntrySelectOptions(showHomes);

  return (
    <EntityDialogActionForm
      resourceName="imageLink"
      onSubmit={onSubmit}
      title={uiText.ACTION_NEW_IMAGE_LINK_LABEL}
      action={{
        type: 'create',
        label: uiText.ACTION_NEW_IMAGE_LINK_LABEL,
      }}
      submitting={submitting}
      submitted={submitted}
      includeUntouchedFields={true}
      showCreateAndPublishAction={true}
    >
      {(renderField) => (
        <LayoutRenderer
          items={[
            {
              container: true,
              direction: 'column',
              spacing: 2,
              items: [
                {
                  renderContent: () => renderField('displayName'),
                },
                {
                  renderContent: () => renderField('url'),
                },
                {
                  renderContent: () => renderField('files'),
                },
                {
                  renderContent: () =>
                    renderField('floorPlans', {
                      options: floorPlanOptions.options,
                      additionalAction: {
                        value: floorPlanOptions.publishedIds,
                        label: uiText.LABEL_ALL_PUBLISHED,
                      },
                    }),
                },
                {
                  renderContent: () =>
                    renderField('communities', {
                      options: communityOptions.options,
                      additionalAction: {
                        value: communityOptions.publishedIds,
                        label: uiText.LABEL_ALL_PUBLISHED,
                      },
                    }),
                },
                {
                  renderContent: () =>
                    renderField('inventories', {
                      options: inventoryOptions.options,
                      additionalAction: {
                        value: inventoryOptions.publishedIds,
                        label: uiText.LABEL_ALL_PUBLISHED,
                      },
                    }),
                },
                {
                  renderContent: () =>
                    renderField('showHomes', {
                      options: showHomeOptions.options,
                      additionalAction: {
                        value: showHomeOptions.publishedIds,
                        label: uiText.LABEL_ALL_PUBLISHED,
                      },
                    }),
                },
              ],
            },
          ]}
        />
      )}
    </EntityDialogActionForm>
  );
};
