import React from 'react';
import { CancelIconButton, EditIconButton } from '_lib/components';

interface OwnProps {
  isEditing: boolean;
  editDisabled: boolean;
  onCancel?: () => void;
  onEdit: () => void;
}

type Props = OwnProps;

export const EditCancelButton = React.memo((props: Props) => {
  const { isEditing, editDisabled, onCancel, onEdit } = props;

  if (isEditing) return <CancelIconButton onCancel={onCancel} />;
  return <EditIconButton onEdit={onEdit} disabled={editDisabled} />;
});
