import { gql, useMutation } from '@apollo/client';
import { useContentSpaceId } from '_lib/auth';
import { createFailedUpdateMessage, createSuccessfulUpdateMessage, useSetMessage } from '_lib/messages';
import { MutationWithInput } from 'domain/api/common/types';
import { AllAssetInfo } from '../fragments';
import { ORDER_ASSETS } from './gql';
import { Asset, AssetAttributeName, EntityWithAssetsConstraint } from '../types';

type Input = MutationWithInput<{
  contentSpaceId: string;
  id: string;
  idType: 'BUILDER_ID' | 'CONTENT_ID';
  assets: string[];
  attributeName: AssetAttributeName;
}>;

type Response = {
  orderAssets: {
    assets: Asset[];
  };
};

export function useOrderAssets<T extends EntityWithAssetsConstraint>(
  attributeName: AssetAttributeName,
  entity: T,
) {
  const contentSpaceId = useContentSpaceId();
  const setMessage = useSetMessage();
  const [mutation, mutationResult] = useMutation<Response, Input>(
    ORDER_ASSETS,
    {
      update(cache, result) {
        cache.modify({
          id: cache.identify(entity),
          fields: {
            assets(existing = [], { readField }) {
              const newRefs: any = [];
              result.data?.orderAssets.assets.forEach(asset => {
                const newAssetRef = cache.writeFragment({
                  data: asset,
                  fragment: gql`
                    fragment NewAsset on Asset {
                      ${AllAssetInfo}
                    }
                  `
                });
                newRefs.push(newAssetRef);
              });
              return [
                ...existing.filter((ref: any) =>
                  readField('attributeName', ref) !== attributeName
                ),
                ...newRefs,
              ];
            }
          }
        });
      },
    },
  );

  const order = (
    entityContentId: string,
    assetIds: string[],
  ) => {
    if (!contentSpaceId) return;
    mutation({
      variables: {
        input: {
          contentSpaceId,
          id: entityContentId,
          idType: 'CONTENT_ID',
          assets: assetIds,
          attributeName,
        },
      },
    })
      .then(() => setMessage(createSuccessfulUpdateMessage('asset order')))
      .catch(err => setMessage(createFailedUpdateMessage('asset order', err)));
  };

  return {
    order,
    result: mutationResult.data?.orderAssets.assets,
    loading: mutationResult.loading,
    called: mutationResult.called,
    error: mutationResult.error,
  };
}
