import { defineUiText } from '_lib/uiText/';

const actionNewEntityLabel = (entitySingular: string) => `New ${entitySingular}`;
const actionEditEntityLabel = (entitySingular: string) => `Edit ${entitySingular}`;
const actionAddExistingEntityLabel = (entityPlural: string) => `Add Existing ${entityPlural}`;
const noEntity = (entityPlural: string) => `No ${entityPlural.toLocaleLowerCase()} yet`;

export type UiText = ReturnType<typeof useUiText>;

export const { defaultUiText, useConfigureUiText, useUiText } = defineUiText(
  {
    VENDOR_SINGULAR: 'Vendor',
    VENDOR_PLURAL: 'Vendors',

    SECTION_BASIC_INFO: 'Basic Information',
    SECTION_DESCRIPTION: 'Description',
    SECTION_MAIN_IMAGE: 'Main Image',
    SECTION_GALLERY: 'Gallery',
    SECTION_OPENHOUSE_WIDGETS: 'OpenHouse Widgets',
    SECTION_LOCATION: 'Location',

    SECTION_STARTING_PRICES: 'Starting Prices',
    SECTION_LOTMAP: 'Lotmap',
    SECTION_THUMBNAIL_IMAGE: 'Thumbnail Image',
    SECTION_BANNER_IMAGE: 'Banner Image',
    SECTION_LOGO_IMAGE: 'Logo Image',
    SECTION_SLOGAN_IMAGE: 'Slogan Image',
    SECTION_PROJECT_DEMOSTATS: 'Demographics Source Area',

    SECTION_FEATURE_TAGS: 'Features',
    SECTION_FLOOR_PLAN_IMAGES: 'Floor Plan Images',
    SECTION_ATTACHMENT_FILE: 'Attachment File',
    SECTION_ATTACHMENT_IMAGE: 'Attachment Image',

    SECTION_PRICING: 'Pricing',
    SECTION_ADDRESS: 'Address',

    ACTION_ADD_FEATURE_TAG: 'Add Feature',

    LABEL_ALL_PUBLISHED: 'All Published',
    LABEL_NAME: 'Name',
    LABEL_DESCRIPTION: 'Description',
    LABEL_CATEGORY: 'Category',
    LABEL_SHOW_FLOOR_PLAN_VIEWER: 'Show Floor Plan Image Viewer',
    LABEL_PRICE: 'Price',
    LABEL_LABEL: 'Label',
    LABEL_COUNTRY_SUBDIVISION_CODE: 'State / Province',
    LABEL_POST_CODE: 'Postal / Zip Code',

    NO_STARTING_PRICES: 'No starting prices yet',
    NO_DESCRIPTION: 'No description',
    NO_FEATURE_TAGS: 'No features yet',
    NO_GALLERY_IMAGES: 'No gallery images yet',
    NO_FLOOR_PLAN_IMAGES: 'No floor plan images yet',
    NO_IMAGES: 'No images yet',
    NO_DEMOSTATS: 'No demographics yet',
    NO_BOUNDARY: 'No source area yet',

    HELP_IMAGE_MAIN: 'Aspect Ratio: 3:2, Minimum: 1200px x 800px, Maximum 2400px x 1600px, Recommended: 1800px x 1200px',
    HELP_IMAGE_GALLERY: 'Aspect Ratio: 3:2, Minimum: 1200px x 800px, Maximum 2400px 1600px, Recommended: 1800px x 1200px',
    HELP_IMAGE_FLOOR_PLAN: 'Aspect Ratio: Tightly Cropped, Minimum: as large as possible, Maximum Size: 3000px x 3000px',
    HELP_IMAGE_LOT_MAP: 'Aspect Ratio: Any, Minimum: 800px x 800px, Maximum: 3000px x 3000px',
    HELP_IMAGE_THUMBNAIL: 'Aspect Ratio 2:1, Minimum: 600px x 300px, Maximum: 2400px x 1200px, Recommended: 1000px x 500px',
    HELP_IMAGE_LOGO: 'Aspect Ratio: Any, Height: 80px exactly, Max Width: 850px',
    HELP_IMAGE_SLOGAN: 'Aspect Ratio: Any, Height: 80px exactly, Max Width: 850px',
    HELP_IMAGE_BANNER: 'Aspect Ratio: 5:1, Minimum: 2250px x 450px, Maximum: 5000px x 1000px, Recommended: 2500px x 500px',
    HELP_IMAGE_ATTACHMENT: 'Size exactly 200px x 200px',
    HELP_UPLOAD_SINGLE: 'Drag and drop a file here',
    HELP_UPLOAD_MULTIPLE: 'Drag and drop files here',
    HELP_DESCRIPTION: 'Also used for SEO meta description',
    HELP_FEATURE_TAG_SHOW_FLOOR_PLAN_VIEWER: 'Include a link to the Floor Plan Image viewer',
    HELP_NO_FEATURE_TAGS: 'Start typing to create a new feature',

    WARNING_BOUNDARY_LOCATION_PREREQUISITE: 'Location must be set to edit source area',
    ERROR_MAX_UPLOAD_SIZE: 'Maximum file size is 10MB',
    ERROR_INVALID_UPLOAD_TYPE: 'Invalid file type',
  },
  {
    FLOOR_PLAN_SINGULAR: 'Floor Plan',
    FLOOR_PLAN_PLURAL: 'Floor Plans',
    FLOOR_PLAN_PATH: 'home models',
    COMMUNITY_SINGULAR: 'Community',
    COMMUNITY_PLURAL: 'Communities',
    COMMUNITY_PATH: 'communities',
    INVENTORY_SINGULAR: 'Inventory',
    INVENTORY_PLURAL: 'Inventories',
    INVENTORY_PATH: 'inventories',
    SHOWHOME_SINGULAR: 'Showhome',
    SHOWHOME_PLURAL: 'Showhomes',
    SHOWHOME_PATH: 'showhomes',
    IMAGE_LINK_SINGULAR: 'Image Link',
    IMAGE_LINK_PLURAL: 'Image Links',
    IMAGE_LINK_PATH: 'image-links',
    BANNER_SINGULAR: 'Banner',
    BANNER_PLURAL: 'Banners',
    BANNER_PATH: 'banners',
    THIRD_PARTY_PLUGIN_SINGULAR: 'Third Party Plugin',
    THIRD_PARTY_PLUGIN_PLURAL: 'Third Party Plugins',
    THIRD_PARTY_PLUGIN_PATH: 'third-party-plugins',
    VIDEO_SINGULAR: 'Video',
    VIDEO_PLURAL: 'Videos',
    VIDEO_PATH: 'videos',
    CONTACT_SINGULAR: 'Sales Person',
    CONTACT_PLURAL: 'Sales People',
    CONTACT_PATH: 'sales-people',
    SALES_CENTRE_SINGULAR: 'Sales Centre',
    SALES_CENTRE_PLURAL: 'Sales Centres',
    SALES_CENTRE_PATH: 'sales-centres',
    FEATURE_TAG_SINGLULAR: 'Feature',
    FEATURE_TAG_PLURAL: 'Features',
  },
  (config) => ({
    ACTION_NEW_FLOOR_PLAN_LABEL: actionNewEntityLabel(config.FLOOR_PLAN_SINGULAR),
    ACTION_NEW_COMMUNITY_LABEL: actionNewEntityLabel(config.COMMUNITY_SINGULAR),
    ACTION_NEW_INVENTORY_LABEL: actionNewEntityLabel(config.INVENTORY_SINGULAR),
    ACTION_EDIT_INVENTORY_LABEL: actionEditEntityLabel(config.INVENTORY_SINGULAR),
    ACTION_NEW_SHOWHOME_LABEL: actionNewEntityLabel(config.SHOWHOME_SINGULAR),
    ACTION_NEW_IMAGE_LINK_LABEL: actionNewEntityLabel(config.IMAGE_LINK_SINGULAR),
    ACTION_EDIT_IMAGE_LINK_LABEL: actionEditEntityLabel(config.IMAGE_LINK_SINGULAR),
    ACTION_ADD_EXISTING_IMAGE_LINK_LABEL: actionAddExistingEntityLabel(config.IMAGE_LINK_PLURAL),
    ACTION_NEW_BANNER_LABEL: actionNewEntityLabel(config.BANNER_SINGULAR),
    ACTION_EDIT_BANNER_LABEL: actionEditEntityLabel(config.BANNER_SINGULAR),
    ACTION_ADD_EXISTING_BANNER_LABEL: actionAddExistingEntityLabel(config.BANNER_PLURAL),
    ACTION_NEW_THIRD_PARTY_PLUGIN_LABEL: actionNewEntityLabel(config.THIRD_PARTY_PLUGIN_SINGULAR),
    ACTION_EDIT_THIRD_PARTY_PLUGIN_LABEL: actionEditEntityLabel(config.THIRD_PARTY_PLUGIN_SINGULAR),
    ACTION_ADD_EXISTING_THIRD_PARTY_PLUGIN_LABEL:
      actionAddExistingEntityLabel(config.THIRD_PARTY_PLUGIN_PLURAL),
    ACTION_NEW_VIDEO_LABEL: actionNewEntityLabel(config.VIDEO_SINGULAR),
    ACTION_EDIT_VIDEO_LABEL: actionEditEntityLabel(config.VIDEO_SINGULAR),
    ACTION_ADD_EXISTING_VIDEO_LABEL: actionAddExistingEntityLabel(config.VIDEO_PLURAL),
    ACTION_NEW_CONTACT_LABEL: actionNewEntityLabel(config.CONTACT_SINGULAR),
    ACTION_EDIT_CONTACT_LABEL: actionEditEntityLabel(config.CONTACT_SINGULAR),
    ACTION_ADD_EXISTING_CONTACT_LABEL: actionAddExistingEntityLabel(config.CONTACT_PLURAL),
    ACTION_NEW_SALES_CENTRE_LABEL: actionNewEntityLabel(config.SALES_CENTRE_SINGULAR),
    ACTION_EDIT_FEATURE_TAG_LABEL: actionEditEntityLabel(config.FEATURE_TAG_SINGLULAR),

    SECTION_COMMUNITY_PRICING: `${config.COMMUNITY_PLURAL} & Pricing`,

    NO_FLOOR_PLAN: noEntity(config.FLOOR_PLAN_PLURAL),
    NO_COMMUNITY: noEntity(config.COMMUNITY_PLURAL),
    NO_INVENTORY: noEntity(config.INVENTORY_PLURAL),
    NO_SHOWHOME: noEntity(config.SHOWHOME_PLURAL),
    NO_IMAGE_LINK: noEntity(config.IMAGE_LINK_PLURAL),
    NO_BANNER: noEntity(config.BANNER_PLURAL),
    NO_THIRD_PARTY_PLUGIN: noEntity(config.THIRD_PARTY_PLUGIN_PLURAL),
    NO_VIDEO: noEntity(config.VIDEO_PLURAL),
    NO_CONTACT: noEntity(config.CONTACT_PLURAL),
    NO_SALES_CENTRE: noEntity(config.SALES_CENTRE_PLURAL),
    NO_COMMUNITY_PRICING: `No ${config.COMMUNITY_SINGULAR.toLowerCase()} pricing yet`,
    NO_CONTACTS_ON_SALES_CENTRE: `No ${config.CONTACT_PLURAL.toLocaleLowerCase()} on this ${config.SALES_CENTRE_SINGULAR.toLocaleLowerCase()} yet`,
  })
);
