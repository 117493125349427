import { AllAssetInfo } from '../asset/fragments';

export const AllFloorPlanLayoutInfo = `
id
builderId
displayOrder
displayName
description
bedrooms
bathrooms
halfBaths
floorSize
assets {
  ${AllAssetInfo}
}
`;
