export const baseUiText = Object.freeze({
  ACTION_NEW_ITEM_LABEL: 'New Item',
  ACTION_NEW_LABEL: 'New',
  ACTION_ADD_LABEL: 'Add',
  ACTION_CREATE_LABEL: 'Create',
  ACTION_CREATE_AND_PUBLISH_LABEL: 'Create and Publish',
  ACTION_EDIT_LABEL: 'Edit',
  ACTION_PREVIEW_LABEL: 'Preview',
  ACTION_PUBLISH_LABEL: 'Publish',
  ACTION_UNPUBLISH_LABEL: 'Unpublish',
  ACTION_REORDER_LABEL: 'Reorder',
  ACTION_REORDER_NOTE: 'Manage the display order',
  ACTION_CLEAR_LABEL: 'Clear',
  ACTION_CANCEL_LABEL: 'Cancel',
  ACTION_SAVE_LABEL: 'Save',
  ACTION_UPLOAD_LABEL: 'Upload',
  ACTION_SELECT_LABEL: 'Select',
  ACTION_REMOVE_LABEL: 'Remove',
  ACTION_DELETE_LABEL: 'Delete',
  ACTION_DELETE_DIALOG_TITLE: 'Delete this entry?',
  ACTION_DELETE_DIALOG_DESCRIPTION: 'You will not be able to recover it',
  ACTION_DELETE_SELECTED_LABEL: 'Delete selected',
  ACTION_DELETE_DIALOG_CONFIRM: 'Yes, I understand',

  PUBLISHED_STATUS_DRAFT_LABEL: 'Draft',
  PUBLISHED_STATUS_DRAFT_NOTE_READY: 'This entry is ready to be published',
  PUBLISHED_STATUS_DRAFT_NOTE_PROBLEMS:
    'There are issues preventing this entry from being published',
  PUBLISHED_STATUS_PUBLISHED_LABEL: 'Published',
  PUBLISHED_STATUS_PUBLISHED_NOTE: 'This entry is published',

  SELECT_ENTRIES_TO_REMOVE: 'Select entries to remove',

  NO_VALUE: 'N/A',
});

export type BaseUiText = typeof baseUiText;

export const useBaseUiText = () => baseUiText;
