import { useAuth0 } from '@auth0/auth0-react';
import { useMultiWindowLogout } from './useMultiWindowAuth';

const AUTH_NAMESPACE_DOMAIN = 'https://openhouse.ai/';

export interface AuthUser {
  nickname: string;
  picture: string;
  email: string;
  contentSpaceId: string;
}

export type UseAuth = () => {
  user?: AuthUser,
  logout: () => void,
};

export const useAuth: UseAuth = () => {
  const { user: user0, logout: logout0 } = useAuth0();
  const { triggerMultiWindowLogout } = useMultiWindowLogout();
  const logout = () => {
    triggerMultiWindowLogout();
    logout0();
  };

  const contentSpaceId = user0 && user0[AUTH_NAMESPACE_DOMAIN]?.contentSpaceId;
  if (user0 && !contentSpaceId) throw Error("Misconfigured User: User does not have 'contentSpaceId'");

  return {
    user: {
      nickname: user0.nickname,
      picture: user0.picture,
      email: user0.email,
      contentSpaceId,
    },
    logout,
  };
};

export const useContentSpaceId = () => useAuth().user?.contentSpaceId;
