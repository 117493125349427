import React from 'react';
import classnames from 'classnames';
import { Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme } from '@material-ui/core';
import { PageSectionOptions } from '../types';

interface PageSectionEntryOptions extends PageSectionOptions {
  active: boolean,
}

type Props = {
  sections: PageSectionEntryOptions[],
};

export const PageSectionMenu = React.memo(({ sections }: Props) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.container}
      container
      direction="column"
      wrap="nowrap"
    >
      <Grid item className={classes.menuItems}>
        <List dense>
          {sections.map(section => (
            <ListItem
              key={section.id}
              button
              component="a"
              href={`#${section.id}`}
            >
              <ListItemIcon className={classnames({ [classes.active]: section.active })}>
                {section.icon}
              </ListItemIcon>
              <ListItemText className={classnames({ [classes.active]: section.active })}>
                {section.title}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 200,
    height: '100%',
    flexShrink: 0,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[2],
  },
  menuItems: {
    flexGrow: 1,
    overflowY: 'auto',
  },
  active: {
    color: theme.palette.primary.main,
  },
}));
