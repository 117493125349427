import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { Action } from 'common/types';
import { useToggle } from 'common/hooks/useToggle';
import { DeleteDialog } from '_lib/components/dialogs/DeleteDialog';

interface OwnProps {}

type Props = OwnProps & Omit<Action, 'type'>;

export const DeleteAction = (props: Props) => {
  const { label, onClick, disabled, isSmall } = props;
  const classes = useStyles();
  const { open, onOpen, onClose } = useToggle();

  const handleOpen = (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    onOpen();
  };

  const handleSubmit = () => {
    onClick && onClick();
    onClose();
  };

  return (
    <React.Fragment>
      <Button
        disabled={disabled}
        variant="outlined"
        className={classes.root}
        onClick={handleOpen}
        endIcon={<DeleteIcon fontSize="small" />}
        size={isSmall ? 'small' : undefined}
      >
        {label}
      </Button>
      <DeleteDialog open={open} onCancel={onClose} onConfirm={handleSubmit} />
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontWeight: 500,
    textTransform: 'capitalize',
    backgroundColor: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    color: theme.palette.getContrastText(theme.palette.error.main),
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[300],
      borderColor: theme.palette.grey[500],
      color: theme.palette.grey[500],
    },
  },
}));
