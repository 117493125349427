import { gql, useMutation } from '@apollo/client';
import { MutationWithInput } from 'domain/api/common/types';
import { AssetAttributeName, AssetFile, EntityWithAssetsConstraint } from 'domain/api/asset/types';
import { isSingleAssetAttribute } from '../helpers';
import { CREATE_ONE_ASSET } from './gql';

export type CreateAssetResponse = {
  createAsset: {
    asset: {
      id: string;
      displayName: string;
    }
  };
};

export type CreateAssetInput = MutationWithInput<{
  file: AssetFile;
  contentSpaceId: string;
  contentId: string;
  attributeName: AssetAttributeName;
  displayOrder: number | null;
}>;

export function useCreateOneAsset<T extends EntityWithAssetsConstraint>(
  attributeName: AssetAttributeName,
  file: File,
  entity: T,
) {
  return useMutation<CreateAssetResponse, CreateAssetInput>(
    CREATE_ONE_ASSET,
    {
      update(cache, result) {
        cache.modify({
          id: cache.identify(entity),
          fields: {
            assets(existing = [], { readField }) {
              const newAsset = {
                __typename: 'Asset',
                id: result.data?.createAsset.asset.id,
                url: URL.createObjectURL(file),
                displayOrder: null,
                attributeName,
                description: '',
              };
              const newAssetRef = cache.writeFragment({
                data: newAsset,
                fragment: gql`
                  fragment NewAsset on Asset {
                    id
                    url
                    displayOrder
                    description
                    attributeName
                  }
                `
              });

              // Some types of assets only display a single image so we
              // need to remove the existing asset from the cache in this case
              if (isSingleAssetAttribute(attributeName)) {
                return [
                  ...existing.filter((ref: any) =>
                    readField('attributeName', ref) !== attributeName
                  ),
                  newAssetRef,
                ];
              }
              return [...existing, newAssetRef];
            }
          }
        });
      },
    },
  );
}
