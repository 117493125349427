import * as yup from 'yup';
import { FieldErrors, FormFields, InitialValues, TransformedFieldValues } from './types';

export function emptyArrayToUndefined<T>(arr: T[]) {
  if (arr.length <= 0) return undefined;
  return arr;
}

export function createErrors<T extends InitialValues>(
  fields: T,
  validation?: yup.ObjectSchema,
  omittedFields?: (keyof T & string)[],
) {
  const errors: any = {};
  if (validation) {
    Object.entries(validateSync(validation, fields)).forEach(([key, value]) => {
      if (!omittedFields?.includes(key)) {
        errors[key] = value;
      }
    });
  }

  return errors as FieldErrors<T>;
}

export function flattenValues<T extends InitialValues>(values: FormFields<T>) {
  const prepared: { [x: string]: any } = {};

  Object.keys(values).forEach(key => {
    prepared[key] = values[key].value;
  });

  return prepared as TransformedFieldValues<T>;
}

export function transformYupError(error: yup.ValidationError) {
  const errors: any = {};
  error.inner.forEach((_error) => {
    if (!errors[_error.path]) {
      errors[_error.path] = [_error.message];
    } else {
      errors[_error.path].push(_error.message);
    }
    // Todo: recursively map over nested errors and transform them(maybe use dot notation with npm flat package?)
  });

  return errors;
}

export const transformFieldErrorToArray = (errors: { [x: string]: string }) => {
  if (Object.keys(errors).length <= 0) return undefined;
  const transformed: string[] = [];
  Object.keys(errors).forEach(key => {
    transformed.push(errors[key]);
  });

  return transformed;
};

export function validateSync<T extends InitialValues>(
  schema: yup.ObjectSchema,
  values: FormFields<T>,
  field?: keyof T & string,
) {
  try {
    if (schema) {
      const preparedValues = flattenValues(values);
      if (field) {
        if (!Object.keys(schema.fields || {}).includes(field)) return {};
        schema.validateSyncAt(field, preparedValues, { abortEarly: false });
      } else {
        schema.validateSync(preparedValues, { abortEarly: false });
      }
      return {};
    }
    return {};
  } catch (err) {
    if (err.name === 'ValidationError') {
      return transformYupError(err);
    }
    throw new Error('An unhandled error occured during form validation');
  }
}
