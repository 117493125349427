import React from 'react';
import { LayoutRenderer } from 'lib/layout/LayoutRenderer';
import { FormFields, InitialValues } from 'lib/form/types';
import { Inventory, InventoryInput } from 'domain/api/inventory/types';
import { EntityDialogActionForm } from 'domain/view/components/form/EntityDialogActionForm';
import { useContentSpaceId } from '_lib/auth';
import { useUpdateOneInventory } from 'domain/api/inventory';
import { useUiText } from 'domain/uiText';
import { useResetableAdapterAction } from 'domain/api/common/hooks';

type Props = {
  inventory: Inventory;
};

export const EditInventoryPricingForm = ({ inventory }: Props) => {
  const uiText = useUiText();
  const contentSpaceId = useContentSpaceId();
  const updateInventory = useResetableAdapterAction(useUpdateOneInventory);

  const onSubmit = (values: Partial<InventoryInput>) => {
    if (!inventory.id || !contentSpaceId) return;
    updateInventory.execute({
      contentSpaceId,
      idType: 'CONTENT_ID',
      id: inventory.id,
      inventory: values,
    });
  };

  // Doing this to prevent navigation to the inventory detail page when interacting with the dialog.
  const handleDialogClick = (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
  };

  return (
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span onClick={handleDialogClick}>
      <EntityDialogActionForm
        resourceName="inventory"
        initialValues={inventory}
        onSubmit={onSubmit}
        fieldModifiers={{
          // Subscribing these fields so they are validated when either of them changes
          priceReduced: (fields: FormFields<InitialValues>) => ({ fields }),
          priceReducedFrom: (fields: FormFields<InitialValues>) => ({ fields }),
          price: (fields: FormFields<InitialValues>) => ({ fields }),
        }}
        title={uiText.ACTION_EDIT_INVENTORY_LABEL}
        action={{
          type: 'edit',
          label: uiText.ACTION_EDIT_LABEL,
          isIcon: true,
          isSmall: true,
        }}
        submitted={updateInventory.called && !updateInventory.loading}
        submitting={updateInventory.loading}

        // Removing possessionDate because any form that has fieldModifiers will run
        // validation on all fields. This can result in dates in the past failing validation,
        // making the form unable to be submitted.

        // A better solution would be to either pass in only the required fields to each form,
        // or to keep track of which fields have been rendered and remove those from the validation
        omittedFields={['possessionDateSec']}
      >
        {(renderField, formFields) => (
          <LayoutRenderer
            items={[
              {
                container: true,
                direction: 'column',
                spacing: 2,
                items: [
                  {
                    container: true,
                    item: true,
                    spacing: 2,
                    items: [
                      {
                        item: true,
                        xl: 4,
                        md: 6,
                        renderContent: () => renderField('price'),
                      },
                      {
                        item: true,
                        xl: 4,
                        md: 6,
                        renderContent: () => renderField('priceReduced'),
                      },
                      {
                        item: true,
                        xl: 4,
                        md: 6,
                        renderContent: () => formFields.priceReduced.value ? renderField('priceReducedFrom') : <div />
                      },
                    ],
                  },
                ],
              },
            ]}
          />
        )}
      </EntityDialogActionForm>
    </span>
  );
};
