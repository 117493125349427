import React from 'react';
import { compact } from 'lodash';
import { makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FieldError } from 'common/types';

type Props = {
  errors?: FieldError[];
};

export const DetailPageValidationErrors = ({ errors }: Props) => {
  const classes = useStyles();
  const filtered = compact(errors)?.filter(el => el?.message?.trim().length > 0);
  if (!errors || filtered.length <= 0) return null;
  return (
    <Paper className={classes.validationErrorsContainer}>
      <Alert className={classes.validationErrorsHeader} severity="warning" variant="filled">
        There are issues preventing this entry from being published
      </Alert>
      <div className={classes.validationErrors}>
        <ul>
          {filtered.map(err => (
            <Typography key={`validation-error-${err.message}`} component="li" variant="caption">
              {err.message}
            </Typography>
          ))}
        </ul>
      </div>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  validationErrorsContainer: {
    backgroundColor: theme.palette.warning.light,
    marginBottom: theme.spacing(3),
  },
  validationErrorsHeader: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  validationErrors: {
    padding: theme.spacing(2),
  }
}));
