import { createFailedDeleteMessage, createSuccessfulDeleteMessage, useSetMessage } from '_lib/messages';
import { useIdInputMutation } from 'domain/api/common/adapters/useIdInputMutation';
import { updateCacheOnDeleteOne } from 'domain/api/common/cache';
import { DeleteOneAdapterAction } from 'domain/api/common/adapters/types';
import { DELETE_ONE_CONTACT } from './gql';

type Response = {
  deleteContact: {
    deletedId: string | null | undefined;
  };
};

export const useDeleteOneContact = (): DeleteOneAdapterAction => {
  const setMessage = useSetMessage();

  return useIdInputMutation<Response, string | undefined>(
    DELETE_ONE_CONTACT,
    (data) => data?.deleteContact.deletedId || undefined,
    {
      updateCache: (cache, data) => updateCacheOnDeleteOne(
        cache, 'contacts', data?.deleteContact.deletedId,
      ),
      onSuccess: () => {
        setMessage(createSuccessfulDeleteMessage(''));
      },
      onError: (err) => setMessage(createFailedDeleteMessage('', err)),
      omitContentSpaceId: true,
    }
  );
};
