import React from 'react';
import { LayoutRenderer } from 'lib/layout/LayoutRenderer';
import { Inventory, InventoryInput } from 'domain/api/inventory/types';
import { EntitySectionForm } from 'domain/view/components/form/EntitySectionForm';
import { PageSectionOptions } from '_lib/sectionedPageLayout';

type Props = PageSectionOptions & {
  initialValues: Inventory;
  onSubmit: (values: InventoryInput) => void,
};

export const InventoryOpenhouseWidgetsForm = ({ initialValues, onSubmit, ...rest }: Props) => {
  return (
    <EntitySectionForm
      resourceName="inventory"
      initialValues={initialValues}
      onUpdate={onSubmit}
      {...rest}
    >
      {renderField => (
        <LayoutRenderer
          items={[
            {
              container: true, direction: 'column', spacing: 2,
              items: [
                {
                  container: true, item: true, spacing: 2,
                  items: [
                    {
                      item: true,
                      renderContent: () => renderField('showMonthlyPaymentCalculator'),
                    },
                    {
                      item: true,
                      renderContent: () => renderField('showCommuteCalculation'),
                    },
                  ]
                },
              ]
            }
          ]}
        />
      )}
    </EntitySectionForm>
  );
};
