import { Message } from './types';

export const createSuccessfulCreationMessage = (entity: string): Message => ({
  severity: 'success',
  content: `Successfully created ${entity}`,
});

export const createFailedCreationMessage = (entity: string, error: string): Message => ({
  severity: 'error',
  content: `Failed to create ${entity}, ${error}`,
});

export const createSuccessfulUpdateMessage = (entity: string): Message => ({
  severity: 'success',
  content: `Successfully updated ${entity}`,
});

export const createFailedUpdateMessage = (entity: string, error: string): Message => ({
  severity: 'error',
  content: `Failed to update ${entity}, ${error}`,
});

export const createSuccessfulPublishMessage = (entity: string): Message => ({
  severity: 'success',
  content: `Successfully published ${entity}`,
});

export const createFailedPublishMessage = (entity: string, error: string): Message => ({
  severity: 'error',
  content: `Failed to publish ${entity}, ${error}`,
});

export const createSuccessfulUnpublishMessage = (entity: string): Message => ({
  severity: 'success',
  content: `Successfully unpublished ${entity}`,
});

export const createFailedUnpublishMessage = (entity: string, error: string): Message => ({
  severity: 'error',
  content: `Failed to unpublish ${entity}, ${error}`,
});

export const createSuccessfulDeleteMessage = (entity: string): Message => ({
  severity: 'success',
  content: `Successfully deleted ${entity}`,
});

export const createFailedDeleteMessage = (entity: string, error: string): Message => ({
  severity: 'error',
  content: `Failed to delete ${entity}, ${error}`,
});
