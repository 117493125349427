import { Alert, AlertTitle } from '@material-ui/lab';
import { useUiText } from 'domain/uiText';
import React from 'react';
import { FileRejection } from 'react-dropzone';

export const useUploadErrors = () => {
  const uiText = useUiText();
  const [fileRejections, setFileRejections] = React.useState<FileRejection[]>([]);
  const renderUploadErrors = () => {
    return (
      <div style={{ marginTop: '20px' }}>
        {fileRejections.map((rejection) => (
          <Alert key={`rejected-file-${rejection.file.name}`} severity="error">
            <AlertTitle>{rejection.file.name}</AlertTitle>
            <ul>
              {rejection.errors.map((error) => (
                <li key={`rejected-file-${rejection.file.name}-error-${error.code}`}>
                  {error.code === 'file-too-large' && uiText.ERROR_MAX_UPLOAD_SIZE}
                  {error.code === 'file-invalid-type' && uiText.ERROR_INVALID_UPLOAD_TYPE}
                </li>
              ))}
            </ul>
          </Alert>
        ))}
      </div>
    );
  };

  return {
    renderUploadErrors,
    setFileRejections,
    fileRejections,
  };
};
