import { useMutation } from '@apollo/client';
import { SalesCentre } from 'system/resources/salesCentre';
import { createFailedUpdateMessage, createSuccessfulUpdateMessage, useSetMessage } from '_lib/messages';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { REMOVE_SALES_CENTRE_CONTACTS } from './gql';

type Input = {
  input: {
    salesCentreId: string;
    contacts: string[];
  };
};

type Response = {
  removeSalesCentreContacts: {
    salesCentre: SalesCentre;
  }
};
export const useRemoveContactsFromSalesCentre= () => {
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();
  const [mutation, mutationResult] = useMutation<Response, Input>(
    REMOVE_SALES_CENTRE_CONTACTS
  );

  const execute = ({ id, input }: { id: string, input: string[] }) => {
    mutation({
      variables: {
        input: {
          salesCentreId: id,
          contacts: input,
        },
      },
    })
      .then(() => setMessage(createSuccessfulUpdateMessage('')))
      .catch(err => setMessage(createFailedUpdateMessage('', err)));
  };

  const validated = schemas?.salesCentre.apiSchema ? validateApiResponseEntity(
    mutationResult.data?.removeSalesCentreContacts.salesCentre,
    schemas.salesCentre.apiSchema,
  ) : undefined;

  return {
    execute,
    result: validated,
    loading: mutationResult.loading,
    called: mutationResult.called,
    error: mutationResult.error?.message,
  };
};
