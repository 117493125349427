export const AllAssetInfo = `
  id
  displayName
  description
  attributeName
  displayOrder
  url
`;

export const ShortAssetInfo = `
  id
  attributeName
`;
