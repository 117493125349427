import React from 'react';
import { Community } from 'domain/api/community';
import { EntitySectionForm } from 'domain/view/components/form/EntitySectionForm';
import { LayoutRenderer } from 'domain/view/components/layout/layoutRenderer/LayoutRenderer';

type Props = {
  initialValues: Community;
  onSubmit: (values: Community) => void;
  id: string;
  title: string;
  icon: JSX.Element;
};

export const CommunityOpenhouseWidgetsForm = ({ initialValues, onSubmit, ...rest }: Props) => {
  return (
    <EntitySectionForm
      resourceName="community"
      initialValues={initialValues}
      onUpdate={onSubmit}
      {...rest}
    >
      {(renderField) => (
        <LayoutRenderer
          items={[
            {
              container: true,
              direction: 'column',
              spacing: 2,
              items: [
                {
                  container: true,
                  item: true,
                  spacing: 2,
                  items: [
                    {
                      item: true,
                      renderContent: () => renderField('showCommunityStats'),
                    },
                    {
                      item: true,
                      renderContent: () => renderField('showCommuteCalculation'),
                    },
                  ],
                },
              ],
            },
          ]}
        />
      )}
    </EntitySectionForm>
  );
};
