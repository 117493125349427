import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { baseUiText } from './baseUiText';

export const defineUiText = <B, C, D>(
  domainBaseUiText: B,
  defaultConfigUiText: C,
  deriveUiText: (c: C) => D,
) => {
  const defaultUiText = Object.freeze({
    ...baseUiText,
    ...domainBaseUiText,
    ...defaultConfigUiText,
    ...deriveUiText(defaultConfigUiText),
  });
  type UiText = typeof defaultUiText;
  const uiTextState = atom<UiText>({
    key: 'UiText',
    default: defaultUiText,
  });
  const useConfigureUiText = () => {
    const setUiTextState = useSetRecoilState(uiTextState);
    return (configUiText: Partial<C> | undefined) => {
      if (!configUiText) return;
      const mergedConfigUiText: C = {
        ...defaultConfigUiText,
        ...configUiText,
      };
      setUiTextState({
        ...baseUiText,
        ...domainBaseUiText,
        ...mergedConfigUiText,
        ...deriveUiText(mergedConfigUiText),
      });
    };
  };
  const useUiText = () => useRecoilValue(uiTextState);
  return { defaultUiText, useConfigureUiText, useUiText };
};
