import React from 'react';
import { CardFormLayout } from './CardFormLayout';
import { FlatFormLayout } from './FlatFormLayout';
import { FormLayoutProps } from './types';

type Props = FormLayoutProps & {
  flat?: boolean;
};

export const FormLayout = ({ flat, ...rest }: Props) => {
  return flat ? <FlatFormLayout {...rest} /> : <CardFormLayout {...rest} />;
};
