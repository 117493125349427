import React from 'react';

export const useToggle = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return {
    open,
    onOpen: handleOpen,
    onClose: handleClose,
  };
};
