import { Asset } from 'domain/api/asset/types';
import { ApiImageLink, ImageLink } from 'domain/api/imageLink/types';
import { CommonApiFields, PublishableApiEntity, PublishedStatus } from '../common/types';
import { ApiEmbedding, Embedding } from '../embedding/types';
import { ApiFeatureTag, FeatureTag } from '../featureTag/types';
import { ApiFloorPlanLayout } from '../floorPlanLayouts';

export type ApiFloorPlan = PublishableApiEntity<
  Pick<
    CommonApiFields,
    | 'id'
    | 'builderId'
    | 'displayName'
    | 'seoMetaDescription'
    | 'seoMetaKeywords'
    | 'seoTitleTag'
    | 'showMonthlyPaymentCalculator'
    | 'homeType'
    | 'garage'
    | 'price'
  > & {
    floorPlanLayouts: ApiFloorPlanLayout[];
    floorPlanCategory: {
      id: string;
      name: string;
      priority: number;
    } | null;
    communityMappings:
      | {
          community: { id: string; displayName: string };
          price: number | null;
        }[]
      | null;

    assets: Asset[];
    embeddings: ApiEmbedding[];
    imageLinks: ApiImageLink[];
    featureTags: ApiFeatureTag[];
  }
>;

export type ApiFloorPlanInput = Partial<
  Pick<
    ApiFloorPlan,
    | 'displayName'
    | 'seoMetaDescription'
    | 'seoMetaKeywords'
    | 'seoTitleTag'
    | 'showMonthlyPaymentCalculator'
    | 'homeType'
    | 'garage'
  > & {
    communityPricing?:
      | {
          id: string;
          idType: 'CONTENT_ID';
          price: number | null;
        }[]
      | null;
    featureTags?: string[] | null;
  }
>;

export const CLEARABLE_FLOOR_PLAN_FIELDS: (keyof ApiFloorPlanInput & string)[] = [
  'garage',
  'seoTitleTag',
  'seoMetaDescription',
  'seoMetaKeywords',
];

export type FloorPlan = Pick<
  ApiFloorPlan,
  | 'id'
  | 'published'
  | 'builderId'
  | 'displayName'
  | 'seoMetaKeywords'
  | 'seoTitleTag'
  | 'showMonthlyPaymentCalculator'
  | 'homeType'
  | 'garage'
  | 'price'
  | 'assets'
  | 'floorPlanLayouts'
> &
  Pick<CommonApiFields, 'bedrooms' | 'bathrooms' | 'halfBaths' | 'floorSize'> & {
    communityPricing: {
      id: string;
      displayName: string;
      price: number | null;
    }[];
    publishedStatus: PublishedStatus;
    seoDescriptionBody: string;
    seoDescriptionBullets: string[];
    embeddings: Embedding[];
    imageLinks: ImageLink[];
    featureTags: FeatureTag[];
  };
