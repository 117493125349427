import { createFailedPublishMessage, createSuccessfulPublishMessage, useSetMessage } from '_lib/messages';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { ValidatedEntity } from 'domain/api/common/types';
import { useIdInputMutation } from 'domain/api/common/adapters/useIdInputMutation';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { PUBLISH_ONE_SHOW_HOME } from './gql';
import { ApiShowHome, ShowHome } from '../types';
import { transformApiShowHomeToShowHome } from '../transforms';

type Response = {
  publishShowHome: {
    showHome?: ApiShowHome;
  };
};

export const usePublishOneShowHome = () => {
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  return useIdInputMutation<Response, ValidatedEntity<ShowHome> | undefined>(
    PUBLISH_ONE_SHOW_HOME,
    (data) => schemas?.inventory.apiSchema ? transformApiShowHomeToShowHome(
      validateApiResponseEntity(
        data?.publishShowHome.showHome,
        schemas.showHome.apiSchema,
      ),
    ) : undefined,
    {
      onSuccess: () => setMessage(createSuccessfulPublishMessage('')),
      onError: (err) => setMessage(createFailedPublishMessage('', err)),
    },
  );
};
