import { IGoogleApiOptions } from 'google-maps-react';

if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
  throw new Error('Missing environment variable REACT_APP_GOOGLE_MAPS_API_KEY');
}
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

/**
 * All uses of GoogleApiWrapper should be set up with these options since
 * different components will share the google object loaded into
 * the window
 */
export const googleApiWrapperOptions: IGoogleApiOptions = {
  apiKey,
  libraries: ['geometry', 'drawing'],
};
