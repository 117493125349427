import React from 'react';
import { useMessagesState } from '../messagesState';
import { MessagesSnackbar } from '../components/MessagesSnackbar';

export const MessagesDisplay = () => {
  const [message, setMessage] = useMessagesState();
  return (
    <MessagesSnackbar
      message={message}
      onClose={() => setMessage(undefined)}
    />
  );
};
