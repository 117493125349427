import React from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { SortableElement } from 'react-sortable-hoc';
import { Asset } from 'domain/api/asset';

type Props = Asset;

export const AssetFormReorderingItem = SortableElement((props: Props) => {
  const classes = useStyles();
  return (
    <Grid
      xl={3}
      lg={3}
      md={6}
      sm={6}
      xs={12}
      item={true}
      container={true}
      className={classes.container}
    >
      <Grid item={true} className={classes.innerContainer}>
        <img src={props.url} className={classes.asset} alt={props.displayName} />
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    zIndex: 100000,
    cursor: 'grab',
  },
  innerContainer: {
    border: `solid 2px ${theme.palette.grey[200]}`,
    padding: theme.spacing(1),
    borderRadius: 4,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: theme.palette.common.white,
  },
  asset: {
    maxWidth: 160,
    maxHeight: 160,
    display: 'flex',
  },
}));
