import { useLazyQuery } from '@apollo/client';
import { GetManyAdapterAction } from 'domain/api/common/adapters/types';
import {
  GetManyVariables,
  ResponseWithContentSpace,
  ValidatedEntity,
} from 'domain/api/common/types';
import { validateApiResponseEntities } from 'domain/api/common/validation';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { useContentSpaceId } from '_lib/auth';
import { ImageLink } from '../types';
import { filterImageLinksBySize, transformApiImageLinksToImageLinks } from '../transforms';
import { GET_MANY_IMAGE_LINKS } from './gql';

type Response = ResponseWithContentSpace<{ imageLinks: ImageLink[] }>;
type Variables = GetManyVariables;

export const useGetManyImageLinks = (
  isLarge?: boolean
): GetManyAdapterAction<ValidatedEntity<ImageLink>> => {
  const schemas = useApiSchemas();
  const contentSpaceId = useContentSpaceId();
  const [query, queryResult] = useLazyQuery<Response, Variables>(GET_MANY_IMAGE_LINKS);

  const execute = () => {
    contentSpaceId && query({ variables: { contentSpaceId } });
  };

  const validated = schemas?.imageLink.apiSchema
    ? validateApiResponseEntities(
      queryResult.data?.contentSpace.imageLinks,
      schemas.imageLink.apiSchema
    )
    : undefined;

  const transformed = transformApiImageLinksToImageLinks(validated || []);

  const filtered = filterImageLinksBySize(transformed, isLarge);

  return {
    execute,
    result: filtered,
    loading: queryResult.loading,
    called: queryResult.called,
    error: queryResult.error?.message,
  };
};
