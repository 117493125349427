import React from 'react';
import { NavLink } from 'react-router-dom';
import { List, ListItem, ListItemText, makeStyles, Theme } from '@material-ui/core';
import { useRoutes } from './useRoutes';
import { NavRouteOptions } from './types';

export const NavList = () => {
  const classes = useStyles();
  const routes = useRoutes();
  const navRoutesList: NavRouteOptions[] = [
    routes.floorPlanList,
    routes.communityList,
    routes.inventoryList,
    routes.showHomeList,
    routes.imageLinkList,
    routes.bannerList,
    routes.videoList,
    routes.thirdParyWidgetList,
    routes.contactList,
    routes.featureTags,
  ];
  return (
    <List dense className={classes.list}>
      {navRoutesList.map(route => (
        <ListItem
          key={route.path}
          button
          component={NavLink}
          className={classes.link}
          activeClassName={classes.activeLink}
          to={route.path}
        >
          <ListItemText primaryTypographyProps={{ variant: 'body1' }}>
            {route.linkText}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    paddingBottom: theme.spacing(4),
  },
  link: {
    borderLeft: '5px solid #00000000',
  },
  activeLink: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}));
