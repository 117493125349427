import { CHECK_FLOOR_PLAN_DISPLAY_NAME } from './gql';
import { useCheckDisplayName } from '../../common/adapters/useCheckDisplayName';

type Response = {
  contentSpace: {
    floorPlanDisplayNameAvailable: boolean;
  };
};

export const useCheckFloorPlanDisplayName = () => {
  return useCheckDisplayName<Response>(
    CHECK_FLOOR_PLAN_DISPLAY_NAME,
    (response) => response?.contentSpace.floorPlanDisplayNameAvailable,
  );
};
