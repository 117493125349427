import React from 'react';
import { LayoutRenderer } from 'lib/layout/LayoutRenderer';
import { PageSectionOptions } from '_lib/sectionedPageLayout';
import { EntitySectionForm } from 'domain/view/components/form/EntitySectionForm';
import { ShowHome, ShowHomeInput } from 'domain/api/showhome';
import { Community } from 'domain/api/community';
import { FloorPlan } from 'domain/api/floorPlan';

type Props = PageSectionOptions & {
  initialValues: ShowHome;
  floorPlans: FloorPlan[];
  communities: Community[];
  onSubmit: (values: ShowHomeInput) => void;
};

const makeFloorPlanOptions = (items: Partial<FloorPlan>[]) => {
  return items.map((item) => ({
    label: item.displayName || '',
    value: item.id || '',
  }));
};

const makeCommunityOptions = (items: Partial<Community>[]) => {
  return items.map((item) => ({
    label: item.displayName || '',
    value: item.id || '',
  }));
};

export const ShowHomeBasicInfoForm = ({
  initialValues,
  floorPlans,
  communities,
  onSubmit,
  ...rest
}: Props) => {
  const floorPlanOptions = makeFloorPlanOptions(floorPlans);
  const communityOptions = makeCommunityOptions(communities);

  return (
    <EntitySectionForm
      resourceName="showHome"
      initialValues={{
        ...initialValues,
        communityId: initialValues.community?.id || null,
        floorPlanId: initialValues.floorPlan?.id || null,
      }}
      onUpdate={onSubmit}
      {...rest}
    >
      {(renderField) => (
        <LayoutRenderer
          items={[
            {
              container: true,
              direction: 'column',
              spacing: 2,
              items: [
                {
                  container: true,
                  item: true,
                  spacing: 2,
                  items: [
                    {
                      item: true,
                      xl: 6,
                      renderContent: () => renderField('displayName'),
                    },
                    {
                      item: true,
                      xl: 6,
                      renderContent: () => renderField('builderId', { disabled: true }),
                    },
                    {
                      item: true, xl: 4,
                      renderContent: () => renderField('homeType'),
                    },
                    {
                      item: true, xl: 4,
                      renderContent: () => renderField('garage'),
                    },
                    {
                      item: true, xl: 4,
                      renderContent: () => renderField('floorSize'),
                    },
                    {
                      item: true,
                      xl: 4,
                      renderContent: () => renderField('bedrooms'),
                    },
                    {
                      item: true,
                      xl: 4,
                      renderContent: () => renderField('bathrooms'),
                    },
                    {
                      item: true,
                      xl: 4,
                      renderContent: () => renderField('halfBaths'),
                    },
                    {
                      item: true,
                      xl: 6,
                      renderContent: () => renderField('seoTitleTag'),
                    },
                    {
                      item: true,
                      xl: 6,
                      renderContent: () => renderField('seoMetaKeywords'),
                    },
                    {
                      item: true,
                      xl: 6,
                      renderContent: () =>
                        renderField('floorPlanId', {
                          options: floorPlanOptions,
                        }),
                    },
                    {
                      item: true,
                      xl: 6,
                      renderContent: () =>
                        renderField('communityId', {
                          options: communityOptions,
                        }),
                    },
                  ],
                },
              ],
            },
          ]}
        />
      )}
    </EntitySectionForm>
  );
};
