import { useHistory } from 'react-router';
import { createFailedCreationMessage, createSuccessfulCreationMessage, useSetMessage } from '_lib/messages';
import { ValidatedEntity } from 'domain/api/common/types';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { updateCacheOnCreateOne } from 'domain/api/common/cache';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { generateCreateOrUpdateString } from 'domain/api/common/fragments';
import { useCreateOrUpdateOne } from 'domain/api/common/adapters/useCreateOrUpdateOne';
import { ApiInventory, ApiInventoryInput, Inventory } from '../types';
import { AllInventoryInfo } from '../fragments';
import { transformApiInventoryToInventory, transformInventoryToApiInventoryInput } from '../transforms';

const CREATE_ONE_INVENTORY = generateCreateOrUpdateString('create', 'inventory', AllInventoryInfo);

type Response = {
  createInventory: {
    inventory: ApiInventory | undefined;
  }
};

type Input<T> = {
  contentSpaceId: string;
  builderId: string;
  inventory: T;
};

export const useCreateOneInventory = (redirectPath?: string) => {
  const history = useHistory();
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  const { execute: executeMutation, ...rest } = useCreateOrUpdateOne<
  Input<ApiInventoryInput>,
  Response,
  ValidatedEntity<Inventory> | undefined
  >(
    CREATE_ONE_INVENTORY,
    (data) => schemas?.inventory.apiSchema ? transformApiInventoryToInventory(
      validateApiResponseEntity(
        data?.createInventory.inventory,
        schemas.inventory.apiSchema,
      ),
    ) : undefined,
    {
      onSuccess: (result) => {
        setMessage(createSuccessfulCreationMessage(''));
        if (redirectPath && result) history.push(`/${redirectPath}/${result.id}`);
      },
      onError: (err) => setMessage(createFailedCreationMessage('', err)),
      updateCache: (cache, data) => updateCacheOnCreateOne(
        cache,
        'inventories',
        data?.createInventory.inventory,
        `fragment AllInventoryInfo on Inventory {
          ${AllInventoryInfo}
        }`,
      ),
    },
  );

  const execute = (input: Input<Partial<Inventory>>) => executeMutation({
    ...input,
    inventory: transformInventoryToApiInventoryInput(input.inventory),
  });

  return { execute, ...rest };
};
