import { compact } from 'lodash';
import { createPublishedStatus } from '../common/transforms';
import { ApiEmbedding, ApiEmbeddingInput, Embedding } from './types';

export const transformEmbeddingToApiEmbeddingInput = (
  data: Partial<Embedding> & { id: string }
): ApiEmbeddingInput => ({
  displayName: data.displayName,
  iframeUrl: data.iframeUrl,
  id: data.id,
});

export const transformApiEmbeddingToEmbedding = (data?: ApiEmbedding): Embedding | undefined =>
  data
    ? {
      id: data.id,
      published: data.published,
      publishedStatus: createPublishedStatus(data),
      publishProblems: data.publishProblems,
      displayName: data.displayName,
      iframeUrl: data.iframeUrl,
      vendor: data.vendor,
      vendorName: data.vendor.displayName,
      contentEntries: (data.contentEntries || []).map((el) => el.id),
      communities: (data.contentEntries || [])
        .filter((el) => el.__typename === 'Community')
        .map((el) => el.id),
      floorPlans: (data.contentEntries || [])
        .filter((el) => el.__typename === 'FloorPlan')
        .map((el) => el.id),
      inventories: (data.contentEntries || [])
        .filter((el) => el.__typename === 'Inventory')
        .map((el) => el.id),
      showHomes: (data.contentEntries || [])
        .filter((el) => el.__typename === 'ShowHome')
        .map((el) => el.id),
    }
    : undefined;

export const transformApiEmbeddingsToEmbeddings = (data?: ApiEmbedding[]) =>
  data ? compact(data.map((el) => transformApiEmbeddingToEmbedding(el))) : [];

export const filterEmbeddingsByType = (embeddings: Embedding[], isVideo?: boolean) => {
  return embeddings.filter((el) =>
    isVideo ? el.vendor.type === 'video' : el.vendor.type !== 'video'
  );
};
