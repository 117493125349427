import { AllAssetInfo } from 'domain/api/asset/fragments';
import { PublishProblemsFragment } from 'domain/api/common/fragments';
import { AllEmbeddingInfo } from '../embedding/fragments';
import { FeatureTagInfo } from '../featureTag/fragments';
import { AllImageLinkInfo } from '../imageLink/fragments';

export const ShortShowHomeInfo = `
  id
  builderId
  displayName
  published
  homeType
  address {
    fullAddress
    address
    city
    countrySubDivisionCode
    country
    postalCode
  }
  ${PublishProblemsFragment}
`;

export const AllShowHomeInfo = `
  id
  builderId
  published
  homeType
  displayName
  floorSize
  bedrooms
  bathrooms
  halfBaths
  garage

  showMonthlyPaymentCalculator
  showCommuteCalculation

  locationVisualizerLat
  locationVisualizerLon

  seoMetaDescription
  seoMetaKeywords
  seoTitleTag

  address {
    fullAddress
    address
    city
    countrySubDivisionCode
    country
    postalCode
  }

  imageLinks {
    ${AllImageLinkInfo}
  }
  embeddings {
    ${AllEmbeddingInfo}
  }
  featureTags {
    ${FeatureTagInfo}
  }

  assets {
    ${AllAssetInfo}
  }

  floorPlan {
    id
    ${PublishProblemsFragment}
  }

  ${PublishProblemsFragment}

  community {
    id
    communityGeoPointLat
    communityGeoPointLon
  }
`;
