import { useUiText } from 'domain/uiText';
import React from 'react';
import { useRecoilState } from 'recoil';
import { useContentSpaceId } from '_lib/auth';
import {
  apiCommunityInputValidationSchema,
  apiCommunityValidationSchema,
} from '../community/validation';
import { apiContactInputValidationSchema, apiContactValidationSchema } from '../contact/validation';
import {
  apiEmbeddingInputValidationSchema,
  apiEmbeddingValidationSchema,
} from '../embedding/validation';
import { apiFeatureTagValidationSchema } from '../featureTag/validation';
import { apiFeatureTagCategoryValidationSchema } from '../featureTagCategory/validation';
import {
  apiFloorPlanInputValidationSchema,
  apiFloorPlanValidationSchema,
} from '../floorPlan/validation';
import {
  apiImageLinkInputValidationSchema,
  apiImageLinkValidationSchema,
} from '../imageLink/validation';
import {
  apiInventoryInputValidationSchema,
  apiInventoryValidationSchema,
} from '../inventory/validation';
import {
  apiSalesCentreInputValidationSchema,
  apiSalesCentreValidationSchema,
} from '../salesCentre/validation';
import {
  apiShowHomeInputValidationSchema,
  apiShowHomeValidationSchema,
} from '../showhome/validation';
import { apiVendorValidationSchema } from '../vendor/validation';
import { apiProviderState } from './state';

type Props = {
  children: React.ReactNode;
};

/**
 * @description
 * Stores the api validation schemas in a recoil atom so the
 * schemas don't need to be created everytime they are used.
 *
 * We can potentially store other things in here as well,
 * such as the some transformation functions
 *
 */
export const ApiProvider = ({ children }: Props) => {
  const uiText = useUiText();
  const contentSpaceId = useContentSpaceId();
  const [state, setState] = useRecoilState(apiProviderState);

  React.useEffect(() => {
    if (contentSpaceId) {
      setState({
        community: {
          apiInputSchema: apiCommunityInputValidationSchema(uiText),
          apiSchema: apiCommunityValidationSchema(uiText),
        },
        contact: {
          apiInputSchema: apiContactInputValidationSchema(uiText),
          apiSchema: apiContactValidationSchema(uiText),
        },
        floorPlan: {
          apiInputSchema: apiFloorPlanInputValidationSchema(uiText),
          apiSchema: apiFloorPlanValidationSchema(uiText),
        },
        showHome: {
          apiInputSchema: apiShowHomeInputValidationSchema(uiText),
          apiSchema: apiShowHomeValidationSchema(uiText),
        },
        inventory: {
          apiInputSchema: apiInventoryInputValidationSchema(uiText),
          apiSchema: apiInventoryValidationSchema(uiText),
        },
        salesCentre: {
          apiInputSchema: apiSalesCentreInputValidationSchema(uiText),
          apiSchema: apiSalesCentreValidationSchema(uiText),
        },
        embedding: {
          apiInputSchema: apiEmbeddingInputValidationSchema(uiText),
          apiSchema: apiEmbeddingValidationSchema(uiText),
        },
        featureTag: {
          apiSchema: apiFeatureTagValidationSchema,
        },
        featureTagCategory: {
          apiSchema: apiFeatureTagCategoryValidationSchema,
        },
        vendor: {
          apiSchema: apiVendorValidationSchema,
        },
        imageLink: {
          apiInputSchema: apiImageLinkInputValidationSchema(uiText),
          apiSchema: apiImageLinkValidationSchema(uiText, false),
        },
      });
    }
  }, [uiText, setState, contentSpaceId]);

  if (!state || !contentSpaceId) return null;
  return <React.Fragment>{children}</React.Fragment>;
};
