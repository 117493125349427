import React from 'react';
import { FileFieldUploader } from './FileFieldUploader';
import { FileFieldControlsAndLabel } from './FileFieldControlsAndLabel';
import { FileFieldReadonly } from './FileFieldReadonly';
import { FileFieldProps } from './types';
import { useFileField } from './useFileField';

export function FileField<TName extends string>({
  existingUrl,
  onChange,
  name,
  ...rest
}: FileFieldProps<TName>) {
  const {
    url,
    step,
    showUseOriginal,
    showReplace,
    setViewing,
    setUploading,
    setInitial,
  } = useFileField(existingUrl);
  const handleUseOriginal = () => setInitial();

  const handleReplace = () => {
    setUploading();
    onChange(name, []);
  };

  const handleChange = (_name: TName, files: File[]) => {
    if (files.length > 0) {
      setViewing(URL.createObjectURL(files[0]));
      onChange(name, files);
    }
  };

  const controlsAndLabel = (
    <FileFieldControlsAndLabel
      hasExisting={!!existingUrl}
      onUseOriginal={showUseOriginal ? handleUseOriginal : undefined}
      onReplace={showReplace ? handleReplace : undefined}
    />
  );

  return (
    <div>
      {controlsAndLabel}
      {url && <FileFieldReadonly url={url} />}
      {step === 'uploading' && (
        <FileFieldUploader {...rest} name={name} onChange={handleChange} />
      )}
    </div>
  );
}
