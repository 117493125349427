import { useHistory } from 'react-router';
import {
  createFailedUpdateMessage,
  createSuccessfulUpdateMessage,
  useSetMessage,
} from '_lib/messages';
import { ValidatedEntity } from 'domain/api/common/types';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { updateCacheOnCreateOne } from 'domain/api/common/cache';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { generateCreateOrUpdateString } from 'domain/api/common/fragments';
import { useCreateOrUpdateOne } from 'domain/api/common/adapters/useCreateOrUpdateOne';
import {
  AllFloorPlanLayoutInfo,
  ApiFloorPlanLayout,
  ApiFloorPlanLayoutInput,
} from 'domain/api/floorPlanLayouts';
import { ApiFloorPlan, ApiFloorPlanInput, FloorPlan } from '../types';
import { AllFloorPlanInfo } from '../fragments';
import {
  transformApiFloorPlanToFloorPlan,
  transformFloorPlanToApiFloorPlanInput,
} from '../transforms';

const CREATE_ONE_FLOOR_PLAN = generateCreateOrUpdateString('create', 'floorPlan', AllFloorPlanInfo);

type CreateFloorPlanResponse = {
  createFloorPlan: {
    floorPlan: ApiFloorPlan | undefined;
  };
};

type CreateFloorPlanInput<T> = {
  contentSpaceId: string;
  builderId: string;
  floorPlan: T;
};

// TODO: Remove when implementing full FloorPlanLayout support
const CREATE_ONE_FLOOR_PLAN_LAYOUT = generateCreateOrUpdateString(
  'create',
  'floorPlanLayout',
  AllFloorPlanLayoutInfo
);

// TODO: Remove when implementing full FloorPlanLayout support
type CreateFloorPlanLayoutResponse = {
  createFloorPlanLayout: {
    floorPlanLayout: ApiFloorPlanLayout | undefined;
  };
};

// TODO: Remove when implementing full FloorPlanLayout support
type CreateFloorPlanLayoutInput<T> = {
  contentSpaceId: string;
  floorPlanLayout: T;
};

export const useCreateOneFloorPlan = (redirectPath?: string) => {
  const history = useHistory();
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  // TODO: Remove when implementing full FloorPlanLayout support
  const createFloorPlanLayoutMutation = useCreateOrUpdateOne<
    CreateFloorPlanLayoutInput<ApiFloorPlanLayoutInput>,
    CreateFloorPlanLayoutResponse,
    ApiFloorPlanLayout
  >(CREATE_ONE_FLOOR_PLAN_LAYOUT, (data) => data?.createFloorPlanLayout.floorPlanLayout, {
    updateCache: (cache, data) =>
      updateCacheOnCreateOne(
        cache,
        'floorPlanLayouts',
        data?.createFloorPlanLayout.floorPlanLayout,
        `fragment AllFloorPlanLayoutInfo on FloorPlanLayout {
            ${AllFloorPlanLayoutInfo}
          }`
      ),
  });

  const createFloorPlanMutation = useCreateOrUpdateOne<
    CreateFloorPlanInput<ApiFloorPlanInput>,
    CreateFloorPlanResponse,
    ValidatedEntity<FloorPlan> | undefined
  >(
    CREATE_ONE_FLOOR_PLAN,
    (data) =>
      schemas?.floorPlan.apiSchema
        ? transformApiFloorPlanToFloorPlan(
            validateApiResponseEntity(data?.createFloorPlan.floorPlan, schemas.floorPlan.apiSchema)
          )
        : undefined,
    {
      onError: (err) => setMessage(createFailedUpdateMessage('', err)),
      updateCache: (cache, data) =>
        updateCacheOnCreateOne(
          cache,
          'floorPlans',
          data?.createFloorPlan.floorPlan,
          `fragment AllFloorPlanInfo on FloorPlan {
            ${AllFloorPlanInfo}
          }`
        ),
    }
  );

  const execute = async (input: CreateFloorPlanInput<Partial<FloorPlan>>) => {
    const floorPlan = await createFloorPlanMutation.execute({
      ...input,
      floorPlan: transformFloorPlanToApiFloorPlanInput(input.floorPlan),
    });
    // TODO: Remove when implementing full FloorPlanLayout support
    if (floorPlan) {
      const floorPlanLayout = await createFloorPlanLayoutMutation.execute({
        contentSpaceId: input.contentSpaceId,
        floorPlanLayout: {
          displayName: 'Standard Layout',
          description: null,
          bedrooms: input.floorPlan.bedrooms || null,
          bathrooms: input.floorPlan.bathrooms || null,
          halfBaths: input.floorPlan.halfBaths || null,
          floorSize: input.floorPlan.floorSize || null,
          floorPlan: {
            id: floorPlan.id,
            idType: 'CONTENT_ID',
          },
        },
      });

      if (floorPlanLayout) {
        setMessage(createSuccessfulUpdateMessage(''));
        if (redirectPath) history.push(`/${redirectPath}/${floorPlan.id}`);
      }
    }
  };

  return {
    execute,
    loading: createFloorPlanMutation.loading || createFloorPlanLayoutMutation.loading,
    error: createFloorPlanMutation.error || createFloorPlanLayoutMutation.error,
    called: createFloorPlanMutation.called && createFloorPlanLayoutMutation.called,
    result: createFloorPlanMutation.result,
  };
};
