import React from 'react';
import classNames from 'classnames';
import { Card, Grid, makeStyles, Theme } from '@material-ui/core';
import { SaveButton } from '_lib/components/buttons/SaveButton';
import { EditCancelButton } from '_lib/form/buttons';
import { FormContent, FormHeader } from '_lib/form/layout';
import { DisabledFormOverlay } from '_lib/form/formSection/components/DisabledFormOverlay';
import { Spinner } from '_lib/loading';
import { SectionFormProps } from '../types';

const getCardElevation = (editing: boolean, editDisabled: boolean) => {
  if (editDisabled) return 0;
  if (editing) return 5;
  return undefined;
};

// Card style container for a form
export const CardForm = (props: SectionFormProps) => {
  const classes = useStyles();

  const editButton = (
    <EditCancelButton
      isEditing={props.editing}
      editDisabled={!!props.formDisabled || !!props.editDisabled}
      onCancel={props.onCancel}
      onEdit={props.onEdit}
    />
  );

  const submitButton = <SaveButton disabled={props.submitDisabled} onSave={props.onSubmit} />;

  return (
    <Grid item={true} id={props.id}>
      <Card
        elevation={getCardElevation(props.editing, !!props.formDisabled)}
        classes={{ root: classes.card }}
        className={classNames({ [classes.editing]: props.editing })}
      >
        <FormHeader
          title={props.title}
          icon={props.icon}
          actions={
            <React.Fragment>
              {props.additionalActions}
              <Grid item={true}>{editButton}</Grid>
              {props.editing && <Grid item={true}>{submitButton}</Grid>}
            </React.Fragment>
          }
        />
        <FormContent>{props.children}</FormContent>
        {props.formDisabled && <DisabledFormOverlay />}
        {props.loading && <Spinner withContent={true} />}
      </Card>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  editing: {
    borderTop: `solid 3px ${theme.palette.primary.main}`,
  },
  card: {
    position: 'relative',
  },
}));
