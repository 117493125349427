import { camelCase, startCase } from 'lodash';

export function pick<
O extends Record<string, unknown>,
K extends keyof O & string
>(obj: O, keys: K[]): Pick<O, K> {
  const picked: { [x: string]: any } = {};

  keys.forEach(key => {
    if (isKeyOf(obj, key)) picked[key] = obj[key];
  });

  return picked as Pick<O, K>;
}

export function isKeyOf<O>(
  obj: O,
  key: string,
): key is keyof O & string {
  return obj && typeof obj === 'object' && Object.keys(obj).includes(key);
}

export const pascalCase = (str: string) => startCase(camelCase(str)).replace(/ /g, '');
