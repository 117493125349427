import React from 'react';

export type FileFieldStep = 'initial' | 'uploading' | 'viewing';

type State = {
  url?: string;
  existingUrl?: string;
  step: FileFieldStep;
};

export const useFileField = (existingUrl?: string) => {
  const [state, setState] = React.useState<State>({ step: 'initial' });

  React.useEffect(() => {
    if (existingUrl && !state.existingUrl) {
      setState({ existingUrl, url: existingUrl, step: 'viewing' });
    } else {
      setState({ step: 'uploading' });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingUrl]);

  const setViewing = (url: string) => setState(prev => ({
    ...prev,
    step: 'viewing',
    url,
  }));
  const setUploading = () => setState(prev => ({
    ...prev,
    step: 'uploading',
    url: undefined,
  }));
  const setInitial = () => setState(prev => ({
    ...prev,
    step: prev.existingUrl ? 'viewing' : 'uploading',
    url: prev.existingUrl,
  }));

  const showUseOriginal = (state.step === 'viewing' && state.existingUrl && state.existingUrl !== state.url)
    || (state.step === 'uploading' && !!state.existingUrl);

  const showReplace = state.step === 'viewing';

  return {
    url: state.url,
    step: state.step,
    setViewing,
    setUploading,
    setInitial,
    showUseOriginal,
    showReplace,
  };
};
