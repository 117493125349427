import React from 'react';
import { PageSectionMenu } from '../components/PageSectionMenu';
import { PageSectionOptions } from '../types';
import { useSectionScrolling } from '../useSectionScrolling';

type Props = {
  scrollContainerId: string,
  sections: PageSectionOptions[],
};

export const ScrollingPageSectionMenu = ({ scrollContainerId, sections }: Props) => {
  const { activeSection } = useSectionScrolling(
    scrollContainerId,
    sections.map(s => s.id),
  );
  const sectionEntries = sections.map(section => ({
    ...section,
    active: section.id === activeSection,
  }));
  return <PageSectionMenu sections={sectionEntries} />;
};
