import React from 'react';
import { useContentSpaceId } from '_lib/auth';
import {
  useGetManyInventories,
  usePublishOneInventory,
  useUnpublishOneInventory,
  useDeleteOneInventory,
  useCreateOneInventory,
  InventoryInput,
} from 'domain/api/inventory';
import { useUiText } from 'domain/uiText';
import { ListPage } from 'domain/view/pages/ListPage/ListPage';
import { useGetManyCommunities } from 'domain/api/community';
import { useGetManyFloorPlans } from 'domain/api/floorPlan';
import { CreateInventoryForm } from '../../forms/CreateInventoryForm';
import { EditInventoryPricingForm } from '../../forms/EditInventoryPricingForm';

export const InventoriesListPageContainer = () => {
  const uiText = useUiText();
  const contentSpaceId = useContentSpaceId();
  const getInventories = useGetManyInventories();
  const getCommunities = useGetManyCommunities();
  const getFloorPlans = useGetManyFloorPlans();
  const publishInventory = usePublishOneInventory();
  const unpublishInventory = useUnpublishOneInventory();
  const deleteInventory = useDeleteOneInventory();
  const createInventory = useCreateOneInventory(uiText.INVENTORY_PATH);

  const get = () => {
    getInventories.execute();
    getFloorPlans.execute();
    getCommunities.execute();
  };

  const handleCreate = (values: InventoryInput & { builderId: string }) => {
    const { builderId, ...rest } = values;
    values.builderId &&
      contentSpaceId &&
      createInventory.execute({
        inventory: rest,
        builderId,
        contentSpaceId,
      });
  };

  return (
    <ListPage
      resourceName="inventory"
      get={get}
      actions={{
        publish: (id: string) => publishInventory.execute({ id, idType: 'CONTENT_ID' }),
        unpublish: (id: string) => unpublishInventory.execute({ id, idType: 'CONTENT_ID' }),
        delete: (id: string) => deleteInventory.execute({ id, idType: 'CONTENT_ID' }),
        edit: (data) => <EditInventoryPricingForm inventory={data} />,
      }}
      loading={getInventories.loading}
      actionLoading={
        unpublishInventory.loading || publishInventory.loading || deleteInventory.loading
      }
      error={undefined}
      data={getInventories.result}
      called={getInventories.called}
      hasData={getInventories.result.length > 0}
      noDataMessage={uiText.NO_INVENTORY}
      topbar={{
        title: uiText.INVENTORY_PLURAL,
        createAction: (
          <CreateInventoryForm
            onSubmit={handleCreate}
            loading={createInventory.loading}
            floorPlans={getFloorPlans.result}
            communities={getCommunities.result}
          />
        ),
      }}
    />
  );
};
