import { Asset } from 'domain/api/asset/types';
import { CommonApiFields, PublishableApiEntity, PublishedStatus } from '../common/types';
import { ApiEmbedding, Embedding } from '../embedding/types';
import { ApiImageLink, ImageLink } from '../imageLink/types';
import { ApiSalesCentre, SalesCentre } from '../salesCentre/types';

export type StartingPrice = {
  name: string;
  price: number;
};

export type ApiCommunity = PublishableApiEntity<Pick<CommonApiFields,
'id' |
'builderId' |
'displayName' |
'displayPriority' |
'seoMetaDescription' |
'seoMetaKeywords' |
'seoTitleTag' |
'showCommuteCalculation'
> & {
  communityType: string | null;
  communityGroup: { name?: string } | null;

  showCommunityStats: boolean | null;

  communityGeoPointLat: number | null;
  communityGeoPointLon: number | null;

  lotmapLink: string | null;
  lotmapText: string | null;

  startingPrices: StartingPrice[] | null;
  floorPlanMappings?: {
    floorPlan: { id: string; displayName: string };
    price: number;
  }[];

  assets: Asset[];
  embeddings: ApiEmbedding[];
  imageLinks: ApiImageLink[];
  salesCentres: ApiSalesCentre[];
}>;

export type ApiCommunityInput = Partial<Pick<ApiCommunity,
'displayName' |
'displayPriority' |
'seoMetaDescription' |
'seoMetaKeywords' |
'seoTitleTag' |
'showCommuteCalculation' |
'showCommunityStats' |
'lotmapText' |
'lotmapLink'|
'communityGeoPointLat' |
'communityGeoPointLon' |
'startingPrices' |
'communityType'
> & {
  communityGroup: string | null;
}>;

export const CLEARABLE_COMMUNITY_FIELDS: (keyof ApiCommunityInput & string)[] = [
  'communityType',
  'displayPriority',
  'communityGeoPointLat',
  'communityGeoPointLon',
  'seoTitleTag',
  'seoMetaDescription',
  'lotmapLink',
  'lotmapText',
];

export type Community = Pick<ApiCommunity,
'id' |
'builderId' |
'displayName' |
'published' |
'displayPriority' |
'seoMetaKeywords' |
'seoTitleTag' |
'showCommuteCalculation' |
'showCommunityStats' |
'lotmapText' |
'lotmapLink'|
'startingPrices' |
'communityType' |
'assets'
> & {
  communityGroup: string | null;
  publishedStatus: PublishedStatus;
  floorPlans: {
    id: string;
    displayName: string;
    price: number;
  }[];
  seoDescriptionBody: string;
  seoDescriptionBullets: string[];
  lat: number | null;
  lng: number | null;
  salesCentres: SalesCentre[];
  embeddings: Embedding[];
  imageLinks: ImageLink[];
};
