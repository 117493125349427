import React from 'react';
import ReportIcon from '@material-ui/icons/Report';
import CheckIcon from '@material-ui/icons/Check';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { AssetAttributeName, useCreateOneAsset } from 'domain/api/asset';
import { useContentSpaceId } from '_lib/auth';
import { Spinner } from '_lib/loading';
import { pollAsset } from 'domain/api/asset/pollAsset/pollAsset';
import { useAssetFormContext } from '../../AssetFormContext';

type Props = {
  file: File;
  displayOrder: number | null;
  attributeName: AssetAttributeName;
  startUpload?: boolean;
  uploadFailed?: boolean;
  onSuccess: () => void;
  onError: (err: string) => void;
};

type PollRequestState = {
  loading: boolean;
  error?: boolean;
};

export const AssetFormUploadingItem = React.memo(
  (props: Props) => {
    const classes = useStyles();
    const contentSpaceId = useContentSpaceId();
    const { entity } = useAssetFormContext();
    const [pollRequestState, setPollRequestState] = React.useState<PollRequestState>({
      loading: false,
    });
    const [createAssetMutation, { called, loading, error }] = useCreateOneAsset(
      props.attributeName,
      props.file,
      entity
    );

    React.useEffect(() => {
      if (props.startUpload && !called && !loading && contentSpaceId) {
        createAssetMutation({
          variables: {
            input: {
              contentSpaceId,
              file: props.file,
              contentId: entity.id,
              attributeName: props.attributeName,
              displayOrder: props.displayOrder,
            },
          },
        })
          .then((res) => {
            if (!res.errors && res.data) {
              setPollRequestState({ loading: true });
              pollAsset(res.data.createAsset.asset.id, contentSpaceId)
                .then(() => {
                  props.onSuccess();
                  setPollRequestState({ loading: false })
                })
                .catch(err => {
                  props.onError(err);
                  setPollRequestState({ loading: false, error: true });
                });
            }
          })
          .catch((err) => props.onError(err));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.startUpload]);

    const isPDF = props.file.type === 'application/pdf';
    const assetUrl = React.useMemo(() => URL.createObjectURL(props.file), [props.file]);

    const isSuccess = (
      called && !(loading || pollRequestState.loading) && !(error || pollRequestState.error)
    );
    return (
      <Grid
        xl={3}
        lg={3}
        md={6}
        sm={6}
        xs={12}
        item={true}
        container={true}
        className={classes.container}
      >
        <Grid item={true} className={classes.innerContainer}>
          <div>
            {isPDF ? (
              <a href={assetUrl} target="_blank" rel="noopener noreferrer">
                <div className={classes.asset}>
                  <Grid
                    container={true}
                    alignItems="center"
                    justify="center"
                    wrap="nowrap"
                    spacing={2}
                  >
                    <Grid item={true}>
                      <PdfIcon />
                    </Grid>
                    <Grid item={true}>
                      <Typography>{props.file.name}</Typography>
                    </Grid>
                  </Grid>
                </div>
              </a>
            ) : (
              <img className={classes.asset} src={assetUrl} alt={props.file.name} />
            )}
            {isSuccess && (
              <div className={classes.uploaded}>
                <CheckIcon color="inherit" />
              </div>
            )}
            {(error || pollRequestState.error) && (
              <div className={classes.failed}>
                <ReportIcon color="inherit" />
              </div>
            )}
            {(loading || pollRequestState.loading) && <Spinner withContent={true} />}
          </div>
        </Grid>
      </Grid>
    );
  },
  (prev, next) => {
    return prev.startUpload === next.startUpload && prev.uploadFailed === next.uploadFailed;
  }
);

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    zIndex: 100000,
  },
  innerContainer: {
    border: `solid 2px ${theme.palette.grey[200]}`,
    padding: theme.spacing(1),
    borderRadius: 4,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: theme.palette.common.white,
  },
  uploaded: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    padding: theme.spacing(0.5),
    color: theme.palette.success.main,
  },
  failed: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    padding: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
  asset: {
    maxWidth: 160,
    maxHeight: 160,
    display: 'flex',
  },
  loading: {
    position: 'absolute',
    width: `calc(100% - ${theme.spacing(2)}px)`,
    height: `calc(100% - ${theme.spacing(2)}px)`,
    left: theme.spacing(1),
    top: theme.spacing(1),
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
}));
