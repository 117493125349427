import React from 'react';
import { Action } from 'common/types';
import { ActionButton } from 'view/components/basic';
import { useToggle } from 'common/hooks/useToggle';
import { FormDialog } from '_lib/form/formDialog/FormDialog';

interface OwnProps {
  title: string;
  description?: string;
  submitDisabled: boolean;
  submitting?: boolean;
  submitted?: boolean;
  onEdit?: () => void;
  onCancel: () => void;
  onSubmit: (close: () => void, createAndPublish?: boolean) => void;
  action: Action | ((onClick: () => void) => JSX.Element);
  children: React.ReactNode;
  hasErrors?: boolean;
  showCreateAndPublishAction?: boolean;
}

type Props = OwnProps;

// Used for actions which have a dialog form associated with them
export const ActionDialogForm = (props: Props) => {
  const { open, onOpen, onClose } = useToggle();

  const handleEdit = () => {
    props.onEdit && props.onEdit();
    onOpen();
  };
  const handleSubmit = (alsoPublish?: boolean) => props.onSubmit(onClose, alsoPublish);
  const handleClose = () =>{
    props.onCancel();
    onClose();
  };

  React.useEffect(() => {
    if (props.submitted && open) {
      props.onCancel();
      onClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const renderAction = () => {
    if (typeof props.action === 'function') {
      return props.action(handleEdit);
    }
    return <ActionButton {...props.action} onClick={handleEdit} />;
  };

  return (
    <React.Fragment>
      {renderAction()}
      <FormDialog
        open={open}
        title={props.title}
        description={props.description}
        onClose={handleClose}
        onSubmit={handleSubmit}
        submitDisabled={props.submitDisabled}
        submitting={props.submitting}
        hasErrors={props.hasErrors}
        showCreateAndPublishAction={props.showCreateAndPublishAction}
      >
        {props.children}
      </FormDialog>
    </React.Fragment>
  );
};
