import React from 'react';
import { arrayMove, SortEnd } from 'react-sortable-hoc';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { useUiText } from 'domain/uiText';
import { bulletInputId, TextListFieldBullet } from './TextListFieldBullet';
import { TextListFieldSortableBullets } from './TextListFieldSortableBullets';

type Props = {
  disabled?: boolean;
  readonly?: boolean;
  items: string[];
  onChange: (value: string[]) => void;
};

export const TextListFieldBullets = ({ readonly, disabled, items, onChange }: Props) => {
  const classes = useStyles();
  const uiText = useUiText();
  const [toFocusIndex, setToFocusIndex] = React.useState<number | undefined>(undefined);
  const [toFocusPosition, setToFocusPosition] = React.useState<number | undefined>(undefined);

  const handleChange = (value: string, index: number) => {
    const newList: Array<string | string[]> = [...items];
    const newValues = value.split('\n');
    newList[index] = newValues;
    if (newValues.length > 1) {
      setToFocusIndex(index + 1);
      setToFocusPosition(0);
    }
    onChange(newList.flat());
  };

  const handleAddItem = () => {
    onChange([...items, '']);
    setToFocusIndex(items.length);
  };

  const handleDelete = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    onChange(newItems);
  };

  const handleSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    onChange([...arrayMove(items, oldIndex, newIndex)]);
  };

  const focusItem = (index: number, position: number| undefined = undefined) => {
    const el = document.getElementById(bulletInputId(index));
    if (el) {
      el.focus();
      if (position !== undefined) {
        (el as HTMLInputElement).setSelectionRange(position, position);
      }
    }
  }

  React.useEffect(() => {
    if (toFocusIndex !== undefined) {
      focusItem(toFocusIndex, toFocusPosition);
      setToFocusIndex(undefined);
      setToFocusPosition(undefined);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const focusPrev = (index: number) => {
    if (index > 0) {
      focusItem(index - 1, items[index - 1].length);
    }
  };

  const focusNext = (index: number) => {
    if (index < items.length - 1) {
      focusItem(index + 1, 0);
    }
  };

  const mergePrev = (index: number) => {
    if (index > 0) {
      mergeNext(index - 1);
    }
  }

  const mergeNext = (index: number) => {
    if (index < items.length - 1) {
      const newItems = [...items];
      newItems.splice(index, 2, `${items[index]}${items[index + 1]}`);
      onChange(newItems);
      setToFocusIndex(index);
      setToFocusPosition(items[index].length);
    }
  }

  return (
    <div className={classes.container}>
      {readonly || disabled ? (
        <div className={classes.bulletsContainer}>
          {items.map((value, i) => (
            <TextListFieldBullet
              key={bulletInputId(i)}
              _index={i}
              value={value}
              readonly={readonly}
              disabled={disabled}
              onChange={handleChange}
              onDelete={handleDelete}
              focusPrev={focusPrev}
              focusNext={focusNext}
              mergePrev={mergePrev}
              mergeNext={mergeNext}
            />
          ))}
        </div>
      ) : (
        <TextListFieldSortableBullets
          className={classes.bulletsContainer}
          items={items}
          readonly={readonly}
          disabled={disabled}
          onChange={handleChange}
          onDelete={handleDelete}
          onSortEnd={handleSortEnd}
          focusPrev={focusPrev}
          focusNext={focusNext}
          mergePrev={mergePrev}
          mergeNext={mergeNext}
          distance={1}
        />
      )}
      {!readonly && (
        <Button
          variant="outlined"
          fullWidth={true}
          className={classes.newBullet}
          onClick={handleAddItem}
          color="primary"
          disabled={disabled}
        >
          {uiText.ACTION_NEW_ITEM_LABEL}
        </Button>
      )}
      {readonly && items.length <= 0 && <Typography>{uiText.NO_VALUE}</Typography>}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  newBullet: {
    marginTop: theme.spacing(1),
    border: `solid 1px ${theme.palette.grey[200]}`,
  },
  bulletsContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
}));
