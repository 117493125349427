import React from 'react';
import { Button } from '@material-ui/core';
import { useBaseUiText } from '_lib/uiText';

interface Props {
  onDelete?: () => void;
  disabled?: boolean;
}

export const DeleteButton = React.memo(({
  disabled,
  onDelete,
}: Props) => {
  const uiText = useBaseUiText();
  return (
    <Button
      onClick={onDelete}
      disabled={disabled}
      color="primary"
      variant="outlined"
    >
      {uiText.ACTION_DELETE_LABEL}
    </Button>
  );
});
