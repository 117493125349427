import React from 'react';
import { ReadonlyField } from '../shared/ReadonlyField/ReadonlyField';
import { DateFieldControl } from './DateFieldControl';
import { DateFieldReadonly } from './DateFieldReadonly';
import { DateFieldProps } from './types';

export function DateField<TName extends string>(props: DateFieldProps<TName>) {
  const { readonly, ...rest } = props;
  if (readonly) {
    return (
      <ReadonlyField errors={rest.errors} label={rest.label}>
        <DateFieldReadonly value={rest.value} />
      </ReadonlyField>
    );
  }
  return <DateFieldControl {...rest} />;
}
