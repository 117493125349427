import React from 'react';
import { LayoutRenderer } from 'lib/layout/LayoutRenderer';
import { ShowHome, ShowHomeInput } from 'domain/api/showhome';
import { PageSectionOptions } from '_lib/sectionedPageLayout';
import { EntitySectionForm } from 'domain/view/components/form/EntitySectionForm';
import { forceSeoMetaDescriptionInclude } from 'system/resources/utilities/fieldModifiers';

type Props = PageSectionOptions & {
  initialValues: ShowHome;
  onSubmit: (values: ShowHomeInput) => void;
};

export const ShowHomeDescriptionForm = ({ initialValues, onSubmit, ...rest }: Props) => {
  return (
    <EntitySectionForm
      resourceName="showHome"
      initialValues={initialValues}
      onUpdate={onSubmit}
      fieldModifiers={{ ...forceSeoMetaDescriptionInclude() }}
      {...rest}
    >
      {renderField => (
        <LayoutRenderer
          items={[
            {
              container: true, direction: 'column', spacing: 2,
              items: [
                {
                  renderContent: () => renderField('seoDescriptionBody'),
                },
                {
                  renderContent: () => renderField('seoDescriptionBullets'),
                },
              ]
            }
          ]}
        />
      )}
    </EntitySectionForm>
  );
};
