import { Asset, AssetAttributeName, SingleAssetAttributeName, singleAssetAttributeNames } from './types';

export const getAssetsByAttributeName = (attributeName: AssetAttributeName, assets?: Asset[]) => {
  if (!assets) return [];
  return assets.filter(asset => asset.attributeName === attributeName);
};

export const getAssetByAttributeName = (attributeName: AssetAttributeName, assets?: Asset[]) => {
  if (!assets) return null;
  return assets.find(asset => asset.attributeName === attributeName) || null;
};

export const sortAssetsByDisplayOrder = (assets?: Asset[]) => {
  if (!assets) return [];
  return assets.sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0));
};

export const isSingleAssetAttribute = (
  attributeName: string,
): attributeName is SingleAssetAttributeName => {
  return singleAssetAttributeNames.includes(attributeName);
};
