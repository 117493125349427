import {
  createFailedCreationMessage,
  createSuccessfulCreationMessage,
  useSetMessage,
} from '_lib/messages';
import { ValidatedEntity } from 'domain/api/common/types';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { updateCacheOnCreateOne } from 'domain/api/common/cache';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { generateCreateOrUpdateString } from 'domain/api/common/fragments';
import { useCreateOrUpdateOne } from 'domain/api/common/adapters/useCreateOrUpdateOne';
import { PureQueryOptions } from '@apollo/client';
import { ApiContact, ApiContactInput, Contact } from '../types';
import { AllContactInfo } from '../fragments';
import { transformApiContactToContact, transformContactToApiContactInput } from '../transforms';

const CREATE_ONE_INVENTORY = generateCreateOrUpdateString('create', 'contact', AllContactInfo);

type Response = {
  createContact: {
    contact: ApiContact | undefined;
  }
};

type Input<T> = T & {
  contentSpaceId: string;
};

export const useCreateOneContact = (refetchQueries?: PureQueryOptions[]) => {
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  const { execute: executeMutation, ...rest } = useCreateOrUpdateOne<
  Input<ApiContactInput>,
  Response,
  ValidatedEntity<Contact> | undefined
  >(
    CREATE_ONE_INVENTORY,
    (data) => schemas?.contact.apiSchema ? transformApiContactToContact(
      validateApiResponseEntity(
        data?.createContact.contact,
        schemas.contact.apiSchema,
      ),
    ) : undefined,
    {
      onSuccess: () => {
        setMessage(createSuccessfulCreationMessage(''));
      },
      onError: (err) => setMessage(createFailedCreationMessage('', err)),
      updateCache: (cache, data) => updateCacheOnCreateOne(
        cache,
        'contacts',
        data?.createContact.contact,
        `fragment AllContactInfo on Contact {
          ${AllContactInfo}
        }`,
      ),
      refetchQueries
    },
  );

  const execute = ({ contentSpaceId, ...restData }: Input<Partial<Contact>>) => executeMutation({
    contentSpaceId,
    ...transformContactToApiContactInput(restData),
  });

  return { execute, ...rest };
};
