import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import { ErrorBoundary } from '_lib/error/ErrorBoundary';

import App from 'domain/App';
import { ThemeProvider } from 'domain/theme/ThemeProvider';
import { ErrorPage } from 'domain/view/pages/ErrorPage';

import * as serviceWorker from './serviceWorker';
import './index.css';

ReactDOM.render(
  <RecoilRoot>
    <ThemeProvider>
      <ErrorBoundary component={ErrorPage}>
        <App />
      </ErrorBoundary>
    </ThemeProvider>
  </RecoilRoot>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
