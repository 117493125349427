import React from 'react';

type Props = {
  url?: string;
};

export const FileFieldReadonly = ({ url }: Props) => {
  if (!url) return null;
  return (
    <img
      alt={`file-field-${url}`}
      style={{ width: '100%' }}
      src={url}
    />
  );
};
