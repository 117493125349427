import React from 'react';

const MULTI_WINDOW_AUTH_KEY = 'openhouse.auth.isAuthenticated';
const setMultiWindowAuthKey = () => window.localStorage.setItem(MULTI_WINDOW_AUTH_KEY, 'true');
const triggerMultiWindowLogout = () => window.localStorage.removeItem(MULTI_WINDOW_AUTH_KEY);
const isLogoutEvent = (event: StorageEvent) => event.key === MULTI_WINDOW_AUTH_KEY
  && event.oldValue && !event.newValue;

export const useMultiWindowLogoutEffect = (logout: () => void) => {
  React.useEffect(() => {
    const storageEventHandler = (e: StorageEvent) => {
      if (isLogoutEvent(e)) {
        logout();
      }
    };
    window.addEventListener('storage', storageEventHandler);
    return () => window.removeEventListener('storage', storageEventHandler);
  }, [logout]);
};

export const useMultiWindowLogout = () => ({ setMultiWindowAuthKey, triggerMultiWindowLogout });
