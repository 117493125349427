import React from 'react';
import {
  useGetManyShowHomes,
  usePublishOneShowHome,
  useUnpublishOneShowHome,
  useDeleteOneShowHome,
} from 'domain/api/showhome';
import { useUiText } from 'domain/uiText';
import { ListPage } from 'domain/view/pages/ListPage/ListPage';
import { CreateShowHomeForm } from '../../forms/CreateShowHomeForm';

export const ShowHomesListPageContainer = () => {
  const uiText = useUiText();
  const getShowHomes = useGetManyShowHomes();
  const publishShowHome = usePublishOneShowHome();
  const unpublishShowHome = useUnpublishOneShowHome();
  const deleteShowHome = useDeleteOneShowHome();

  const get = () => {
    getShowHomes.execute();
  };

  return (
    <ListPage
      resourceName="showHome"
      get={get}
      actions={{
        publish: (id: string) => publishShowHome.execute({ id, idType: 'CONTENT_ID' }),
        unpublish: (id: string) => unpublishShowHome.execute({ id, idType: 'CONTENT_ID' }),
        delete: (id: string) => deleteShowHome.execute({ id, idType: 'CONTENT_ID' }),
      }}
      loading={getShowHomes.loading}
      actionLoading={
        unpublishShowHome.loading || publishShowHome.loading || deleteShowHome.loading
      }
      error={undefined}
      data={getShowHomes.result}
      called={getShowHomes.called}
      hasData={getShowHomes.result.length > 0}
      noDataMessage={uiText.NO_SHOWHOME}
      topbar={{
        title: uiText.SHOWHOME_PLURAL,
        createAction: (
          <CreateShowHomeForm />
        ),
      }}
    />
  );
};
