import { HttpLink } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';

export const assetsLink = new RestLink({
  uri: process.env.REACT_APP_BASE_ASSETS_URL || '',
  bodySerializers: {
    fileEncode: (data: any, headers: Headers) => {
      const formData = new FormData();
      formData.append('file', data.file, data.file.name);
      formData.append('attributeName', data.attributeName);
      formData.append('contentSpaceId', data.contentSpaceId);
      formData.append('contentId', data.contentId);
      return { body: formData, headers };
    },
  },
  responseTransformer: async (response, typename) => {
    try {
      const json = await response.json();
      return {
        __typename: 'CreateAssetResponse',
        asset: {
          __typename: 'Asset',
          id: json.contentId,
          displayName: json.displayName,
        },
      };
    } catch (error) {
      return error;
    }
  }
});

export const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BASE_GRAPHQL_URL || '',
});
