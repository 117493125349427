import { createFailedUnpublishMessage, createSuccessfulUnpublishMessage, useSetMessage } from '_lib/messages';
import { useIdInputMutation } from 'domain/api/common/adapters/useIdInputMutation';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { ValidatedEntity } from 'domain/api/common/types';
import { UNPUBLISH_ONE_IMAGE_LINK } from './gql';
import { ApiImageLink, ImageLink } from '../types';
import { transformApiImageLinkToImageLink } from '../transforms';

type Response = {
  unpublishImageLink: {
    imageLink?: ApiImageLink;
  };
};

export const useUnpublishOneImageLink = () => {
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  return useIdInputMutation<Response, ValidatedEntity<ImageLink> | undefined>(
    UNPUBLISH_ONE_IMAGE_LINK,
    (data) => schemas?.imageLink.apiSchema ? transformApiImageLinkToImageLink(
      validateApiResponseEntity(
        data?.unpublishImageLink.imageLink,
        schemas.imageLink.apiSchema,
      ),
    ) : undefined,
    {
      onSuccess: () => setMessage(createSuccessfulUnpublishMessage('')),
      onError: (err) => setMessage(createFailedUnpublishMessage('', err)),
    }
  );
};
