import { uiText } from 'constants/uiText';
import { Action, Status } from 'common/types';

export const getStatusToolTip = (publishedStatus: Status) => {
  switch (publishedStatus) {
    case 'published':
      return uiText.ENTITY_PUBLISHED_TOOLTIP;
    case 'error':
      return uiText.ENTITY_PUBLISH_ERROR_TOOLTIP;
    case 'draft':
    default:
      return uiText.ENTITY_PUBLISH_DRAFT_TOOLTIP;
  }
};

export const getStatusLabel = (publishedStatus: Status) => {
  switch (publishedStatus) {
    case 'published':
      return uiText.ENTITY_PUBLISHED_LABEL;
    case 'error':
    case 'draft':
    default:
      return uiText.ENTITY_PUBLISH_DRAFT_LABEL;
  }
};

export const getPublishActionState = (publishedStatus: Status): Action => {
  switch (publishedStatus) {
    case 'error':
      return { type: 'publish', label: uiText.ENTITY_PUBLISH_LABEL, disabled: true };
    case 'draft':
      return { type: 'publish', label: uiText.ENTITY_PUBLISH_LABEL };
    case 'published':
    default:
      return { type: 'unpublish', label: uiText.ENTITY_UNPUBLISH_LABEL };
  }
};
