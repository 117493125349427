import { FieldErrors, FormFields, InitialValues } from 'lib/form/types';

export function autoGenerateBuilderIdFromDisplayName<
T extends InitialValues
>(fields: FormFields<T>): { fields: FormFields<T>, errors?: FieldErrors<T> } {
  if (fields.builderId && fields.displayName) {
    if (fields.builderId.touched) return { fields };
    return {
      fields: {
        ...fields,
        builderId: {
          ...fields.builderId,
          value: fields.displayName.value.replace(new RegExp(' ', 'g'), '_').toUpperCase()
        },
      },
    };
  }
  return { fields };
}

/**
 *
 * @description
 * This is needed for the following 2 reasons:
 * 1. The useFormFields hook only returns the values that have been touched
 *    when onSubmit is called(fixes OHC-3718).
 * 2. Because of the above reason and the fact that seoMetaDescription is
 *    actually a single field in the BE(split into 2 'synthetic' fields in the FE)
 *    we must send both 'synthetic' fields when either of them change to
 *    ensure we don't overwrite the field that wasn't touched.
 *
 */
export function forceSeoMetaDescriptionInclude(){
  return {
    seoDescriptionBody: (fields: FormFields<InitialValues>) => ({
      fields: {
        ...fields,
        seoDescriptionBody: {
          ...fields.seoDescriptionBody,
          value: (fields.seoDescriptionBody.value || '').replaceAll('\n-', '-'),
        },
        seoDescriptionBullets: {
          ...fields.seoDescriptionBullets,
          touched: true,
        }
      },
    }),
    seoDescriptionBullets: (fields: FormFields<InitialValues>) => ({
      fields: {
        ...fields,
        seoDescriptionBody: {
          ...fields.seoDescriptionBody,
          touched: true,
        }
      },
    }),
  };
}

export function validateEmbeddingUrlWithVendorRegex<
T extends InitialValues
>(
  fields: FormFields<T>,
  errors: FieldErrors<T>,
  regex?: string,
): { fields: FormFields<T>, errors?: Partial<FieldErrors<T>> } {
  if (fields.iframeUrl && fields.vendorId && regex) {
    const isValidIframeUrl = RegExp(regex).test(fields.iframeUrl.value);
    if (isValidIframeUrl) return { fields };

    return {
      fields,
      errors: {
        iframeUrl: [
          ...errors?.iframeUrl || [],
          'Invalid URL'
        ],
      } as any,
    };
  }
  return { fields };
}
