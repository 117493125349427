import React from 'react';
import { PageSectionOptions, SectionRegistrar } from './types';

export const usePageSectioning = () => {
  const [sections, setSections] = React.useState<PageSectionOptions[]>([]);

  const useRegisterPageSection: SectionRegistrar = (options) => {
    React.useEffect(() => {
      if (options) {
        if (sections.findIndex(section => section.id === options.id) === -1) {
          setSections(s => [...s, options]);
        }
      }
    }, [options]);
  };

  return {
    useRegisterPageSection,
    sections,
  };
};
