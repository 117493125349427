import React from 'react';

export const useFetchConfig = <C>(url: string) => {
  const [config, setConfig] = React.useState<C | undefined>(undefined);

  React.useEffect(() => {
    const fetchConfig = async () => {
      try {
        const result = await (await fetch(url)).json();
        setConfig(result);
      } catch (err) {
        throw new Error('An error occurred when fetching config');
      }
    };
    fetchConfig();
  }, [url]);

  return config;
};
