import React from 'react';
import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { secondsToMilliseconds } from 'utilities/helpers';

interface OwnProps {
  value?: number | null;
}

type Props = OwnProps;

export const DateFieldReadonly = ({ value }: Props) => {
  const label = !value ? 'N/A' : format(new Date(secondsToMilliseconds(value)), 'yyyy-MM-dd');
  return (
    <Typography style={{ whiteSpace: 'pre' }}>
      {label}
    </Typography>
  );
};
