import React from 'react';
import { useResourceFields } from 'system/resources/hooks/useResourceFields';
import { ResourcesContextValue } from 'system/resources/types';
import { ResourceFields } from 'lib/resource';
import { useResource } from 'system/hooks';
import { FieldModifiers } from 'lib/form/types';
import { FormLayout } from '_lib/form/formSection/components/FormLayout';
import { useFormState } from '_lib/multiFormSemaphore';
import { PageSectionOptions } from '_lib/sectionedPageLayout';

type ResourceFieldsConstraint<TResourceName extends keyof ResourcesContextValue> = ResourceFields<
ResourcesContextValue[TResourceName]['defaultResourceFields']
>;

type Props<
  TResourceName extends keyof ResourcesContextValue,
  TInitialValues extends { [x: string]: any }
> = PageSectionOptions & {
  resourceName: TResourceName;
  initialValues: TInitialValues;
  flat?: boolean;
  children(
    renderField: <
      TFieldName extends keyof ResourceFields<
      ResourcesContextValue[TResourceName]['defaultResourceFields']
      > &
      string
    >(
      field: TFieldName,
      fieldConfig?: Partial<
      ResourceFields<
      ResourcesContextValue[TResourceName]['defaultResourceFields']
      >[TFieldName]['fieldConfig']
      >
    ) => JSX.Element,
    fields: {
      [Key in keyof ResourceFieldsConstraint<TResourceName>]: {
        type: ResourceFieldsConstraint<TResourceName>[Key]['type'];
        value: ResourceFieldsConstraint<TResourceName>[Key]['value'];
      };
    },
    editing: boolean
  ): React.ReactNode;
  fieldModifiers?: Partial<FieldModifiers<any>>;
  omittedFields?: (keyof ResourceFields<
  ResourcesContextValue[TResourceName]['defaultResourceFields']
  > & string)[];
  onUpdate: (values: TInitialValues) => void;
};

/**
 * Uses the new FormLayout component instead of the CardForm component.
 * Temporary solution until forms are refactored.
 */
export function EntitySectionForm<
  TResourceName extends keyof ResourcesContextValue,
  TInitialValues extends { [x: string]: any }
>({
  resourceName,
  initialValues,
  id,
  icon,
  title,
  helpText,
  children,
  onUpdate,
  fieldModifiers,
  flat,
  omittedFields,
}: Props<TResourceName, TInitialValues>) {
  const { helpers } = useResource(resourceName);
  const form = useResourceFields(resourceName, {
    initialValues,
    hasReadonly: true,
    fieldModifiers,
    omittedFields,
  });
  const { formId, editing, enabled, openForm, closeForm } = useFormState(id);

  const handleEdit = () => {
    form.onEdit();
    openForm();
  };

  const handleCancel = () => {
    form.reset();
    closeForm();
  };

  const handleSubmit = () => {
    form.onSubmit(
      () => {},
      (values) => {
        onUpdate(values);
        closeForm();
      }
    );
  };

  return (
    <FormLayout
      editing={form.isEditing}
      formDisabled={!enabled}
      editDisabled={helpers.isActionDisabled('update')}
      submitDisabled={!form.canSubmit}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSubmit={handleSubmit}
      formId={formId}
      icon={icon || <React.Fragment />}
      title={title}
      helpText={helpText}
      flat={flat}
      hasErrors={!form.canSubmit && form.attemptedSubmit}
    >
      {children(form.renderField, form.fields, editing)}
    </FormLayout>
  );
}
