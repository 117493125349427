import { ExternalResourceConfig } from 'lib/resource/types';

export const defaultExternalResourceConfig: ExternalResourceConfig =  {
  enabled: false,
  aliases: {
    path: '',
    plural: '',
    singular: '',
  },
  fields: {
    allAutomatic: true,
    allManual: false,
    manual: [],
    automatic: [],
  },
  actions: {
    allAutomatic: true,
    allManual: false,
    manual: [],
    automatic: [],
  },
};
