import React from 'react';

type State = {
  selected: { key: string; data: any }[];
  jobs: { key: string; data: any }[];
  failed: { key: string; data: any }[];
  isRunning: boolean;
  isDelaying: boolean;
};

type Props = {
  onCompleted: () => void;
  delayMs?: number;
};

/**
 *
 * @description Hook to simplify executing multiple operations in series
 * @todo Improve the typings of this so we don't lose typings we pass in
 */
export function useQueuedJobs({ onCompleted, delayMs }: Props) {
  const [state, setState] = React.useState<State>({
    // If we use 'jobs' to store both the selected items and the currently executing jobs
    // then when a job is completed and removed from 'jobs', the component using that data
    // will also disappear. So we store selected items in a separate array and copy them
    // into 'jobs' when execution has begun.
    selected: [],
    jobs: [],
    failed: [],
    isRunning: false,
    isDelaying: false,
  });

  const hasJobs = state.jobs.length > 0;
  const hasSelected = state.selected.length > 0;

  // Delay between jobs
  React.useEffect(() => {
    if (state.isDelaying) {
      if (state.isRunning) {
        setTimeout(
          () => setState(prev => ({ ...prev, isDelaying: false })),
          delayMs,
        );
      } else {
        setState(prev => ({ ...prev, isDelaying: false }));
      }
    }
  }, [state, delayMs])

  // When all the jobs have finished, we can call the onCompleted callback
  React.useEffect(() => {
    if (state.isRunning && !state.isDelaying && state.jobs.length === 0) {
      setState(prev => ({ ...prev, isRunning: false }));
      onCompleted();
    }
  }, [state, onCompleted]);

  const addSelected = (key: string, data: any) => {
    if (!state.isRunning) {
      setState(prev => ({
        ...prev,
        failed: [
          ...prev.failed.filter(el => el.key !== key),
        ],
        selected: [...prev.selected, { key, data }],
      }));
    }
  };

  const removeSelected = (key: string) => {
    if (!state.isRunning) {
      setState(prev => ({
        ...prev,
        selected: [...prev.selected].filter(el => el.key !== key),
      }));
    }
  };

  const editSelected = (key: string, data: any) => {
    const index = state.selected.findIndex(el => el.key === key);

    if (!state.isRunning && index > -1) {
      setState(prev => {
        const selected = [...prev.selected];
        selected[index] = { ...selected[index], data };
        return { ...prev, selected };
      });
    }
  };

  // Copies the contents of 'selected' into the 'jobs' array and set the isRunning flag
  const start = () => setState(prev => ({
    ...prev,
    jobs: prev.selected,
    isRunning: true,
  }));

  // Called by a component when the job has finished.
  // Removes this job from the job array
  // Example: a mutation has succesfully completed
  const onJobSuccess = (key: string) => {
    if (state.jobs.find(el => el.key === key)) {
      setState(prev => ({
        ...prev,
        jobs: [...prev.jobs].filter(el => el.key !== key),
        isDelaying: true,
      }));
    }
  };

  // Called by a component when the job has failed.
  // Removes this job from the job array and adds it to the failed jobs
  const onJobFailed = (key: string) => {
    const job = state.jobs.find(el => el.key === key);
    if (job) {
      setState(prev => ({
        ...prev,
        jobs: [...prev.jobs].filter(el => el.key !== key),
        failed: [...prev.failed, job],
        isDelaying: true,
      }));
    }
  };

  // Some helper functions to be used inside components using this hook
  const isSelected = (key: string) => !!state.selected.find(el => el.key === key);
  const getSelectedItem = (key: string) => state.selected.find(el => el.key === key);
  const isCurrentJob = (key: string) => (
    state.isRunning && !state.isDelaying && hasJobs && state.jobs[0].key === key
  );
  const isFailedJob = (key: string) => !!state.failed.find(el => el.key === key);

  const reset = () => setState(prev => ({
    ...prev,
    selected: [],
    jobs: [],
    isRunning: false,
  }));

  return {
    hasJobs,
    hasFailedJobs: state.failed.length > 0,
    jobs: state.jobs,
    hasSelected,
    selected: state.selected,
    isRunning: state.isRunning,
    currentJob: state.isRunning && hasJobs ? state.jobs[0] : undefined,
    isCurrentJob,
    addSelected,
    removeSelected,
    editSelected,
    start,
    onJobSuccess,
    onJobFailed,
    isSelected,
    getSelectedItem,
    isFailedJob,
    reset,
  };
}
