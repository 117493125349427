import { Grid } from '@material-ui/core';
import React from 'react';
import { CancelIconButton } from '_lib/components/buttons/CancelIconButton';
import { ClearButton } from '_lib/components/buttons/ClearButton';
import { EditButton } from '_lib/components/buttons/EditButton';
import { EditIconButton } from '_lib/components/buttons/EditIconButton';
import { PreviewButton } from '_lib/components/buttons/PreviewButton';
import { SaveButton } from '_lib/components/buttons/SaveButton';

interface Props {
  previewing: boolean;
  calculating: boolean;
  formOpen: boolean;
  formDisabled: boolean;
  previewDisabled: boolean;
  onCancel: () => void;
  onEdit: () => void;
  onReEdit: () => void;
  onSave: () => void;
  onClear: () => void;
  onPreview: () => void;
}

export const DemostatsActions = ({
  previewing,
  calculating,
  formOpen,
  formDisabled,
  previewDisabled,
  onCancel,
  onEdit,
  onReEdit,
  onSave,
  onClear,
  onPreview,
}: Props) => {
  const editingActions = previewing ? (
    <Grid container spacing={1} wrap="nowrap" alignItems="baseline">
      <Grid item><CancelIconButton onCancel={onCancel} /></Grid>
      <Grid item><EditButton onEdit={onReEdit} disabled={calculating} /></Grid>
      <Grid item><SaveButton onSave={onSave} disabled={calculating} /></Grid>
    </Grid>
  ) : (
    <Grid container spacing={1} wrap="nowrap" alignItems="baseline">
      <Grid item><CancelIconButton onCancel={onCancel} /></Grid>
      <Grid item><ClearButton onClear={onClear} disabled={calculating} /></Grid>
      <Grid item>
        <PreviewButton onPreview={onPreview} disabled={calculating || previewDisabled} />
      </Grid>
    </Grid>
  );
  return formOpen ? editingActions : (
    <EditIconButton onEdit={onEdit} disabled={formDisabled} />
  );
};
