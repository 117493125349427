import React from 'react';
import _ from 'lodash';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {
  CardContent,
  CardMedia,
  Checkbox,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { useUiText } from 'domain/uiText';
import { Spinner } from '_lib/loading';
import classNames from 'classnames';

type Props = {
  index: number;
  url: string;
  id: string;
  displayName?: string;
  description?: string;
  isReadonly?: boolean;
  isPdf?: boolean;
  showCheckeredBackground?: boolean;
  showDescription?: boolean;
  onDescriptionChange?: (index: number, value: string) => void;
  onSelect?: () => void;
  selected?: boolean;
  loading?: boolean;
  descriptionFieldError?: string | undefined;
  error?: string;
};

export const AssetFormItem = (props: Props) => {
  const classes = useStyles();
  const uiText = useUiText();

  const handleDescriptionChange = (e: React.BaseSyntheticEvent) => {
    props.onDescriptionChange && props.onDescriptionChange(props.index, e.target.value);
  };

  const content = props.isReadonly ? (
    <Typography variant="body2" color="textSecondary" component="p">
      {!_.isEmpty(props.description) ? props.description : uiText.NO_DESCRIPTION}
    </Typography>
  ) : (
    <TextField
      fullWidth={true}
      multiline={true}
      value={props.description}
      onChange={handleDescriptionChange}
      placeholder="Description"
      error={!!props.descriptionFieldError}
      helperText={props.descriptionFieldError}
    />
  );
  return (
    <div className={classes.container}>
      {props.error && (
        <Paper className={classes.error}>
          <Typography
            variant="caption"
            color="error"
          >
            {props.error}
          </Typography>
        </Paper>
      )}
      {props.onSelect && (
        <Paper className={classes.selectContainer}>
          <Checkbox
            size="small"
            color="primary"
            checked={props.selected}
            onClick={props.onSelect}
            disabled={props.loading}
          />
        </Paper>
      )}
      <div className={classes.imageContainer}>
        {props.isPdf ? (
          <CardMedia className={classes.media} classes={{ root: classes.asset }}>
            <a href={props.url} target="_blank" rel="noopener noreferrer">
              <Grid
                container={true}
                alignItems="center"
                justify="center"
                wrap="nowrap"
                spacing={2}
                direction="column"
                style={{ height: '100%' }}
              >
                <Grid item={true}>
                  <PictureAsPdfIcon className={classes.pdfIcon} />
                </Grid>
                {props.displayName && (
                  <Grid item={true}>
                    <Typography>{props.displayName}</Typography>
                  </Grid>
                )}
              </Grid>
            </a>
          </CardMedia>
        ) : (
          <CardMedia
            className={classNames(
              classes.media,
              { [classes.checker]: props.showCheckeredBackground }
            )}
            image={props.url}
            component="img"
          />
        )}
      </div>
      {props.showDescription && <CardContent className={classes.content}>{content}</CardContent>}
      {props.loading && <Spinner withContent />}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
  },
  selectContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: theme.spacing(0.5),
    backgroundColor: '#fff',
    borderRadius: 0,
    borderBottomLeftRadius: 4,
  },
  imageContainer: {
    height: 200,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
  },
  media: {
    objectFit: 'scale-down',
  },
  checker: {
    background: "url('/checker.png') center repeat",
  },
  asset: {
    maxWidth: 200,
    maxHeight: 200,
  },
  content: {
    borderTop: `solid 1px ${theme.palette.grey[100]}`,
    padding: `${theme.spacing(2)}px !important`,
  },
  pdfIcon: {
    color: theme.palette.text.secondary,
    fontSize: 48,
  },
  error: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: theme.spacing(1),
    backgroundColor: '#fff',
    borderRadius: 0,
    borderBottomRightRadius: 4,
  }
}));
