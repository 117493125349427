import { UiText } from 'domain/uiText';
import { makeResource } from 'lib/resource';
import { communityInternalResourceConfig } from './community';
import { contactInternalResourceConfig } from './contact';
import { embeddingInternalResourceConfig } from './embedding';
import { defaultVendorExternalResourceConfig, vendorInternalResourceConfig } from './vendor';
import { floorPlanInternalResourceConfig } from './floorPlan';
import { inventoryInternalResourceConfig } from './inventory';
import { showHomeInternalResourceConfig } from './showHome';
import {
  imageLinkInternalResourceConfig,
  bannerInternalResourceConfig,
} from './imageLink';
import { salesCentreInternalResourceConfig } from './salesCentre';
import { ExternalResourcesConfig, ResourcesContextValue } from './types';
import { featureTagInternalResourceConfig } from './featureTag/featureTagResource';

// This is where all the resource in the application are created
// using the externalResourcesConfig and uiText generated from makeUiText.
// The generated uiText is passed in so we can easily use the aliases
// of other resources when generating things like form field labels.
export const makeResources = (
  externalResourcesConfig: ExternalResourcesConfig,
  uiText: UiText,
): ResourcesContextValue => {
  return {
    floorPlan: makeResource({
      internalConfig: floorPlanInternalResourceConfig(uiText),
      externalConfig: externalResourcesConfig.floorPlan,
    }),
    community: makeResource({
      internalConfig: communityInternalResourceConfig(uiText),
      externalConfig: externalResourcesConfig.community,
    }),
    inventory: makeResource({
      internalConfig: inventoryInternalResourceConfig(uiText),
      externalConfig: externalResourcesConfig.inventory,
    }),
    showHome: makeResource({
      internalConfig: showHomeInternalResourceConfig(uiText),
      externalConfig: externalResourcesConfig.showHome,
    }),
    imageLink: makeResource({
      internalConfig: imageLinkInternalResourceConfig(uiText),
      externalConfig: externalResourcesConfig.imageLink,
    }),
    banner: makeResource({
      internalConfig: bannerInternalResourceConfig(uiText),
      externalConfig: externalResourcesConfig.banner,
    }),
    video: makeResource({
      internalConfig: embeddingInternalResourceConfig(uiText),
      externalConfig: externalResourcesConfig.video,
    }),
    embedding: makeResource({
      internalConfig: embeddingInternalResourceConfig(uiText),
      externalConfig: externalResourcesConfig.embedding,
    }),
    // Vendor does not have external config but is still a resource,
    // so we use it's 'default' external config to generate the vendor resource.
    vendor: makeResource({
      internalConfig: vendorInternalResourceConfig,
      externalConfig: defaultVendorExternalResourceConfig,
    }),
    contact: makeResource({
      internalConfig: contactInternalResourceConfig(uiText),
      externalConfig: externalResourcesConfig.contact,
    }),
    salesCentre: makeResource({
      internalConfig: salesCentreInternalResourceConfig(uiText),
      externalConfig: externalResourcesConfig.salesCentre,
    }),
    featureTag: makeResource({
      internalConfig: featureTagInternalResourceConfig(uiText),
      externalConfig: externalResourcesConfig.featureTag,
    })
  };
};
