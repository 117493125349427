import { compact } from 'lodash';
import {
  combineDescriptionBodyAndBullets,
  createPublishedStatus,
  splitSeoMetaDescription,
} from '../common/transforms';
import { ValidatedEntity } from '../common/types';
import { transformApiEmbeddingsToEmbeddings } from '../embedding/transforms';
import { transformApiFeatureTagsToFeatureTags } from '../featureTag/transforms';
import { transformApiFloorPlanLayoutToFloorPlanAttributes } from '../floorPlanLayouts';
import { transformApiImageLinksToImageLinks } from '../imageLink/transforms';
import { ApiFloorPlan, ApiFloorPlanInput, FloorPlan } from './types';

export const transformFloorPlanToApiFloorPlanInput = (
  data: Partial<Omit<FloorPlan, 'builderId'>>
): ApiFloorPlanInput => ({
  displayName: data.displayName,
  seoMetaKeywords: data.seoMetaKeywords,
  seoTitleTag: data.seoTitleTag,
  showMonthlyPaymentCalculator: data.showMonthlyPaymentCalculator,
  homeType: data.homeType,
  garage: data.garage,
  communityPricing: data.communityPricing?.map((el) => ({
    id: el.id,
    idType: 'CONTENT_ID',
    price: el.price,
  })),
  featureTags: data.featureTags?.map((tag) => tag.id),
  ...combineDescriptionBodyAndBullets(data.seoDescriptionBody, data.seoDescriptionBullets),
});

export const transformApiFloorPlanToFloorPlan = (
  data?: ValidatedEntity<ApiFloorPlan>
): ValidatedEntity<FloorPlan> | undefined =>
  data
    ? {
        id: data.id,
        published: data.published,
        builderId: data.builderId,
        price: data.price,
        displayName: data.displayName,
        seoMetaKeywords: data.seoMetaKeywords,
        seoTitleTag: data.seoTitleTag,
        showMonthlyPaymentCalculator: data.showMonthlyPaymentCalculator,
        homeType: data.homeType,
        ...transformApiFloorPlanLayoutToFloorPlanAttributes(data?.floorPlanLayouts[0]),
        garage: data.garage,
        communityPricing:
          data.communityMappings?.map((el) => ({
            id: el.community.id,
            displayName: el.community.displayName,
            price: el.price,
          })) || [],
        assets: data.assets,
        floorPlanLayouts: data.floorPlanLayouts || [],
        embeddings: transformApiEmbeddingsToEmbeddings(data.embeddings),
        imageLinks: transformApiImageLinksToImageLinks(data.imageLinks),
        featureTags: transformApiFeatureTagsToFeatureTags(data.featureTags),
        publishedStatus: createPublishedStatus(data),
        validationErrors: data.validationErrors,

        ...splitSeoMetaDescription(data.seoMetaDescription),
      }
    : undefined;

export const transformApiFloorPlansToFloorPlans = (data?: ValidatedEntity<ApiFloorPlan>[]) =>
  data ? compact(data.map((el) => transformApiFloorPlanToFloorPlan(el))) : [];
