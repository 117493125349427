import React from 'react';
import { LayoutRenderer } from 'domain/view/components/layout/layoutRenderer/LayoutRenderer';
import { EntitySectionForm } from 'domain/view/components/form/EntitySectionForm';
import { Community } from 'domain/api/community';

type Props = {
  initialValues: Community;
  onSubmit: (values: Community) => void;
  id: string;
  title: string;
  icon: JSX.Element;
};

export const CommunityLotmapForm = ({ initialValues, onSubmit, ...rest }: Props) => {
  return (
    <EntitySectionForm
      resourceName="community"
      initialValues={initialValues}
      onUpdate={onSubmit}
      {...rest}
    >
      {(renderField) => (
        <LayoutRenderer
          items={[
            {
              container: true,
              item: true,
              direction: 'row',
              spacing: 2,
              items: [
                {
                  container: true,
                  direction: 'column',
                  item: true,
                  spacing: 2,
                  items: [
                    {
                      item: true,
                      renderContent: () => renderField('lotmapText'),
                    },
                    {
                      item: true,
                      renderContent: () => renderField('lotmapLink'),
                    },
                  ],
                },
              ],
            },
          ]}
        />
      )}
    </EntitySectionForm>
  );
};
