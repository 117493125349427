import { useMutation } from '@apollo/client';
import { MutationWithInput } from 'domain/api/common/types';
import { Asset } from '../types';
import { UPDATE_ASSET } from './gql';

type Input = MutationWithInput<{
  contentSpaceId: string;
  id: string;
  displayName?: string | null;
  description?: string | null;
}>;

type Response = {
  updateAsset: {
    asset: Asset;
  };
};

export const useUpdateOneAsset = () => useMutation<Response, Input>(UPDATE_ASSET);
