import React from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';

interface Props {
  topbar: React.ReactNode;
  children: React.ReactNode;
}

export const TopbarLayout = React.memo(({ topbar, children }: Props) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" wrap="nowrap" className={classes.layout}>
      <Grid item>
        {topbar}
      </Grid>
      <Grid item className={classes.content}>
        {children}
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    backgroundColor: theme.palette.grey[100],
    height: '100%',
    width: '100%',
  },
  content: {
    flexGrow: 1,
    overflow: 'hidden',
  },
}));
