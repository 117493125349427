import React from 'react';
import { Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { AuthUser } from '_lib/auth';

type Props = {
  user: AuthUser,
  logout: () => void,
};

export const AuthFooter = React.memo(({ user, logout }: Props) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" wrap="nowrap" alignItems="center">
      <Grid item>
        <Typography
          className={classes.user}
          display="block"
          align="center"
          variant="caption"
        >
          Signed in as {user.nickname}
        </Typography>
      </Grid>
      <Grid item>
        <Button className={classes.logout} onClick={() => logout()}>
          Logout
        </Button>
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  user: {
    color: theme.palette.common.white,
  },
  logout: {
    color: theme.palette.common.white,
    textTransform: 'capitalize',
  },
}));
