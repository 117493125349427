import { useLazyQuery } from '@apollo/client';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { GetManyAdapterAction } from 'domain/api/common/adapters/types';
import { GetManyVariables, ResponseWithContentSpace, ValidatedEntity } from 'domain/api/common/types';
import { validateApiResponseEntities } from 'domain/api/common/validation';
import { useContentSpaceId } from '_lib/auth';
import { transformApiFeatureTagsToFeatureTags } from '../transforms';
import { FeatureTag } from '../types';
import { GET_MANY_FEATURE_TAGS } from './gql';

type Response = ResponseWithContentSpace<{ featureTags: FeatureTag[] }>;
type Variables = GetManyVariables;

export const useGetManyFeatureTags:
    () => GetManyAdapterAction<ValidatedEntity<FeatureTag>> = () => {
  const contentSpaceId = useContentSpaceId();
  const schemas = useApiSchemas();
  const [query, queryResult] = useLazyQuery<Response, Variables>(GET_MANY_FEATURE_TAGS);

  const execute = () => {
    contentSpaceId && query({ variables: { contentSpaceId } });
  };

  const validated = schemas?.featureTag.apiSchema
    ? validateApiResponseEntities(
      queryResult.data?.contentSpace.featureTags,
      schemas.featureTag.apiSchema
    )
    : undefined;

  const transformed = transformApiFeatureTagsToFeatureTags(validated);

  return {
    execute,
    result: transformed,
    loading: queryResult.loading,
    called: queryResult.called,
    error: queryResult.error?.message,
  }
};
