import React from 'react';
import { makeStyles, Theme, Avatar as MaterialAvatar } from '@material-ui/core';
import { NO_IMAGE_URL } from 'domain/api/asset/types';

interface OwnProps {
  size?: number;
  url?: string | null;
  hideBorder?: boolean;
}

type Props = OwnProps;

export const Avatar = (props: Props) => {
  const { url, size = 55 } = props;
  const classes = useStyles();
  return (
    <div>
      <MaterialAvatar
        src={url && url.length ? url : NO_IMAGE_URL}
        style={{ minHeight: size, minWidth: size }}
        className={props.hideBorder ? undefined : classes.image}
        classes={{
          fallback: classes.fallback,
          colorDefault: classes.fallback,
        }}
      >
        <img src={NO_IMAGE_URL} style={{ height: size }} alt="thumbnail" />
      </MaterialAvatar>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    border: `solid 1px ${theme.palette.grey[200]}`
  },
  fallback: {
    backgroundColor: 'transparent !important',
  }
}));
