import React from 'react';
import { useContentSpaceId } from '_lib/auth';
import {
  Contact,
  useGetManyContacts,
  useDeleteOneContact,
  useCreateOneContact,
  useUpdateOneContact,
} from 'domain/api/contact';
import { useUiText } from 'domain/uiText';
import { ListPage } from 'domain/view/pages/ListPage/ListPage';
import { useResetableAdapterAction } from 'domain/api/common/hooks';
import { CreateContactForm } from '../../forms/CreateContactForm';
import { EditContactForm } from '../../forms';

export const ContactsListPageContainer = () => {
  const uiText = useUiText();
  const contentSpaceId = useContentSpaceId();
  const getContacts = useGetManyContacts();
  const deleteContact = useDeleteOneContact();
  const createContact = useResetableAdapterAction(useCreateOneContact);
  const updateContact = useResetableAdapterAction(useUpdateOneContact);

  const get = () => {
    getContacts.execute();
  };

  const handleCreate = (values: Omit<Contact, 'id'>) => {
    contentSpaceId && createContact.execute({ ...values, contentSpaceId });
  };

  const handleUpdate = (values: Contact) => {
    contentSpaceId && updateContact.execute({ ...values });
  };

  return (
    <ListPage
      resourceName="contact"
      get={get}
      actions={{
        delete: (id: string) => deleteContact.execute({ id }),
        edit: (contact) => (
          <EditContactForm
            contact={contact}
            submitting={updateContact.loading}
            submitted={updateContact.called && !updateContact.loading}
            onSubmit={(values) => handleUpdate({ ...values, id: contact.id })}
          />
        ),
      }}
      loading={getContacts.loading}
      actionLoading={deleteContact.loading}
      error={undefined}
      data={getContacts.result}
      called={getContacts.called}
      hasData={getContacts.result.length > 0}
      noDataMessage={uiText.NO_CONTACT}
      topbar={{
        title: uiText.CONTACT_PLURAL,
        createAction: (
          <CreateContactForm
            onSubmit={handleCreate}
            submitting={createContact.loading}
            submitted={createContact.called}
          />
        ),
      }}
    />
  );
};
