import { useLazyQuery } from '@apollo/client';
import { useContentSpaceId } from '_lib/auth';
import { GetManyAdapterAction } from 'domain/api/common/adapters/types';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import {
  GetManyVariables,
  ResponseWithContentSpace,
  ValidatedEntity,
} from 'domain/api/common/types';
import { validateApiResponseEntities } from 'domain/api/common/validation';
import { transformApiFloorPlansToFloorPlans } from '../transforms';
import { ApiFloorPlan, FloorPlan } from '../types';
import { GET_MANY_FLOOR_PLANS } from './gql';

type Response = ResponseWithContentSpace<{ floorPlans: ApiFloorPlan[] }>;
type Variables = GetManyVariables;

export const useGetManyFloorPlans: () => GetManyAdapterAction<ValidatedEntity<FloorPlan>> = () => {
  const contentSpaceId = useContentSpaceId();
  const schemas = useApiSchemas();
  const [query, queryResult] = useLazyQuery<Response, Variables>(GET_MANY_FLOOR_PLANS);

  const execute = () => {
    contentSpaceId && query({ variables: { contentSpaceId } });
  };

  const validated = schemas?.floorPlan.apiSchema
    ? validateApiResponseEntities(
      queryResult.data?.contentSpace.floorPlans,
      schemas.floorPlan.apiSchema
    )
    : undefined;

  return {
    execute,
    result: transformApiFloorPlansToFloorPlans(validated),
    loading: queryResult.loading,
    called: queryResult.called,
    error: queryResult.error?.message,
  };
};
