import React from 'react';
import {
  createMuiTheme,
  ThemeProvider as MaterialThemeProvider,
} from '@material-ui/core/styles';

type Props = {
  children?: React.ReactNode;
};

// Fonts
const fontFamilies = {
  FUTURA_PT: 'futura-pt, sans-serif',
};

const fontConfigurations = {
  futuraBook: {
    fontFamily: fontFamilies.FUTURA_PT,
    fontStyle: 'normal',
    fontWeight: 400,
  },
  futuraMedium: {
    fontFamily: fontFamilies.FUTURA_PT,
    fontStyle: 'normal',
    fontWeight: 500,
  },
  futuraOblique: {
    fontFamily: fontFamilies.FUTURA_PT,
    fontStyle: 'oblique',
    fontWeight: 500,
  },
};

// Colours
const openhouseColours = {
  // primary
  TEAL: '#009999',
  BERMUDA: '#84CEC3',
  YELLOW: '#FAB349',

  // secondary
  TAHITI_GOLD: '#E47B2D',
  DARK_GREY: '#606060',
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: openhouseColours.TEAL,
    },
    secondary: {
      main: openhouseColours.YELLOW,
    },
    error: {
      main: '#C04F5C',
    },
    success: {
      main: '#65A357',
    }
  },
  overrides: {},
  typography: {
    h6: {
      ...fontConfigurations.futuraMedium,
    },
    h5: {
      ...fontConfigurations.futuraMedium,
      // textTransform: 'uppercase',
    },
    h4: {
      ...fontConfigurations.futuraMedium,
      // textTransform: 'uppercase',
    },
    h3: {
      ...fontConfigurations.futuraMedium,
    },
    h2: {
      ...fontConfigurations.futuraMedium,
    },
    h1: {
      ...fontConfigurations.futuraMedium,
    },
    body2: {
      ...fontConfigurations.futuraBook,
    },
    body1: {
      ...fontConfigurations.futuraBook,
      fontSize: '1.1rem',
    },
    button: {
      ...fontConfigurations.futuraMedium,
    },
    subtitle1: {
      ...fontConfigurations.futuraMedium,
    },
    subtitle2: {
      ...fontConfigurations.futuraMedium,
    },
    caption: {
      ...fontConfigurations.futuraOblique,
    },
    overline: {
      ...fontConfigurations.futuraOblique,
    },
  },
});

export const ThemeProvider = ({ children }: Props) => {
  return (
    <MaterialThemeProvider theme={theme}>
      {children}
    </MaterialThemeProvider>
  );
};
