import React from 'react';
import { useGetManyFloorPlans } from 'domain/api/floorPlan';
import { useGetManyCommunities } from 'domain/api/community';
import { useGetManyInventories } from 'domain/api/inventory';
import { useGetManyVendors } from 'domain/api/vendor';
import { useGetManyShowHomes } from 'domain/api/showhome';
import {
  useCreateOneEmbedding,
  useDeleteOneEmbedding,
  useGetManyEmbeddings,
  usePublishOneEmbedding,
  useUnpublishOneEmbedding,
} from 'domain/api/embedding';
import { EmbeddingInput } from 'domain/api/embedding/types';
import { useResetableAdapterAction } from 'domain/api/common/hooks';
import { useUiText } from 'domain/uiText';
import { ListPage } from 'domain/view/pages/ListPage/ListPage';
import { CreateVideoForm, EditVideoForm } from '../../forms';

export const VideosListPageContainer = () => {
  const uiText = useUiText();

  const getVideos = useGetManyEmbeddings(true);
  const getCommunities = useGetManyCommunities();
  const getFloorPlans = useGetManyFloorPlans();
  const createVideo = useResetableAdapterAction(useCreateOneEmbedding);
  const getVendors = useGetManyVendors();
  const getInventories = useGetManyInventories();
  const getShowHomes = useGetManyShowHomes();
  const publishVideo = usePublishOneEmbedding();
  const unpublishVideo = useUnpublishOneEmbedding();
  const deleteVideo = useDeleteOneEmbedding();
  const vendorsFilteredByType = getVendors.result.filter((vendor) => vendor.type === 'video');

  const get = () => {
    getVideos.execute();
    getCommunities.execute();
    getFloorPlans.execute();
    getVendors.execute();
    getInventories.execute();
    getShowHomes.execute();
  };

  const handleCreateVideo = (video: EmbeddingInput) => {
    createVideo.execute(video);
  };

  return (
    <ListPage
      resourceName="video"
      get={get}
      actions={{
        publish: (id: string) => publishVideo.execute({ id }),
        unpublish: (id: string) => unpublishVideo.execute({ id }),
        delete: (id: string) => deleteVideo.execute({ id }),
        edit: (data) => (
          <EditVideoForm
            video={data}
            floorPlans={getFloorPlans.result}
            communities={getCommunities.result}
            inventories={getInventories.result}
            showHomes={getShowHomes.result}
          />
        ),
      }}
      loading={getVideos.loading || createVideo.loading}
      actionLoading={deleteVideo.loading || publishVideo.loading || unpublishVideo.loading}
      error={undefined}
      data={getVideos.result}
      called={getVideos.called}
      hasData={getVideos.result.length > 0}
      noDataMessage={uiText.NO_VIDEO}
      topbar={{
        title: uiText.VIDEO_PLURAL,
        createAction: (
          <CreateVideoForm
            onSubmit={handleCreateVideo}
            submitting={createVideo.loading}
            submitted={!createVideo.loading && createVideo.called}
            vendors={vendorsFilteredByType}
            floorPlans={getFloorPlans.result}
            communities={getCommunities.result}
            inventories={getInventories.result}
            showHomes={getShowHomes.result}
          />
        ),
      }}
    />
  );
};
