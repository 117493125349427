import { defaultUiText, UiText } from 'domain/uiText';
import { ExternalResourceConfig, InternalResourceConfig, makeResource } from 'lib/resource';
import { defaultExternalResourceConfig } from '../shared';
import { showHomeFieldsSchema } from './schemas';
import {
  defaultShowHome,
  defaultShowHomeFields,
  ShowHome,
  showHomeFields,
  ShowHomeFields,
  showHomeDataDisplayFields,
} from './types';

export const showHomeInternalResourceConfig = (uiText: UiText): InternalResourceConfig<
ShowHome,
ShowHomeFields
> => ({
  enabledPages: ['list', 'single'],
  defaultResource: defaultShowHome,
  defaultResourceFields: defaultShowHomeFields,
  fields: showHomeFields(uiText),
  fieldsValidationSchema: showHomeFieldsSchema(uiText),
  dataDisplay: showHomeDataDisplayFields(uiText),
});

export const defaultShowHomeExternalResourceConfig: ExternalResourceConfig = {
  ...defaultExternalResourceConfig,
  aliases: {
    path: 'show-homes',
    plural: 'Show Homes',
    singular: 'Show Home',
  },
};

export const defaultShowHomeResource = makeResource({
  internalConfig: showHomeInternalResourceConfig(defaultUiText),
  externalConfig: defaultShowHomeExternalResourceConfig,
});
