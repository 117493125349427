import { createFailedDeleteMessage, createSuccessfulDeleteMessage, useSetMessage } from '_lib/messages';
import { useIdInputMutation } from 'domain/api/common/adapters/useIdInputMutation';
import { updateCacheOnDeleteOne } from 'domain/api/common/cache';
import { DeleteOneAdapterAction } from 'domain/api/common/adapters/types';
import { DELETE_ONE_IMAGE_LINK } from './gql';

type Response = {
  deleteImageLink: {
    deletedId: string | null | undefined;
  };
};

export const useDeleteOneImageLink = (): DeleteOneAdapterAction => {
  const setMessage = useSetMessage();

  return useIdInputMutation<Response, string | undefined>(
    DELETE_ONE_IMAGE_LINK,
    (data) => data?.deleteImageLink.deletedId || undefined,
    {
      updateCache: (cache, data) => updateCacheOnDeleteOne(
        cache, 'imageLinks', data?.deleteImageLink.deletedId,
      ),
      onSuccess: () => {
        setMessage(createSuccessfulDeleteMessage(''));
      },
      onError: (err) => setMessage(createFailedDeleteMessage('', err)),
    }
  );
};
