import { gql } from '@apollo/client';
import { AllEmbeddingInfo } from '../fragments';

export const ADD_EMBEDDING_CONTENT_ENTRIES = gql`
fragment AddEmbeddingContentEntriesInfo on Embedding {
  ${AllEmbeddingInfo}
}

mutation addEmbeddingContentEntries($input: AddEmbeddingContentEntriesInput) {
  addEmbeddingContentEntries(input: $input) {
    embedding {
      ...AddEmbeddingContentEntriesInfo
    }
  }
}
`;
