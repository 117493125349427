import React from 'react';
import { ConfigProvider as CoreConfigProvider } from '_lib/config';
import { useContentSpaceId } from '_lib/auth';
import { useConfigureUiText } from 'domain/uiText/';
import { configSchema } from './schema';
import { useSetConfig } from './hooks';
import { Config } from './types';

type Props = {
  children?: React.ReactNode;
};

export const ConfigProvider = ({ children }: Props) => {
  const setConfigState = useSetConfig();
  const configureUiText = useConfigureUiText();
  const contentSpaceId = useContentSpaceId();

  if (!process.env.REACT_APP_BASE_CONFIG_URL) {
    throw new Error('Missing environment variable REACT_APP_BASE_CONFIG_URL');
  }

  if (!contentSpaceId) return null;
  const setConfig = (config: Config | undefined) => {
    setConfigState(config);
    config &&
      configureUiText({
        FLOOR_PLAN_SINGULAR: config.resources.floorPlan.aliases.singular,
        FLOOR_PLAN_PLURAL: config.resources.floorPlan.aliases.plural,
        FLOOR_PLAN_PATH: config.resources.floorPlan.aliases.path,
        COMMUNITY_SINGULAR: config.resources.community.aliases.singular,
        COMMUNITY_PLURAL: config.resources.community.aliases.plural,
        COMMUNITY_PATH: config.resources.community.aliases.path,
        INVENTORY_SINGULAR: config.resources.inventory.aliases.singular,
        INVENTORY_PLURAL: config.resources.inventory.aliases.plural,
        INVENTORY_PATH: config.resources.inventory.aliases.path,
        SHOWHOME_SINGULAR: config.resources.showHome.aliases.singular,
        SHOWHOME_PLURAL: config.resources.showHome.aliases.plural,
        SHOWHOME_PATH: config.resources.showHome.aliases.path,
        IMAGE_LINK_SINGULAR: config.resources.imageLink.aliases.singular,
        IMAGE_LINK_PLURAL: config.resources.imageLink.aliases.plural,
        IMAGE_LINK_PATH: config.resources.imageLink.aliases.path,
        BANNER_SINGULAR: config.resources.banner.aliases.singular,
        BANNER_PLURAL: config.resources.banner.aliases.plural,
        BANNER_PATH: config.resources.banner.aliases.path,
        THIRD_PARTY_PLUGIN_SINGULAR: config.resources.embedding.aliases.singular,
        THIRD_PARTY_PLUGIN_PLURAL: config.resources.embedding.aliases.plural,
        THIRD_PARTY_PLUGIN_PATH: config.resources.embedding.aliases.path,
        VIDEO_SINGULAR: config.resources.video.aliases.singular,
        VIDEO_PLURAL: config.resources.video.aliases.plural,
        VIDEO_PATH: config.resources.video.aliases.path,
        CONTACT_SINGULAR: config.resources.contact.aliases.singular,
        CONTACT_PLURAL: config.resources.contact.aliases.plural,
        CONTACT_PATH: config.resources.contact.aliases.path,
        SALES_CENTRE_SINGULAR: config.resources.salesCentre.aliases.singular,
        SALES_CENTRE_PLURAL: config.resources.salesCentre.aliases.plural,
        SALES_CENTRE_PATH: config.resources.salesCentre.aliases.path,
      });
  };
  return (
    <CoreConfigProvider
      url={`${process.env.REACT_APP_BASE_CONFIG_URL}-${contentSpaceId}.json`}
      schema={configSchema}
      setConfig={setConfig}
    >
      {children}
    </CoreConfigProvider>
  );
};
