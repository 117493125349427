import { CommonApiFields } from '../common/types';
import { ApiFloorPlanLayout } from './types';

export const transformApiFloorPlanLayoutToFloorPlanAttributes = (
  floorPlanLayout?: ApiFloorPlanLayout
): Pick<CommonApiFields, 'bedrooms' | 'bathrooms' | 'halfBaths' | 'floorSize'> => {
  return {
    bedrooms: floorPlanLayout?.bedrooms || null,
    bathrooms: floorPlanLayout?.bathrooms || null,
    halfBaths: floorPlanLayout?.halfBaths || null,
    floorSize: floorPlanLayout?.floorSize || null,
  };
};
