import { PureQueryOptions } from '@apollo/client';
import { GET_ONE_COMMUNITY } from 'domain/api/community/getOne/gql';
import { GET_ONE_FLOOR_PLAN } from 'domain/api/floorPlan/getOne/gql';
import { GET_ONE_INVENTORY } from 'domain/api/inventory/getOne/gql';
import { GET_ONE_SHOW_HOME } from 'domain/api/showhome/getOne/gql';
import { useContentSpaceId } from '_lib/auth';

export type RefetchContext = 'floorPlan' | 'community' | 'inventory' | 'showHome';

export const useRefetchQueries = (context: RefetchContext, id: string): PureQueryOptions[] => {
  const contentSpaceId = useContentSpaceId();
  switch (context) {
    case 'floorPlan':
      return [{
        query: GET_ONE_FLOOR_PLAN,
        variables: { contentSpaceId, id }
      }];
    case 'inventory':
      return [{
        query: GET_ONE_INVENTORY,
        variables: { contentSpaceId, id }
      }];
    case 'community':
      return [{
        query: GET_ONE_COMMUNITY,
        variables: { contentSpaceId, id }
      }];
    case 'showHome':
      return [{
        query: GET_ONE_SHOW_HOME,
        variables: { contentSpaceId, id }
      }];
    default:
      throw new Error();
  }
};
