import { UiText } from 'domain/uiText';
import { DataDisplay, Resource, ResourceFields } from 'lib/resource';

export type FeatureTagCategory = {
  id: string;
  name: string;
  displayName: string;
  displayOrder: number;
};

export type FeatureTag = {
  id: string;
  name: string;
  description: string;
  showFloorPlanViewer: boolean;
  featureTagCategory: FeatureTagCategory | null;
};

export const defaultFeatureTag: FeatureTag = {
  id: '',
  name: '',
  description: '',
  showFloorPlanViewer: false,
  featureTagCategory: null,
};

export type FeatureTagFields = Partial<{
  name: string;
  description: string;
  showFloorPlanViewer: boolean;
  featureTagCategory: string | null;
}>;

export type FeatureTagResource = Resource<FeatureTag, FeatureTagFields>;

export const defaultFeatureTagFields: FeatureTagFields = {
  name: '',
  description: '',
  showFloorPlanViewer: false,
  featureTagCategory: '',
};

export const featureTagFields = (
  uiText: UiText
): ResourceFields<FeatureTagFields> => ({
  name: {
    value: '',
    name: 'name',
    type: 'text',
    fieldConfig: {
      label: 'Name',
      required: true,
    },
  },
  description: {
    value: '',
    name: 'description',
    type: 'text',
    fieldConfig: {
      label: 'Description',
      nullable: false,
    },
  },
  showFloorPlanViewer: {
    value: false,
    name: 'showFloorPlanViewer',
    type: 'check-box',
    fieldConfig: {
      label: 'Show Floor Plan Viewer',
      nullable: false,
    },
  },
  featureTagCategory: {
    value: '',
    name: 'featureTagCategory',
    type: 'select',
    fieldConfig: {
      label: 'Category',
      nullable: true,
    },
  },
});

export const featureTagDataDisplayFields = (uiText: UiText): DataDisplay<FeatureTag> => ({
  fields: {
    name: {
      dataKey: 'name',
      label: 'Name',
    },
    description: {
      dataKey: 'description',
      label: 'Description',
    },
    featureTagCategory: {
      dataKey: 'featureTagCategory',
      label: 'Category',
    },
  },
});
