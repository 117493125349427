import React from 'react';
import { DocumentNode } from 'graphql';
import { apolloClient } from 'domain/api';
import { MutationUpdaterFn, PureQueryOptions } from '@apollo/client';

type State<T> = {
  loading: boolean;
  done: boolean;
  called: boolean;
  result: (T | undefined | null)[];
};

const initialState = {
  loading: false,
  called: false,
  done: false,
  result: [],
};

type BatchMutationEntity<Input> = {
  input: Input;
};

export function useBatchMutation<MutationResponse, MutationInput>(
  mutation: DocumentNode,
  update?: MutationUpdaterFn<MutationResponse>,
  refetchQueries?: PureQueryOptions[],
) {
  const [state, setState] = React.useState<State<MutationResponse>>(initialState);

  const executeMutation = async (
    input: MutationInput,
  ): Promise<MutationResponse> => {
    try {
      const result = await apolloClient.mutate<MutationResponse, MutationInput>({
        mutation,
        variables: input,
        update,
        awaitRefetchQueries: true,
        refetchQueries,
      });


      if (result.errors) throw new Error('Failed to execute mutation');
      if (!result.data) throw new Error('Failed to execute mutation');
      return result.data;
    } catch (error) {
      return error;
    }
  };

  function executeMutations(
    entities: BatchMutationEntity<MutationInput>[],
  ): void {
    if (!state.loading) setState(prev => ({ ...prev, loading: true, called: true }));

    let index = 0;

    (async () => {
      while (index < entities.length) {
        // eslint-disable-next-line no-await-in-loop
        const result = await executeMutation(entities[index].input);
        index += 1;
        const done = index === entities.length;
        setState(prev => ({
          called: true,
          result: [...prev.result, result],
          loading: !done,
          done,
        }));
      }
    })();
  }

  return {
    execute: executeMutations,
    ...state,
  };
}
