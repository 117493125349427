import React from 'react';
import { Card, Grid, Typography } from '@material-ui/core';
import { NO_IMAGE_URL } from 'domain/api/asset';
import { useUiText } from 'domain/uiText';
import { useAssetFormContext } from '../AssetFormContext';
import { AssetFormItem } from './AssetFormItem';

type Props = {
  children: (renderAssetItem: (el: JSX.Element, id: string) => JSX.Element) => JSX.Element;
};
export const AssetFormPageSectionItems = ({ children }: Props) => {
  const uiText = useUiText();
  const { assets, isMultiple, attributeName, entityContentId, isPdf } = useAssetFormContext();
  const hasAssets = assets.length > 0;

  const renderMultipleAsset = (el: JSX.Element, id: string) => (
    <Grid
      xl={3}
      lg={3}
      md={6}
      sm={6}
      xs={12}
      key={`${id}-readonly-multiple-${entityContentId || ''}-${attributeName}`}
      item={true}
    >
      <Card style={{ maxWidth: '300px' }}>{el}</Card>
    </Grid>
  );

  const renderSingleAsset = (el: JSX.Element, id: string) => el;

  if (isMultiple) {
    return (
      <Grid container={true} spacing={2}>
        {hasAssets ? (
          children(renderMultipleAsset)
        ) : (
          <Grid item={true} container={true} justify="center">
            <Grid item={true}>
              <Typography color="textSecondary">{uiText.NO_IMAGES}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
  return hasAssets ? (
    children(renderSingleAsset)
  ) : (
    <AssetFormItem
      index={0}
      url={NO_IMAGE_URL}
      isPdf={isPdf}
      id={`${attributeName}-single-readonly-placeholder`}
    />
  );
};
