import React from 'react';
import { DefaultResourcesContextValue, ResourcesContextValue } from './resources/types';

export type SystemContextValue = {
  contentSpaceId: string;
  defaultGeoPoint: {
    lat: number;
    lng: number;
  };
  resources: ResourcesContextValue;
};

/**
 * @description Global context for accessing application config and resources
 */
export const SystemContext = React.createContext<SystemContextValue>({
  contentSpaceId: '',
  defaultGeoPoint: {
    lat: 0,
    lng: 0,
  },
  resources: DefaultResourcesContextValue,
});
