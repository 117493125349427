import { useLazyQuery } from '@apollo/client';
import {
  GetOneVariables,
  ResponseWithContentSpace,
  ValidatedEntity,
} from 'domain/api/common/types';
import { GetOneAdapterAction } from 'domain/api/common/adapters/types';
import { useContentSpaceId } from '_lib/auth';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { ApiFloorPlan, FloorPlan } from '../types';
import { GET_ONE_FLOOR_PLAN } from './gql';
import { transformApiFloorPlanToFloorPlan } from '../transforms';

type Response = ResponseWithContentSpace<{ floorPlan: ApiFloorPlan }>;
type Variables = GetOneVariables;

export const useGetOneFloorPlan: () => GetOneAdapterAction<ValidatedEntity<FloorPlan>> = () => {
  const contentSpaceId = useContentSpaceId();
  const schemas = useApiSchemas();
  const [query, queryResult] = useLazyQuery<Response, Variables>(GET_ONE_FLOOR_PLAN);

  const execute = (id: string) => {
    contentSpaceId && query({ variables: { contentSpaceId, id } });
  };

  const refetch = (id: string) => {
    contentSpaceId && queryResult?.refetch?.({ contentSpaceId, id });
  };

  const validated = schemas?.floorPlan.apiSchema
    ? validateApiResponseEntity(
      queryResult.data?.contentSpace.floorPlan,
      schemas.floorPlan.apiSchema
    )
    : undefined;

  return {
    execute,
    refetch,
    result: transformApiFloorPlanToFloorPlan(validated),
    loading: queryResult.loading,
    called: queryResult.called,
    error: queryResult.error?.message,
  };
};
