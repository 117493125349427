import { useLazyQuery } from '@apollo/client';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { GetManyAdapterAction } from 'domain/api/common/adapters/types';
import { GetManyVariables, ResponseWithContentSpace, ValidatedEntity } from 'domain/api/common/types';
import { validateApiResponseEntities } from 'domain/api/common/validation';
import { useContentSpaceId } from '_lib/auth';
import { FeatureTagCategory } from '../types';
import { GET_MANY_FEATURE_TAG_CATEGORIES } from './gql';

type Response = ResponseWithContentSpace<{ featureTagCategories: FeatureTagCategory[] }>;
type Variables = GetManyVariables;

export const useGetManyFeatureTagCategories:
    () => GetManyAdapterAction<ValidatedEntity<FeatureTagCategory>> = () => {
  const contentSpaceId = useContentSpaceId();
  const schemas = useApiSchemas();
  const [query, queryResult] = useLazyQuery<Response, Variables>(GET_MANY_FEATURE_TAG_CATEGORIES);

  const execute = () => {
    contentSpaceId && query({ variables: { contentSpaceId } });
  };

  const validated = schemas?.featureTagCategory.apiSchema
    ? validateApiResponseEntities(
      queryResult.data?.contentSpace.featureTagCategories,
      schemas.featureTagCategory.apiSchema
    )
    : undefined;

  return {
    execute,
    result: validated || [],
    loading: queryResult.loading,
    called: queryResult.called,
    error: queryResult.error?.message,
  }
};
