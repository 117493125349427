import { ShowHome } from 'domain/api/showhome';
import { SelectOption } from 'lib/form/fields/SelectField/types';
import { ResourceConstraint } from 'lib/resource';

export function generateContentEntrySelectOptions<
  TResource extends ResourceConstraint & { published: boolean; displayName: string }
>(data: TResource[]) {
  const options: SelectOption[] = data.map((el) => {
  if (el.fullAddress)  return {
    label: `${el.displayName},${el.fullAddress || ''}`,
    value: el.id,
  }
  return { label: el.displayName, value: el.id }
  });
  const publishedIds: string[] = data.filter((el) => el.published).map((el) => el.id);

  return {
    options,
    publishedIds,
  };
}

export const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const millisecondsToSeconds = (ms: number) => ms / 1000;
export const secondsToMilliseconds = (s: number) => s * 1000;

/**
 *
 * @description This can be deleted when the BE is updated
 */
type CorrectedEntityConstraint = {
  showHomes: string[];
  inventories: string[];
} & object;

/**
 *
 * @description This can be deleted when the BE is updated
 */
export function correctShowHomeAndInventoryContentEntries<T extends CorrectedEntityConstraint>(
  _showHomes: ShowHome[],
  entity: T,
)  {
  const showHomes: string[] = [];
  const inventories: string[] = [];

  entity.inventories.forEach((entry) => {
    if (_showHomes.find(showHome => showHome.id === entry)) {
      showHomes.push(entry);
    } else {
      inventories.push(entry);
    }
  });

  return {
    ...entity,
    showHomes,
    inventories,
  };
}
