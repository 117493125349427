import { useLazyQuery } from '@apollo/client';
import {
  GetOneVariables,
  ResponseWithContentSpace,
  ValidatedEntity,
} from 'domain/api/common/types';
import { GetOneAdapterAction } from 'domain/api/common/adapters/types';
import { useContentSpaceId } from '_lib/auth';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { useUiText } from 'domain/uiText';
import { ApiCommunity, Community } from '../types';
import { GET_ONE_COMMUNITY } from './gql';
import { apiCommunityValidationSchema } from '../validation';
import { transformApiCommunityToCommunity } from '../transforms';

type Response = ResponseWithContentSpace<{ community: ApiCommunity }>;
type Variables = GetOneVariables;

export const useGetOneCommunity: () => GetOneAdapterAction<ValidatedEntity<Community>> = () => {
  const contentSpaceId = useContentSpaceId();
  const uiText = useUiText();
  const [query, queryResult] = useLazyQuery<Response, Variables>(GET_ONE_COMMUNITY);

  const execute = (id: string) => {
    contentSpaceId && query({ variables: { contentSpaceId, id } });
  };

  const refetch = (id: string) => {
    contentSpaceId && queryResult?.refetch?.({ contentSpaceId, id });
  };

  const validated = validateApiResponseEntity(
    queryResult.data?.contentSpace.community,
    apiCommunityValidationSchema(uiText)
  );

  return {
    execute,
    refetch,
    result: transformApiCommunityToCommunity(validated),
    loading: queryResult.loading,
    called: queryResult.called,
    error: queryResult.error?.message,
  };
};
