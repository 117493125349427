import React from 'react';
import {
  Map as GoogleMapComponent, GoogleApiWrapper,
  IGoogleApiOptions, Marker, GoogleAPI,
} from 'google-maps-react';

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

export const googleApiWrapperOptions: IGoogleApiOptions = {
  apiKey,
  libraries: ['geometry', 'drawing'],
};

type Props = {
  disableInteractions?: boolean;
  lat: number | null;
  lng: number | null;
  google: GoogleAPI;
};

const mapStyles = {
  width: '100%',
  height: '300px',
};

const MapComponent = ({
  lat,
  lng,
  google,
  disableInteractions,
}: Props) => {
  const position = lat && lng ? { lat, lng } : undefined;
  return (
    <div style={{ position: 'relative', ...mapStyles }}>
      <GoogleMapComponent
        google={google}
        zoom={11}
        initialCenter={position}
        center={position}
        mapTypeControl={false}
        streetViewControl={false}
        fullscreenControl={false}
        gestureHandling={disableInteractions ? 'none' : 'greedy'}
        maxZoom={15}
        minZoom={1}
      >
        <Marker position={position} />
      </GoogleMapComponent>
    </div>
  );
};

export const Map = GoogleApiWrapper(googleApiWrapperOptions)(MapComponent);
