import React from 'react';
import { FullScreenLoader } from '../components/FullScreenLoader';
import { useBlockerRegistration } from '../useBlockerRegistration';
import { useLoadOnce } from '../useLoadOnce';
import { noopRegistrationHook, useSetLoadingRegistration } from '../registrationState';
import { useSytemLoading } from '../systemLoadingState';

type Props = {
  children: React.ReactNode;
};

export const LoadingBlind = ({ children }: Props) => {
  const { isLoading } = useSytemLoading();
  const { isBlocked, registrationHook } = useBlockerRegistration();

  const { hasFinished: showLoader } = useLoadOnce(isLoading || isBlocked);

  useSetLoadingRegistration(showLoader ? registrationHook : noopRegistrationHook);

  return (
    <React.Fragment>
      {(!showLoader) && (<FullScreenLoader />)}
      {children}
    </React.Fragment>
  );
};
