import React from 'react';
import { useConfig } from 'domain/config';
import { makeResources } from 'system/resources/makeResources';
import { SystemContext } from 'system/SystemContext';
import { useContentSpaceId } from '_lib/auth';
import { useUiText } from 'domain/uiText';

type Props = {
  children: React.ReactNode;
};

/**
 * Because we have not yet refactored the resource and are still using it
 * throughout the application, we need to support it.
 *
 */
export const EntitiesProvider = ({ children }: Props) => {
  const contentSpaceId = useContentSpaceId();
  const config = useConfig();
  const uiText = useUiText();

  if (!config || !contentSpaceId) return null;

  const entities = makeResources(config.resources, uiText);
  return (
    <SystemContext.Provider
      value={{
        contentSpaceId,
        resources: entities,
        defaultGeoPoint: config.defaultGeoPoint,
      }}
    >
      {children}
    </SystemContext.Provider>
  );
};
