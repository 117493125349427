import { PublishableApiEntity, PublishedStatus } from './types';

export const splitSeoMetaDescription = (description?: string | null) => {
  const str = description || '';
  const firstBulletIndex = str.indexOf('\n- ');
  const hasBullets = firstBulletIndex > -1;
  return {
    seoDescriptionBody: hasBullets ? str.substring(0, firstBulletIndex) : str,
    seoDescriptionBullets: hasBullets
      ? str
        .substring(firstBulletIndex)
        .split('\n- ')
        .filter(el => el.trim().length > 0)
      : [],
  };
};

export const combineDescriptionBodyAndBullets = (body?: string, bullets?: string[]) => {
  if (!body && !bullets) return {};
  const bulletsString = (bullets && bullets.length) ? `\n- ${bullets.join('\n- ')}` : '';
  return {
    seoMetaDescription: `${(body?.replaceAll('\n-', '-') || '')}${bulletsString}`,
  };
};

export function createPublishedStatus<T extends PublishableApiEntity<Record<string, any>>>({
  published,
  publishProblems = [],
}: T): PublishedStatus {
  if (published) return 'published';
  if (publishProblems.length > 0) return 'error';
  return 'draft';
}

type ContentEntries = {
  communities?: string[];
  floorPlans?: string[];
  showHomes?: string[];
  inventories?: string[];
};
export function getContentEntriesToAddAndRemove<P extends ContentEntries, C extends ContentEntries>(
  _prev?: P,
  _current?: C,
) {
  if (!_prev || !_current) return { toAdd: [], toRemove: [] };
  const prev = [
    ..._prev.communities || [],
    ..._prev.floorPlans || [],
    ..._prev.showHomes || [],
    ..._prev.inventories || [],
  ];

  const current = [
    ..._current.communities || [],
    ..._current.floorPlans || [],
    ..._current.showHomes || [],
    ..._current.inventories || [],
  ];

  return {
    toAdd: current.filter(el => !prev.includes(el)),
    toRemove: prev.filter(el => !current.includes(el)),
  };
}

export function transformClearableFieldsToClearList<
T extends Record<string, any>,
K extends keyof T & string,
>(input: T, clearableFields: K[]) {
  return clearableFields.reduce((prev, curr) => {
    if (input[curr] === null) return [...prev, curr];
    return prev;
  }, [] as K[]);
}
