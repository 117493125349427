// Replace this with our own image
export const NO_IMAGE_URL = 'https://redthread.uoregon.edu/files/original/affd16fd5264cab9197da4cd1a996f820e601ee4.png';

export type AssetAttributeName =
  'galleryPhotos' | 'sitemapImage' | 'sloganImage' |
  'logoImage' | 'bannerImage' | 'thumbnailImage' | 'listingMainImage' |
  'floorPlanImages' | 'attachmentFile' | 'attachmentImage' | 'displayImageNormal';

export type SingleAssetAttributeName =
  'sitemapImage' | 'sloganImage' | 'logoImage' |
  'bannerImage' | 'thumbnailImage' | 'listingMainImage' |
  'attachmentFile' | 'attachmentImage' | 'displayImageNormal';

export const singleAssetAttributeNames = [
  'sitemapImage', 'sloganImage', 'logoImage',
  'bannerImage', 'thumbnailImage', 'listingMainImage',
  'attachmentFile', 'attachmentImage', 'displayImageNormal',
];
export type Asset<Attribute extends AssetAttributeName | undefined = AssetAttributeName> = {
  __typename?: string;
  id: string;
  url: string;
  description: string;
  displayOrder: number | null;
  displayName: string
  attributeName: Attribute;
};

export type AssetFile = Pick<File, 'name' | 'lastModified' | 'size' | 'type'>;
export type EntityWithAssetsConstraint = {
  __typename: string;
  id: string;
  assets: Asset[];
  [x: string]: any;
};
