import {
  createFailedPublishMessage,
  createSuccessfulPublishMessage,
  useSetMessage,
} from '_lib/messages';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { ValidatedEntity } from 'domain/api/common/types';
import { useIdInputMutation } from 'domain/api/common/adapters/useIdInputMutation';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { PUBLISH_ONE_COMMUNITY } from './gql';
import { ApiCommunity, Community } from '../types';
import { transformApiCommunityToCommunity } from '../transforms';

type Response = {
  publishCommunity: {
    community?: ApiCommunity;
  };
};

export const usePublishOneCommunity = () => {
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  return useIdInputMutation<Response, ValidatedEntity<Community> | undefined>(
    PUBLISH_ONE_COMMUNITY,
    (data) =>
      schemas?.community.apiSchema
        ? transformApiCommunityToCommunity(
          validateApiResponseEntity(data?.publishCommunity.community, schemas.community.apiSchema)
        )
        : undefined,
    {
      onSuccess: () => setMessage(createSuccessfulPublishMessage('')),
      onError: (err) => setMessage(createFailedPublishMessage('', err)),
    }
  );
};
