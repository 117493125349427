import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { useCreateOrUpdateOne } from 'domain/api/common/adapters/useCreateOrUpdateOne';
import { updateCacheOnCreateOne } from 'domain/api/common/cache';
import { generateCreateOrUpdateString } from 'domain/api/common/fragments';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { useContentSpaceId } from '_lib/auth';
import { createFailedCreationMessage, createSuccessfulCreationMessage, useSetMessage } from '_lib/messages';
import { FeatureTagInfo } from '../fragments';
import { transformApiFeatureTagToFeatureTag } from '../transforms';
import { ApiFeatureTag, ApiFeatureTagInput, FeatureTag } from '../types';

const CREATE_ONE_FEATURE_TAG = generateCreateOrUpdateString('create', 'featureTag', FeatureTagInfo);

type Response = {
  createFeatureTag: {
    featureTag: ApiFeatureTag | undefined;
  };
};

type Input<T> = T & {
  contentSpaceId: string;
};

export const useCreateOneFeatureTag = () => {
  const contentSpaceId = useContentSpaceId();
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  const { execute: executeMutation, ...rest } = useCreateOrUpdateOne<
  Input<ApiFeatureTagInput>,
  Response,
  FeatureTag
  >(
    CREATE_ONE_FEATURE_TAG,
    (data) => schemas?.featureTag.apiSchema
      ? transformApiFeatureTagToFeatureTag(
          validateApiResponseEntity(
          data?.createFeatureTag.featureTag,
          schemas.featureTag.apiSchema,
        )
      )
      : undefined,
    {
      onSuccess: () => {
        setMessage(createSuccessfulCreationMessage(''));
      },
      onError: (err) => setMessage(createFailedCreationMessage('', err)),
      updateCache: (cache, data) =>
        updateCacheOnCreateOne(
          cache,
          'featureTags',
          data?.createFeatureTag.featureTag,
          `fragment FeatureTagInfo on FeatureTag {
            ${FeatureTagInfo}
          }`
        ),
    }
  )
  const execute = (input: ApiFeatureTagInput) => {
    if (!contentSpaceId) return;
    executeMutation({
      ...input,
      contentSpaceId,
    });
  }
  return { execute, ...rest };
}
