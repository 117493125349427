import { useHistory } from 'react-router';
import { useIdInputMutation } from 'domain/api/common/adapters/useIdInputMutation';
import { updateCacheOnDeleteOne } from 'domain/api/common/cache';
import { createFailedDeleteMessage, createSuccessfulDeleteMessage, useSetMessage } from '_lib/messages';
import { DeleteOneAdapterAction } from 'domain/api/common/adapters/types';
import { DELETE_ONE_INVENTORY } from './gql';

type Response = {
  deleteInventory: {
    deletedId: string | null | undefined;
  };
};

export const useDeleteOneInventory = (redirectPath?: string): DeleteOneAdapterAction => {
  const history = useHistory();
  const setMessage = useSetMessage();

  return useIdInputMutation<Response, string | undefined>(
    DELETE_ONE_INVENTORY,
    (data) => data?.deleteInventory.deletedId || undefined,
    {
      updateCache: (cache, data) => updateCacheOnDeleteOne(
        cache, 'inventories', data?.deleteInventory.deletedId,
      ),
      onSuccess: () => {
        setMessage(createSuccessfulDeleteMessage(''));
        if (redirectPath) history.push(`/${redirectPath}`);
      },
      onError: (err) => setMessage(createFailedDeleteMessage('', err)),
    }
  );
};
