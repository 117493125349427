import * as yup from 'yup';
import { UiText } from 'domain/uiText';
import { SalesCentreFields } from './types';

const sharedSchemas = (uiText: UiText) => ({
  name: yup.string().defined().required().default('').label('Name'),
  phone: yup.string().defined().default('').label('Phone Number'),
  address: yup.string().defined().default('').label('Address'),
  email: yup.string().defined().default('').label('Email'),
});

export const salesCentreFieldsSchema = (uiText: UiText) => yup.object<SalesCentreFields>({
  ...sharedSchemas(uiText),
  hours: yup.array().of(yup.string().defined()).compact().defined().default('').label('Hours'),
}).defined();
