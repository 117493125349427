import { defaultUiText, UiText } from 'domain/uiText';
import { ExternalResourceConfig, InternalResourceConfig, makeResource } from 'lib/resource';
import {
  defaultSalesCentre,
  defaultSalesCentreFields,
  SalesCentre,
  SalesCentreFields,
  salesCentreFields,
} from './types';
import { salesCentreFieldsSchema } from './schemas';
import { defaultExternalResourceConfig } from '../shared';

export const salesCentreInternalResourceConfig = (uiText: UiText): InternalResourceConfig<
SalesCentre,
SalesCentreFields
> => ({
  enabledPages: [],
  defaultResource: defaultSalesCentre,
  defaultResourceFields: defaultSalesCentreFields,
  fields: salesCentreFields(uiText),
  fieldsValidationSchema: salesCentreFieldsSchema(uiText),
  dataDisplay: {
    fields: {},
  },
});

export const defaultSalesCentreExternalResourceConfig: ExternalResourceConfig = {
  ...defaultExternalResourceConfig,
  aliases: {
    path: 'sales-centres',
    plural: 'Sales Centres',
    singular: 'Sales Centres',
  },
};

export const defaultSalesCentreResource = makeResource({
  internalConfig: salesCentreInternalResourceConfig(defaultUiText),
  externalConfig: defaultSalesCentreExternalResourceConfig,
});
