import { useMutation } from '@apollo/client';
import { MutationWithInput } from 'domain/api/common/types';
import { EntityWithAssetsConstraint } from 'domain/api/asset/types';
import { DELETE_ONE_ASSET } from './gql';

type Input = MutationWithInput<{
  id: string;
  contentSpaceId: string;
}>;

type Response = {
  deleteAsset: {
    deletedId: string | null;
  };
};

export function useDeleteOneAsset<T extends EntityWithAssetsConstraint>(
  entity: T,
) {
  return useMutation<Response, Input>(DELETE_ONE_ASSET, {
    update(cache, result) {
      cache.modify({
        id: cache.identify(entity),
        fields: {
          assets(existing = [], { DELETE }) {
            return DELETE;
          }
        }
      });
    },
  });
}
