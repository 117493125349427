import React from 'react';
import { LayoutRenderer } from 'lib/layout/LayoutRenderer';
import { EntitySectionForm } from 'domain/view/components/form/EntitySectionForm';
import { FormFields, InitialValues } from 'lib/form/types';
import { Inventory, InventoryInput } from 'domain/api/inventory/types';
import { PageSectionOptions } from '_lib/sectionedPageLayout';

type Props = PageSectionOptions & {
  initialValues: Inventory;
  onSubmit: (values: InventoryInput) => void;
};

export const InventoryPricingForm = ({ initialValues, onSubmit, ...rest }: Props) => {
  return (
    <EntitySectionForm
      resourceName="inventory"
      initialValues={initialValues}
      onUpdate={onSubmit}
      fieldModifiers={{
        // Subscribing these fields so they are validated when either of them changes
        priceReduced: (fields: FormFields<InitialValues>) => ({ fields }),
        price: (fields: FormFields<InitialValues>) => ({ fields }),
      }}
      // Removing possessionDate because any form that has fieldModifiers will run
      // validation on all fields. This can result in dates in the past failing validation,
      // making the form unable to be submitted.

      // A better solution would be to either pass in only the required fields to each form,
      // or to keep track of which fields have been rendered and remove those from the validation
      omittedFields={['possessionDateSec']}
      {...rest}
    >
      {(renderField, formFields) => (
        <LayoutRenderer
          items={[
            {
              container: true,
              direction: 'column',
              spacing: 2,
              items: [
                {
                  container: true,
                  item: true,
                  spacing: 2,
                  items: [
                    {
                      item: true,
                      xl: 4,
                      md: 6,
                      renderContent: () => renderField('price'),
                    },
                    {
                      item: true,
                      xl: 4,
                      md: 6,
                      renderContent: () => renderField('priceReduced', {
                        disabled: !formFields.price.value
                      }),
                    },
                    {
                      item: true,
                      xl: 4,
                      md: 6,
                      renderContent: () => formFields.priceReduced.value ? renderField('priceReducedFrom') : <div />
                    },
                  ],
                },
              ],
            },
          ]}
        />
      )}
    </EntitySectionForm>
  );
};
