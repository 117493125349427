import React from 'react';
import { useContentSpaceId } from '_lib/auth';
import {
  Community,
  useGetOneCommunity,
  useUpdateOneCommunity,
  usePublishOneCommunity,
  useUnpublishOneCommunity,
  useDeleteOneCommunity,
} from 'domain/api/community';
import { useGetManyContacts } from 'domain/api/contact';
import { useEntityFields } from 'domain/entities/hooks';
import { useUiText } from 'domain/uiText';
import { useResourceTopbarConfig } from 'domain/view/components/layout/topbar/useResourceTopbarConfig';
import { CommunityDetailPage } from './CommunityDetailPage';

export const CommunityDetailPageContainer = () => {
  const uiText = useUiText();
  const { removeDisabledFields } = useEntityFields('community');
  const contentSpaceId = useContentSpaceId();
  const getContacts = useGetManyContacts();
  const getCommunity = useGetOneCommunity();
  const updateCommunity = useUpdateOneCommunity();
  const publishCommunity = usePublishOneCommunity();
  const unpublishCommunity = useUnpublishOneCommunity();
  const deleteCommunity = useDeleteOneCommunity(uiText.COMMUNITY_PATH);

  const data = getCommunity.result;
  const { id = '', publishedStatus = 'error', validationErrors } = data || {};

  // Fetch required data for the model single page
  const get = (_id: string) => {
    getCommunity.execute(_id);
    getContacts.execute();
  };

  const handleUpdate = (values: Partial<Community>) => {
    if (!id || !contentSpaceId) return;
    updateCommunity.execute({
      contentSpaceId,
      idType: 'CONTENT_ID',
      id,
      community: removeDisabledFields(values),
    });
  };

  const handlePublish = () => id && publishCommunity.execute({ id, idType: 'CONTENT_ID' });

  const handleUnpublish = () => id && unpublishCommunity.execute({ id, idType: 'CONTENT_ID' });

  const handleDelete = () => id && deleteCommunity.execute({ id, idType: 'CONTENT_ID' });
  const handleRefetch = () => getCommunity.refetch?.(id);

  /**
   * @todo
   * Refactor this when/if we refactor the resoure
   */
  const topbarConfig = useResourceTopbarConfig(
    'community',
    publishedStatus,
    handleDelete,
    handlePublish,
    handleUnpublish,
    { ...data, id }
  );

  const loading =
    getCommunity.loading ||
    updateCommunity.loading ||
    deleteCommunity.loading ||
    publishCommunity.loading ||
    unpublishCommunity.loading;

  return (
    <CommunityDetailPage
      get={get}
      handleUpdate={handleUpdate}
      handleRefetch={handleRefetch}
      loading={loading}
      called={getCommunity.called}
      publishedStatus={publishedStatus}
      data={data as Community}
      validation={{ errors: validationErrors }}
      topbar={topbarConfig}
      contentSpaceId={contentSpaceId || ''}
      contacts={getContacts.result}
    />
  );
};
