import { Asset, AssetAttributeName, EntityWithAssetsConstraint } from 'domain/api/asset';

export const MAX_UPLOAD_SIZE = 10485760; // File size in bytes.

export type AssetFormProps = {
  contentSpaceId: string;
  entityContentId?: string;
  assets: Asset[];
  attributeName: AssetAttributeName;
  isMultiple?: boolean;
  isDeletable?: boolean;
  showCheckeredBackground?: boolean;
  onUploadCompleted?: () => void;
  sectionConfig: {
    id: string;
    title: string;
    icon?: JSX.Element;
  };
  isPdf?: boolean;
  isImage?: boolean;
  entity: EntityWithAssetsConstraint;
};

export const acceptedAssetFileTypes = {
  image: ['.gif', '.jpg', '.jpeg', '.png', '.svg', '.webp'],
  pdf: ['.pdf'],
};

export type AssetFormStep = 'editing' | 'readonly' | 'uploading' | 'deleting' | 'reordering';

export type AssetFormState = AssetFormProps & {
  step: AssetFormStep;
};
export type AssetFormContextType = AssetFormState & {
  updateContext: (values: Partial<AssetFormState>) => void;
};

export const DefaultAssetFormContextValues: AssetFormState = {
  step: 'readonly',
  attributeName: 'galleryPhotos',
  contentSpaceId: '',
  entityContentId: '',
  assets: [],
  sectionConfig: {
    id: '',
    title: '',
  },
  entity: {
    __typename: '',
    id: '',
    assets: [],
  },
};

export const DefaultAssetFormContext: AssetFormContextType = {
  ...DefaultAssetFormContextValues,
  updateContext: (values: Partial<AssetFormState>) => {},
};
