import React from 'react';
import { useContentSpaceId } from '_lib/auth';
import { LayoutRenderer } from 'lib';
import { autoGenerateBuilderIdFromDisplayName } from 'system/resources/utilities/fieldModifiers';
import { Community, useGetManyCommunities } from 'domain/api/community';
import { FloorPlan, useGetManyFloorPlans } from 'domain/api/floorPlan';
import { ShowHomeInput, useCreateOneShowHome } from 'domain/api/showhome';
import { useUiText } from 'domain/uiText';
import { EntityDialogActionForm } from 'domain/view/components/form/EntityDialogActionForm';

export const CreateShowHomeForm = () => {
  const uiText = useUiText();

  const contentSpaceId = useContentSpaceId();
  const getCommunities = useGetManyCommunities();
  const getFloorPlans = useGetManyFloorPlans();
  const createShowHome = useCreateOneShowHome(uiText.SHOWHOME_PATH);

  React.useEffect(() => {
    if (!getCommunities.called) {
      getCommunities.execute();
    }
    if (!getFloorPlans.called) {
      getFloorPlans.execute();
    }
  }, [getCommunities, getFloorPlans]);

  const handleCreate = (values: ShowHomeInput & { builderId: string }) => {
    const { builderId, ...rest } = values;
    values.builderId &&
      contentSpaceId &&
      createShowHome.execute({
        showHome: rest,
        builderId,
        contentSpaceId,
      });
  };

  return (
    <EntityDialogActionForm
      resourceName="showHome"
      submitting={createShowHome.loading}
      onSubmit={handleCreate}
      fieldModifiers={{
        floorPlanId: (fields) => {
          const selectedFloorPlan = getFloorPlans.result.find(el =>
            el.id === fields.floorPlanId.value
          );
          return {
            fields: {
              ...fields,
              homeType: {
                ...fields.homeType,
                value: selectedFloorPlan ? selectedFloorPlan.homeType : fields.homeType.value,
              },
              bedrooms: {
                ...fields.bedrooms,
                value: selectedFloorPlan?.bedrooms || 0,
              },
              bathrooms: {
                ...fields.bathrooms,
                value: selectedFloorPlan?.bathrooms || 0,
              },
              halfBaths: {
                ...fields.halfBaths,
                value: selectedFloorPlan?.halfBaths || 0,
              },
              floorSize: {
                ...fields.floorSize,
                value: selectedFloorPlan?.floorSize || 0,
              },
            },
          };
        },
        displayName: autoGenerateBuilderIdFromDisplayName,
      }}
      title={uiText.ACTION_NEW_SHOWHOME_LABEL}
      action={{
        type: 'create',
        label: uiText.ACTION_NEW_SHOWHOME_LABEL,
      }}
      includeUntouchedFields={true}
    >
      {renderField => (
        <LayoutRenderer
          items={[
            {
              container: true, direction: 'column', spacing: 2,
              items: [
                {
                  container: true, item: true, spacing: 2,
                  items: [
                    {
                      item: true, xl: 6,
                      renderContent: () => renderField('displayName'),
                    },
                    {
                      item: true, xl: 6,
                      renderContent: () => renderField('builderId'),
                    },
                    {
                      item: true, xl: 6,
                      renderContent: () => renderField('floorPlanId', {
                        options: makeFloorPlanOptions(getFloorPlans.result),
                      }),
                    },
                    {
                      item: true,  xl: 6,
                      renderContent: () =>
                        renderField('communityId', {
                          options: makeCommunityOptions(getCommunities.result),
                        }),
                    },
                    {
                      item: true, xl: 4,
                      renderContent: () => renderField('homeType'),
                    },
                    {
                      item: true, xl: 4,
                      renderContent: () => renderField('floorSize'),
                    },
                    {
                      item: true, xl: 4, sm: 6,
                      renderContent: () => renderField('bedrooms'),
                    },
                    {
                      item: true, xl: 4, sm: 6,
                      renderContent: () => renderField('bathrooms'),
                    },
                    {
                      item: true, xl: 4, sm: 6,
                      renderContent: () => renderField('halfBaths'),
                    },
                    {
                      item: true,
                      xl: 4,
                      md: 6,
                      renderContent: () => renderField('address', { required: false }),
                    },
                    {
                      item: true,
                      xl: 4,
                      md: 6,
                      renderContent: () => renderField('city'),
                    },
                    {
                      item: true,
                      xl: 4,
                      md: 6,
                      renderContent: () => renderField('countrySubDivisionCode'),
                    },
                    {
                      item: true,
                      xl: 6,
                      renderContent: () => renderField('country'),
                    },
                    {
                      item: true,
                      xl: 6,
                      renderContent: () => renderField('postalCode'),
                    },
                  ]
                },
              ]
            }
          ]}
        />
      )}
    </EntityDialogActionForm>
  );
};

const makeFloorPlanOptions = (items: Partial<FloorPlan>[]) => {
  return items.map((item) => ({
    label: item.displayName || '',
    value: item.id || '',
  }));
};

const makeCommunityOptions = (items: Partial<Community>[]) => {
  return items.map((item) => ({
    label: item.displayName || '',
    value: item.id || '',
  }));
};
