import React from 'react';
import { Typography } from '@material-ui/core';
import { SelectOption } from './types';

interface OwnProps {
  value?: SelectOption[] | null;
}

type Props = OwnProps;

export const SelectFieldReadonly = ({ value }: Props) => {
  return (
    <React.Fragment>
      {value && value.length > 0 ? value.map(el => (
        <Typography
          style={{ whiteSpace: 'pre' }}
          key={`select-value-readonly-${el.value}-${el.label}`}
        >
          {el.label || 'N/A'}
        </Typography>
      )) : (
        <Typography style={{ whiteSpace: 'pre' }}>N/A</Typography>
      )}
    </React.Fragment>
  );
};
