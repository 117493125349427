import React from 'react';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { CloudUpload, SwapHoriz } from '@material-ui/icons';
import { CancelIconButton, DeleteIconButton, EditIconButton } from '_lib/components';
import { useUiText } from 'domain/uiText';
import { FormHeader } from '_lib/form/layout';
import { AssetFormStep } from '../types';
import { useAssetFormContext } from '../AssetFormContext';

type Props = {
  actionsDisabled?: boolean;
  additionalActions?: JSX.Element;
};

const wrapWithGridItem = (el: JSX.Element) => (
  <Grid item={true}>{el}</Grid>
);

export const AssetFormPageSectionHeader = (props: Props) => {
  const uiText = useUiText();
  const {
    step, assets, sectionConfig, isMultiple, updateContext, isDeletable,
  } = useAssetFormContext();
  const hasAssets = assets.length > 0;

  const updateStep = (_step: AssetFormStep) => () => updateContext({ step: _step });
  const getActiveStepColor = (_step: AssetFormStep) => _step === step ? 'primary' : undefined;

  const editButton = wrapWithGridItem(
    <EditIconButton disabled={props.actionsDisabled} onEdit={updateStep('editing')} />,
  );

  const cancelButton = wrapWithGridItem(
    <CancelIconButton disabled={props.actionsDisabled} onCancel={updateStep('readonly')} />,
  );

  const uploadButton = wrapWithGridItem(
    <Tooltip title={uiText.ACTION_UPLOAD_LABEL}>
      <IconButton
        onClick={updateStep('uploading')}
        color={getActiveStepColor('uploading')}
        disabled={props.actionsDisabled}
      >
        <CloudUpload />
      </IconButton>
    </Tooltip>,
  );

  const reorderButton = wrapWithGridItem(
    <Tooltip title={uiText.ACTION_REORDER_LABEL}>
      <IconButton
        onClick={updateStep('reordering')}
        color={getActiveStepColor('reordering')}
        disabled={props.actionsDisabled}
      >
        <SwapHoriz />
      </IconButton>
    </Tooltip>,
  );

  const deleteAssetsButton = wrapWithGridItem(
    <DeleteIconButton
      onDelete={updateStep('deleting')}
      tooltip={uiText.ACTION_DELETE_SELECTED_LABEL}
      disabled={props.actionsDisabled}
    />
  );

  return (
    <FormHeader
      title={sectionConfig.title}
      icon={sectionConfig.icon}
      noPadding={isMultiple}
      actions={(
        <React.Fragment>
          {step !== 'editing' && step !== 'deleting' && (
            <React.Fragment>
              {isMultiple && assets.length > 1 && reorderButton}
              {uploadButton}
            </React.Fragment>
          )}
          {step === 'readonly' && hasAssets && editButton}
          {step !== 'readonly' && cancelButton}
          {props.additionalActions}
          {step !== 'deleting' && step !== 'editing'
            && hasAssets && isDeletable && deleteAssetsButton}
        </React.Fragment>
      )}
    />
  );
};
