import {
  createFailedPublishMessage,
  createSuccessfulPublishMessage,
  useSetMessage,
} from '_lib/messages';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { ValidatedEntity } from 'domain/api/common/types';
import { useIdInputMutation } from 'domain/api/common/adapters/useIdInputMutation';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { PUBLISH_ONE_EMBEDDING } from './gql';
import { ApiEmbedding, Embedding } from '../types';
import { transformApiEmbeddingToEmbedding } from '../transforms';

type Response = {
  publishEmbedding: {
    embedding?: ApiEmbedding;
  };
};

export const usePublishOneEmbedding = () => {
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  return useIdInputMutation<Response, ValidatedEntity<Embedding> | undefined>(
    PUBLISH_ONE_EMBEDDING,
    (data) =>
      schemas?.embedding.apiSchema
        ? transformApiEmbeddingToEmbedding(
          validateApiResponseEntity(data?.publishEmbedding.embedding, schemas.embedding.apiSchema)
        )
        : undefined,
    {
      onSuccess: () => setMessage(createSuccessfulPublishMessage('')),
      onError: (err) => setMessage(createFailedPublishMessage('', err)),
    }
  );
};
