import React from 'react';
import { useContentSpaceId } from '_lib/auth';
import {
  useCreateOneImageLink,
  useGetManyImageLinks,
  usePublishOneImageLink,
  useUnpublishOneImageLink,
  useDeleteOneImageLink,
  ImageLinkInput,
} from 'domain/api/imageLink';
import { useResetableAdapterAction } from 'domain/api/common/hooks';
import { useGetManyCommunities } from 'domain/api/community';
import { useGetManyFloorPlans } from 'domain/api/floorPlan';
import { useGetManyShowHomes } from 'domain/api/showhome';
import { useGetManyInventories } from 'domain/api/inventory';
import { useUiText } from 'domain/uiText';
import { useEntityFields } from 'domain/entities/hooks';
import { ListPage } from 'domain/view/pages/ListPage/ListPage';
import { EditBannerForm, CreateBannerForm } from '../../forms';

const useCreateOneBanner = () => useCreateOneImageLink(undefined, true);

export const BannersListPageContainer = () => {
  const uiText = useUiText();
  const { removeDisabledFields } = useEntityFields('banner');
  const contentSpaceId = useContentSpaceId();
  const getImageLinks = useGetManyImageLinks(true);
  const getCommunities = useGetManyCommunities();
  const getFloorPlans = useGetManyFloorPlans();
  const getInventories = useGetManyInventories();
  const getShowHomes = useGetManyShowHomes();
  const publishImageLink = usePublishOneImageLink();
  const unpublishImageLink = useUnpublishOneImageLink();
  const deleteImageLink = useDeleteOneImageLink();
  const createImageLink = useResetableAdapterAction(useCreateOneBanner);

  const get = () => {
    getImageLinks.execute();
    getFloorPlans.execute();
    getCommunities.execute();
    getInventories.execute();
    getShowHomes.execute();
  };

  const handleCreate = (values: ImageLinkInput, close: () => void, alsoPublish?: boolean) => {
    contentSpaceId && createImageLink.execute({
      ...removeDisabledFields(values)
    },
    alsoPublish
    );
  };

  return (
    <ListPage
      resourceName="imageLink"
      get={get}
      actions={{
        publish: (id: string) => publishImageLink.execute({ id }),
        unpublish: (id: string) => unpublishImageLink.execute({ id }),
        delete: (id: string) => deleteImageLink.execute({ id }),
        edit: (data) => (
          <EditBannerForm
            imageLink={data}
            floorPlans={getFloorPlans.result}
            communities={getCommunities.result}
            inventories={getInventories.result}
            showHomes={getShowHomes.result}
          />
        )
      }}
      loading={getImageLinks.loading}
      actionLoading={
        unpublishImageLink.loading || publishImageLink.loading || deleteImageLink.loading
      }
      error={undefined}
      data={getImageLinks.result}
      called={getImageLinks.called}
      hasData={getImageLinks.result.length > 0}
      noDataMessage={uiText.NO_BANNER}
      topbar={{
        title: uiText.BANNER_PLURAL,
        createAction: (
          <CreateBannerForm
            onSubmit={handleCreate}
            submitting={createImageLink.loading}
            submitted={!createImageLink.loading && createImageLink.called}
            floorPlans={getFloorPlans.result}
            communities={getCommunities.result}
            showHomes={getShowHomes.result}
            inventories={getInventories.result}
          />
        ),
      }}
    />
  );
};
