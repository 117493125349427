import React from 'react';
import { useContentSpaceId } from '_lib/auth';
import {
  Inventory,
  useGetOneInventory,
  useUpdateOneInventory,
  usePublishOneInventory,
  useUnpublishOneInventory,
  useDeleteOneInventory,
} from 'domain/api/inventory';
import { useGetManyCommunities } from 'domain/api/community';
import { useGetManyFloorPlans } from 'domain/api/floorPlan';
import { useEntityFields } from 'domain/entities/hooks';
import { useUiText } from 'domain/uiText';
import { useResourceTopbarConfig } from 'domain/view/components/layout/topbar/useResourceTopbarConfig';
import { InventoryDetailPage } from './InventoryDetailPage';

export const InventoryDetailPageContainer = () => {
  const uiText = useUiText();
  const { removeDisabledFields } = useEntityFields('inventory');
  const contentSpaceId = useContentSpaceId();
  const getCommunities = useGetManyCommunities();
  const getFloorPlans = useGetManyFloorPlans();
  const getInventory = useGetOneInventory();
  const updateInventory = useUpdateOneInventory();
  const publishInventory = usePublishOneInventory();
  const unpublishInventory = useUnpublishOneInventory();
  const deleteInventory = useDeleteOneInventory(uiText.INVENTORY_PATH);

  const data = getInventory.result;
  const { id = '', publishedStatus = 'error', validationErrors } = data || {};

  // Fetch required data for the model single page
  const get = (_id: string) => {
    getInventory.execute(_id);
    getCommunities.execute();
    getFloorPlans.execute();
  };

  const handleUpdate = (values: Partial<Inventory>) => {
    if (!id || !contentSpaceId) return;
    updateInventory.execute({
      contentSpaceId,
      idType: 'CONTENT_ID',
      id,
      inventory: removeDisabledFields(values),
    });
  };

  const handlePublish = () => id && publishInventory.execute({ id, idType: 'CONTENT_ID' });

  const handleUnpublish = () => id && unpublishInventory.execute({ id, idType: 'CONTENT_ID' });

  const handleDelete = () => id && deleteInventory.execute({ id, idType: 'CONTENT_ID' });

  const handleRefetch = () => getInventory.refetch?.(id);

  /**
   * @todo
   * Refactor this when/if we refactor the resoure
   */
  const topbarConfig = useResourceTopbarConfig(
    'inventory',
    publishedStatus,
    handleDelete,
    handlePublish,
    handleUnpublish,
    { ...data, id }
  );

  const loading =
    getInventory.loading ||
    updateInventory.loading ||
    deleteInventory.loading ||
    publishInventory.loading ||
    unpublishInventory.loading;

  return (
    <InventoryDetailPage
      get={get}
      handleUpdate={handleUpdate}
      handleRefetch={handleRefetch}
      loading={loading}
      called={getInventory.called}
      publishedStatus={publishedStatus}
      data={data}
      validation={{ errors: validationErrors }}
      topbar={topbarConfig}
      contentSpaceId={contentSpaceId || ''}
      floorPlans={getFloorPlans.result}
      communities={getCommunities.result}
    />
  );
};
