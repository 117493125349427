import React from 'react';
import { useContentSpaceId } from '_lib/auth';
import { createFailedUpdateMessage, createSuccessfulUpdateMessage, useSetMessage } from '_lib/messages';
import { ValidatedEntity } from 'domain/api/common/types';
import { getContentEntriesToAddAndRemove } from 'domain/api/common/transforms';
import { Embedding } from '../types';
import { useAddEmbeddingMappingsMutation } from '../addMappings';
import { useUpdateOneEmbeddingInfo } from '../updateInfo';
import { useRemoveEmbeddingMappingsMutation } from '../removeMappings';

export const useUpdateOneEmbedding = (embedding: Embedding) => {
  const contentSpaceId = useContentSpaceId();
  const setMessage = useSetMessage();
  const [loading, setLoading] = React.useState(false);
  const [result, setResult] = React.useState<ValidatedEntity<Embedding> | undefined>();
  const addMappings = useAddEmbeddingMappingsMutation();
  const removeMappings = useRemoveEmbeddingMappingsMutation();
  const updateInfo = useUpdateOneEmbeddingInfo();

  const execute = (input: Embedding): Promise<void> => {
    return (async () => {
      try {
        if (!contentSpaceId) throw new Error('Failed to update');
        setLoading(true);

        const { toAdd, toRemove } = getContentEntriesToAddAndRemove(embedding, input);
        const updateInfoResult = await updateInfo.execute({ ...input, contentSpaceId });


        if (!updateInfoResult) throw new Error('Failed to update');
        setResult(updateInfoResult);

        const addMappingsResult =
          toAdd.length > 0
            ? await addMappings.execute({
              contentSpaceId,
              embeddingId: input.id,
              contentEntries: toAdd,
            })
            : undefined;

        if (!addMappingsResult && toAdd.length > 0) throw new Error('Failed to update');
        setResult(addMappingsResult);

        const removeMappingsResult =
          toRemove.length > 0
            ? await removeMappings.execute({
              contentSpaceId,
              embeddingId: input.id,
              contentEntries: toRemove,
            })
            : undefined;

        if (!removeMappingsResult && toRemove.length > 0) throw new Error('Failed to update');
        setResult(removeMappingsResult);
        setMessage(createSuccessfulUpdateMessage(''));
        setLoading(false);
        return;
      } catch (error) {
        setMessage(createFailedUpdateMessage('', ''));
        setLoading(false);
      }
    })();
  };

  return {
    execute,
    result,
    loading,
    called: addMappings.called || removeMappings.called || updateInfo.called,
    error: addMappings.error || removeMappings.error || updateInfo.error,
  };
};
