import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useUiText } from 'domain/uiText';
import { Demostats, Distribution, Summary } from './types';
import { DougnutChart } from './DougnutChart';

interface Props {
  demostats: Demostats | null | undefined;
}

const HEIGHT = 500;

export const DemostatsCharts = ({ demostats }: Props) => {
  const classes = useStyles();
  const uiText = useUiText();
  if (demostats === undefined) {
    return <Skeleton variant="rect" height={HEIGHT} />;
  }
  if (demostats === null) {
    return (
      <div className={classes.scrollBox}>
        <Typography>{uiText.NO_DEMOSTATS}</Typography>
      </div>
    );
  }
  return (
    <div className={classes.scrollBox}>
      {demostats.summaries.map((summary: Summary) => (
        <div key={summary.variable}>
          <Typography variant="h6">{summary.description}</Typography>
          <Typography variant="body1" gutterBottom>{summary.value}</Typography>
        </div>
      ))}
      {demostats.distributions.map((distribution: Distribution) => (
        <div key={distribution.category}>
          <Typography variant="h6">{distribution.description}</Typography>
          <DougnutChart data={distribution.data} />
        </div>
      ))}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  scrollBox: {
    height: HEIGHT,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));
