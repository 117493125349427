import React from 'react';
import { Button } from '@material-ui/core';
import { useBaseUiText } from '_lib/uiText';

interface Props {
  onCancel?: () => void;
  disabled?: boolean;
}

export const CancelButton = React.memo(({
  disabled,
  onCancel: onClear,
}: Props) => {
  const uiText = useBaseUiText();
  return (
    <Button
      onClick={onClear}
      disabled={disabled}
      variant="outlined"
    >
      {uiText.ACTION_CANCEL_LABEL}
    </Button>
  );
});
