import {
  createFailedUnpublishMessage,
  createSuccessfulUnpublishMessage,
  useSetMessage,
} from '_lib/messages';
import { useIdInputMutation } from 'domain/api/common/adapters/useIdInputMutation';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { ValidatedEntity } from 'domain/api/common/types';
import { UNPUBLISH_ONE_FLOOR_PLAN } from './gql';
import { ApiFloorPlan, FloorPlan } from '../types';
import { transformApiFloorPlanToFloorPlan } from '../transforms';

type Response = {
  unpublishFloorPlan: {
    floorPlan?: ApiFloorPlan;
  };
};

export const useUnpublishOneFloorPlan = () => {
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  return useIdInputMutation<Response, ValidatedEntity<FloorPlan> | undefined>(
    UNPUBLISH_ONE_FLOOR_PLAN,
    (data) =>
      schemas?.floorPlan.apiSchema
        ? transformApiFloorPlanToFloorPlan(
          validateApiResponseEntity(
            data?.unpublishFloorPlan.floorPlan,
            schemas.floorPlan.apiSchema
          )
        )
        : undefined,
    {
      onSuccess: () => setMessage(createSuccessfulUnpublishMessage('')),
      onError: (err) => setMessage(createFailedUnpublishMessage('', err)),
    }
  );
};
