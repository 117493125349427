import React from 'react';
import { useAuth } from '_lib/auth';
import { sendEvent } from 'utilities/googleAnalytics/googleAnalytics';
import { NavList } from 'domain/routes/NavList';
import { SidebarLayout } from '../components/layout/sidebar/SidebarLayout';
import { AppSidebar } from '../components/layout/sidebar/AppSidebar';
import { AuthFooter } from '../components/layout/sidebar/AuthFooter';

interface Props {
  children: React.ReactNode;
}

export const AuthLayout = ({ children }: Props) => {
  const { user, logout } = useAuth();


  if (user?.contentSpaceId && user?.email) {
    sendEvent('login', {
      contentSpaceId: user.contentSpaceId,
      email: user.email,
    });
  }

  const footer = (user && <AuthFooter user={user} logout={logout} />) || null;
  const sidebar = (
    <AppSidebar footer={footer}>
      <NavList />
    </AppSidebar>
  );
  return (
    <SidebarLayout sidebar={sidebar}>
      {children}
    </SidebarLayout>
  );
};
