import { CHECK_COMMUNITY_DISPLAY_NAME } from './gql';
import { useCheckDisplayName } from '../../common/adapters/useCheckDisplayName';

type Response = {
  contentSpace: {
    communityDisplayNameAvailable: boolean;
  };
};

export const useCheckCommunityDisplayName = () => {
  return useCheckDisplayName<Response>(
    CHECK_COMMUNITY_DISPLAY_NAME,
    (response) => response?.contentSpace.communityDisplayNameAvailable,
  );
};
