import * as yup from 'yup';
import { UiText } from 'domain/uiText/uiText';
import { pickCommonApiValidationSchemas } from '../common/validation';
import { ApiFloorPlan, ApiFloorPlanInput } from './types';
import { apiEmbeddingValidationSchema } from '../embedding/validation';
import { apiAssetValidationSchema } from '../asset/validation';
import { apiImageLinkValidationSchema } from '../imageLink/validation';
import { apiFeatureTagValidationSchema } from '../featureTag/validation';
import { apiFloorPlanLayoutValidationSchema } from '../floorPlanLayouts';

export const apiFloorPlanInputValidationSchema = (uiText: UiText) => {
  return yup
    .object<ApiFloorPlanInput, object>({
      ...pickCommonApiValidationSchemas(uiText)([
        'displayName',
        'seoTitleTag',
        'seoMetaKeywords',
        'seoMetaDescription',
        'displayPriority',
        'halfBaths',
        'homeType',
        'bathrooms',
        'bedrooms',
        'floorSize',
        'showMonthlyPaymentCalculator',
      ]),
      communityPricing: yup
        .array()
        .of(
          yup
            .object({
              id: yup.string(),
              idType: yup.string(),
              price: yup.number(),
            })
            .defined()
        )
        .label(`${uiText.COMMUNITY_PLURAL} & Pricing`),
    })
    .defined();
};

export const apiFloorPlanValidationSchema = (uiText: UiText) =>
  yup
    .object<ApiFloorPlan, object>({
      ...pickCommonApiValidationSchemas(uiText)([
        'id',
        'builderId',
        'published',
        'publishProblems',
        'displayName',
        'seoTitleTag',
        'seoMetaKeywords',
        'seoMetaDescription',
        'displayPriority',
        'homeType',
        'showMonthlyPaymentCalculator',
      ]),
      communityMappings: yup
        .array()
        .of(
          yup
            .object({
              community: yup
                .object({
                  id: yup.string().defined().default(''),
                  displayName: yup.string().defined().default('').label('Name'),
                })
                .defined(),
              price: yup.number().defined().default(0),
            })
            .defined()
        )
        .defined()
        .label(`${uiText.COMMUNITY_PLURAL} & Pricing`),
      floorPlanCategory: yup
        .object({
          id: yup.string().defined().default(''),
          name: yup.string().defined().default(''),
          priority: yup.number().defined().default(0),
        })
        .defined()
        .nullable()
        .default(null)
        .label('Category'),
      price: yup.number().defined().nullable().default(null),
      assets: yup
        .array()
        .of(apiAssetValidationSchema)
        .test(
          'hasListingMainImage',
          'Main image is required',
          (value) => !!value?.find((el) => el.attributeName === 'listingMainImage')
        )
        .defined()
        .default([]),
      floorPlanLayouts: yup
        .array()
        .of(apiFloorPlanLayoutValidationSchema(uiText))
        .defined()
        .default([]),
      imageLinks: yup.array().of(apiImageLinkValidationSchema(uiText, true)).defined().default([]),
      embeddings: yup.array().of(apiEmbeddingValidationSchema(uiText)).defined().default([]),
      featureTags: yup.array().of(apiFeatureTagValidationSchema).defined().default([]),
    })
    .defined();
