import React from 'react';
import { InputBase, makeStyles, Theme } from '@material-ui/core';
import { KeyOf, ValueOf } from 'utilities/types';

type Props<T> = {
  data: T[];
  primaryDataKey: KeyOf<T>;
  dataKeys: KeyOf<T>[];
  onChange: (ids: string[]) => void;
};

type SearchableData<T> = {
  [x: string]: ValueOf<T>;
};

// Creates a key value pair for every entry where the key is a string containing
// all of the property values of that entry, and the value is the primaryKey of that entry
function transformDataToSearchableData<T>(
  data: T[],
  primaryDataKey: KeyOf<T>,
  dataKeys: KeyOf<T>[],
) {
  const searchableData: SearchableData<T> = {};
  data.forEach(entry => {
    const primaryKey = entry[primaryDataKey];
    const values: string[] = [];
    if (typeof entry === 'object') {
      dataKeys.forEach(key => {
        const value = entry[key];
        if (typeof value === 'string') {
          values.push(value);
        }
      });
      if (typeof primaryKey === 'string') {
        values.push(primaryKey);
      }
      const key = values.join('');
      searchableData[key] = primaryKey;
    }
  });
  return searchableData;
}


function filterSearchableData<T>(data: SearchableData<T>, value: string) {
  return Object.keys(data).filter(key =>
    key.toLocaleLowerCase().includes(value.toLocaleLowerCase())).map(key => data[key],
  );
}

export function ResultsSearch<T>(props: Props<T>) {
  const { onChange, data, primaryDataKey, dataKeys } = props;
  const classes = useStyles();
  // Storing the searchable data in a ref since this will not be mutated manually
  const searchableDataRef = React.useRef<SearchableData<T> | null>(null);
  const [state, setState] = React.useState('');

  // Todo: debounce/throttle this to prevent excessive updates
  React.useEffect(() => {
    searchableDataRef.current = transformDataToSearchableData(
      data, primaryDataKey, dataKeys,
    );
  }, [data, primaryDataKey, dataKeys]);

  const handleChange = React.useCallback((e: React.BaseSyntheticEvent) => {
    setState(e.target.value);
  }, []);

  React.useEffect(() => {
    if (searchableDataRef.current !== null) {
      onChange(filterSearchableData(searchableDataRef.current, state) as any);
    }
  }, [state, onChange]);

  return (
    <InputBase
      onChange={handleChange}
      value={state}
      className={classes.container}
      placeholder="Search..."
    />
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: `solid 1px ${theme.palette.grey[400]}`,
    width: 250,
    borderRadius: 4,
    fontSize: theme.typography.fontSize,
    paddingLeft: theme.spacing(1),
  },
}));
