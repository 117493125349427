import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Topbar } from './Topbar';
import { TopbarTitle } from './TopbarTitle';

export interface Props {
  title: string,
  actions?: React.ReactNode,
}

export const ListPageTopbar = ({ title, actions }: Props) => {
  const classes = useStyles();
  return (
    <Topbar
      left={
        <div className={classes.titleMargin}>
          <TopbarTitle title={title} />
        </div>
      }
      right={
        <div className={classes.actionsMargin}>
          {actions}
        </div>
      }
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  titleMargin: {
    marginLeft: theme.spacing(3),
  },
  actionsMargin: {
    marginRight: theme.spacing(3),
  },
}));
