import React from 'react';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { ErrorPageProps } from '_lib/error';

export const ErrorPage = ({ error }: ErrorPageProps) => {
  const classes = useStyles();
  const wrapHelperText = (content: React.ReactNode) => (
    <Typography
      color="textSecondary"
      variant="caption"
      align="center"
      display="block"
      className={classes.helperText}
    >
      {content}
    </Typography>
  );
  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      wrap="nowrap"
      className={classes.errorPage}
    >
      <Grid
        item
        container 
        alignItems="center"
        justify="center"
        direction="column"
        wrap="nowrap"
        className={classes.messageArea}
      >
        <Typography
          variant="h4"
          gutterBottom={true}
          align="center"
        >
          {error || 'Something went wrong.'}
        </Typography>
        {wrapHelperText('Try Reloading the page.')}
        {wrapHelperText('If the problem persists, please contact support.')}
      </Grid>
      <Grid item>
        <img
          src={`${process.env.PUBLIC_URL}/android-chrome-192x192.png`}
          alt="OpenhouseHouse.ai logo 192x192"
          className={classes.logo}
          width="64"
          height="64"
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  errorPage: {
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.grey[100],
  },
  messageArea: {
    flexGrow: 2,
    maxWidth: 400,
    width: '100%',
    padding: theme.spacing(2),
  },
  helperText: {
    margin: `0px ${theme.spacing(4)}px`,
  },
  logo: {
    padding: theme.spacing(4),
  }
}));
