import { createFailedUnpublishMessage, createSuccessfulUnpublishMessage, useSetMessage } from '_lib/messages';
import { useIdInputMutation } from 'domain/api/common/adapters/useIdInputMutation';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { ValidatedEntity } from 'domain/api/common/types';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { UNPUBLISH_ONE_SHOW_HOME } from './gql';
import { ApiShowHome, ShowHome } from '../types';
import { transformApiShowHomeToShowHome } from '../transforms';

type Response = {
  unpublishShowHome: {
    showHome?: ApiShowHome;
  };
};

export const useUnpublishOneShowHome = () => {
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  return useIdInputMutation<Response, ValidatedEntity<ShowHome> | undefined>(
    UNPUBLISH_ONE_SHOW_HOME,
    (data) => schemas?.showHome.apiSchema ? transformApiShowHomeToShowHome(
      validateApiResponseEntity(
        data?.unpublishShowHome.showHome,
        schemas.showHome.apiSchema,
      ),
    ) : undefined,
    {
      onSuccess: () => setMessage(createSuccessfulUnpublishMessage('')),
      onError: (err) => setMessage(createFailedUnpublishMessage('', err)),
    }
  );
};
