import { gql } from '@apollo/client';
import { AllAssetInfo } from '../fragments';

export const GET_ONE_ASSET = gql`
query getOneAsset($contentSpaceId: ID!, $id: ID!) {
  contentSpace(id: $contentSpaceId) {
    asset(id: $id)  {
      ${AllAssetInfo}
    }
  }
}
`;
