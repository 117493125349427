import React from 'react';
import { Typography } from '@material-ui/core';

interface OwnProps {
  value?: string;
}

type Props = OwnProps;

export const TextFieldReadonly = ({ value }: Props) => (
  <Typography style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>{value || 'N/A'}</Typography>
);
