import * as yup from 'yup';
import { UiText } from 'domain/uiText';
import { EmbeddingFields } from './types';

const sharedSchemas = (uiText: UiText) => ({
  displayName: yup.string().defined().required().default('').label('Name'),
  iframeUrl: yup.string().defined().required().default('').label('URL'),
});

export const embeddingFieldsSchema = (uiText: UiText) => yup.object<EmbeddingFields>({
  ...sharedSchemas(uiText),
  vendorId: yup.string().defined().default('').label('Vendor'),
  floorPlans:
    yup.array().of(yup.string().defined()).compact().defined().default([])
      .label(uiText.FLOOR_PLAN_PLURAL),
  communities:
    yup.array().of(yup.string().defined()).compact().defined().default([])
      .label(uiText.COMMUNITY_PLURAL),
  inventories:
    yup.array().of(yup.string().defined()).compact().defined().default([])
      .label(uiText.INVENTORY_PLURAL),
  showHomes:
    yup.array().of(yup.string().defined()).compact().defined().default([])
      .label(uiText.SHOWHOME_PLURAL),
}).defined();
