import React from 'react';
import { useFormState } from '_lib/multiFormSemaphore';
import { getAssetsByAttributeName } from 'domain/api/asset';
import { AssetFormProps, AssetFormState, DefaultAssetFormContextValues } from './types';
import { AssetFormPageSection } from './components/AssetFormPageSection';
import { AssetFormDialog } from './components/AssetFormDialog';
import { AssetFormContext } from './AssetFormContext';

export const AssetForm = ({
  entity,
  assets,
  isImage = true,
  isDeletable = true,
  showCheckeredBackground,
  sectionConfig,
  ...rest
}: AssetFormProps) => {
  const [state, setState] = React.useState<AssetFormState>(DefaultAssetFormContextValues);
  const { editing, enabled, openForm, closeForm } = useFormState(sectionConfig.id);

  const entityId = state?.entity.id;
  React.useEffect(() => {
    const assetsByAttributeName = getAssetsByAttributeName(rest.attributeName, assets).sort(
      (a, b) => Number(a.displayOrder) - Number(b.displayOrder)
    );

    if (entity.id !== entityId) {
      setState({
        ...rest,
        sectionConfig,
        entity,
        isImage,
        isDeletable,
        assets: assetsByAttributeName,
        step: 'readonly',
      });
    }
    if (JSON.stringify(assetsByAttributeName) !== JSON.stringify(state.assets)) {
      setState((prev) => ({ ...prev, assets: assetsByAttributeName }));
    }
  }, [entity, assets, isImage, isDeletable, rest, entityId, state.assets, sectionConfig]);

  React.useEffect(() => {
    if (state.step !== 'readonly' && !editing) {
      openForm();
    }
    if (state.step === 'readonly' && editing) {
      closeForm();
    }
  }, [state, editing, closeForm, openForm]);

  const updateContext = (value: Partial<AssetFormState>) =>
    setState((prev) => ({ ...prev, ...value }));
  const editDisabled = !enabled;

  return (
    <AssetFormContext.Provider value={{ ...state, updateContext }}>
      <AssetFormPageSection
        isMultiple={state.isMultiple}
        id={state.sectionConfig.id}
        step={state.step}
        editDisabled={editDisabled}
        showCheckeredBackground={showCheckeredBackground}
      />
      <AssetFormDialog />
    </AssetFormContext.Provider>
  );
};
