import { gql } from '@apollo/client';
import { AllEmbeddingInfo } from '../fragments';

export const REMOVE_EMBEDDING_CONTENT_ENTRIES = gql`
fragment RemoveEmbeddingContentEntriesInfo on Embedding {
  ${AllEmbeddingInfo}
}

mutation removeEmbeddingContentEntries($input: RemoveEmbeddingContentEntriesInput) {
  removeEmbeddingContentEntries(input: $input) {
    embedding {
      ...RemoveEmbeddingContentEntriesInfo
    }
  }
}
`;
