import { useContentSpaceId } from '_lib/auth';
import { ValidatedEntity, WithClearList } from 'domain/api/common/types';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { generateCreateOrUpdateString } from 'domain/api/common/fragments';
import { useCreateOrUpdateOne } from 'domain/api/common/adapters/useCreateOrUpdateOne';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { transformClearableFieldsToClearList } from 'domain/api/common/transforms';
import { ApiImageLink, ApiImageLinkInput, CLEARABLE_IMAGE_LINK_FIELDS, ImageLink } from '../types';
import { AllImageLinkInfo } from '../fragments';
import {
  transformApiImageLinkToImageLink,
  transformImageLinkToApiImageLinkInput,
} from '../transforms';

const UPDATE_ONE_EMBEDDING_INFO = generateCreateOrUpdateString(
  'update',
  'imageLink',
  AllImageLinkInfo
);

type Response = {
  updateImageLink: {
    imageLink: ApiImageLink | undefined;
  };
};

type Input<T> = T & {
  contentSpaceId: string;
};

export const useUpdateOneImageLinkInfo = () => {
  const schemas = useApiSchemas();
  const contentSpaceId = useContentSpaceId();

  const { execute: executeMutation, ...rest } = useCreateOrUpdateOne<
  WithClearList<ApiImageLinkInput, Input<ApiImageLinkInput & { id: string }>>,
  Response,
  ValidatedEntity<ImageLink> | undefined
  >(UPDATE_ONE_EMBEDDING_INFO, (data) =>
    schemas?.imageLink.apiSchema
      ? transformApiImageLinkToImageLink(
        validateApiResponseEntity(data?.updateImageLink.imageLink, schemas.imageLink.apiSchema)
      )
      : undefined
  );

  const execute = async (input: Input<Partial<ImageLink>> & { id: string }) => {
    try {
      if (!contentSpaceId) return undefined;
      const transformed = transformImageLinkToApiImageLinkInput(input);
      return await executeMutation({
        contentSpaceId,
        ...transformed,
        id: input.id,
        clear: transformClearableFieldsToClearList(transformed, CLEARABLE_IMAGE_LINK_FIELDS),
      });
    } catch (error) {
      return error;
    }
  };

  return { execute, ...rest };
};
