import { gql } from '@apollo/client';

export const CREATE_ONE_ASSET = gql`
fragment CreateAssetInfo on Asset {
  id
  displayName
}
mutation createAsset($input: CreateAssetInput!) {
  createAsset(input: $input)
    @rest(
      type: "Asset"
      path: ""
      method: "POST"
      bodySerializer: "fileEncode"
    ) {
      asset {
        ...CreateAssetInfo
      }
  }
}
`;
