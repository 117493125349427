import { compact } from 'lodash';
import { combineDescriptionBodyAndBullets, createPublishedStatus, splitSeoMetaDescription } from '../common/transforms';
import { ValidatedEntity } from '../common/types';
import { transformApiEmbeddingsToEmbeddings } from '../embedding/transforms';
import { transformApiFeatureTagsToFeatureTags } from '../featureTag/transforms';
import { transformApiImageLinksToImageLinks } from '../imageLink/transforms';
import { ApiInventory, ApiInventoryInput, InventoryInput, Inventory } from './types';

export const transformInventoryToApiInventoryInput = (
  data: Partial<Omit<InventoryInput, 'builderId'>>,
): ApiInventoryInput => ({
  displayName: data.displayName,
  seoMetaKeywords: data.seoMetaKeywords,
  seoTitleTag: data.seoTitleTag,
  showMonthlyPaymentCalculator: data.showMonthlyPaymentCalculator,
  showCommuteCalculation: data.showCommuteCalculation,
  homeType: data.homeType,
  bedrooms: data.bedrooms,
  bathrooms: data.bathrooms,
  halfBaths: data.halfBaths,
  floorSize: data.floorSize,
  garage: data.garage,
  address: data.address,
  city: data.city,
  country: data.country,
  countrySubDivisionCode: data.countrySubDivisionCode,
  postalCode: data.postalCode,
  community: data.communityId !== undefined ? {
    id: data.communityId,
  } : undefined,
  floorPlan: data.floorPlanId !== undefined ? {
    id: data.floorPlanId,
  } : undefined,
  featureTags: data.featureTags?.map(tag => tag.id),
  locationVisualizerLat: data.lat,
  locationVisualizerLon: data.lng,
  possessionDateSec: data.possessionDateSec,
  price: data.price,
  priceReduced: data.priceReduced,
  priceReducedFrom: data.priceReducedFrom,
  status: data.status !== undefined ? {
    value: data.status,
  } : undefined,
  ...combineDescriptionBodyAndBullets(data.seoDescriptionBody, data.seoDescriptionBullets),
});

export const transformApiInventoryToInventory = (
  data?: ValidatedEntity<ApiInventory>,
): ValidatedEntity<Inventory> | undefined => data ? ({
  id: data.id,
  builderId: data.builderId,
  published: data.published,
  displayName: data.displayName,
  seoMetaKeywords: data.seoMetaKeywords,
  seoTitleTag: data.seoTitleTag,
  showMonthlyPaymentCalculator: data.showMonthlyPaymentCalculator,
  showCommuteCalculation: data.showCommuteCalculation,
  homeType: data.homeType,
  bedrooms: data.bedrooms,
  bathrooms: data.bathrooms,
  halfBaths: data.halfBaths,
  floorSize: data.floorSize,
  garage: data.garage,
  address: data.address?.address || null,
  fullAddress: data.address?.fullAddress?.replace(', , ,', '') || null,
  city: data.address?.city || null,
  country: data.address?.country || null,
  countrySubDivisionCode: data.address?.countrySubDivisionCode || null,
  postalCode: data.address?.postalCode || null,
  community: data.community,
  floorPlan: data.floorPlan,
  lat: data.locationVisualizerLat,
  lng: data.locationVisualizerLon,
  possessionDateSec: data.possessionDate || null,
  price: data.price,
  priceReduced: data.priceReduced,
  priceReducedFrom: data.priceReducedFrom,
  status: data.status,
  assets: data.assets,
  embeddings: transformApiEmbeddingsToEmbeddings(data.embeddings),
  imageLinks: transformApiImageLinksToImageLinks(data.imageLinks),
  featureTags: transformApiFeatureTagsToFeatureTags(data.featureTags),

  publishedStatus: createPublishedStatus(data),
  validationErrors: data.validationErrors,

  ...splitSeoMetaDescription(data.seoMetaDescription),
}) : undefined;

export const transformApiInventoriesToInventories = (
  data?: ValidatedEntity<ApiInventory>[],
) => data ? compact(data.map(el => transformApiInventoryToInventory(el))) : [];
