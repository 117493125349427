import { AssetAttributeName } from 'domain/api/asset';
import { useUiText } from 'domain/uiText';
import { acceptedAssetFileTypes } from './types';

export const isPdf = (url: string): boolean => url.toLowerCase().trim().endsWith('.pdf');

export const generateAcceptedFileTypes = (isImage: boolean, _isPdf: boolean) =>
  [
    ...(isImage ? acceptedAssetFileTypes.image : []),
    ...(_isPdf ? acceptedAssetFileTypes.pdf : []),
  ].join(',');

export const useImageHelp = (attributeName: AssetAttributeName): string => {
  const uiText = useUiText();
  switch (attributeName) {
    case 'listingMainImage':
      return uiText.HELP_IMAGE_MAIN;
    case 'galleryPhotos':
      return uiText.HELP_IMAGE_GALLERY;
    case 'floorPlanImages':
      return uiText.HELP_IMAGE_FLOOR_PLAN;
    case 'sitemapImage':
      return uiText.HELP_IMAGE_LOT_MAP;
    case 'sloganImage':
      return uiText.HELP_IMAGE_SLOGAN;
    case 'thumbnailImage':
      return uiText.HELP_IMAGE_THUMBNAIL;
    case 'logoImage':
      return uiText.HELP_IMAGE_LOGO;
    case 'bannerImage':
      return uiText.HELP_IMAGE_BANNER;
    case 'attachmentImage':
      return uiText.HELP_IMAGE_ATTACHMENT;
    default:
      return '';
  }
};
