import { FeatureTagCategoryInfo } from '../featureTagCategory/fragments';

export const FeatureTagInfo = `
  id
  name
  description
  showFloorPlanViewer
  featureTagCategory {
    ${FeatureTagCategoryInfo}
  }
`;
