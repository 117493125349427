export type ApiContact = {
  id: string;
  name: string;
  email: string | null;
  title: string | null;
  phone: string | null;
  salesCentres: { id: string; name: string }[];
};

export type ApiContactInput = Partial<Pick<ApiContact, 'name' | 'email' | 'title' | 'phone'> & {
  salesCentres: string[];
}>;

export const CLEARABLE_CONTACT_FIELDS: (keyof ApiContactInput & string)[] = [
  'email',
  'title',
  'phone',
];

export type Contact = ApiContact;
