import { PublishProblemsFragment } from 'domain/api/common/fragments';
import { AllSalesCentreInfo } from '../salesCentre/fragments';
import { AllEmbeddingInfo } from '../embedding/fragments';
import { AllAssetInfo } from '../asset/fragments';
import { AllImageLinkInfo } from '../imageLink/fragments';

export const ShortCommunityInfo = `
  id
  builderId
  displayName
  communityType
  published
  ${PublishProblemsFragment}
`;

export const AllCommunityInfo = `
  id
  displayName
  builderId
  published
  communityType
  showCommunityStats
  showCommuteCalculation
  displayPriority
  startingPrices {
    name
    price
  }
  communityGeoPointLat
  communityGeoPointLon
  seoTitleTag
  seoMetaDescription
  seoMetaKeywords
  lotmapLink
  lotmapText
  communityGroup {
    name
  }
  floorPlanMappings {
    price
    floorPlan {
      builderId
      displayName
    }
  }

  embeddings {
    ${AllEmbeddingInfo}
  }
  imageLinks {
    ${AllImageLinkInfo}
  }
  salesCentres {
    ${AllSalesCentreInfo}
  }
  assets {
    ${AllAssetInfo}
  }
  ${PublishProblemsFragment}
`;
