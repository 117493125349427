import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Switch,
  TextField,
} from '@material-ui/core';
import { CancelButton, SaveButton } from '_lib/components';
import { FeatureTag } from 'domain/api/featureTag';
import { useUiText } from 'domain/uiText';
import { useAllFeatureTagCategories, useCreateFeatureTagAndAdd } from './hooks';

interface Props {
  newTagName?: string | null;
  onAddTag: (tag: FeatureTag) => void;
  onClose: () => void;
}

export const CreateFeatureTagDialog = ({ newTagName, onAddTag, onClose }: Props) => {
  const uiText = useUiText();
  const { createFeatureTagAndAdd } = useCreateFeatureTagAndAdd(onAddTag);
  const { allCategories } = useAllFeatureTagCategories();

  const [description, setDescription] = React.useState('');
  const [categoryId, setCategoryId] = React.useState('');
  const [showFloorPlan, setShowFloorPlan] = React.useState(false);
  React.useEffect(() => {
    if (!newTagName) {
      setDescription('');
      setCategoryId('');
      setShowFloorPlan(false);
    }
  }, [newTagName])

  const ready = newTagName && description && categoryId;
  const handleSave = () => {
    if (newTagName && description && categoryId) {
      createFeatureTagAndAdd(newTagName, description, categoryId, showFloorPlan);
    }
    onClose();
  }
  return (
    <Dialog open={!!newTagName} onClose={onClose} fullWidth>
      <DialogTitle>
        {`${uiText.ACTION_CREATE_LABEL} "${newTagName}" ${uiText.FEATURE_TAG_SINGLULAR.toLowerCase()}`}
      </DialogTitle>
      <DialogContent>
        <TextField
          id="new-feature-description"
          label={uiText.LABEL_DESCRIPTION}
          value={description}
          onChange={event => setDescription(event.target.value)}
          margin="normal"
          autoFocus
          fullWidth
        />
        <TextField
          id="new-feature-category"
          label={uiText.LABEL_CATEGORY}
          value={categoryId}
          onChange={(event: any) => setCategoryId(event.target.value)}
          margin="normal"
          select
          fullWidth
        >
          {allCategories.map(cat => (
            <MenuItem key={cat.id} value={cat.id}>{cat.displayName}</MenuItem>
          ))}
        </TextField>
        <FormControlLabel
          control={
            <Switch
              checked={showFloorPlan}
              onChange={(_, checked) => setShowFloorPlan(checked)}
              color="primary"
            />
          }
          label={uiText.LABEL_SHOW_FLOOR_PLAN_VIEWER}
        />
        <FormHelperText>{uiText.HELP_FEATURE_TAG_SHOW_FLOOR_PLAN_VIEWER}</FormHelperText>
      </DialogContent>
      <DialogActions>
        <CancelButton onCancel={onClose} />
        <SaveButton onSave={handleSave} disabled={!ready} />
      </DialogActions>
    </Dialog>
  );
}
