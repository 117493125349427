import { ApolloCache, DocumentNode, PureQueryOptions, useMutation } from '@apollo/client';
import { AdapterActionWithPromise } from 'domain/api/common/adapters/types';
import { MutationWithInput, ValidatedEntity } from 'domain/api/common/types';

export type CreateOrUpdateOneOptions<
  Response extends object | undefined,
  Result extends object | undefined
> = {
  onSuccess?: (result?: Result) => void;
  onError?: (err: string) => void;
  updateCache?: (cache: ApolloCache<Response>, data?: Response | null) => void;
  refetchQueries?: PureQueryOptions[];
};

/**
 * @description
 * A hook that encapsulates functionality for create/update operations.
 * Used in *most* entities, but more complex create/update operations
 * may implement their own(example: imageLinks).
 */
export function useCreateOrUpdateOne<
  Input extends object,
  Response extends object | undefined,
  Result extends object | undefined
>(
  query: DocumentNode,
  validator: (data?: Response | null) => ValidatedEntity<Result> | undefined,
  options?: CreateOrUpdateOneOptions<Response, Result>
): AdapterActionWithPromise<Input, ValidatedEntity<Result> | undefined, Response> {
  const [mutation, mutationResult] = useMutation<Response, MutationWithInput<Input>>(query, {
    awaitRefetchQueries: true,
    refetchQueries: options?.refetchQueries,
    update: options?.updateCache
      ? (cache, { data }) => {
          options.updateCache && options.updateCache(cache, data);
        }
      : undefined,
  });

  const execute = async (input: Input): Promise<ValidatedEntity<Result> | undefined> => {
    try {
      const result = await mutation({ variables: { input } });

      const validated = validator(result.data);
      if (options?.onSuccess) options.onSuccess(validated);

      return validated;
    } catch (error) {
      if (options?.onError) options.onError(error);
      return error;
    }
  };

  return {
    execute,
    result: validator(mutationResult.data),
    loading: mutationResult.loading,
    called: mutationResult.called,
    error: mutationResult.error?.message,
    response: mutationResult.data,
  };
}
