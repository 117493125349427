import React from 'react';
import { useContentSpaceId } from '_lib/auth';
import {
  Community,
  useGetManyCommunities,
  usePublishOneCommunity,
  useUnpublishOneCommunity,
  useDeleteOneCommunity,
  useCreateOneCommunity,
} from 'domain/api/community';
import { useUiText } from 'domain/uiText';
import { ListPage } from 'domain/view/pages/ListPage/ListPage';
import { useConfig } from 'domain/config';
import { CreateCommunityForm } from '../../forms/CreateCommunityForm';

export const CommunitiesListPageContainer = () => {
  const uiText = useUiText();
  const getCommunities = useGetManyCommunities();
  const publishCommunity = usePublishOneCommunity();
  const unpublishCommunity = useUnpublishOneCommunity();
  const deleteCommunity = useDeleteOneCommunity();
  const createCommunity = useCreateOneCommunity(uiText.COMMUNITY_PATH);
  const contentSpaceId = useContentSpaceId();
  const { defaultGeoPoint } = useConfig() || {};

  const get = () => {
    getCommunities.execute();
  };

  const handleCreate = (values: Community) => {
    const { builderId, ...rest } = values;
    values.builderId &&
      contentSpaceId &&
      createCommunity.execute({
        community: {
          ...rest,
          ...defaultGeoPoint,
        },
        builderId,
        contentSpaceId,
      });
  };

  return (
    <ListPage
      resourceName="community"
      get={get}
      actions={{
        publish: (id: string) => publishCommunity.execute({ id, idType: 'CONTENT_ID' }),
        unpublish: (id: string) => unpublishCommunity.execute({ id, idType: 'CONTENT_ID' }),
        delete: (id: string) => deleteCommunity.execute({ id, idType: 'CONTENT_ID' }),
      }}
      loading={getCommunities.loading}
      actionLoading={
        unpublishCommunity.loading || publishCommunity.loading || deleteCommunity.loading
      }
      error={undefined}
      data={getCommunities.result}
      called={getCommunities.called}
      hasData={getCommunities.result.length > 0}
      noDataMessage={uiText.NO_COMMUNITY}
      topbar={{
        title: uiText.COMMUNITY_PLURAL,
        createAction: (
          <CreateCommunityForm onSubmit={handleCreate} submitting={createCommunity.loading} />
        ),
      }}
    />
  );
};
