import * as yup from 'yup';
import { UiText } from 'domain/uiText';
import { FloorPlanFields } from './types';

// Schemas used in both the resource and fields
const sharedSchemas = (uiText: UiText) => ({
  builderId: yup.string().defined().required().default('').label('ID'),
  displayName: yup.string().defined().required().default('').label('Name'),
  homeType: yup.string().defined().required().default('').label('Home Type'),

  bedrooms: yup.number().defined().default(null).label('Bedrooms'),
  bathrooms: yup.number().defined().default(null).label('Bathrooms'),
  halfBaths: yup.number().defined().default(null).label('Half Bathrooms'),
  floorSize: yup.number().defined().default(null).label('Floor Size'),
  garage: yup.string().defined().default(''),

  showMonthlyPaymentCalculator: yup.boolean().defined().default(true).label('Monthly Payment' +
                                                                           ' Calculator'),

  seoTitleTag: yup.string().defined().default('').label('SEO Title'),
  seoMetaKeywords: yup.array().of(yup.string().defined()).defined().compact().default([]).label('SEO Keywords'),
});

export const floorPlanFieldsSchema = (uiText: UiText) => yup.object<FloorPlanFields>({
  ...sharedSchemas(uiText),
  seoDescriptionBullets:
    yup.array().of(yup.string().defined()).defined().compact().default([]).label('Bullet Points'),
  seoDescriptionBody: yup.string().defined().default('').label('Body Text'),
}).defined();
