import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Message } from '../types';

type Props = {
  message: Message | undefined;
  onClose: () => void;
};

export const MessagesSnackbar = ({ message, onClose }: Props) => {
  const handleClose = (_: any, reason?: string) => reason !== 'clickaway' && onClose();
  return (
    <Snackbar onClose={handleClose} open={!!message} autoHideDuration={5000} onExited={onClose}>
      <Alert variant="filled" elevation={6} severity={message?.severity} onClose={handleClose}>
        {message?.content}
      </Alert>
    </Snackbar>
  );
};
