import { UiText } from 'domain/uiText';
import { DataDisplay, Resource, ResourceFields } from 'lib/resource';
import { idCellRenderer } from '_lib/components/table/CellRenderers';
import { Asset } from 'domain/api/asset/types';
import { Embedding } from '../embedding';
import { ImageLink } from '../imageLink';

export type FloorPlanCategory = {
  id: string;
  name: string;
  priority: number;
};

export type CommunityPricingIdInput = {
  id: string;
  idType: 'CONTENT_ID';
  price: number | null;
};

export type CommunityPricingMapping = {
  community: { id: string, displayName: string };
  price: number | null;
};

export type FloorPlan = {
  id: string;
  builderId: string;
  published: boolean;
  floorPlanCategory: FloorPlanCategory | null;
  displayName: string;

  homeType: string | null;
  bedrooms: number | null;
  bathrooms: number | null;
  halfBaths: number | null;
  floorSize: number | null;
  garage: string | null;
  price: number | null;

  showMonthlyPaymentCalculator: boolean | null;

  seoMetaDescription: string | null;
  seoMetaKeywords: string[] | null;
  seoTitleTag: string | null;

  assets: Asset[];
  communityMappings: CommunityPricingMapping[] | null;
  embeddings: Embedding[];
  imageLinks: ImageLink[];
};

export type FloorPlanInput = Partial<{
  displayName: string;

  homeType: string | null;
  bedrooms: number | null;
  bathrooms: number | null;
  halfBaths: number | null;
  floorSize: number | null;
  garage: string | null;

  showMonthlyPaymentCalculator: boolean | null;

  seoTitleTag: string | null;
  seoMetaDescription: string | null;
  seoMetaKeywords: string[] | null;

  communityPricing: CommunityPricingIdInput[];
}> & { builderId: string };

export const defaultFloorPlan: FloorPlan = {
  id: '',
  builderId: '',
  published: false,
  floorPlanCategory: null,
  displayName: '',

  homeType: null,
  bedrooms: null,
  bathrooms: null,
  halfBaths: null,
  floorSize: null,
  garage: null,
  price: null,

  showMonthlyPaymentCalculator: true,

  seoMetaDescription: null,
  seoMetaKeywords: null,
  seoTitleTag: null,

  assets: [],
  communityMappings: [],
  embeddings: [],
  imageLinks: [],
};

export type FloorPlanFields = Partial<{
  displayName: string;

  homeType: string | null;
  bedrooms: number | null;
  bathrooms: number | null;
  halfBaths: number | null;
  floorSize: number | null;
  garage: string | null;

  showMonthlyPaymentCalculator: boolean | null;

  seoTitleTag: string | null;
  seoMetaKeywords: string[] | null;

  communityPricing: CommunityPricingIdInput[];
  seoDescriptionBody: string;
  seoDescriptionBullets: string[];
}> & { builderId: string };

export type FloorPlanResource = Resource<
FloorPlan,
FloorPlanFields
>;

export const defaultFloorPlanFields: FloorPlanFields = {
  builderId: '',
  displayName: '',

  homeType: null,
  bedrooms: 0,
  bathrooms: 0,
  halfBaths: 0,
  floorSize: 0,
  garage: null,

  showMonthlyPaymentCalculator: true,
  communityPricing: [],

  seoTitleTag: null,
  seoDescriptionBody: '',
  seoDescriptionBullets: [],
  seoMetaKeywords: [],
};

export const floorPlanFields = (
  uiText: UiText,
): ResourceFields<Omit<FloorPlanFields, 'communityPricing'>> => ({
  builderId: {
    value: '',
    name: 'builderId',
    type: 'text',
    fieldConfig: {
      label: 'ID',
      required: true,
    }
  },
  displayName: {
    value: '',
    name: 'displayName',
    type: 'text',
    fieldConfig: {
      label: 'Name',
      required: true,
    }
  },
  homeType: {
    value: '',
    name: 'homeType',
    type: 'text',
    fieldConfig: {
      label: 'Home Type',
      required: true,
    }
  },
  bedrooms: {
    value: 0,
    name: 'bedrooms',
    type: 'number',
    fieldConfig: {
      label: 'Bedrooms',
      nullable: true,
    }
  },
  bathrooms: {
    value: 0,
    name: 'bathrooms',
    type: 'number',
    fieldConfig: {
      label: 'Bathrooms',
      nullable: true,
    }
  },
  halfBaths: {
    value: 0,
    name: 'halfBaths',
    type: 'number',
    fieldConfig: {
      label: 'Half Bathrooms',
      nullable: true,
    }
  },
  floorSize: {
    value: 0,
    name: 'floorSize',
    type: 'number',
    fieldConfig: {
      label: 'Floor Size',
      suffix: 'Sq.Ft',
      nullable: true,
    }
  },
  garage: {
    value: '',
    name: 'garage',
    type: 'text',
    fieldConfig: {
      label: 'Garage',
      nullable: true,
    }
  },
  showMonthlyPaymentCalculator: {
    value: true,
    name: 'showMonthlyPaymentCalculator',
    type: 'check-box',
    fieldConfig: {
      label: 'Monthly Payment Calculator',
    },
  },
  seoTitleTag: {
    value: '',
    name: 'seoTitleTag',
    type: 'text',
    fieldConfig: {
      label: 'SEO Title',
      helperText: `This defaults to the ${uiText.FLOOR_PLAN_SINGULAR.toLocaleLowerCase()} name and your company name.`,
      nullable: true,
    }
  },
  seoDescriptionBody: {
    value: '',
    name: 'seoDescriptionBody',
    type: 'text',
    fieldConfig: {
      label: 'Body Text',
      multiline: true,
      nullable: true,
    }
  },
  seoDescriptionBullets: {
    value: [],
    name: 'seoDescriptionBullets',
    type: 'text-list',
    fieldConfig: {
      label: 'Bullet Points',
      type: 'bullet',
    }
  },
  seoMetaKeywords: {
    value: [],
    name: 'seoMetaKeywords',
    type: 'text-list',
    fieldConfig: {
      label: 'SEO Keywords',
    }
  },
});

export const floorPlanDataDisplayFields = (
  uiText: UiText,
): DataDisplay<FloorPlan> => ({
  fields: {
    displayName: {
      dataKey: 'displayName',
      label: 'Name',
    },
    builderId: {
      dataKey: 'builderId',
      label: 'ID',
      renderer: idCellRenderer,
    },
    homeType: {
      dataKey: 'homeType',
      label: 'Home Type',
    },
    bedrooms: {
      dataKey: 'bedrooms',
      label: 'Bedrooms',
    },
    bathrooms: {
      dataKey: 'bathrooms',
      label: 'Bathrooms',
    },
    halfBaths: {
      dataKey: 'halfBaths',
      label: 'Half Bathrooms',
    },
  },
});
