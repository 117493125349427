import React from 'react';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { DisabledFormOverlay } from './DisabledFormOverlay';
import { FormActions } from './FormActions';
import { FormLayoutProps } from './types';

export const FlatFormLayout = ({
  icon,
  title,
  helpText,
  actions,
  children,
  formDisabled,
  ...rest
}: FormLayoutProps) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" wrap="nowrap" spacing={4} className={classes.layout}>
      {formDisabled && <DisabledFormOverlay />}
      <Grid item container alignItems="center" wrap="nowrap" spacing={2}>
        <Grid item>{React.cloneElement(icon, { color: 'action' })}</Grid>
        <Grid item className={classes.title}>
          <Typography variant="h5">{title}</Typography>
          {helpText && <Typography color="textSecondary" variant="caption">{helpText}</Typography>}
        </Grid>
        <Grid item className={classes.actions}>
          {actions || <FormActions formDisabled={formDisabled} {...rest} />}
        </Grid>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    position: 'relative',
  },
  title: {
    flexGrow: 2,
  },
  actions: {
    alignSelf: 'flex-start',
    marginTop: -theme.spacing(1),
    display: 'flex',
    flexWrap: 'nowrap',
  },
}));
