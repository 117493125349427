import React from 'react';
import { useRegisterPageSection } from '../PageSectionRegisrarContext';
import { PageSectionOptions } from '../types';

interface Props {
  options?: PageSectionOptions,
  children: React.ReactNode | ((props: PageSectionOptions) => JSX.Element);
}

export const Section = ({ options, children }: Props) => {
  useRegisterPageSection(options);
  if (typeof children === 'function' && options) {
    return (
      <span id={options?.id}>
        {children(options)}
      </span>
    );
  }
  return (
    <span id={options?.id}>
      {children}
    </span>
  );
};
