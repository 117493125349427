import React from 'react';
import { CircularProgress, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';

interface Props {
  withContent?: boolean;
}

export const Spinner = React.memo(({ withContent }: Props) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.container, { [classes.withContent]: withContent })}>
      <CircularProgress />
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  withContent: {
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    zIndex: theme.zIndex.modal,
  },
}));
