import { ApolloCache, DocumentNode, useMutation } from '@apollo/client';
import { useContentSpaceId } from '_lib/auth';
import { IdType, MutationWithIdInput, ValidatedEntity } from 'domain/api/common/types';
import { AdapterAction } from 'domain/api/common/adapters/types';

type Options<Response extends object | undefined> = {
  onSuccess?: () => void;
  onError?: (err: string) => void;
  updateCache?: (cache: ApolloCache<Response>, data?: Response | null) => void;
  omitContentSpaceId?: boolean;
};

type Input = { id: string, idType?: IdType };

/**
 * @description
 * A hook that encapsulates the functionality for deleteOne, publishOne, and
 * unpublishOne mutations.
 */
export function useIdInputMutation<
  Response extends object | undefined,
  Result extends object | any
>(
  query: DocumentNode,
  validator: (data?: Response | null) => ValidatedEntity<Result> | Result | undefined,
  options?: Options<Response>
): AdapterAction<Input, ValidatedEntity<Result> | Result | undefined> {
  const contentSpaceId = useContentSpaceId();
  const [mutation, mutationResult] = useMutation<Response, MutationWithIdInput>(
    query,
    options?.updateCache
      ? {
        update: (cache, { data }) => {
          options.updateCache && options.updateCache(cache, data);
        },
      }
      : undefined
  );

  const execute = async ({ id, idType }: Input) => {
    try {
      if (!contentSpaceId) return undefined;
      const result = await mutation({
        variables: {
          input: {
            contentSpaceId: options?.omitContentSpaceId ? undefined : contentSpaceId,
            id,
            idType
          },
        },
      });

      if (options?.onSuccess) options.onSuccess();

      return validator(result.data);
    } catch (error) {
      if (options?.onError) options.onError(error);
      return error;
    }
  };

  return {
    execute,
    result: validator(mutationResult.data),
    loading: mutationResult.loading,
    called: mutationResult.called,
    error: mutationResult.error?.message,
  };
}
