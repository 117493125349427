import React from 'react';
import {
  Checkbox,
  TableCell, TableCellProps, TableHead as MaterialTableHead, TableRow, TableSortLabel,
} from '@material-ui/core';
import { ResourcesContextValue } from 'system/resources/types';
import { useResource } from 'system/hooks';
import { isKeyOf } from '_lib/common/helpers';

import { DataDisplayField } from 'lib/resource';
import { TableDataRow, TableDataDisplayKeys } from './types';

type Props<TResourceName extends keyof ResourcesContextValue> = {
  selectDisabled: boolean;
  onSelectAllRows?: () => void;
  allRowsSelected: boolean;
  resourceName: TResourceName;
  hasActions: boolean;
  hasStatus: boolean;
  hasData: boolean;
  onSortClick: (key: string) => void;
  activeColumn?: string;
  activeOrder: 'desc' | 'asc';
};

const headCellProps: Partial<TableCellProps> = {
  style: { wordWrap: 'break-word', whiteSpace: 'normal', maxWidth: '300px' },
  variant: 'head',
};

export function TableHead<
TResourceName extends keyof ResourcesContextValue
>({
  selectDisabled,
  resourceName,
  onSortClick,
  onSelectAllRows,
  allRowsSelected,
  hasActions,
  hasStatus,
  hasData,
  activeColumn,
  activeOrder,
}: Props<TResourceName>) {
  const { dataDisplay } = useResource(resourceName);
  const dataDisplayKeys = Object.keys(dataDisplay.fields) as TableDataDisplayKeys<TResourceName>;

  const handleSortClick = (key: string) => () => onSortClick(key);

  const renderSortLabel = (label: string, key: string) => {
    return (
      <TableSortLabel
        active={activeColumn === key}
        direction={activeOrder === 'desc' ? 'desc' : 'asc'}
        onClick={handleSortClick(key)}
      >
        {label}
      </TableSortLabel>
    );
  };

  const renderHeadCells = () => {
    const cells: JSX.Element[] = dataDisplayKeys.map((key) => {
      const cellDisplay = isKeyOf(dataDisplay.fields, key)
        && dataDisplay.fields[key] as unknown as DataDisplayField<
        TableDataRow<TResourceName>, typeof key>;
      if (cellDisplay && cellDisplay.label) {
        return (
          <TableCell {...headCellProps} key={`table-head-cell-${key}`}>
            {cellDisplay.label && renderSortLabel(cellDisplay.label, key)}
          </TableCell>
        );
      }
      return <TableCell {...headCellProps} key={`table-head-cell-${key}`} />;
    });

    return (
      <TableRow>
        {cells}
        {hasStatus && (
          <TableCell {...headCellProps}>{renderSortLabel('Status', 'publishedStatus')}</TableCell>
        )}
        {hasActions && (
          <TableCell variant="head" align="right">
            {onSelectAllRows ? (
              <Checkbox
                color="primary"
                size="small"
                onClick={onSelectAllRows}
                checked={allRowsSelected}
                disabled={!hasData || selectDisabled}
              />
            ) : null}
          </TableCell>
        )}
      </TableRow>
    );
  };

  return (
    <MaterialTableHead>
      {renderHeadCells()}
    </MaterialTableHead>
  );
}
