import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import {
  Grid,
  TableContainer,
  Theme,
  Typography,
  makeStyles,
  Paper,
  Table as MaterialTable,
  Toolbar,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { getComparator, SortOrder, stableSort } from 'utilities/sort';
import { Spinner } from '_lib/loading';
import { ResourcesContextValue } from 'system/resources/types';
import { useResource } from 'system/hooks';
import { isKeyOf } from '_lib/common/helpers';

import { TableFilters } from './TableFilters/TableFilters';
import { TableBody } from './TableBody';
import { TableActions, TableDataDisplayKeys, TableDataRow } from './types';
import { TableHead } from './TableHead';

type Props<TResourceName extends keyof ResourcesContextValue> = {
  resourceName: TResourceName;
  data: TableDataRow<TResourceName>[];
  noDataMessage: string;
  defaultSortKey?: Extract<keyof ResourcesContextValue[TResourceName]['defaultResource'], string>;
  actions: TableActions<TResourceName>;
  onSelect?: (ids: string[]) => void;
  selectedRows?: string[];
  loading?: boolean;
  variant?: 'paper' | 'flat';
  searchable?: boolean;
};

type State<TResourceName extends keyof ResourcesContextValue> = {
  activeColumn?: string;
  activeOrder: SortOrder;
  filteredEntries?: TableDataRow<TResourceName>['id'][];
};

export function Table<TResourceName extends keyof ResourcesContextValue>({
  resourceName,
  data,
  noDataMessage,
  defaultSortKey,
  actions,
  loading,
  variant = 'paper',
  onSelect,
  selectedRows = [],
  searchable = true,
}: Props<TResourceName>) {
  const classes = useStyles();
  const history = useHistory();
  const { dataDisplay, helpers } = useResource(resourceName);
  const dataDisplayKeys = Object.keys(dataDisplay.fields) as TableDataDisplayKeys<TResourceName>;
  const defaultSortKeyIndex = defaultSortKey && dataDisplayKeys.indexOf(defaultSortKey as any);
  const [state, setState] = React.useState<State<TResourceName>>({
    activeOrder: 'asc',
    activeColumn:
      dataDisplayKeys.length > 0 ? dataDisplayKeys[defaultSortKeyIndex || 0] : undefined,
    filteredEntries: undefined,
  });

  const handleRowClick = helpers.getSinglePageLink
    ? (row: TableDataRow<TResourceName>) => {
      helpers.getSinglePageLink && history.push(helpers.getSinglePageLink(row.id).path);
    }
    : undefined;

  const sortTableData = (_data: any) => {
    if (!state.activeColumn) return _data;
    return stableSort(_data, getComparator(state.activeOrder, state.activeColumn));
  };

  const filterTableData = React.useCallback(
    (_data: TableDataRow<TResourceName>[]) => {
      if (state.filteredEntries) {
        const primaryKeys = state.filteredEntries;
        return _data.filter((entry) => primaryKeys.includes(entry.id));
      }
      return _data;
    },
    [state]
  );

  const handleSortClick = (key: string) => {
    setState((prev) => ({
      ...prev,
      activeColumn: key,
      activeOrder: prev.activeOrder === 'desc' ? 'asc' : 'desc',
    }));
  };
  const handleFiltersChange = React.useCallback(
    (primaryKeys: TableDataRow<TResourceName>['id'][]) => {
      setState((prev) => {
        return {
          ...prev,
          filteredEntries: primaryKeys,
        };
      });
    },
    []
  );

  const sortedFilteredData = sortTableData(filterTableData(data));

  const handleSelectRow = onSelect
    ? (rowId: string) => (e: React.BaseSyntheticEvent) => {
      e.stopPropagation();
      const isRowSelected = selectedRows.includes(rowId);
      const rows = isRowSelected
        ? [...selectedRows].filter((id) => id !== rowId)
        : [...selectedRows, rowId];
      onSelect(rows);
    }
    : undefined;

  const handleSelectAllRows = onSelect
    ? () => {
      const allRowsSelected = selectedRows.length === sortedFilteredData.length;
      onSelect(allRowsSelected ? [] : sortedFilteredData.map((row: any) => row.id));
    }
    : undefined;

  const hasActions = !!(
    actions.delete ||
    actions.publish ||
    actions.unpublish ||
    actions.edit ||
    onSelect
  );

  const hasStatus = data.length > 0 && isKeyOf(data[0], 'publishedStatus');

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.wrapper}
      component={variant === 'paper' ? Paper : 'div'}
    >
      <Grid item>
        <TableFilters
          data={data}
          primaryDataKey="id"
          dataKeys={dataDisplayKeys}
          onFiltersChange={handleFiltersChange}
        >
          {
            (searchInput) => searchable ? (
              <Toolbar
                classes={{
                  root: classNames({
                    [classes.filterFlatVariant]: variant === 'flat',
                  }),
                }}
              >
                <Grid container wrap="nowrap" alignItems="center" spacing={2}>
                  <Grid item>{searchInput}</Grid>
                </Grid>
              </Toolbar>
            ) : (
              <React.Fragment />
            )
          }
        </TableFilters>
      </Grid>
      <Grid item component={TableContainer}>
        <MaterialTable stickyHeader>
          <TableHead
            selectDisabled={!!loading}
            onSelectAllRows={handleSelectAllRows}
            allRowsSelected={
              selectedRows.length === sortedFilteredData.length && sortedFilteredData.length > 0
            }
            hasStatus={hasStatus}
            hasActions={hasActions}
            hasData={sortedFilteredData.length > 0}
            resourceName={resourceName}
            activeColumn={state.activeColumn}
            activeOrder={state.activeOrder}
            onSortClick={handleSortClick}
          />
          <TableBody
            data={sortedFilteredData}
            actions={actions}
            resourceName={resourceName}
            onRowClick={handleRowClick}
            onSelectRow={handleSelectRow}
            selectedRows={selectedRows}
          />
          {loading && <Spinner withContent />}
        </MaterialTable>
      </Grid>
      {sortedFilteredData.length <= 0 && (
        <Grid
          item
          container
          spacing={1}
          justify="center"
          alignItems="center"
          className={classes.noResults}
        >
          {data.length > 0 && (
            <Grid item>
              <SearchIcon color="inherit" />
            </Grid>
          )}
          <Grid item>
            <Typography color="inherit" variant="subtitle1">
              {data.length <= 0 ? noDataMessage : 'No results'}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    maxHeight: '100%',
  },
  noResults: {
    padding: `${theme.spacing(2)}px 0`,
    color: theme.palette.text.secondary,
  },
  filterFlatVariant: {
    padding: '0px !important',
  },
}));
