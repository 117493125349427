import React from 'react';
import { ReadonlyField } from '../shared/ReadonlyField/ReadonlyField';
import { TextListFieldBullets } from './bullets';
import { TextListFieldChips } from './chips/TextListFieldChips';
import { TextListFieldProps } from './types';

export function TextListField<TName extends string>({
  readonly,
  onChange,
  name,
  type,
  disabled,
  ...rest
}: TextListFieldProps<TName>) {
  const handleChange = React.useCallback((value: string[]) => {
    if (type === 'bullet') {
      onChange(name, value);
    } else {
      onChange(name, value);
    }
  }, [onChange, name, type]);

  if (type === 'bullet') {
    return (
      <ReadonlyField errors={rest.errors} label={rest.label}>
        <TextListFieldBullets
          disabled={disabled}
          items={rest.value}
          onChange={handleChange}
          readonly={readonly}
        />
      </ReadonlyField>
    );
  }
  return (
    <ReadonlyField errors={rest.errors} label={rest.label}>
      <TextListFieldChips
        disabled={disabled}
        items={rest.value}
        onChange={handleChange}
        readonly={readonly}
      />
    </ReadonlyField>
  );
}
