import { useContentSpaceId } from '_lib/auth';
import { ValidatedEntity } from 'domain/api/common/types';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { generateCreateOrUpdateString } from 'domain/api/common/fragments';
import { useCreateOrUpdateOne } from 'domain/api/common/adapters/useCreateOrUpdateOne';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { ApiEmbedding, ApiEmbeddingInput, Embedding } from '../types';
import { AllEmbeddingInfo } from '../fragments';
import {
  transformApiEmbeddingToEmbedding,
  transformEmbeddingToApiEmbeddingInput,
} from '../transforms';

const UPDATE_ONE_EMBEDDING_INFO = generateCreateOrUpdateString(
  'update',
  'embedding',
  AllEmbeddingInfo
);

type Response = {
  updateEmbedding: {
    embedding: ApiEmbedding | undefined;
  };
};

type Input<T> = T & {
  contentSpaceId: string;
};

export const useUpdateOneEmbeddingInfo = () => {
  const schemas = useApiSchemas();
  const contentSpaceId = useContentSpaceId();

  const { execute: executeMutation, ...rest } = useCreateOrUpdateOne<
  Input<ApiEmbeddingInput>,
  Response,
  ValidatedEntity<Embedding> | undefined
  >(UPDATE_ONE_EMBEDDING_INFO, (data) =>
    schemas?.embedding.apiSchema
      ? transformApiEmbeddingToEmbedding(
        validateApiResponseEntity(data?.updateEmbedding.embedding, schemas.embedding.apiSchema)
      )
      : undefined
  );

  const execute = async (input: Input<Partial<Embedding>> & { id: string }) => {
    try {
      if (!contentSpaceId) return undefined;
      return await executeMutation({
        contentSpaceId,
        ...transformEmbeddingToApiEmbeddingInput(input),
      });
    } catch (error) {
      return error;
    }
  };

  return { execute, ...rest };
};
