import React from 'react';
import { useContentSpaceId, useResource } from 'system/hooks';
import { ResourcesContextValue } from 'system/resources/types';
import {
  useAddMultipleImageLinkMappingsMutation,
  useCreateOneImageLink,
  usePublishOneImageLink,
  useRemoveMultipleImageLinkMappingsMutation,
  useUnpublishOneImageLink,
} from 'domain/api/imageLink';
import { useFormState } from '_lib/multiFormSemaphore';
import { ImageLink } from 'domain/api/imageLink/types';
import { RefetchContext, useRefetchQueries, useResetableAdapterAction } from 'domain/api/common/hooks';
import { useUiText } from 'domain/uiText';
import { CreateImageLinkMappingForm } from './CreateImageLinkMapping';
import { useImageLinkMappingsData } from './useImageLinkMappingsData';
import { RelationMappingForm } from '../RelationMappingForm/RelationMappingForm';

type Props = {
  id: string;
  title: string;
  icon?: JSX.Element;
  resourceName: Extract<
  keyof Pick<ResourcesContextValue, 'imageLink' | 'banner'>, string
  >;
  sourceId: string;
  sourceTypeName: string;
  sourceContext: RefetchContext;
  mapped: ImageLink[];
};


export function ImageLinkMappingForm({
  id,
  title,
  icon,
  resourceName,
  sourceId,
  sourceTypeName,
  sourceContext,
  mapped,
}: Props) {
  const uiText = useUiText();
  const isLarge = resourceName === 'banner';
  const { enabled } = useFormState(id);
  const { helpers } = useResource(resourceName);
  const contentSpaceId = useContentSpaceId();
  const refetchQueries = useRefetchQueries(sourceContext, sourceId);
  const useCreateOne = () =>
    useCreateOneImageLink(
      {
        id: sourceId,
        __typename: sourceTypeName,
      },
      isLarge
    );
  const createImageLink = useResetableAdapterAction(useCreateOne);
  const addMappingsMutation = useResetableAdapterAction(
    useAddMultipleImageLinkMappingsMutation,
    refetchQueries,
  );
  const removeMappingsMutation = useRemoveMultipleImageLinkMappingsMutation(refetchQueries);
  const publishImageLink = usePublishOneImageLink();
  const unpublishImageLink = useUnpublishOneImageLink();
  const transformedData = useImageLinkMappingsData({
    mapped,
    sourceId,
    resourceName,
  });

  const handleRemoveMappings = (entries: string[]) => {
    const batchInput = entries.map((entry) => ({
      input: {
        input: {
          imageLinkId: entry,
          contentSpaceId,
          contentEntries: [sourceId],
        },
      },
    }));
    removeMappingsMutation.execute(batchInput);
  };

  const handleAddMappings = (entries: string[]) => {
    const batchInput = entries.map((entry) => ({
      input: {
        input: {
          imageLinkId: entry,
          contentSpaceId,
          contentEntries: [sourceId],
        },
      },
    }));

    addMappingsMutation.execute(batchInput);
  };

  /**
   *
   * @todo Fix this
   */
  const handleCreate = (values: any, onClose: () => void, alsoPublish?: boolean) => {
    createImageLink.execute({
      ...values,
      contentEntries: [sourceId],
      showHomes: [],
      communities: [],
      floorPlans: [],
      inventories: [],
    }, alsoPublish
    );
  };

  const createForm = (
    <CreateImageLinkMappingForm
      onSubmit={handleCreate}
      submitting={createImageLink.loading}
      submitted={!createImageLink.loading && createImageLink.called}
      disabled={helpers.isActionDisabled('create')}
      resourceName={resourceName}
    />
  );

  return (
    <RelationMappingForm
      id={id}
      title={title}
      addMappingDialogTitle={isLarge
        ? uiText.ACTION_ADD_EXISTING_BANNER_LABEL
        : uiText.ACTION_ADD_EXISTING_IMAGE_LINK_LABEL
      }
      icon={icon}
      targetsName={resourceName}
      mappedTargets={transformedData.mapped}
      unmappedTargets={transformedData.unmapped}
      addMappings={{
        execute: handleAddMappings,
        loading: addMappingsMutation.loading,
        called: addMappingsMutation.called,
      }}
      removeMappings={{
        execute: handleRemoveMappings,
        loading: removeMappingsMutation.loading,
        called: removeMappingsMutation.called,
      }}
      createAction={createForm}
      publishAction={(deleteId) => publishImageLink.execute({ id: deleteId })}
      unpublishAction={(deleteId) => unpublishImageLink.execute({ id: deleteId })}
      loading={publishImageLink.loading || unpublishImageLink.loading}
      enabled={enabled}
    />
  );
}
