import { UiText } from 'domain/uiText';
import { Resource, ResourceFields } from 'lib/resource';
import { Contact } from 'domain/api/contact/types';

export type SalesCentre = {
  id: string;
  name: string;
  phone: string;
  address: string;
  hours: string;
  email: string;
  contacts: Contact[];
  community: { id: string };
};

export type SalesCentreFields = Partial<{
  name: string;
  phone: string;
  address: string;
  hours: string[];
  email: string;
}>;

export type SalesCentreResource = Resource<SalesCentre, SalesCentreFields>;

export const defaultSalesCentre: SalesCentre = {
  id: '',
  name: '',
  phone: '',
  address: '',
  hours: '',
  email: '',
  contacts: [],
  community: { id: '' },
};

export const defaultSalesCentreFields: SalesCentreFields = {
  name: '',
  phone: '',
  address: '',
  hours: [],
  email: '',
};

export const salesCentreFields = (uiText: UiText): ResourceFields<SalesCentreFields> => ({
  name: {
    value: '',
    name: 'name',
    type: 'text',
    fieldConfig: {
      label: 'Name',
      required: true,
    },
  },
  phone: {
    value: '',
    name: 'phone',
    type: 'text',
    fieldConfig: {
      label: 'Phone Number',
      nullable: true,
    },
  },
  address: {
    value: '',
    name: 'address',
    type: 'text',
    fieldConfig: {
      label: 'Address',
      nullable: true,
    },
  },
  hours: {
    value: [],
    name: 'hours',
    type: 'text-list',
    fieldConfig: {
      label: 'Hours',
      type: 'bullet',
    },
  },
  email: {
    value: '',
    name: 'email',
    type: 'text',
    fieldConfig: {
      label: 'Email',
      nullable: true,
    },
  },
});
