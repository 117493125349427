export const NO_MODIFIERS_WITHOUT_SCHEMA_ERROR =
  'You used modifiers without providing a validation schema. \nModifiers mutate the form values outside of useForm, making this very unsafe. Please provide a validationSchema in order to use modifiers.';

// Todo: Move these field config types into their respective fields
export type TextFieldConfig = {
  onBlur?: (value?: string | null) => void;
  onChangeCallback?: (value?: string | null) => void;
  label: string;
  required?: boolean;
  nullable?: boolean;
  placeholder?: string;
  multiline?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  helperText?: string;
  loading?: boolean;
  successText?: string;
};

export type NumberFieldConfig = {
  prefix?: string;
  suffix?: string;
  label: string;
  required?: boolean;
  nullable?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  onBlur?: (name: string, value?: number | null) => void;
};

export type DateFieldConfig = {
  label: string;
  required?: boolean;
  nullable?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  onBlur?: (name: string, value?: number | null) => void;
};

export type TextListFieldConfig = {
  type?: 'chip' | 'bullet';
  label: string;
  required?: boolean;
  nullable?: boolean;
  readonly?: boolean;
  disabled?: boolean;
};

export type CheckboxFieldConfig = {
  checkedLabel?: string;
  unCheckedLabel?: string;
  label: string;
  required?: boolean;
  nullable?: boolean;
  readonly?: boolean;
  disabled?: boolean;
};

export type SelectFieldConfig = {
  options: { label: string; value: string }[];
  isMulti?: boolean;
  label: string;
  required?: boolean;
  nullable?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  additionalAction?: {
    value: string | string[];
    label: string;
  };
  helperText?: string;
  isFullWidth?: boolean;
};

export type FileFieldConfig = {
  isMultiple?: boolean;
  label: string;
  required?: boolean;
  nullable?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  existingUrl?: string;
};

export type FieldConfigs = {
  text: TextFieldConfig;
  number: NumberFieldConfig;
  'text-list': TextListFieldConfig;
  'check-box': CheckboxFieldConfig;
  select: SelectFieldConfig;
  file: FileFieldConfig;
  date: DateFieldConfig;
};

export type FieldConfigKeys = keyof FieldConfigs;

export type FieldConfig<TValue, TFieldType extends FieldConfigKeys & string> = {
  value: TValue;
  type: TFieldType;
};

export type InitialValues = {
  [x: string]: {
    value: any;
    type: FieldConfigKeys;
  };
};

export type FieldErrors<T extends InitialValues> = {
  [key in keyof T & string]: string[];
};

export type FormFields<T extends InitialValues> = {
  [key in keyof T & string]: FieldConfig<T[key]['value'], T[key]['type']> & { touched?: boolean };
};

export type FieldModifiers<T extends InitialValues> = {
  [key in keyof T & string]: (values: FormFields<T>, errors: FieldErrors<T>) => {
    fields: FormFields<T>;
    errors?: Partial<FieldErrors<T>>;
  };
};

export type TransformedFieldValues<T extends InitialValues> = {
  [key in keyof T & string]: T[key]['value'];
};

export type FormState<T extends InitialValues> = {
  fields: FormFields<T>;
  errors: FieldErrors<T>;
  isEditing: boolean;
  attemptedSubmit: boolean;
};

export type OnChange<TName, TValue> = (name: TName, value: TValue) => void;
