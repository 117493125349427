import React from 'react';
import { Divider, Grid, GridSize } from '@material-ui/core';
import { LayoutItem } from './types';

export type LayoutRendererProps = {
  items: LayoutItem[];
  index?: number;
};

type GridLayout = Partial<{
  xs: GridSize | boolean;
  sm: GridSize | boolean;
  md: GridSize | boolean;
  lg: GridSize | boolean;
  xl: GridSize | boolean;
}>;

// Lets us specify only the size we are interested in, rather than
// having to specify all size
const generateGridLayout = (_items: GridLayout) => {
  const { xs, sm, md, lg, xl } = _items;
  return {
    xl,
    lg: lg || xl,
    md: md || lg || xl,
    sm: sm || md || lg || xl,
    xs: xs || sm || md || lg || xl,
  };
};

export const LayoutRenderer = (props: LayoutRendererProps) => {
  const index = props.index || 0;
  return (
    <React.Fragment>
      {props.items.map((section, i) => {
        const {
          xs, sm, md, lg, xl, spacing,
          renderContent, items, hasDivider,
          renderContentGridProps,
          ...rest
        } = section;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Grid
            key={`layout-section-${i + index}`}
            spacing={spacing}
            item={true}
            {...rest}
            {...generateGridLayout({ xs, sm, md, lg, xl })}
            style={{
              flexGrow: 1,
              flexShrink: 0,
              // Ensure the max width of the section doesn't exceed the container
              // <Grid /> uses negative margin to space elements, which has some caveats
              // see https://material-ui.com/components/grid/#limitations
              maxWidth: `calc(100% + ${(spacing || 0) * 8 * 2}px)`
            }}
          >
            {renderContent && (
              <Grid item={true} {...renderContentGridProps}>
                {renderContent()}
              </Grid>
            )}
            {items && <LayoutRenderer items={items} index={i + index} />}
            {hasDivider && (
              <Grid item={true}>
                <Divider style={{ height: 2 }} light={true} />
              </Grid>
            )}
          </Grid>
        );
      })}
    </React.Fragment>
  );
};
