import React from 'react';
import { Button } from '@material-ui/core';
import { useResourceFields } from 'system/resources/hooks/useResourceFields';
import { useContentSpaceId } from '_lib/auth';
import { SalesCentre } from 'domain/api/salesCentre';
import { useToggle } from 'common/hooks/useToggle';
import { useCreateOneContact } from 'domain/api/contact';
import { useUiText } from 'domain/uiText';
import { useRefetchQueries, useResetableAdapterAction } from 'domain/api/common/hooks';
import { FormDialog } from '_lib/form/formDialog/FormDialog';

type Props = {
  salesCentre: SalesCentre;
  entityContentId: string;
};

export const CreateContactDialog = ({ salesCentre, entityContentId }: Props) => {
  const uiText = useUiText();
  const contentSpaceId = useContentSpaceId();
  const dialogState = useToggle();
  const refetchQueries = useRefetchQueries('community', entityContentId);
  const createContact = useResetableAdapterAction(useCreateOneContact, refetchQueries);
  const form = useResourceFields('contact', { hasReadonly: false });
  const handleSubmit = () => {
    form.onSubmit(
      () => {},
      (values) =>
        contentSpaceId && createContact.execute({
          ...values,
          salesCentres: [{ id: salesCentre.id, name: salesCentre.name }],
          contentSpaceId,
        })
    );
  };

  React.useEffect(() => {
    if (createContact.called && !createContact.loading) {
      dialogState.onClose();
    }
  }, [createContact, dialogState]);

  return (
    <React.Fragment>
      <Button
        size="small"
        variant="outlined"
        style={{ textTransform: 'capitalize' }}
        onClick={dialogState.onOpen}
      >
        Create New
      </Button>
      <FormDialog
        title={uiText.ACTION_NEW_CONTACT_LABEL}
        open={dialogState.open}
        submitDisabled={!form.canSubmit}
        onClose={dialogState.onClose}
        onSubmit={handleSubmit}
        submitting={createContact.loading}
      >
        {form.renderField('name')}
        {form.renderField('title')}
        {form.renderField('phone')}
        {form.renderField('email')}
      </FormDialog>
    </React.Fragment>
  );
};
