import React from 'react';
import { LayoutRenderer } from 'domain/view/components/layout/layoutRenderer/LayoutRenderer';
import { Community } from 'domain/api/community';
import { EntitySectionForm } from 'domain/view/components/form/EntitySectionForm';
import { forceSeoMetaDescriptionInclude } from 'system/resources/utilities/fieldModifiers';

type Props = {
  initialValues: Community;
  id: string;
  title: string;
  icon: JSX.Element;
  onSubmit: (values: Community) => void;
};

export const CommunityDescriptionForm = ({ initialValues, onSubmit, ...rest }: Props) => {
  return (
    <EntitySectionForm
      resourceName="community"
      initialValues={initialValues}
      onUpdate={onSubmit}
      fieldModifiers={{ ...forceSeoMetaDescriptionInclude() }}
      {...rest}
    >
      {(renderField) => (
        <LayoutRenderer
          items={[
            {
              container: true,
              direction: 'column',
              spacing: 2,
              items: [
                {
                  renderContent: () => renderField('seoDescriptionBody'),
                },
                {
                  renderContent: () => renderField('seoDescriptionBullets'),
                },
              ],
            },
          ]}
        />
      )}
    </EntitySectionForm>
  );
};
