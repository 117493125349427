import { Asset } from 'domain/api/asset/types';
import { ApiImageLink, ImageLink } from 'domain/api/imageLink/types';
import { CommonApiFields, PublishableApiEntity, PublishedStatus } from '../common/types';
import { ApiCommunity } from '../community/types';
import { ApiEmbedding, Embedding } from '../embedding/types';
import { ApiFeatureTag, FeatureTag } from '../featureTag/types';
import { ApiFloorPlan } from '../floorPlan/types';

export type ApiInventory = PublishableApiEntity<Pick<CommonApiFields,
'id' |
'builderId' |
'displayName' |
'seoMetaDescription' |
'seoMetaKeywords' |
'seoTitleTag' |
'showMonthlyPaymentCalculator' |
'showCommuteCalculation' |
'homeType' |
'bedrooms' |
'bathrooms' |
'halfBaths' |
'floorSize' |
'garage'
> & {
  inventoryCategory: {
    id: string;
    name: string;
    priority: number;
  } | null;

  status: string | null;
  possessionDate: number | null,

  price: number | null;
  priceReduced: boolean | null;
  priceReducedFrom: number | null;

  locationVisualizerLat: number | null;
  locationVisualizerLon: number | null;

  address: Pick<CommonApiFields,
  'fullAddress' |
  'address' |
  'city' |
  'countrySubDivisionCode' |
  'country' |
  'postalCode'
  > | null;

  floorPlan: Pick<ApiFloorPlan, 'id' | 'publishProblems'> | null,
  community: Pick<ApiCommunity, 'id' | 'communityGeoPointLat' | 'communityGeoPointLon'> | null,
  assets: Asset[];
  embeddings: ApiEmbedding[];
  imageLinks: ApiImageLink[];
  featureTags: ApiFeatureTag[];
}>;

export type ApiInventoryInput = Partial<Pick<ApiInventory,
'displayName' |
'seoMetaDescription' |
'seoMetaKeywords' |
'seoTitleTag' |
'showMonthlyPaymentCalculator' |
'showCommuteCalculation' |
'homeType' |
'bedrooms' |
'bathrooms' |
'halfBaths' |
'floorSize' |
'garage' |
'locationVisualizerLat' |
'locationVisualizerLon' |
'price' |
'priceReduced' |
'priceReducedFrom'
> & {
  possessionDateSec: number | null;

  floorPlan?: { id: string | null } | null;
  community?: { id: string | null } | null;
  featureTags?: string[] | null;
  status?: { value?: string | null } | undefined;
}> & Partial<Pick<CommonApiFields,
'address' |
'city' |
'countrySubDivisionCode' |
'country' |
'postalCode'
>>;

export const CLEARABLE_INVENTORY_FIELDS: (keyof ApiInventoryInput & string)[] = [
  'homeType',
  'bedrooms',
  'bathrooms',
  'halfBaths',
  'floorSize',
  'garage',
  'price',
  'possessionDateSec',
  'address',
  'priceReducedFrom',
  'locationVisualizerLat',
  'locationVisualizerLon',
];

export type Inventory = Pick<ApiInventory,
'id' |
'published' |
'builderId' |
'displayName' |
'seoMetaKeywords' |
'seoTitleTag' |
'showMonthlyPaymentCalculator' |
'showCommuteCalculation' |
'homeType' |
'bedrooms' |
'bathrooms' |
'halfBaths' |
'floorSize' |
'garage' |
'assets' |
'price' |
'priceReduced' |
'priceReducedFrom'
> & {
  publishedStatus: PublishedStatus;
  seoDescriptionBody: string;
  seoDescriptionBullets: string[];
  fullAddress: string | null;
  lat: number | null;
  lng: number | null;

  possessionDateSec: number | null;

  embeddings: Embedding[];
  imageLinks: ImageLink[];
  featureTags: FeatureTag[];
  floorPlan: Pick<ApiFloorPlan, 'id' | 'publishProblems'> | null;
  community: Pick<ApiCommunity, 'id' | 'communityGeoPointLat' | 'communityGeoPointLon'> | null;
  status: string | null;
} & Pick<CommonApiFields,
'address' |
'fullAddress' |
'city' |
'countrySubDivisionCode' |
'country' |
'postalCode'
>;

export type InventoryInput = Partial<Omit<Inventory, 'floorPlan' | 'community'> & {
  floorPlanId: string | null;
  communityId: string | null;
}>;
