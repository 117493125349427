import React from 'react';
import Check from '@material-ui/icons/Check';
import {
  CircularProgress,
  FormHelperText,
  InputAdornment,
  makeStyles,
  TextField as MaterialTextField,
  Theme,
} from '@material-ui/core';
import { TextFieldControlProps } from './types';
import { FieldErrors } from '../shared/FieldErrors/FieldErrors';

export function TextFieldControl<TName extends string>({
  required,
  value,
  name,
  label,
  disabled,
  errors,
  onChange,
  onBlur,
  onChangeCallback,
  multiline,
  helperText,
  loading,
  successText,
  nullable,
}: TextFieldControlProps<TName>) {
  const classes = useStyles();
  const handleChange = (e: React.BaseSyntheticEvent) => {
    e.persist();
    const { value: val } = e.currentTarget;
    const newValue = nullable && (val === '' || val === undefined) ? null : val;

    onChangeCallback && onChangeCallback(newValue);
    onChange(e.currentTarget.name, newValue);
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value: val } = e.currentTarget;
    const newValue = nullable && (val === '' || val === undefined) ? null : val;
    onBlur && onBlur(newValue);
  };
  const showSuccess = successText && (!errors || (errors && errors.length <= 0));
  return (
    <MaterialTextField
      type="text"
      required={required}
      value={value}
      name={name}
      onChange={handleChange}
      onBlur={handleBlur}
      label={label}
      disabled={disabled}
      error={errors && errors.length > 0}
      multiline={multiline}
      fullWidth={true}
      variant={multiline ? 'outlined' : 'standard'}
      InputProps={{
        endAdornment: loading || successText ? (
          <InputAdornment position="end">
            {loading && !successText && <CircularProgress size={14} />}
            {!loading && showSuccess && (
              <Check className={classes.success} fontSize="inherit" color="inherit" />
            )}
          </InputAdornment>
        ) : undefined,
      }}
      helperText={
        <React.Fragment>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          {showSuccess && (
            <FormHelperText className={classes.success}>
              {successText}
            </FormHelperText>
          )}
          <FieldErrors errors={errors} />
        </React.Fragment>
      }
    />
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    color: theme.palette.success.main,
  },
}));
