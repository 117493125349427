import { gql } from '@apollo/client';
import { AllImageLinkInfo } from '../fragments';

export const REMOVE_IMAGE_LINK_CONTENT_ENTRIES = gql`
fragment RemoveImageLinkContentEntriesInfo on ImageLink {
  ${AllImageLinkInfo}
}

mutation removeImageLinkContentEntries($input: RemoveImageLinkContentEntriesInput) {
  removeImageLinkContentEntries(input: $input) {
    imageLink {
      ...RemoveImageLinkContentEntriesInfo
    }
  }
}
`;
