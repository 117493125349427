import { gql } from '@apollo/client';
import { AllSalesCentreInfo } from '../fragments';

export const REMOVE_SALES_CENTRE_CONTACTS = gql`
fragment AllSalesCentreInfo on SalesCentre {
  ${AllSalesCentreInfo}
}
mutation removeSalesCentreContacts($input: RemoveSalesCentreContactsInput) {
  removeSalesCentreContacts(input: $input) {
    salesCentre {
      ...AllSalesCentreInfo
    }
  }
}
`;
