import { createFailedPublishMessage, createSuccessfulPublishMessage, useSetMessage } from '_lib/messages';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { ValidatedEntity } from 'domain/api/common/types';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { useIdInputMutation } from 'domain/api/common/adapters/useIdInputMutation';
import { PUBLISH_ONE_INVENTORY } from './gql';
import { ApiInventory, Inventory } from '../types';
import { transformApiInventoryToInventory } from '../transforms';

type Response = {
  publishInventory: {
    inventory?: ApiInventory;
  };
};

export const usePublishOneInventory = () => {
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  return useIdInputMutation<Response, ValidatedEntity<Inventory> | undefined>(
    PUBLISH_ONE_INVENTORY,
    (data) => schemas?.inventory.apiSchema ? transformApiInventoryToInventory(
      validateApiResponseEntity(
        data?.publishInventory.inventory,
        schemas.inventory.apiSchema,
      ),
    ) : undefined,
    {
      onSuccess: () => setMessage(createSuccessfulPublishMessage('')),
      onError: (err) => setMessage(createFailedPublishMessage('', err)),
    },
  );
};
