import { ApolloCache, gql } from '@apollo/client';

export function updateCacheOnDeleteOne<T>(
  cache: ApolloCache<T>,
  key: string,
  id?: string | null,
) {
  cache.modify({
    fields: {
      [key](existing = [], { readField }) {
        return existing.filter(
          (ref: any) => id !== readField('id', ref),
        );
      },
    }
  });
}

export function updateCacheOnCreateOne<T, D>(
  cache: ApolloCache<T>,
  key: string,
  data: D,
  fragment: string,
) {
  cache.modify({
    fields: {
      [key](existing = []) {
        const newRef = cache.writeFragment({
          data,
          fragment: gql`${fragment}`,
        });
        return [...existing, newRef];
      }
    },
  });

}
