import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

interface OwnProps {
  id: string;
}

type Props = OwnProps;

const IdChip = (props: Props) => {
  const classes = useStyles();
  return (
    <span className={classes.container}>
      <code className={classes.text}>{props.id}</code>
    </span>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(.5),
    borderRadius: 4,
    backgroundColor: theme.palette.grey[200],
  },
  text: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.error.light,
  },
}));

export const idCellRenderer = (id: string) => <IdChip id={id} />;
