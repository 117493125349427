import * as yup from 'yup';
import { atom } from 'recoil';
import { ApiCommunity, ApiCommunityInput } from '../community/types';
import { ApiContact, ApiContactInput } from '../contact/types';
import { ApiFloorPlan, ApiFloorPlanInput } from '../floorPlan/types';
import { ApiEmbedding, ApiEmbeddingInput } from '../embedding/types';
import { ApiFeatureTag } from '../featureTag/types';
import { ApiFeatureTagCategory } from '../featureTagCategory/types';
import { ApiInventory, ApiInventoryInput } from '../inventory/types';
import { ApiSalesCentre, ApiSalesCentreInput } from '../salesCentre/types';
import { Vendor } from '../vendor/types';
import { ApiShowHome, ApiShowHomeInput } from '../showhome/types';
import { ApiImageLink, ApiImageLinkInput } from '../imageLink/types';

export type ApiProviderState = {
  community: {
    apiInputSchema: yup.ObjectSchema<ApiCommunityInput, object>;
    apiSchema: yup.ObjectSchema<ApiCommunity, object>;
  };
  contact: {
    apiInputSchema: yup.ObjectSchema<ApiContactInput, object>;
    apiSchema: yup.ObjectSchema<ApiContact, object>;
  };
  floorPlan: {
    apiInputSchema: yup.ObjectSchema<ApiFloorPlanInput, object>;
    apiSchema: yup.ObjectSchema<ApiFloorPlan, object>;
  };
  showHome: {
    apiInputSchema: yup.ObjectSchema<ApiShowHomeInput, object>;
    apiSchema: yup.ObjectSchema<ApiShowHome, object>;
  };
  inventory: {
    apiInputSchema: yup.ObjectSchema<ApiInventoryInput, object>;
    apiSchema: yup.ObjectSchema<ApiInventory, object>;
  };
  salesCentre: {
    apiInputSchema: yup.ObjectSchema<ApiSalesCentreInput, object>;
    apiSchema: yup.ObjectSchema<ApiSalesCentre, object>;
  };
  embedding: {
    apiInputSchema: yup.ObjectSchema<ApiEmbeddingInput, object>;
    apiSchema: yup.ObjectSchema<ApiEmbedding, object>;
  };
  vendor: {
    apiSchema: yup.ObjectSchema<Vendor, object>;
  };
  featureTag: {
    apiSchema: yup.ObjectSchema<ApiFeatureTag, object>;
  }
  featureTagCategory: {
    apiSchema: yup.ObjectSchema<ApiFeatureTagCategory, object>;
  },
  imageLink: {
    apiInputSchema: yup.ObjectSchema<ApiImageLinkInput, object>;
    apiSchema: yup.ObjectSchema<ApiImageLink, object>;
  }
};

export const apiProviderState = atom<ApiProviderState | null>({
  key: 'ApiProviderState',
  default: null,
});
