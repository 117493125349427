import * as yup from 'yup';
import { Vendor } from './types';

export const apiVendorValidationSchema = yup.object<Vendor>({
  id: yup.string().defined().default(''),
  type: yup.string().defined().default(''),
  displayName: yup.string().defined().default(''),
  icon: yup.string().defined().default(''),
  allowedRegex: yup.string().defined().default(''),
  urlHelperText: yup.string().defined().default(''),
}).defined();
