import { compact } from 'lodash';
import { ApiSalesCentre, ApiSalesCentreInput, SalesCentre } from './types';

export const transformSalesCentreToApiSalesCentreInput = (
  data: Partial<SalesCentre>,
): ApiSalesCentreInput => ({
  name: data.name,
  email: data.email,
  phone: data.phone,
  address: data.address,
  hours: data.hours,
  community: data.community,
});

export const transformApiSalesCentreToSalesCentre = (
  data?: ApiSalesCentre,
): SalesCentre | undefined => data ? ({
  id: data.id,
  name: data.name,
  email: data.email,
  phone: data.phone,
  address: data.address,
  hours: data.hours,
  contacts: data.contacts,
  community: data.community.id,
}) : undefined;

export const transformApiInventoriesToInventories = (
  data?: ApiSalesCentre[],
) => data ? compact(data.map(el => transformApiSalesCentreToSalesCentre(el))) : [];
