import React from 'react';
import { useContentSpaceId } from '_lib/auth';
import {
  ShowHome,
  useGetOneShowHome,
  useUpdateOneShowHome,
  usePublishOneShowHome,
  useUnpublishOneShowHome,
  useDeleteOneShowHome,
} from 'domain/api/showhome';
import { useGetManyCommunities } from 'domain/api/community';
import { useGetManyFloorPlans } from 'domain/api/floorPlan';
import { useUiText } from 'domain/uiText';
import { useEntityFields } from 'domain/entities/hooks';
import { useResourceTopbarConfig } from 'domain/view/components/layout/topbar/useResourceTopbarConfig';
import { ShowHomeDetailPage } from './ShowHomeDetailPage';

export const ShowHomeDetailPageContainer = () => {
  const uiText = useUiText();
  const { removeDisabledFields } = useEntityFields('showHome');
  const contentSpaceId = useContentSpaceId();
  const getCommunities = useGetManyCommunities();
  const getFloorPlans = useGetManyFloorPlans();
  const getShowHome = useGetOneShowHome();
  const updateShowHome = useUpdateOneShowHome();
  const publishShowHome = usePublishOneShowHome();
  const unpublishShowHome = useUnpublishOneShowHome();
  const deleteShowHome = useDeleteOneShowHome(uiText.SHOWHOME_PATH);

  const data = getShowHome.result;
  const { id = '', publishedStatus = 'error', validationErrors } = data || {};

  const get = (_id: string) => {
    getShowHome.execute(_id);
    getCommunities.execute();
    getFloorPlans.execute();
  };

  const handleUpdate = (values: Partial<ShowHome>) => {
    if (!id || !contentSpaceId) return;
    updateShowHome.execute({
      contentSpaceId,
      idType: 'CONTENT_ID',
      id,
      showHome: removeDisabledFields(values),
    });
  };

  const handlePublish = () => id && publishShowHome.execute({ id, idType: 'CONTENT_ID' });

  const handleUnpublish = () => id && unpublishShowHome.execute({ id, idType: 'CONTENT_ID' });

  const handleDelete = () => id && deleteShowHome.execute({ id, idType: 'CONTENT_ID' });

  const handleRefetch = () => getShowHome.refetch?.(id);

  /**
   * @todo
   * Refactor this when/if we refactor the resoure
   */
  const topbarConfig = useResourceTopbarConfig(
    'showHome',
    publishedStatus,
    handleDelete,
    handlePublish,
    handleUnpublish,
    { ...data, id }
  );

  const loading =
    getShowHome.loading ||
    updateShowHome.loading ||
    deleteShowHome.loading ||
    publishShowHome.loading ||
    unpublishShowHome.loading;

  return (
    <ShowHomeDetailPage
      get={get}
      handleUpdate={handleUpdate}
      handleRefetch={handleRefetch}
      loading={loading}
      called={getShowHome.called}
      publishedStatus={publishedStatus}
      data={data as ShowHome}
      validation={{ errors: validationErrors }}
      topbar={topbarConfig}
      contentSpaceId={contentSpaceId || ''}
      floorPlans={getFloorPlans.result}
      communities={getCommunities.result}
    />
  );
};
