import * as yup from 'yup';
import { compact } from 'lodash';
import { UiText } from 'domain/uiText';
import { pick } from '_lib/common/helpers';
import { ValidatedEntity } from './types';

export const publishProblemsSchema = yup
  .array()
  .of(
    yup
      .object()
      .shape({
        field: yup.string().required().defined(),
        problemCode: yup.string().required().defined(),
      })
      .defined()
  )
  .defined();

export const commonApiValidationSchemas = (uiText: UiText) => ({
  id: yup.string().defined().required().default('').label('Content ID'),
  builderId: yup.string().defined().required().default('').label('ID'),
  displayName: yup.string().defined().required().default('').label('Name'),
  displayPriority: yup.number().defined().default(null).label('Display Priority'),
  showCommuteCalculation: yup.boolean().defined().default(false).label('Commute Calculator'),
  showMonthlyPaymentCalculator: yup
    .boolean()
    .defined()
    .default(false)
    .label('Monthly Payment Calculator'),
  seoTitleTag: yup.string().defined().default('').label('SEO Title'),
  seoMetaKeywords: yup
    .array()
    .of(yup.string().defined())
    .defined()
    .compact()
    .default([])
    .label('SEO Keywords'),
  seoMetaDescription: yup.string().defined().default(''),
  homeType: yup.string().defined().required().default('').label('Home Type'),
  bedrooms: yup.number().defined().default(null).label('Bedrooms'),
  bathrooms: yup.number().defined().default(null).label('Bathrooms'),
  halfBaths: yup.number().defined().default(null).label('Half Bathrooms'),
  floorSize: yup.number().defined().default(null).label('Floor Size'),
  garage: yup.string().defined().default(''),
  publishProblems: publishProblemsSchema,
  published: yup.boolean().defined().required().default(false).label('Published'),
  fullAddress: yup.string().defined().default(''),
  address: yup.string().defined().default('').label('Address'),
  city: yup.string().defined().default('').label('City'),
  countrySubDivisionCode: yup
    .string()
    .defined()
    .nullable(true)
    .default(null)
    .label(uiText.LABEL_COUNTRY_SUBDIVISION_CODE),
  country: yup.string().defined().default('').label('Country'),
  postalCode: yup.string().defined().nullable(true).default(null).label(uiText.LABEL_POST_CODE),
});

export const pickCommonApiValidationSchemas = (uiText: UiText) => {
  const commonSchemas = commonApiValidationSchemas(uiText);
  return (keys: (keyof typeof commonSchemas)[]) => pick(commonSchemas, keys);
};

export const longitudeValidationSchema = yup
  .number()
  .defined()
  .min(-180)
  .max(180)
  .nullable()
  .default(null)
  .when('community', {
    is: null,
    then: yup.number().defined().min(-180).max(180).required(''),
  })
  .label('Longitude');
export const latitudeValidationSchema = yup
  .number()
  .defined()
  .min(-90)
  .max(90)
  .nullable()
  .default(null)
  .when('community', {
    is: null,
    then: yup.number().defined().min(-180).max(180).required(''),
  })
  .label('Latitude');

export const contentEntryValidationSchema = yup.object({
  __typename: yup.string().defined().default(''),
  id: yup.string().defined().default(''),
  builderId: yup.string().defined().default(''),
  published: yup.boolean().defined().default(false),
});
/**
 * @description
 * Synchronously validates the response from an entity query/mutation.
 */
export function validateApiResponseEntity<T extends Record<string, any>>(
  data: any,
  schema: yup.ObjectSchema<T, object>
): ValidatedEntity<T> | undefined {
  try {
    if (!data) return undefined;

    const result = schema.validateSync(data, { abortEarly: false });

    return { ...result };
  } catch (err) {
    const error = err as any;
    if (error && error.name && error.name === 'ValidationError' && error.value && error.errors) {
      return {
        ...(error.value || {}),
        validationErrors: error.errors.map((el: string) => ({ message: el, path: '' })),
      };
    }
    throw new Error('An unknown error occurred during validation');
  }
}

export function validateApiResponseEntities<T extends Record<string, any>>(
  data: any,
  schema: yup.ObjectSchema<T, object>
): ValidatedEntity<T>[] | undefined {
  if (data && Array.isArray(data)) {
    return compact(data.map((el) => validateApiResponseEntity(el, schema)));
  }
  return undefined;
}
