import React from 'react';
import _ from 'lodash';
import { assertUnreachable } from 'utilities/errors';
import * as yup from 'yup';
import {
  CheckboxField,
  NumberField,
  TextField,
  TextListField,
  SelectField,
  FileField,
} from '../fields';
import {
  NO_MODIFIERS_WITHOUT_SCHEMA_ERROR,
  InitialValues,
  OnChange,
  FormState,
  TransformedFieldValues,
  FieldModifiers,
  FieldConfigs,
  // Todo: Make these part of the field types rather than defining them here
  TextFieldConfig,
  NumberFieldConfig,
  TextListFieldConfig,
  CheckboxFieldConfig,
  SelectFieldConfig,
  FieldErrors,
  FileFieldConfig,
  FormFields,
} from '../types';
import { createErrors, flattenValues } from '../utils';
import { DateField } from '../fields/DateField/DateField';

type Props<T extends InitialValues> = {
  fields: T;
  validation?: yup.ObjectSchema;
  fieldModifiers?: Partial<FieldModifiers<T>>;
  hasReadonly?: boolean;
  omittedFields?: (keyof T & string)[];
  includeUntouchedFields?: boolean;
};

// This prevents the initial values of the form being stale
function useInitialState<T extends InitialValues>(
  fields: T,
  validation?: yup.ObjectSchema,
  omittedFields?: (keyof T & string)[],
) {
  return React.useCallback(() => ({
    fields: { ...fields },
    errors: createErrors(fields, validation, omittedFields),
    isEditing: false,
    attemptedSubmit: false
  }), [fields, validation, omittedFields]);
}

export function useFieldRenderer<T extends InitialValues>({
  fields,
  validation,
  fieldModifiers,
  hasReadonly,
  omittedFields,
  includeUntouchedFields,
}: Props<T>) {
  if (fieldModifiers && !validation) throw new Error(NO_MODIFIERS_WITHOUT_SCHEMA_ERROR);
  const initialState = useInitialState(fields, undefined, omittedFields);
  const [externalErrors, setExternalErrors] = React.useState<Partial<FieldErrors<T>>>({});
  const [state, setState] = React.useState<FormState<T>>(initialState());
  const onChange: OnChange<keyof T & string, unknown> = React.useCallback((
    name,
    value,
  ) => {
    setState(prev => {
      const newState = { ...prev };

      // Update the value of the field
      newState.fields[name].value = value;

      // This is used to prevent running validation on fields that haven't yet been changed,
      // which can lead to field errors being displayed in the default form state.
      // All fields will still be validated when onSubmit is called.
      if (!newState.fields[name].touched) newState.fields[name].touched = true;


      if (validation) {
        // Validate the newly update field and update state with new validation output.
        const fieldErrors = createErrors(newState.fields, validation, omittedFields)[name];
        newState.errors[name] = fieldErrors;

        const modifier = fieldModifiers ? fieldModifiers[name] : undefined;
        if (modifier) {
          // ** Important **
          // A fieldModifier receives the entire form state as an arugment and is expected
          // to return the entire form state. But this also means fieldModifers mutate fields other
          // than the current one, so we need to validate the 'modified state'.

          // The solution below isn't ideal, since it validates all form fields regardless of if they
          // have changed or not. A more performant approach would be to diff the modified state with the
          // current state and only validate fields that have change.
          const modifierResult = modifier(newState.fields, newState.errors);
          newState.fields = modifierResult.fields;
          const errors = {
            ...createErrors(newState.fields, validation, omittedFields),
            ...modifierResult.errors,
          };

          newState.errors = errors;
        }
      }

      return newState;
    });
  }, [fieldModifiers, validation, omittedFields]);

  const onSubmit = (
    // Todo: Fix validation so we can call this onError function
    onError: (errors: FieldErrors<T>) => void,
    onSuccess: (values: TransformedFieldValues<T>) => void,
  ) => {
    setState({ ...state, attemptedSubmit: true });
    // Selecting only the fields that have been touched
    const touchedFields = includeUntouchedFields
      ? state.fields
      : Object.fromEntries(Object.entries(state.fields).filter(
        (entry) => entry[1].touched),
      ) as FormFields<T>;

    if (validation) {
      const errors = createErrors(
        touchedFields,
        validation,
        [
          ...(omittedFields || []),
          // Don't validate fields that haven't been touched
          ...(
            includeUntouchedFields
              ? []
              : Object.keys(state.fields).filter(key => (
                !Object.keys(touchedFields).includes(key)
              ))
          ),
        ],
      );

      if (hasFieldErrors(errors) || hasFieldErrors(state.errors)) {
        const newState = state;
        const names = Object.keys(errors);
        names.map(name => {
          newState.fields[name].touched = true;
          return undefined;
        });

        onError(errors);
      } else {
        onSuccess(flattenValues(touchedFields));
        setEditing(false);
      }
    } else {
      onSuccess(flattenValues(touchedFields));
      setEditing(false);
    }
  };

  // eslint-disable-next-line consistent-return
  function renderField<TName extends keyof T & string>(
    name: TName,
    fieldProps: FieldConfigs[T[TName]['type']],
  ) {
    const field = state.fields[name];

    const externalFieldErrors: string[] | undefined = externalErrors[name];

    const combinedErrors = [
      ...(state.errors[name] ? state.errors[name] : []),
      ...(externalFieldErrors ? externalFieldErrors : [])
    ];

    // Don't display field errors if the field hasn't been touched.
    const errors = field.touched ? combinedErrors : undefined;
    const readonly = hasReadonly && !state.isEditing;
    switch (field.type) {
      case 'text':
        return (
          <TextField
            name={name}
            value={field.value}
            onChange={onChange}
            errors={errors}
            readonly={readonly}
            {...fieldProps as TextFieldConfig}
          />
        );
      case 'number':
        return (
          <NumberField
            name={name}
            value={field.value}
            onChange={onChange}
            errors={errors}
            readonly={readonly}
            {...fieldProps as NumberFieldConfig}
          />
        );
      case 'text-list':
        return (
          <TextListField
            name={name}
            value={field.value}
            onChange={onChange}
            errors={errors}
            readonly={readonly}
            {...fieldProps as TextListFieldConfig}
          />
        );
      case 'check-box':
        return (
          <CheckboxField
            name={name}
            value={field.value}
            onChange={onChange}
            errors={errors}
            readonly={readonly}
            {...fieldProps as CheckboxFieldConfig}
          />
        );
      case 'select':
        return (
          <SelectField
            name={name}
            value={field.value}
            onChange={onChange}
            errors={errors}
            readonly={readonly}
            {...fieldProps as SelectFieldConfig}
          />
        );
      case 'file':
        return (
          <FileField
            name={name}
            value={field.value}
            onChange={onChange}
            errors={errors}
            readonly={readonly}
            {...fieldProps as FileFieldConfig}
          />
        );
      case 'date':
        return (
          <DateField
            name={name}
            value={field.value}
            onChange={onChange}
            errors={errors}
            readonly={readonly}
            {...fieldProps as FileFieldConfig}
          />
        );
      default:
        assertUnreachable(field.type as never);
    }
  }
  // Internal helpers
  const setEditing = (value: boolean) => setState(prev => ({ ...prev, isEditing: value }));
  const hasFieldErrors = (errors: FieldErrors<T>) => {
    return Object.keys(errors).some(key => errors[key]?.length > 0);
  };
  // External helpers
  const reset = () => setState(prev => initialState());
  const onEdit = () => setEditing(true);
  const onCancel = () => reset();
  const canSubmit = Object.entries(state.fields).some(([key, value]) => value.touched);
  const setErrorsExternally = (errors: Partial<FieldErrors<T>>) => setExternalErrors(prev => ({
    ...prev,
    ...errors,
  }));

  const allErrors = _.merge({ ...state.errors }, { ...externalErrors });

  return {
    fields: state.fields,
    renderField,
    onSubmit,
    onEdit,
    onCancel,
    reset,
    setErrorsExternally,
    isEditing: state.isEditing,
    hasErrors: hasFieldErrors(allErrors),
    canSubmit,
    errors: allErrors,
    attemptedSubmit: state.attemptedSubmit
  };
}
