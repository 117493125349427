import * as yup from 'yup';
import { Config, EntityAction, EntityConfig } from './types';

const resourceActions: EntityAction[] = ['create', 'delete', 'update', 'publish', 'unpublish'];

const entityConfigSchema = yup.object<EntityConfig>({
  enabled: yup.boolean().defined().default(false).required(),
  aliases: yup.object({
    path: yup.string().defined().default('').required(),
    plural: yup.string().defined().default('').required(),
    singular: yup.string().defined().default('').required(),
  }).defined().required(),
  fields: yup.object({
    allAutomatic: yup.boolean().defined().required().default(true),
    allManual: yup.boolean().defined().required().default(false),
    manual: yup.array().of(yup.string().defined()).defined().nullable().default([]),
    automatic: yup.array().of(yup.string().defined()).defined().nullable().default([]),
  }).defined().required(),
  actions: yup.object({
    allAutomatic: yup.boolean().defined().required().default(true),
    allManual: yup.boolean().defined().required().default(false),
    manual:
      yup.array()
        .of(yup.string().equals(resourceActions).trim().defined())
        .compact()
        .defined().nullable().default([]),
    automatic:
      yup.array()
        .of(yup.string().equals(resourceActions).trim().defined())
        .compact()
        .defined().nullable().default([]),
  }).defined().required(),
}).defined().required();

export const configSchema = yup.object<Config>({
  defaultGeoPoint: yup.object({
    lat: yup.number().defined().default(0).required(),
    lng: yup.number().defined().default(0).required(),
  }).defined().required(),
  resources: yup.object({
    floorPlan: entityConfigSchema,
    community: entityConfigSchema,
    inventory: entityConfigSchema,
    showHome: entityConfigSchema,
    imageLink: entityConfigSchema,
    banner: entityConfigSchema,
    embedding: entityConfigSchema,
    video: entityConfigSchema,
    contact: entityConfigSchema,
    salesCentre: entityConfigSchema,
    featureTag: entityConfigSchema,
  }).defined().required(),
}).defined().required();
