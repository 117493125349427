import React from 'react';
import { Button } from '@material-ui/core';
import { useToggle } from 'common/hooks/useToggle';
import { ResourcesContextValue } from 'system/resources/types';
import { Table } from '_lib/components';
import { ResourceConstraint } from 'lib/resource';
import { FormDialog } from '_lib/form/formDialog/FormDialog';

type Props<
TTarget extends ResourceConstraint,
TTargetsName extends keyof ResourcesContextValue,
> = {
  title: string;
  targets: TTarget[];
  targetsName: TTargetsName;
  loading: boolean;
  called: boolean;
  disabled: boolean;
  onAddMappings: (ids: string[]) => void;
};

export function AddMappingsDialog<
TTarget extends ResourceConstraint,
TTargetsName extends keyof ResourcesContextValue,
>({
  title,
  targetsName,
  targets,
  loading,
  called,
  disabled,
  onAddMappings,
}: Props<TTarget, TTargetsName>) {
  const dialogState = useToggle();
  const [selected, setSelected] = React.useState<string[]>([]);

  const handleSelect = (ids: string[]) => setSelected(() => ids);
  const handleSubmit = () => selected.length > 0 && onAddMappings(selected);

  React.useEffect(() => {
    if (called && !loading && selected.length > 0 && dialogState.open) {
      dialogState.onClose();
      setSelected([]);
    }
  }, [called, loading, dialogState, selected]);

  return (
    <React.Fragment>
      <Button
        size="small"
        variant="outlined"
        style={{ textTransform: 'capitalize', marginRight: 8 }}
        onClick={dialogState.onOpen}
        disabled={targets.length <= 0 || disabled}
      >
        {title}
      </Button>
      <FormDialog
        title={title}
        open={dialogState.open}
        submitDisabled={selected.length <= 0}
        onClose={dialogState.onClose}
        onSubmit={handleSubmit}
        submitting={loading}
        wideDialog
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Table
            resourceName={targetsName}
            /**
             * @todo Fix this
             */
            data={targets as any}
            noDataMessage="No data"
            actions={{}}
            variant="flat"
            onSelect={handleSelect}
            selectedRows={selected}
          />
        </div>
      </FormDialog>
    </React.Fragment>
  );
}
