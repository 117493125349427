import React from 'react';
import { FormControlLabel, Checkbox as MaterialCheckbox } from '@material-ui/core';
import { CheckboxFieldControlProps } from './types';

export function CheckboxFieldControl<TName extends string>({
  required,
  value,
  name,
  label,
  disabled,
  errors,
  onChange,
}: CheckboxFieldControlProps<TName>) {
  const handleChange = (e: React.BaseSyntheticEvent) => {
    e.persist();
    onChange(e.target.name, e.target.checked);
  };
  return (
    <FormControlLabel
      control={(
        <MaterialCheckbox
          color="primary"
          checked={value}
          name={name}
          required={required}
          disabled={disabled}
          onChange={handleChange}
        />
      )}
      label={label}
    />
  );
}
