import * as yup from 'yup';
import { UiText } from 'domain/uiText';
import { ImageLinkFields } from './types';

const sharedSchemas = (uiText: UiText) => ({
  displayName: yup.string().defined().required().default('').label('Name'),
  url: yup.string().defined().default('').label('URL'),
});

export const imageLinkFieldsSchema = (uiText: UiText) => yup.object<ImageLinkFields>({
  ...sharedSchemas(uiText),
  floorPlans:
    yup.array().of(yup.string().defined()).compact().defined().default([])
      .label(uiText.FLOOR_PLAN_PLURAL),
  communities:
    yup.array().of(yup.string().defined()).compact().defined().default([])
      .label(uiText.COMMUNITY_PLURAL),
  inventories:
    yup.array().of(yup.string().defined()).compact().defined().default([])
      .label(uiText.INVENTORY_PLURAL),
  showHomes:
    yup.array().of(yup.string().defined()).compact().defined().default([])
      .label(uiText.SHOWHOME_PLURAL),
  files: yup.array().of(yup.object<Pick<File, 'name' | 'lastModified' | 'size' | 'type'>>({
    name: yup.string().defined().required(),
    lastModified: yup.number().defined().required(),
    size: yup.number().defined().required(),
    type: yup.string().defined().required(),
  }).defined()).defined(),
}).defined();
