import { useLazyQuery } from '@apollo/client';
import {
  GetOneVariables,
  ResponseWithContentSpace,
  ValidatedEntity,
} from 'domain/api/common/types';
import { GetOneAdapterAction } from 'domain/api/common/adapters/types';
import { useContentSpaceId } from '_lib/auth';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { ApiInventory, Inventory } from '../types';
import { GET_ONE_INVENTORY } from './gql';
import { transformApiInventoryToInventory } from '../transforms';

type Response = ResponseWithContentSpace<{ inventory: ApiInventory }>;
type Variables = GetOneVariables;

export const useGetOneInventory: () => GetOneAdapterAction<ValidatedEntity<Inventory>> = () => {
  const schemas = useApiSchemas();
  const contentSpaceId = useContentSpaceId();
  const [query, queryResult] = useLazyQuery<Response, Variables>(GET_ONE_INVENTORY);

  const execute = (id: string) => {
    contentSpaceId && query({ variables: { contentSpaceId, id } });
  };

  const refetch = (id: string) => {
    contentSpaceId && queryResult?.refetch?.({ contentSpaceId, id });
  };

  const validated = schemas?.inventory.apiSchema
    ? validateApiResponseEntity(
      queryResult.data?.contentSpace.inventory,
      schemas.inventory.apiSchema
    ) : undefined;

  return {
    execute,
    refetch,
    result: transformApiInventoryToInventory(validated),
    loading: queryResult.loading,
    called: queryResult.called,
    error: queryResult.error?.message,
  };
};
