import React from 'react';

import CardAccountDetailsIcon from 'mdi-material-ui/CardAccountDetails';
import CardTextOutlineIcon from 'mdi-material-ui/CardTextOutline';
import FileIcon from 'mdi-material-ui/File';
import FileImageIcon from 'mdi-material-ui/FileImage';
import HomeMapMarkerIcon from 'mdi-material-ui/HomeMapMarker';
import HomeRoofIcon from 'mdi-material-ui/HomeRoof';
import ImageIcon from 'mdi-material-ui/Image';
import ImageMultipleIcon from 'mdi-material-ui/ImageMultiple';
import InformationVariantIcon from 'mdi-material-ui/InformationVariant';
import LinkBoxVariantIcon from 'mdi-material-ui/LinkBoxVariant';
import FormatListBulletedIcon from 'mdi-material-ui/FormatListBulleted';
import MapIcon from 'mdi-material-ui/Map';
import MapMarkerIcon from 'mdi-material-ui/MapMarker';
import PanoramaIcon from 'mdi-material-ui/Panorama';
import PlayBoxMultipleIcon from 'mdi-material-ui/PlayBoxMultiple';
import PuzzleIcon from 'mdi-material-ui/Puzzle';
import SelectionMarkerIcon from 'mdi-material-ui/SelectionMarker';
import TagIcon from 'mdi-material-ui/Tag';
import TextIcon from 'mdi-material-ui/Text';
import TextureBoxIcon from 'mdi-material-ui/TextureBox';
import WidgetsIcon from 'mdi-material-ui/Widgets';
import WindowMinimizeIcon from 'mdi-material-ui/WindowMinimize';

import { useUiText } from 'domain/uiText';
import { SectionsOptions } from './types';

export const useSections = (): SectionsOptions => {
  const uiText = useUiText();
  return {
    basicInformation: {
      id: 'basic-information',
      icon: <InformationVariantIcon />,
      title: uiText.SECTION_BASIC_INFO,
    },
    description: {
      id: 'description',
      icon: <TextIcon />,
      title: uiText.SECTION_DESCRIPTION,
      helpText: uiText.HELP_DESCRIPTION,
    },
    features: {
      id: 'features',
      icon: <FormatListBulletedIcon/>,
      title: uiText.SECTION_FEATURE_TAGS,
    },
    mainImage: {
      id: 'main-image',
      icon: <ImageIcon />,
      title: uiText.SECTION_MAIN_IMAGE,
    },
    gallery: {
      id: 'gallery',
      icon: <ImageMultipleIcon />,
      title: uiText.SECTION_GALLERY,
    },
    openhouseWidgets: {
      id: 'openhouse-widgets',
      icon: <WidgetsIcon />,
      title: uiText.SECTION_OPENHOUSE_WIDGETS,
    },
    location: {
      id: 'location',
      icon: <MapMarkerIcon />,
      title: uiText.SECTION_LOCATION,
    },

    startingPrices: {
      id: 'starting-prices',
      icon: <TagIcon />,
      title: uiText.SECTION_STARTING_PRICES,
    },
    communityPricing: {
      id: 'community-pricing',
      icon: <TagIcon />,
      title: uiText.SECTION_COMMUNITY_PRICING,
    },
    lotmap: {
      id: 'lotmap',
      icon: <MapIcon />,
      title: uiText.SECTION_LOTMAP,
    },
    thumbnail: {
      id: 'thumbnail-image',
      icon: <ImageIcon />,
      title: uiText.SECTION_THUMBNAIL_IMAGE,
    },
    bannerImage: {
      id: 'banner-image',
      icon: <PanoramaIcon />,
      title: uiText.SECTION_BANNER_IMAGE,
    },
    logoImage: {
      id: 'logo-image',
      icon: <TextureBoxIcon />,
      title: uiText.SECTION_LOGO_IMAGE,
    },
    sloganImage: {
      id: 'slogan-image',
      icon: <CardTextOutlineIcon />,
      title: uiText.SECTION_SLOGAN_IMAGE,
    },
    demostats: {
      id: 'demostats',
      icon: <SelectionMarkerIcon />,
      title: uiText.SECTION_PROJECT_DEMOSTATS,
    },

    floorplans: {
      id: 'floor-plan-images',
      icon: <HomeRoofIcon />,
      title: uiText.SECTION_FLOOR_PLAN_IMAGES,
    },
    attachmentFile: {
      id: 'attachment-file',
      icon: <FileIcon />,
      title: uiText.SECTION_ATTACHMENT_FILE,
    },
    attachmentImage: {
      id: 'attachment-image',
      icon: <FileImageIcon />,
      title: uiText.SECTION_ATTACHMENT_IMAGE,
    },

    pricing: {
      id: 'pricing',
      icon: <TagIcon />,
      title: uiText.SECTION_PRICING,
    },
    address: {
      id: 'address',
      icon: <HomeMapMarkerIcon />,
      title: uiText.SECTION_ADDRESS,
    },

    banners: {
      id: uiText.BANNER_PATH,
      icon: <WindowMinimizeIcon />,
      title: uiText.BANNER_PLURAL,
    },
    imageLinks: {
      id: uiText.IMAGE_LINK_PATH,
      icon: <LinkBoxVariantIcon />,
      title: uiText.IMAGE_LINK_PLURAL,
    },
    thirdPartyWidgets: {
      id: uiText.THIRD_PARTY_PLUGIN_PATH,
      icon: <PuzzleIcon />,
      title: uiText.THIRD_PARTY_PLUGIN_PLURAL,
    },
    videos: {
      id: uiText.VIDEO_PATH,
      icon: <PlayBoxMultipleIcon />,
      title: uiText.VIDEO_PLURAL,
    },
    salesCentres: {
      id: uiText.SALES_CENTRE_PATH,
      icon: <CardAccountDetailsIcon />,
      title: uiText.SALES_CENTRE_PLURAL,
    },
  };
};
