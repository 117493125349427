import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

interface OwnProps {
  errors?: string[]
}

type Props = OwnProps;

export const FieldErrors = ({ errors }: Props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      {errors && errors.map(err => (
        <span key={`field-error-${err}`} className={classes.root}>{err}</span>
      ))}
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'block',
  },
}));
