import { gql } from '@apollo/client';
import { AllAssetInfo } from '../fragments';

export const ORDER_ASSETS = gql`
fragment AllAssetInfo on Asset {
  ${AllAssetInfo}
}

mutation orderAssets($input: OrderAssetsInput) {
  orderAssets(input: $input) {
    assets {
      ...AllAssetInfo
    }
  }
}
`;
