import {
  createFailedUnpublishMessage,
  createSuccessfulUnpublishMessage,
  useSetMessage,
} from '_lib/messages';
import { useIdInputMutation } from 'domain/api/common/adapters/useIdInputMutation';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { ValidatedEntity } from 'domain/api/common/types';
import { UNPUBLISH_ONE_COMMUNITY } from './gql';
import { ApiCommunity, Community } from '../types';
import { transformApiCommunityToCommunity } from '../transforms';

type Response = {
  unpublishCommunity: {
    community?: ApiCommunity;
  };
};

export const useUnpublishOneCommunity = () => {
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  return useIdInputMutation<Response, ValidatedEntity<Community> | undefined>(
    UNPUBLISH_ONE_COMMUNITY,
    (data) =>
      schemas?.community.apiSchema
        ? transformApiCommunityToCommunity(
          validateApiResponseEntity(
            data?.unpublishCommunity.community,
            schemas.community.apiSchema
          )
        )
        : undefined,
    {
      onSuccess: () => setMessage(createSuccessfulUnpublishMessage('')),
      onError: (err) => setMessage(createFailedUnpublishMessage('', err)),
    }
  );
};
