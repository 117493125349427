import React from 'react';
import { CommunitiesListPageContainer } from 'domain/features/community/pages/listPage/CommunitiesListPageContainer';
import { CommunityDetailPageContainer } from 'domain/features/community/pages/detailPage/CommunityDetailPageContainer';
import { FloorPlanListPageContainer } from 'domain/features/floorPlan/pages/listPage/FloorPlanListPageContainer';
import { FloorPlanDetailPageContainer } from 'domain/features/floorPlan/pages/detailPage/FloorPlanDetailPageContainer';
import { InventoryDetailPageContainer } from 'domain/features/inventory/pages/detailPage/InventoryDetailPageContainer';
import { InventoriesListPageContainer } from 'domain/features/inventory/pages/listPage/InventoriesListPageContainer';
import { ShowHomesListPageContainer } from 'domain/features/showHome/pages/listPage/ShowHomesListPageContainer';
import { ShowHomeDetailPageContainer } from 'domain/features/showHome/pages/detailPage/ShowHomeDetailPageContainer';
import { ImageLinksListPageContainer } from 'domain/features/imageLinks/pages/listPage/ImageLinksListPageContainer';
import { BannersListPageContainer } from 'domain/features/banners/pages/listPage/BannersListPageContainer';
import { ContactsListPageContainer } from 'domain/features/contacts/pages/listPage/ContactsListPageContainer';
import { VideosListPageContainer } from 'domain/features/videos/pages/listPage/VideosListPageContainer';
import { ThirdPartyWidgetsListPageContainer } from 'domain/features/thirdPartyWidgets/pages/listPage/ThirdPartyWidgetsListPageContainer';
import { LandingPageContainer } from 'domain/view/pages/LandingPage/LandingPageContainer';
import { FeatureTagsListPageContainer } from 'domain/features/featureTags/pages/listPage/FeatureTagsListPageContainer'
import { RoutesOptions } from './types';
import { useLinks } from './useLinks';

export const useRoutes = (): RoutesOptions => {
  const links = useLinks();

  return {
    landing: {
      ...links.landing,
      component: <LandingPageContainer />,
    },
    communityList: {
      ...links.communityList,
      component: <CommunitiesListPageContainer />,
    },
    communityDetail: {
      ...links.communityDetail,
      component: <CommunityDetailPageContainer />,
    },
    floorPlanList: {
      ...links.floorPlanList,
      component: <FloorPlanListPageContainer />,
    },
    floorPlanDetail: {
      ...links.floorPlanDetail,
      component: <FloorPlanDetailPageContainer />,
    },
    inventoryList: {
      ...links.inventoryList,
      component: <InventoriesListPageContainer />,
    },
    inventoryDetail: {
      ...links.inventoryDetail,
      component: <InventoryDetailPageContainer />,
    },
    showHomeList: {
      ...links.showHomeList,
      component: <ShowHomesListPageContainer />,
    },
    showHomeDetail: {
      ...links.showHomeDetail,
      component: <ShowHomeDetailPageContainer />,
    },
    imageLinkList: {
      ...links.imageLinkList,
      component: <ImageLinksListPageContainer />,
    },
    bannerList: {
      ...links.bannerList,
      component: <BannersListPageContainer />,
    },
    videoList: {
      ...links.videoList,
      component: <VideosListPageContainer />,
    },
    thirdParyWidgetList: {
      ...links.thirdParyWidgetList,
      component: <ThirdPartyWidgetsListPageContainer />,
    },
    contactList: {
      ...links.contactList,
      component: <ContactsListPageContainer />,
    },
    featureTags: {
      ...links.featureTags,
      component: <FeatureTagsListPageContainer />,
    }
  };
};
