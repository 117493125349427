import React from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';

interface Props {
  menu: React.ReactNode;
  scrollContainerId: string;
  children: React.ReactNode;
}

export const ScrollMenuPageLayout = React.memo(({ menu, scrollContainerId, children }: Props) => {
  const classes = useStyles();
  return (
    <Grid container wrap="nowrap" className={classes.layout}>
      <Grid item>
        {menu}
      </Grid>
      <Grid
        item
        container
        direction="column"
        wrap="nowrap"
        id={scrollContainerId}
        className={classes.content}
      >
        {children}
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    backgroundColor: theme.palette.grey[100],
    height: '100%',
    width: '100%',
  },
  content: {
    flexGrow: 2,
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
  }
}));
