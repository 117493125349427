import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useUiText } from 'domain/uiText';
import { chipInputId, TextListFieldChip } from './TextListFieldChip';

type Props = {
  disabled?: boolean;
  readonly?: boolean;
  items: string[];
  onChange: (value: string[]) => void;
};

const NEW_ITEM_INPUT_ID = chipInputId(-1);
const FIRST_ITEM_INPUT_ID = chipInputId(0);

export const TextListFieldChips = ({ readonly, disabled, items, onChange }: Props) => {
  const classes = useStyles();
  const uiText = useUiText();
  const firstRenderRef = React.useRef(true);
  const [shouldFocus, setShouldFocus] = React.useState(false);

  const handleChange = (value: string, index: number) => {
    const newItems = [...items];
    newItems[index] = value;
    onChange(newItems);
  };

  const handleStartNewItem = (value: string) => {
    setShouldFocus(true);
    onChange([value, ...items]);
  };

  const handleDelete = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    onChange(newItems);
  };

  // Focus the 'New Item' input when enter is pressed on an item
  const handleEnterPressed = () => {
    const el = document.getElementById(NEW_ITEM_INPUT_ID);
    if (el) el.focus();
  };

  React.useEffect(() => {
    if (shouldFocus) {
      const el = document.getElementById(FIRST_ITEM_INPUT_ID);
      if (el) {
        el.focus();
        setShouldFocus(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  React.useEffect(() => {
    firstRenderRef.current = true;
  }, []);

  return (
    <div className={classes.chips}>
      {!readonly && !disabled && (
        <TextListFieldChip
          className={classes.newKeyword}
          index={-1}
          placeholder={uiText.ACTION_NEW_ITEM_LABEL}
          value=""
          onChange={handleStartNewItem}
          onEnterPressed={handleEnterPressed}
        />
      )}
      {items.map((value, i) => (
        <TextListFieldChip
          // eslint-disable-next-line react/no-array-index-key
          key={chipInputId(i)}
          value={value}
          index={i}
          onChange={handleChange}
          onDelete={handleDelete}
          onEnterPressed={handleEnterPressed}
          readonly={readonly}
          autoFocus={!firstRenderRef.current}
          disabled={disabled}
        />
      ))}
      {readonly && items.length <= 0 && <Typography>{uiText.NO_VALUE}</Typography>}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  newKeyword: {
    borderStyle: 'solid !important',
    borderWidth: '2px !important',
    borderColor: theme.palette.grey[500],
  },
  chips: {
    overflow: 'hidden',
    position: 'relative',
    maxWidth: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
      overflow: 'hidden',
    },
  },
}));
