import React from 'react';
import { Button, makeStyles, Theme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Action } from 'common/types';

interface OwnProps {}

type Props = OwnProps & Omit<Action, 'type'>;

export const CreateAction = (props: Props) => {
  const { label, onClick, disabled, isSmall } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <Button
        disabled={disabled}
        variant="outlined"
        className={classes.root}
        onClick={onClick}
        endIcon={<AddIcon fontSize="small" />}
        size={isSmall ? 'small' : undefined}
      >
        {label}
      </Button>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontWeight: 500,
    textTransform: 'capitalize',
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[300],
      borderColor: theme.palette.grey[500],
      color: theme.palette.grey[500],
    },
  },
}));
