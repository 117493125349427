import { DocumentNode, useMutation } from '@apollo/client';
import { AdapterActionWithPromise } from 'domain/api/common/adapters/types';
import { ValidatedEntity } from '../types';

type Options = {
  onSuccess?: () => void;
  onError?: (err: string) => void;
};

export function useAddOrRemoveContentEntries<
Input extends { contentEntries: string[]; contentSpaceId: string; [x: string]: any },
Response extends object | undefined,
Result extends object | undefined
>(
  query: DocumentNode,
  validator: (data?: Response | null) => ValidatedEntity<Result> | undefined,
  options?: Options,
): AdapterActionWithPromise<Input, ValidatedEntity<Result> | undefined> {
  const [mutation, mutationResult] = useMutation<Response, any>(query);

  const execute = async (input: Input): Promise<ValidatedEntity<Result> | undefined> => {
    try {
      const result = await mutation({
        variables: {
          input: {
            ...input,
          }
        }
      });

      if (options?.onSuccess) options.onSuccess();

      return validator(result.data);
    } catch (error) {
      if (options?.onError) options.onError(error);
      return error;
    }
  };

  return {
    execute,
    result: validator(mutationResult.data),
    loading: mutationResult.loading,
    called: mutationResult.called,
    error: mutationResult.error?.message,
  };
}
