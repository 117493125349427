import React from 'react';
import { ResourcesContextValue } from 'system/resources/types';
import { ResultsSearch } from './ResultsSearch/ResultsSearch';

type Props<TResourceName extends keyof ResourcesContextValue> = {
  data: ResourcesContextValue[TResourceName]['defaultResource'][];
  primaryDataKey: 'id';
  dataKeys: Extract<keyof ResourcesContextValue[TResourceName]['dataDisplay'], string>[];
  onFiltersChange: (primaryKeys: string[]) => void;
  children: (searchInput: JSX.Element) => JSX.Element;
};

export function TableFilters<
TResourceName extends keyof ResourcesContextValue
>(props: Props<TResourceName>) {
  const search = (
    <ResultsSearch
      onChange={props.onFiltersChange}
      dataKeys={props.dataKeys as any}
      primaryDataKey={props.primaryDataKey}
      data={props.data}
    />
  );
  return props.children(search);
}
