import React from 'react';
import { useFieldRenderer } from 'lib/form';
import { isKeyOf } from '_lib/common/helpers';

import { useResource } from 'system/hooks';
import { ResourceFields, ResourceFieldsConstraint } from 'lib/resource';
import { FieldErrors, FieldModifiers, InitialValues } from 'lib/form/types';
import { ResourcesContextValue } from '../types';

type TResourceFieldsConstraint<
TResourceName extends keyof ResourcesContextValue
> = ResourceFields<ResourcesContextValue[TResourceName]['defaultResourceFields']>;

type FieldNameConstraint<
TResourceName extends keyof ResourcesContextValue
> = keyof TResourceFieldsConstraint<TResourceName>;

type UseResourceFieldsOptions<
TResourceName extends keyof ResourcesContextValue,
TInitialValues extends { [x: string]: any },
> = {
  hasReadonly?: boolean;
  initialValues?: TInitialValues;
  omittedFields?: (FieldNameConstraint<TResourceName> & string)[];
  fieldModifiers?: Partial<FieldModifiers<TInitialValues>>;
  includeUntouchedFields?: boolean;
};

export function useResourceFields<
TResourceName extends keyof ResourcesContextValue,
TInitialValues extends ResourceFieldsConstraint,
>(
  resourceName: TResourceName,
  options?: UseResourceFieldsOptions<TResourceName, TInitialValues>,
) {
  const resource = useResource(resourceName);

  const fields = Object.fromEntries(
    Object.entries(resource.fields)
      .filter((entry) => options?.omittedFields && options?.omittedFields.length > 0
        ? !options.omittedFields.includes(entry[0] as any)
        : true
      )
      .map((entry) => ([
        entry[0],
        {
          value: options?.initialValues && isKeyOf(options.initialValues, entry[0])
            ? options.initialValues[entry[0]] : entry[1].value,
          type: entry[1].type,
        }
      ]))
  );

  const form = useFieldRenderer({
    fields,
    hasReadonly: options?.hasReadonly,
    fieldModifiers: options?.fieldModifiers as any,
    validation: resource.fieldsValidationSchema,
    omittedFields: options?.omittedFields,
    includeUntouchedFields: options?.includeUntouchedFields,
  });

  const renderField = React.useCallback(<
  TFieldName extends keyof TInitialValues & string,
  TFieldConfig extends { [x: string]: unknown }
  >(
      fieldName: TFieldName,
      _fieldConfig?: TFieldConfig,
      externalErrors?: string[],
    ) => {
    if (isKeyOf(resource.fields, fieldName)) {
      const field = resource.fields[fieldName] as any;
      return form.renderField(
        fieldName,
        {
          /**
           * @todo Fix this
           */
          ...field.fieldConfig as any,
          ..._fieldConfig,
          disabled: field.disabled || _fieldConfig?.disabled,
          readonly: options?.hasReadonly && !form.isEditing,
          externalErrors,
        },
      );
    }

    throw new Error(`${fieldName} is not a valid field of this resource`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource, form]);

  const onSubmit = (
    onError: (errors: FieldErrors<InitialValues>) => void,
    onSuccess: (values: TInitialValues) => void,
  ) => {
    form.onSubmit(
      /**
       *
       * @@todo Implement validation errors on form submit
       */
      (errors) => onError(errors),
      _values => {
        onSuccess(_values as TInitialValues);
      },
    );
  };

  return {
    ...form,
    /**
     * @todo Find a way to fix this...
     */
    fields: form.fields as unknown as {
      [Key in keyof ResourceFields<ResourcesContextValue[TResourceName]['fields']>]: {
        type: ResourceFields<ResourcesContextValue[TResourceName]['fields']>[Key]['type'],
        value: ResourceFields<ResourcesContextValue[TResourceName]['fields']>[Key]['value'],
      }
    },
    renderField,
    onSubmit,
  };
}
