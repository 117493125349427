import React from 'react';
import { ErrorPageProps } from './types';

type Props = {
  component: React.ComponentType<ErrorPageProps>;
};

type State = {
  error?: string;
};

// This component will catch any uncaught errors from it's children.
// It is rendered very near the top of the component tree, so it should catch all
// uncaught errors.
export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error) {
    return { error: error.message };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // TODO: log errors with Google Cloud Stackdriver Error Reporting
  }

  render() {
    if (this.state.error) return React.createElement(
      this.props.component, { error: this.state.error }
    );
    return this.props.children;
  }
}
