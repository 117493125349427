import React from 'react';
import { useContentSpaceId } from '_lib/auth';
import {
  useCreateOneImageLink,
  useGetManyImageLinks,
  usePublishOneImageLink,
  useUnpublishOneImageLink,
  useDeleteOneImageLink,
  ImageLinkInput,
} from 'domain/api/imageLink';
import { useResetableAdapterAction } from 'domain/api/common/hooks';
import { useGetManyCommunities } from 'domain/api/community';
import { useGetManyFloorPlans } from 'domain/api/floorPlan';
import { useGetManyShowHomes } from 'domain/api/showhome';
import { useGetManyInventories } from 'domain/api/inventory';
import { useUiText } from 'domain/uiText';
import { useEntityFields } from 'domain/entities/hooks';
import { ListPage } from 'domain/view/pages/ListPage/ListPage';
import { CreateImageLinkForm } from '../../forms/CreateImageLinkForm';
import { EditImageLinkForm } from '../../forms';

export const ImageLinksListPageContainer = () => {
  const uiText = useUiText();
  const { removeDisabledFields } = useEntityFields('imageLink');
  const contentSpaceId = useContentSpaceId();
  const getImageLinks = useGetManyImageLinks();
  const getCommunities = useGetManyCommunities();
  const getFloorPlans = useGetManyFloorPlans();
  const getInventories = useGetManyInventories();
  const getShowHomes = useGetManyShowHomes();
  const publishImageLink = usePublishOneImageLink();
  const unpublishImageLink = useUnpublishOneImageLink();
  const deleteImageLink = useDeleteOneImageLink();
  const createImageLink = useResetableAdapterAction(useCreateOneImageLink);

  const get = () => {
    getImageLinks.execute();
    getFloorPlans.execute();
    getCommunities.execute();
    getInventories.execute();
    getShowHomes.execute();
  };

  const handleCreate = (values: ImageLinkInput, onClose: () => void, alsoPublish?: boolean) => {
    contentSpaceId &&
      createImageLink.execute(
        { ...removeDisabledFields(values) },
        alsoPublish
      );
  };

  return (
    <ListPage
      resourceName="imageLink"
      get={get}
      actions={{
        publish: (id: string) => publishImageLink.execute({ id }),
        unpublish: (id: string) => unpublishImageLink.execute({ id }),
        delete: (id: string) => deleteImageLink.execute({ id }),
        edit: (data) => (
          <EditImageLinkForm
            imageLink={data}
            floorPlans={getFloorPlans.result}
            communities={getCommunities.result}
            inventories={getInventories.result}
            showHomes={getShowHomes.result}
          />
        ),
      }}
      loading={getImageLinks.loading}
      actionLoading={
        unpublishImageLink.loading || publishImageLink.loading || deleteImageLink.loading
      }
      error={undefined}
      data={getImageLinks.result}
      called={getImageLinks.called}
      hasData={getImageLinks.result.length > 0}
      noDataMessage={uiText.NO_IMAGE_LINK}
      topbar={{
        title: uiText.IMAGE_LINK_PLURAL,
        createAction: (
          <CreateImageLinkForm
            onSubmit={handleCreate}
            submitting={createImageLink.loading}
            submitted={!createImageLink.loading && createImageLink.called}
            floorPlans={getFloorPlans.result}
            communities={getCommunities.result}
            showHomes={getShowHomes.result}
            inventories={getInventories.result}
          />
        ),
      }}
    />
  );
};
