import { defaultUiText, UiText } from 'domain/uiText';
import { ExternalResourceConfig, InternalResourceConfig, makeResource } from 'lib/resource';
import {
  defaultCommunity,
  Community,
  communityFields,
  CommunityFields,
  defaultCommunityFields,
  communityDataDisplayFields,
} from './types';
import { communityFieldsSchema } from './schemas';
import { defaultExternalResourceConfig } from '../shared';

export const communityInternalResourceConfig = (uiText: UiText): InternalResourceConfig<
Community,
CommunityFields
> =>  ({
  enabledPages: ['list', 'single'],
  defaultResource: defaultCommunity,
  defaultResourceFields: defaultCommunityFields,
  fields: communityFields(uiText),
  fieldsValidationSchema: communityFieldsSchema(uiText),
  dataDisplay: communityDataDisplayFields(uiText),
});

export const defaultCommunityExternalResourceConfig: ExternalResourceConfig = {
  ...defaultExternalResourceConfig,
  aliases: {
    path: 'communities',
    plural: 'Communities',
    singular: 'Community',
  },
};

export const defaultCommunityResource = makeResource({
  internalConfig: communityInternalResourceConfig(defaultUiText),
  externalConfig: defaultCommunityExternalResourceConfig,
});
