export const AllSalesCentreInfo = `
  id
  name
  phone
  address
  hours
  email
  contacts {
    id
    name
    email
    title
    phone
  }
  community {
    id
  }
`;
