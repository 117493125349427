import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { useBaseUiText } from '_lib/uiText';
import { Spinner } from '_lib/loading';
import classNames from 'classnames';

interface Props {
  title: string;
  description?: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (alsoPublish?: boolean) => void;
  submitDisabled: boolean;
  submitting?: boolean;
  children: React.ReactNode;
  wideDialog?: boolean;
  hasErrors?: boolean;
  showCreateAndPublishAction?: boolean;
}

export const FormDialog = (props: Props) => {
  const classes = useStyles();
  const uiText = useBaseUiText();

  const handleClose = (e: React.BaseSyntheticEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
    props.onClose();
  };

  const handleSubmit = (alsoPublish?: boolean) => {
    props.onSubmit(alsoPublish);
  };

  const renderFormActions = () => {
    return (
      <React.Fragment>
        <Button variant="outlined" onClick={props.onClose} disabled={props.submitting}>
          {uiText.ACTION_CANCEL_LABEL}
        </Button>
        {props.showCreateAndPublishAction ? (
          <Button
            variant="outlined"
            color="primary"
            disabled={props.submitDisabled || props.submitting}
            onClick={() => handleSubmit(true)}
          >
            {uiText.ACTION_CREATE_AND_PUBLISH_LABEL}
          </Button>
        ) : null}
        <Button
          variant="outlined"
          color="primary"
          disabled={props.submitDisabled || props.submitting}
          onClick={() => handleSubmit()}
        >
          {props.showCreateAndPublishAction ? uiText.ACTION_CREATE_LABEL : uiText.ACTION_SAVE_LABEL}
        </Button>
      </React.Fragment>
    );
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.submitting ? undefined : handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        className: classNames(
          classes.dialog,
          { [classes.dialogWide]: props.wideDialog },
          { [classes.formError]: props.hasErrors }
        ),
      }}
      className={classes.dialogContainer}
    >
      <DialogTitle className={classes.dialogTitle}>{props.title}</DialogTitle>
      <DialogContent>
        <Grid className={classes.dialogContent}>
          {props.description && <DialogContentText>{props.description}</DialogContentText>}
          {props.children}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>{renderFormActions()}</DialogActions>
      {props.submitting && <Spinner withContent />}
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    position: 'relative',
    borderTop: `solid 4px ${theme.palette.primary.main}`,
    maxWidth: '40',
    minWidth: '40vw',
    [theme.breakpoints.down('xl')]: {
      maxWidth: '50vw',
      minWidth: '50vw',
    },
  },
  dialogWide: {
    position: 'relative',
    borderTop: `solid 4px ${theme.palette.primary.main}`,
    maxWidth: '100%',
  },
  formError: {
    borderTop: `solid 4px ${theme.palette.error.main}`,
  },
  dialogContainer: {},
  dialogContent: {
    padding: `${theme.spacing(2)}px 0px`,
  },
  actions: {
    backgroundColor: theme.palette.grey[100],
  },
  dialogTitle: {
    borderBottom: `solid 1px ${theme.palette.grey[300]}`,
  },
}));
