import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { Action } from 'common/types';

interface OwnProps {}

type Props = OwnProps & Omit<Action, 'type'>;

export const PublishAction = (props: Props) => {
  const { label, onClick, disabled, isSmall } = props;
  const classes = useStyles();
  const handleClick = (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    onClick && onClick();
  };

  return (
    <React.Fragment>
      <Button
        disabled={disabled}
        variant="outlined"
        className={classes.root}
        onClick={handleClick}
        endIcon={<VisibilityIcon fontSize="small" />}
        size={isSmall ? 'small' : undefined}
      >
        {label}
      </Button>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textTransform: 'capitalize',
    fontWeight: 500,
    backgroundColor: theme.palette.success.dark,
    borderColor: theme.palette.success.dark,
    color: theme.palette.getContrastText(theme.palette.success.dark),
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[300],
      borderColor: theme.palette.grey[500],
      color: theme.palette.grey[500],
    },
  },
}));
