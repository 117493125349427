import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Tooltip } from '@material-ui/core';
import { useBaseUiText } from '_lib/uiText';

interface Props {
  onCancel?: () => void;
  disabled?: boolean;
}

export const CancelIconButton = React.memo(({ onCancel, disabled }: Props) => {
  const uiText = useBaseUiText();
  return (
    <Tooltip title={uiText.ACTION_CANCEL_LABEL}>
      <div>
        <IconButton disabled={disabled} onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
});
