import * as yup from 'yup';
import { UiText } from 'domain/uiText/uiText';
import {
  latitudeValidationSchema,
  longitudeValidationSchema,
  pickCommonApiValidationSchemas,
} from '../common/validation';
import { ApiCommunity, ApiCommunityInput } from './types';
import { apiEmbeddingValidationSchema } from '../embedding/validation';
import { apiAssetValidationSchema } from '../asset/validation';
import { apiImageLinkValidationSchema } from '../imageLink/validation';
import { apiSalesCentreValidationSchema } from '../salesCentre/validation';

const commonCommunityValidationSchemas = (uiText: UiText) => ({
  ...pickCommonApiValidationSchemas(uiText)([
    'displayName',
    'showCommuteCalculation',
    'seoTitleTag',
    'seoMetaKeywords',
    'seoMetaDescription',
    'displayPriority',
  ]),
  communityType: yup
    .string()
    .defined()
    .label(`${uiText.COMMUNITY_SINGULAR} Type`)
    .default(null),
  showCommunityStats: yup
    .boolean()
    .defined()
    .default(false)
    .label(`${uiText.COMMUNITY_SINGULAR} Statistics`),
  lotmapLink: yup.string().defined().default(null).label('Lotmap Link'),
  lotmapText: yup.string().defined().default(null).label('Lotmap Text'),
  startingPrices: yup.array().of(yup.object({
    name: yup.string().required(),
    price: yup.number().required().min(0),
  }).defined()).defined(),
  communityGeoPointLat: latitudeValidationSchema,
  communityGeoPointLon: longitudeValidationSchema,
});

export const apiCommunityInputValidationSchema = (
  uiText: UiText,
) => yup.object<ApiCommunityInput, object>({
  ...commonCommunityValidationSchemas(uiText),
  communityGroup: yup.string().defined().nullable().label(`${uiText.COMMUNITY_SINGULAR} Group`),
}).defined();

export const apiCommunityValidationSchema = (uiText: UiText) => yup.object<ApiCommunity, object>({
  ...commonCommunityValidationSchemas(uiText),
  ...pickCommonApiValidationSchemas(uiText)(['id', 'builderId']),
  communityGroup: yup.object({ name: yup.string() }).defined().nullable(),
  assets: yup.array()
    .of(apiAssetValidationSchema)
    .test(
      'hasThumbnailImage',
      'Thumbnail image is required',
      value => !!value?.find(el => el.attributeName === 'thumbnailImage'),
    )
    .defined()
    .default([]),
  imageLinks: yup.array().of(apiImageLinkValidationSchema(uiText, true)).defined().default([]),
  embeddings: yup.array().of(apiEmbeddingValidationSchema(uiText)).defined().default([]),
  salesCentres: yup.array().of(apiSalesCentreValidationSchema(uiText)).defined().default([]),
}).defined();
