import { UiText } from 'domain/uiText/uiText';
import * as yup from 'yup';
import { contentEntryValidationSchema, pickCommonApiValidationSchemas } from '../common/validation';
import { apiVendorValidationSchema } from '../vendor/validation';
import { ApiEmbedding, ApiEmbeddingInput } from './types';

export const apiEmbeddingInputValidationSchema = (
  uiText: UiText,
) => yup.object<ApiEmbeddingInput, object>({
  ...pickCommonApiValidationSchemas(uiText)(['displayName']),
  iframeUrl: yup.string().defined().default('').label('URL'),
  vendorId: yup.string().defined(),
}).defined();

export const apiEmbeddingValidationSchema = (
  uiText: UiText,
) => yup.object<ApiEmbedding, object>({
  ...pickCommonApiValidationSchemas(uiText)(['displayName']),
  iframeUrl: yup.string().required().label('URL'),
  vendor: apiVendorValidationSchema,
  contentEntries: yup.array().of(contentEntryValidationSchema.defined()).defined().default([]),
}).defined();
