import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

export const DisabledFormOverlay = () => {
  const classes = useStyles();
  return (
    <div className={classes.container} />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.grey[100],
    height: '100%',
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    opacity: .4,
  },
}));
