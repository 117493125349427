import { useLazyQuery } from '@apollo/client';
import { GetOneAdapterAction } from 'domain/api/common/adapters/types';
import { useContentSpaceId } from '_lib/auth';
import {
  GetOneVariables,
  ResponseWithContentSpace,
  ValidatedEntity,
} from 'domain/api/common/types';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { ApiShowHome, ShowHome } from '../types';
import { GET_ONE_SHOW_HOME } from './gql';
import { transformApiShowHomeToShowHome } from '../transforms';

type Response = ResponseWithContentSpace<{ showHome: ApiShowHome }>;
type Variables = GetOneVariables;

export const useGetOneShowHome: () => GetOneAdapterAction<ValidatedEntity<ShowHome>> = () => {
  const contentSpaceId = useContentSpaceId();
  const schemas = useApiSchemas();
  const [query, queryResult] = useLazyQuery<Response, Variables>(GET_ONE_SHOW_HOME);

  const execute = (id: string) => {
    contentSpaceId && query({ variables: { contentSpaceId, id } });
  };

  const refetch = (id: string) => {
    contentSpaceId && queryResult?.refetch?.({ contentSpaceId, id });
  };

  const validated = schemas?.showHome.apiSchema
    ? validateApiResponseEntity(queryResult.data?.contentSpace.showHome, schemas.showHome.apiSchema)
    : undefined;

  return {
    execute,
    refetch,
    result: transformApiShowHomeToShowHome(validated),
    loading: queryResult.loading,
    called: queryResult.called,
    error: queryResult.error?.message,
  };
};
