import React from 'react';
import {
  IconButton,
  TableCellProps,
  TableRow,
  Theme,
  makeStyles,
  TableBody as MaterialTableBody,
  TableCell,
  Tooltip,
  Checkbox,
} from '@material-ui/core';
import classNames from 'classnames';
import { ResourcesContextValue } from 'system/resources/types';
import { isKeyOf } from '_lib/common/helpers';

import { DataDisplayField } from 'lib/resource';
import { Delete, Visibility, VisibilityOff } from '@material-ui/icons';
import { useResource } from 'system/hooks';
import { baseUiText as uiText } from '_lib/uiText/baseUiText';
import { statusCellRenderer } from './CellRenderers';
import { TableActions, TableDataDisplayKeys, TableDataRow } from './types';
import { DeleteDialog } from '../dialogs/DeleteDialog';

type Props<TResourceName extends keyof ResourcesContextValue> = {
  data: TableDataRow<TResourceName>[];
  resourceName: TResourceName;
  onRowClick?: (row: TableDataRow<TResourceName>) => void;
  onSelectRow?: (id: string) => (e: React.BaseSyntheticEvent) => void;
  selectedRows: string[];
  actions: TableActions<TResourceName>;
};

const bodyCellProps: Partial<TableCellProps> = {
  variant: 'body',
  style: { wordWrap: 'break-word', whiteSpace: 'normal', maxWidth: '300px' },
};

export function TableBody<TResourceName extends keyof ResourcesContextValue>({
  data,
  resourceName,
  onRowClick,
  onSelectRow,
  selectedRows,
  actions,
}: Props<TResourceName>) {
  const classes = useStyles();
  const [rowToDelete, setRowToDelete] = React.useState<string | null>(null);
  const { helpers, dataDisplay } = useResource(resourceName);
  const dataDisplayKeys = Object.keys(dataDisplay.fields) as TableDataDisplayKeys<TResourceName>;

  const handleRowClick = (row: TableDataRow<TResourceName>) => (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    onRowClick && onRowClick(row);
  };

  const handlePublishClick = (row: TableDataRow<TResourceName>) => (
    e: React.BaseSyntheticEvent
  ) => {
    e.stopPropagation();
    if (row.publishedStatus === 'published') {
      actions.unpublish && actions.unpublish(row.id);
    } else {
      actions.publish && actions.publish(row.id);
    }
  };

  const handleDeleteClick = (row: TableDataRow<TResourceName>) => (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    actions.delete && setRowToDelete(row.id);
  };

  const renderRow = (row: TableDataRow<TResourceName>) => {
    const cells: JSX.Element[] = dataDisplayKeys.map((key) => {
      const cellData = isKeyOf(row, key) && row[key];
      const cellDisplay =
        isKeyOf(dataDisplay.fields, key) &&
        ((dataDisplay.fields[key] as unknown) as DataDisplayField<
        TableDataRow<TResourceName>,
        any
        >);

      if (cellData) {
        return (
          <TableCell {...bodyCellProps} key={`table-row-${row.id}-cell-${key}`}>
            {cellDisplay && cellDisplay.renderer ? cellDisplay.renderer(cellData as any) : cellData}
          </TableCell>
        );
      }
      return (
        <TableCell {...bodyCellProps} key={`table-row-${row.id}-cell-${key}-place-holder`}>
          N/A
        </TableCell>
      );
    });

    return (
      <TableRow
        key={`table-row-${row.id}`}
        onClick={handleRowClick(row)}
        className={classNames({ [classes.rowHover]: !!onRowClick })}
      >
        {cells}
        {row.publishedStatus && (
          <TableCell variant="body">{statusCellRenderer(row.publishedStatus)}</TableCell>
        )}
        <TableCell variant="body" align="right">
          {actions.delete && (
            <Tooltip title={uiText.ACTION_DELETE_LABEL}>
              <span>
                <IconButton
                  size="small"
                  disabled={helpers.isActionDisabled('delete')}
                  onClick={handleDeleteClick(row)}
                >
                  <Delete fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {actions.edit && actions.edit(row)}
          {actions.publish && actions.unpublish && row.publishedStatus && (
            <Tooltip
              title={
                row.publishedStatus === 'draft'
                  ? uiText.ACTION_PUBLISH_LABEL
                  : uiText.ACTION_UNPUBLISH_LABEL
              }
            >
              <span>
                <IconButton
                  size="small"
                  disabled={helpers.isActionDisabled('publish') || row.publishedStatus === 'error'}
                  onClick={handlePublishClick(row)}
                >
                  {row.publishedStatus === 'draft' ? (
                    <Visibility fontSize="small" />
                  ) : (
                    <VisibilityOff fontSize="small" />
                  )}
                </IconButton>
              </span>
            </Tooltip>
          )}
          {onSelectRow && (
            <Checkbox
              color="primary"
              size="small"
              onClick={onSelectRow(row.id)}
              checked={selectedRows.includes(row.id)}
            />
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <React.Fragment>
      <MaterialTableBody>{data.length > 0 && data.map(renderRow)}</MaterialTableBody>
      <DeleteDialog
        open={!!rowToDelete}
        onCancel={() => setRowToDelete(null)}
        onConfirm={() => {
          actions.delete && !!rowToDelete && actions.delete(rowToDelete);
          setRowToDelete(null);
        }}
      />
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  rowHover: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));
