import React from 'react';
import { ReadonlyField } from '../shared/ReadonlyField/ReadonlyField';
import { CheckboxFieldControl } from './CheckboxFieldControl';
import { CheckboxFieldReadonly } from './CheckboxFieldReadonly';
import { CheckboxFieldProps } from './types';

export function CheckboxField<TName extends string>({
  readonly,
  ...rest
}: CheckboxFieldProps<TName>) {
  if (readonly) {
    return (
      <ReadonlyField errors={rest.errors} label={rest.label}>
        <CheckboxFieldReadonly
          value={rest.value}
          unCheckedLabel={rest.unCheckedLabel}
          checkedLabel={rest.checkedLabel}
        />
      </ReadonlyField>
    );
  }
  return <CheckboxFieldControl {...rest} />;
}
