import * as yup from 'yup';
import { apiFeatureTagCategoryValidationSchema } from '../featureTagCategory/validation';
import { ApiFeatureTag } from './types';

export const apiFeatureTagValidationSchema = yup.object<ApiFeatureTag>({
  id: yup.string().defined().default(''),
  name: yup.string().defined().default(''),
  description: yup.string().defined().default(''),
  showFloorPlanViewer: yup.boolean().defined(),
  featureTagCategory: apiFeatureTagCategoryValidationSchema,
}).defined();
