import React from 'react';
import { useContentSpaceId } from '_lib/auth';
import {
  useDeleteOneFloorPlan,
  useGetOneFloorPlan,
  usePublishOneFloorPlan,
  useUnpublishOneFloorPlan,
  useUpdateOneFloorPlan,
} from 'domain/api/floorPlan';
import { useGetManyCommunities } from 'domain/api/community';
import { FloorPlan } from 'domain/api/floorPlan/types';
import { useUiText } from 'domain/uiText';
import { useEntityFields } from 'domain/entities/hooks';
import { useResourceTopbarConfig } from 'domain/view/components/layout/topbar/useResourceTopbarConfig';
import { FloorPlanDetailPage } from './FloorPlanDetailPage';

export const FloorPlanDetailPageContainer = () => {
  const uiText = useUiText();
  const { removeDisabledFields } = useEntityFields('floorPlan');
  const contentSpaceId = useContentSpaceId();
  const getFloorPlan = useGetOneFloorPlan();
  const getCommunities = useGetManyCommunities();
  const updateFloorPlan = useUpdateOneFloorPlan();
  const publishFloorPlan = usePublishOneFloorPlan();
  const unpublishFloorPlan = useUnpublishOneFloorPlan();
  const deleteFloorPlan = useDeleteOneFloorPlan(uiText.FLOOR_PLAN_PATH);

  const data = getFloorPlan.result;
  const { id = '', publishedStatus = 'error', validationErrors } = data || {};

  // Fetch required data for the model single page
  const get = (_id: string) => {
    getFloorPlan.execute(_id);
    getCommunities.execute();
  };

  const handleUpdate = (values: Partial<FloorPlan>) => {
    if (!id|| !contentSpaceId) return;
    updateFloorPlan.execute({
      id,
      contentSpaceId,
      floorPlan: removeDisabledFields(values),
      idType: 'CONTENT_ID',
    });
  };

  const handlePublish = () => id && publishFloorPlan.execute({ id });

  const handleUnpublish = () => id && unpublishFloorPlan.execute({ id });

  const handleDelete = () => id && deleteFloorPlan.execute({ id });

  const handleRefetch = () => getFloorPlan.refetch?.(id);

  const topbarConfig = useResourceTopbarConfig(
    'floorPlan',
    data?.publishedStatus || 'error',
    handleDelete,
    handlePublish,
    handleUnpublish,
    data
  );

  const loading =
    getFloorPlan.loading ||
    getCommunities.loading ||
    updateFloorPlan.loading ||
    deleteFloorPlan.loading ||
    publishFloorPlan.loading ||
    unpublishFloorPlan.loading;

  return (
    <FloorPlanDetailPage
      get={get}
      handleUpdate={handleUpdate}
      handleRefetch={handleRefetch}
      loading={loading}
      called={getFloorPlan.called}
      publishedStatus={publishedStatus}
      data={data as FloorPlan}
      communities={getCommunities.result}
      validation={{ errors: validationErrors }}
      topbar={topbarConfig}
      contentSpaceId={contentSpaceId || ''}
    />
  );
};
