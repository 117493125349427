import { defaultUiText, UiText } from 'domain/uiText';
import { ExternalResourceConfig, InternalResourceConfig, makeResource } from 'lib/resource';
import {
  defaultEmbedding,
  defaultEmbeddingFields,
  Embedding,
  embeddingDataDisplayFields,
  EmbeddingFields,
  embeddingFields,
} from './types';
import { defaultExternalResourceConfig } from '../shared';
import { embeddingFieldsSchema } from './schemas';

export const embeddingInternalResourceConfig = (uiText: UiText): InternalResourceConfig<
Embedding,
EmbeddingFields,
Embedding & { vendorName: string }
> => ({
  enabledPages: ['list'],
  defaultResource: defaultEmbedding,
  defaultResourceFields: defaultEmbeddingFields,
  fields: embeddingFields(uiText),
  fieldsValidationSchema: embeddingFieldsSchema(uiText),
  dataDisplay: embeddingDataDisplayFields(uiText),
});

export const defaultEmbeddingExternalResourceConfig: ExternalResourceConfig = {
  ...defaultExternalResourceConfig,
  aliases: {
    path: 'third-party-widgets',
    plural: 'Third Party Widgets',
    singular: 'Third Party Widget',
  },
};

export const defaultEmbeddingResource = makeResource({
  internalConfig: embeddingInternalResourceConfig(defaultUiText),
  externalConfig: defaultEmbeddingExternalResourceConfig,
});
