import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';

type Props = {
  title: string;
};

export const TopbarTitle = ({ title }: Props) => {
  const classes = useStyles();
  return (
    <Typography variant="h4" className={classes.title}>
      {title}
    </Typography>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    margin: 0,
    marginRight: theme.spacing(2),
  },
}));
