import React from 'react';
import {
  RefetchContext,
  useRefetchQueries,
  useResetableAdapterAction,
} from 'domain/api/common/hooks';
import {
  useAddMultipleEmbeddingMappingsMutation,
  useCreateOneEmbedding,
  usePublishOneEmbedding,
  useRemoveMultipleEmbeddingMappingsMutation,
  useUnpublishOneEmbedding,
} from 'domain/api/embedding';
import { Embedding, EmbeddingInput } from 'domain/api/embedding/types';
import { useUiText } from 'domain/uiText';
import { useContentSpaceId, useResource } from 'system/hooks';
import { useFormState } from '_lib/multiFormSemaphore';
import { ResourcesContextValue } from 'system/resources/types';
import { CreateEmbeddingMappingForm } from './CreateEmbeddingMapping';
import { useEmbeddingMappingsData } from './useEmbeddingMappingsData';
import { RelationMappingForm } from '../RelationMappingForm/RelationMappingForm';

type Props = {
  id: string;
  title: string;
  icon?: JSX.Element;
  resourceName: Extract<keyof Pick<ResourcesContextValue, 'embedding' | 'video'>, string>;
  sourceId: string;
  sourceContext: RefetchContext;
  mapped: Embedding[];
};

export function EmbeddingMappingForm({
  id,
  title,
  icon,
  resourceName,
  sourceId,
  sourceContext,
  mapped,
}: Props) {
  const uiText = useUiText();
  const { helpers } = useResource(resourceName);
  const contentSpaceId = useContentSpaceId();
  const { enabled } = useFormState(id);

  const refetchQueries = useRefetchQueries(sourceContext, sourceId);
  const createEmbedding = useResetableAdapterAction(useCreateOneEmbedding, refetchQueries);
  const addMappingsMutation = useResetableAdapterAction(
    useAddMultipleEmbeddingMappingsMutation,
    refetchQueries
  );
  const removeMappingsMutation = useRemoveMultipleEmbeddingMappingsMutation(refetchQueries);
  const publishEmbedding = usePublishOneEmbedding();
  const unpublishEmbedding = useUnpublishOneEmbedding();
  const transformedData = useEmbeddingMappingsData({
    mapped,
    sourceId,
    resourceName,
  });

  const handleRemoveMappings = (entries: string[]) => {
    const batchInput = entries.map((entry) => ({
      input: {
        input: {
          embeddingId: entry,
          contentSpaceId,
          contentEntries: [sourceId],
        },
      },
    }));
    removeMappingsMutation.execute(batchInput);
  };

  const handleAddMappings = (entries: string[]) => {
    const batchInput = entries.map((entry) => ({
      input: {
        input: {
          embeddingId: entry,
          contentSpaceId,
          contentEntries: [sourceId],
        },
      },
    }));

    addMappingsMutation.execute(batchInput);
  };

  const handleCreate = (values: EmbeddingInput) => {
    createEmbedding.execute({
      ...values,
      communities: [sourceId],
    });
  };

  const createForm = (
    <CreateEmbeddingMappingForm
      onSubmit={handleCreate}
      submitting={createEmbedding.loading}
      submitted={!createEmbedding.loading && createEmbedding.called}
      disabled={helpers.isActionDisabled('create')}
      vendors={transformedData.vendors}
      resourceName={resourceName}
    />
  );

  return (
    <RelationMappingForm
      id={id}
      title={title}
      addMappingDialogTitle={resourceName === 'embedding'
        ? uiText.ACTION_ADD_EXISTING_THIRD_PARTY_PLUGIN_LABEL
        : uiText.ACTION_ADD_EXISTING_VIDEO_LABEL
      }
      icon={icon}
      targetsName={resourceName}
      mappedTargets={transformedData.mapped}
      unmappedTargets={transformedData.unmapped}
      addMappings={{
        execute: handleAddMappings,
        loading: addMappingsMutation.loading,
        called: addMappingsMutation.called,
      }}
      removeMappings={{
        execute: handleRemoveMappings,
        loading: removeMappingsMutation.loading,
        called: removeMappingsMutation.called,
      }}
      createAction={createForm}
      publishAction={(deleteId) => publishEmbedding.execute({ id: deleteId })}
      unpublishAction={(deleteId) => unpublishEmbedding.execute({ id: deleteId })}
      loading={publishEmbedding.loading || unpublishEmbedding.loading}
      enabled={enabled}
    />
  );
}
