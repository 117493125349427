import { Action, Link, Status } from 'common/types';
import { useResource } from 'system/hooks';
import { SinglePageResource } from 'system/routes/types';
import { getPublishActionState } from 'utilities/status';
import { Asset } from 'domain/api/asset/types';
import { getAssetByAttributeName } from 'domain/api/asset';
import { useEntityActions } from 'domain/entities/hooks';

export type TopbarConfig = {
  breadcrumbs: Link[];
  title: string;
  backButtonPath: string;
  actions: Action[];
  thumbnailUrl?: string;
  publishedStatus: Status;
};

export function useResourceTopbarConfig<
  TResourceName extends SinglePageResource,
  TData extends { id?: string; displayName?: string; assets?: Asset[] }
>(
  resourceName: TResourceName,
  publishedStatus: Status,
  onDelete: () => void,
  onPublish: () => void,
  onUnpublish: () => void,
  resource?: TData
): TopbarConfig {
  const { isActionDisabled } = useEntityActions(resourceName);
  const { helpers } = useResource(resourceName);

  // Generate the links used in the breadcrumbs
  const listPageLink = helpers.getListPageLink
    ? helpers.getListPageLink()
    : { label: '', path: '' };
  const singlePageLink =
    helpers.getSinglePageLink && resource && resource.id
      ? helpers.getSinglePageLink(resource.id, resource?.displayName || 'Untitled Entry')
      : { label: '', path: '' };

  // Generate the delete and publish actions
  const publishActionState = getPublishActionState(publishedStatus);
  const actions: Action[] = [
    {
      type: 'delete',
      label: 'Delete',
      onClick: onDelete,
      disabled: isActionDisabled('delete'),
    },
    {
      ...publishActionState,
      onClick: publishedStatus === 'published' ? onUnpublish : onPublish,
      disabled:
        publishedStatus === 'published'
          ? isActionDisabled('unpublish') || publishActionState.disabled
          : isActionDisabled('publish') || publishActionState.disabled,
    },
  ];

  return {
    title: singlePageLink.label,
    thumbnailUrl: resource
      ? getAssetByAttributeName(
        resourceName === 'community' ? 'thumbnailImage' : 'listingMainImage',
        resource.assets
      )?.url
      : undefined,
    backButtonPath: listPageLink.path,
    breadcrumbs: [listPageLink, singlePageLink],
    publishedStatus,
    actions,
  };
}
