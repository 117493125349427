import React from 'react';
import { Card, Grid, makeStyles, Theme } from '@material-ui/core';
import { DisabledFormOverlay } from '_lib/form/formSection/components/DisabledFormOverlay';
import { AssetFormStep } from '../types';
import { AssetFormReadonly } from '../steps/readonly/AssetFormReadonly';
import { AssetFormEditing } from '../steps/editing/AssetFormEditing';
import { AssetFormDeleting } from '../steps/deleting/AssetFormDeleting';

type Props = {
  id: string;
  step: AssetFormStep;
  isMultiple?: boolean;
  editDisabled?: boolean;
  showCheckeredBackground?: boolean;
};

// Simple wrapper for the steps which display their content in the page
export const AssetFormPageSection = (props: Props) => {
  const classes = useStyles();

  const content = (
    <React.Fragment>
      {props.step !== 'editing' && props.step !== 'deleting' && <AssetFormReadonly showCheckeredBackground={props.showCheckeredBackground} />}
      {props.step === 'editing' && <AssetFormEditing showCheckeredBackground={props.showCheckeredBackground} />}
      {props.step === 'deleting' && <AssetFormDeleting showCheckeredBackground={props.showCheckeredBackground} />}
    </React.Fragment>
  );
  return (
    <Grid item={true} id={props.id} className={classes.container}>
      {props.isMultiple ? content : (
        <Card elevation={props.editDisabled ? 0 : undefined}>{content}</Card>
      )}
      {props.editDisabled && <DisabledFormOverlay />}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
  },
}));
