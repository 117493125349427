import { PublishProblemsFragment } from 'domain/api/common/fragments';
import { AllEmbeddingInfo } from '../embedding/fragments';
import { AllAssetInfo } from '../asset/fragments';
import { AllImageLinkInfo } from '../imageLink/fragments';
import { FeatureTagInfo } from '../featureTag/fragments';
import { AllFloorPlanLayoutInfo } from '../floorPlanLayouts';

export const ShortFloorPlanInfo = `
  id
  builderId
  displayName
  published
  homeType
  price
  floorPlanLayouts {
    ${AllFloorPlanLayoutInfo}
  }
  ${PublishProblemsFragment}
`;

export const AllFloorPlanInfo = `
  id
  builderId
  published
  homeType
  displayName
  garage

  floorPlanLayouts {
    ${AllFloorPlanLayoutInfo}
  }

  showMonthlyPaymentCalculator

  seoMetaDescription
  seoMetaKeywords
  seoTitleTag

  communityMappings {
    community {
      id
      builderId
      displayName
    }
    price
  }

  assets {
    ${AllAssetInfo}
  }
  imageLinks {
    ${AllImageLinkInfo}
  }
  embeddings {
    ${AllEmbeddingInfo}
  }
  featureTags {
    ${FeatureTagInfo}
  }
  ${PublishProblemsFragment}
`;
