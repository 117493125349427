import { Grid } from '@material-ui/core';
import React from 'react';
import { CancelIconButton, EditIconButton, SaveButton } from '_lib/components';

export interface Props {
  editing?: boolean;
  formDisabled: boolean;
  submitDisabled?: boolean;
  onEdit?: () => void;
  onCancel?: () => void;
  onSubmit?: () => void;
}

export const FormActions = ({
  editing,
  formDisabled,
  submitDisabled,
  onEdit,
  onCancel,
  onSubmit,
}: Props) => {
  if (editing) {
    return (
      <Grid container={true} wrap="nowrap">
        <CancelIconButton onCancel={onCancel} />
        <SaveButton disabled={!!submitDisabled} onSave={onSubmit} />
      </Grid>
    );
  }
  return <EditIconButton onEdit={onEdit} disabled={formDisabled} />;
};
