import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { DistributionEntry } from './types';

interface Props {
  data: DistributionEntry[];
}

export const DougnutChart = ({ data }: Props) => {
  const values = data.map(entry => entry.value);
  const labels = data.map(entry => entry.description);
  return <Doughnut
    data={{
      labels,
      datasets: [{
        data: values,
        backgroundColor: CHART_COLORS,
      }],
    }}
  />;
};

export const CHART_COLORS = [
  'rgb(255, 143, 0)',
  'rgb(0, 172, 193)',
  'rgb(255, 213, 79)',
  'rgb(76, 175, 80)',
  'rgb(240, 98, 146)',
  'rgb(255, 193, 7)',
  'rgb(100, 181, 246)',
  'rgb(129, 199, 132)',
  'rgb(159, 168, 218)',
  'rgb(0, 151, 167)',
  'rgb(255, 171, 145)',
  'rgb(178, 235, 242)',
  'rgb(255, 236, 179)',
  'rgb(200, 230, 201)',
  'rgb(248, 187, 208)',
  'rgb(225, 190, 231)',
  'rgb(187, 222, 251)',
  'rgb(240, 244, 195)',
  'rgb(77, 208, 225)',
  'rgb(197, 202, 233)',
  // repeat
  'rgb(255, 143, 0)',
  'rgb(0, 172, 193)',
  'rgb(255, 213, 79)',
  'rgb(76, 175, 80)',
  'rgb(240, 98, 146)',
  'rgb(255, 193, 7)',
  'rgb(100, 181, 246)',
  'rgb(129, 199, 132)',
  'rgb(159, 168, 218)',
  'rgb(0, 151, 167)',
  'rgb(255, 171, 145)',
  'rgb(178, 235, 242)',
  'rgb(255, 236, 179)',
  'rgb(200, 230, 201)',
  'rgb(248, 187, 208)',
  'rgb(225, 190, 231)',
  'rgb(187, 222, 251)',
  'rgb(240, 244, 195)',
  'rgb(77, 208, 225)',
  'rgb(197, 202, 233)',
];
