import { useAddOrRemoveContentEntries } from 'domain/api/common/adapters/useAddOrRemoveContentEntries';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { generateAddOrRemoveContentEntriesString } from 'domain/api/common/fragments';
import { ApiEmbedding, Embedding } from '../types';
import { transformApiEmbeddingToEmbedding } from '../transforms';
import { AllEmbeddingInfo } from '../fragments';

type Input = {
  embeddingId: string;
  contentSpaceId: string;
  contentEntries: string[];
};

type Response = {
  addEmbeddingContentEntries: {
    embedding: ApiEmbedding;
  };
};

export const useAddEmbeddingMappingsMutation = () => {
  const schemas = useApiSchemas();
  return useAddOrRemoveContentEntries<Input, Response, Embedding>(
    generateAddOrRemoveContentEntriesString('add', 'Embedding', AllEmbeddingInfo),
    (data) => schemas?.embedding.apiSchema ? transformApiEmbeddingToEmbedding(
      validateApiResponseEntity(
        data?.addEmbeddingContentEntries?.embedding,
        schemas.embedding.apiSchema,
      ),
    ) : undefined,
  );
};
