import React from 'react';
import { ReadonlyField } from '../shared/ReadonlyField/ReadonlyField';
import { NumberFieldControl } from './NumberFieldControl';
import { NumberFieldReadonly } from './NumberFieldReadonly';
import { NumberFieldProps } from './types';

export function NumberField<TName extends string>(props: NumberFieldProps<TName>) {
  const { readonly, ...rest } = props;
  if (readonly) {
    return (
      <ReadonlyField errors={rest.errors} label={rest.label}>
        <NumberFieldReadonly value={rest.value} prefix={rest.prefix} suffix={rest.suffix} />
      </ReadonlyField>
    );
  }
  return <NumberFieldControl {...rest} />;
}
