import {
  createFailedPublishMessage,
  createSuccessfulPublishMessage,
  useSetMessage,
} from '_lib/messages';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { ValidatedEntity } from 'domain/api/common/types';
import { useIdInputMutation } from 'domain/api/common/adapters/useIdInputMutation';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { PUBLISH_ONE_FLOOR_PLAN } from './gql';
import { ApiFloorPlan, FloorPlan } from '../types';
import { transformApiFloorPlanToFloorPlan } from '../transforms';

type Response = {
  publishFloorPlan: {
    floorPlan?: ApiFloorPlan;
  };
};

export const usePublishOneFloorPlan = () => {
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  return useIdInputMutation<Response, ValidatedEntity<FloorPlan> | undefined>(
    PUBLISH_ONE_FLOOR_PLAN,
    (data) =>
      schemas?.floorPlan.apiSchema
        ? transformApiFloorPlanToFloorPlan(
          validateApiResponseEntity(data?.publishFloorPlan.floorPlan, schemas.floorPlan.apiSchema)
        )
        : undefined,
    {
      onSuccess: () => setMessage(createSuccessfulPublishMessage('')),
      onError: (err) => setMessage(createFailedPublishMessage('', err)),
    }
  );
};
