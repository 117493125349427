import React from 'react';
import { LayoutRenderer } from 'lib/layout/LayoutRenderer';
import { EntitySectionForm } from 'domain/view/components/form/EntitySectionForm';
import { FloorPlan } from 'domain/api/floorPlan/types';
import { PageSectionOptions } from '_lib/sectionedPageLayout';

type Props = PageSectionOptions & {
  initialValues: FloorPlan;
  onSubmit: (values: FloorPlan) => void;
};

export const FloorPlanBasicInfoForm = ({ initialValues: fields, onSubmit, ...rest }: Props) => {
  return (
    <EntitySectionForm
      resourceName="floorPlan"
      initialValues={fields}
      onUpdate={onSubmit}
      {...rest}
    >
      {(renderField) => (
        <LayoutRenderer
          items={[
            {
              container: true,
              direction: 'column',
              spacing: 2,
              items: [
                {
                  container: true,
                  item: true,
                  spacing: 2,
                  items: [
                    {
                      item: true,
                      xl: 6,
                      renderContent: () => renderField('displayName'),
                    },
                    {
                      item: true,
                      xl: 6,
                      renderContent: () => renderField('builderId', { disabled: true }),
                    },
                    {
                      item: true,
                      xl: 3,
                      renderContent: () => renderField('homeType'),
                    },
                    {
                      item: true,
                      xl: 3,
                      renderContent: () => renderField('floorSize'),
                    },
                    {
                      item: true,
                      xl: 3,
                      renderContent: () => renderField('bedrooms'),
                    },
                    {
                      item: true,
                      xl: 3,
                      renderContent: () => renderField('bathrooms'),
                    },
                    {
                      item: true,
                      xl: 3,
                      renderContent: () => renderField('halfBaths'),
                    },
                    {
                      item: true,
                      xl: 3,
                      renderContent: () => renderField('garage'),
                    },
                    {
                      item: true,
                      xl: 3,
                      renderContent: () => renderField('seoTitleTag'),
                    },
                    {
                      item: true,
                      xl: 3,
                      renderContent: () => renderField('seoMetaKeywords'),
                    },
                  ],
                },
              ],
            },
          ]}
        />
      )}
    </EntitySectionForm>
  );
};
