import { Asset, AssetFile } from '../asset/types';
import { ContentEntry, PublishableApiEntity, PublishedStatus } from '../common/types';

export type ApiImageLink = PublishableApiEntity<{
  id: string;
  published: boolean;
  displayName: string;
  url: string;
  isLarge: boolean;
  contentEntries: ContentEntry[];
  assets: Asset[];
}>;

export type ApiImageLinkInput = Partial<Pick<ApiImageLink, 'displayName' | 'url' | 'isLarge'> & {
  files: AssetFile[];
  contentEntries?: string[];
}>;

export const CLEARABLE_IMAGE_LINK_FIELDS: (keyof ApiImageLinkInput & string)[] = [
  'url',
];

export type ImageLink = Omit<ApiImageLink, 'contentEntries'> & {
  publishedStatus: PublishedStatus;
  communities: string[];
  floorPlans: string[];
  inventories: string[];
  showHomes: string[];
  contentEntries: string[];
};

export type ImageLinkInput = Partial<Omit<ImageLink, 'id' | 'publishedStatus' | 'published' | 'assets' | 'publishProblems'> & {
  files: AssetFile[];
}>;
