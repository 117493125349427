import React from 'react';
import { useContentSpaceId } from '_lib/auth';
import {
  useCreateOneFloorPlan,
  useDeleteOneFloorPlan,
  useGetManyFloorPlans,
  usePublishOneFloorPlan,
  useUnpublishOneFloorPlan,
} from 'domain/api/floorPlan';
import { FloorPlan } from 'domain/api/floorPlan/types';
import { ListPage } from 'domain/view/pages/ListPage/ListPage';
import { useUiText } from 'domain/uiText';
import { CreateFloorPlanForm } from '../../forms/CreateFloorPlanForm';

export const FloorPlanListPageContainer = () => {
  const uiText = useUiText();
  const contentSpaceId = useContentSpaceId();
  const getFloorPlans = useGetManyFloorPlans();
  const createFloorPlan = useCreateOneFloorPlan(uiText.FLOOR_PLAN_PATH);
  const publishFloorPlan = usePublishOneFloorPlan();
  const unpublishFloorPlan = useUnpublishOneFloorPlan();
  const deleteFloorPlan = useDeleteOneFloorPlan();

  const get = () => getFloorPlans.execute();

  const handleCreate = (values: FloorPlan) => {
    const { builderId, ...rest } = values;
    values.builderId &&
      contentSpaceId &&
      createFloorPlan.execute({
        floorPlan: rest,
        builderId,
        contentSpaceId,
      });
  };
  return (
    <ListPage
      resourceName="floorPlan"
      get={get}
      actions={{
        publish: (id: string) => publishFloorPlan.execute({ id }),
        unpublish: (id: string) => unpublishFloorPlan.execute({ id }),
        delete: (id: string) => deleteFloorPlan.execute({ id }),
      }}
      loading={getFloorPlans.loading}
      actionLoading={
        unpublishFloorPlan.loading || publishFloorPlan.loading || deleteFloorPlan.loading
      }
      error={undefined}
      data={getFloorPlans.result}
      called={getFloorPlans.called}
      hasData={getFloorPlans.result.length > 0}
      noDataMessage={uiText.NO_FLOOR_PLAN}
      topbar={{
        title: uiText.FLOOR_PLAN_PLURAL,
        createAction: <CreateFloorPlanForm onSubmit={handleCreate} />,
      }}
    />
  );
};
