import React from 'react';
import { useContentSpaceId } from '_lib/auth';
import { useUpdateOneAsset } from 'domain/api/asset';
import { AssetFormItem } from '../../components/AssetFormItem';
import { isPdf } from '../../helpers';

type Props = {
  index: number;
  url: string;
  id: string;
  isPdf?: boolean;
  showCheckeredBackground?: boolean;
  description?: string;
  displayName?: string;
  startUpdate?: boolean;
  onDescriptionChange?: (index: number, value: string) => void;
  onSuccess: () => void;
  descriptionFieldError: string | undefined;
};

export const AssetFormEditingItem = (props: Props) => {
  const contentSpaceId = useContentSpaceId();
  const [updateAssetMutation, { called, loading }] = useUpdateOneAsset();

  React.useEffect(() => {
    if (props.startUpdate && !called && !loading && contentSpaceId) {
      updateAssetMutation({
        variables: {
          input: {
            contentSpaceId,
            id: props.id,
            description: props.description,
          },
        },
      }).then((res) => {
        if (!res.errors && res.data) {
          props.onSuccess();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.startUpdate]);
  const handleDescriptionChange = (_index: number, value: string) => {
    props.onDescriptionChange && props.onDescriptionChange(props.index, value);
  };

  return (
    <React.Fragment>
      <AssetFormItem
        index={props.index}
        url={props.url}
        id={props.id}
        showDescription={true}
        isReadonly={false}
        description={props.description}
        onDescriptionChange={handleDescriptionChange}
        loading={loading}
        isPdf={isPdf(props.url) || props.isPdf}
        showCheckeredBackground={props.showCheckeredBackground}
        displayName={props.displayName}
        descriptionFieldError={props.descriptionFieldError}
      />
    </React.Fragment>
  );
};
