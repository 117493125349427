import React from 'react';
import { Grid } from '@material-ui/core';
import { Redirect, useParams } from 'react-router';
import { FieldError, Status } from 'common/types';
import { PublishedStatusChip } from '_lib/components/display/publishedStatus/PublishedStatusChip';
import { Section } from '_lib/sectionedPageLayout';
import { Spinner } from '_lib/loading';
import { SectionSeparator } from '_lib/components';
import { useUiText } from 'domain/uiText';
import { useLinks } from 'domain/routes/useLinks';
import { AssetForm } from 'domain/view/components/form/AssetForm/AssetForm';
import { DetailPageLayout } from 'domain/view/containers/DetailPageLayout';
import { ImageLinkMappingForm } from 'domain/view/components/form/ImageLinkMappingForm/ImageLinkMappingForm';
import { EmbeddingMappingForm } from 'domain/view/components/form/EmbeddingMappingForm/EmbeddingMappingForm';
import { LocationForm } from 'domain/view/components/form/LocationForm/LocationForm';
import { ValidatedEntity } from 'domain/api/common/types';
import { Contact } from 'domain/api/contact';
import { Community } from 'domain/api/community';
import { TopbarConfig } from 'domain/view/components/layout/topbar/useResourceTopbarConfig';
import { useSections } from 'domain/sections/useSections';
import { CommunityPricingForm } from 'domain/view/components/form/CommunityPricingForm/CommunityPricingForm';
import { CommunityDescriptionForm } from '../../forms/CommunityDescriptionForm';
import { CommunityLotmapForm } from '../../forms/CommunityLotmapForm';
import { CommunityBasicInfoForm } from '../../forms/CommunityBasicInfoForm';
import { CommunityOpenhouseWidgetsForm } from '../../forms/CommunityOpenhouseWidgetsForm';
import { CommunitySalesCentresForm } from '../../forms/communitySalesCentres/CommunitySalesCentresForm';
import { DemostatsSection } from '../../forms/demostats/DemostatsSection';

interface CommunityDetailPageProps {
  get: (id: string) => void;
  handleUpdate: (values: Partial<Community>) => void;
  handleRefetch: () => void;
  data: ValidatedEntity<Community>;
  validation?: {
    errors?: FieldError[] | undefined;
  };
  publishedStatus: Status;
  loading: boolean;
  called: boolean;
  topbar: TopbarConfig;
  contentSpaceId: string;
  contacts: Contact[];
}

export const CommunityDetailPage: React.FunctionComponent<CommunityDetailPageProps> = ({
  get,
  data,
  topbar,
  loading,
  validation,
  publishedStatus,
  handleUpdate,
  handleRefetch,
  contentSpaceId,
  contacts,
  called,
}) => {
  const params = useParams<{ id: string }>();
  const uiText = useUiText();
  const links = useLinks();
  const sections = useSections();

  React.useEffect(() => {
    get(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  if ((!called && !data) || (!data && loading)) return <Spinner />;
  if (!data) {
    return <Redirect to={links.communityList.path} />;
  }

  return (
    <DetailPageLayout
      backTo={topbar.backButtonPath}
      backLabel={uiText.COMMUNITY_PLURAL}
      thumbnail={topbar.thumbnailUrl}
      title={topbar.title}
      statusChip={<PublishedStatusChip publishedStatus={publishedStatus} />}
      actions={topbar.actions}
      validationErrors={validation?.errors}
    >
      {loading && <Spinner withContent />}
      <Grid item container spacing={3} direction="column" wrap="nowrap">
        <Grid item>
          <Section options={{ ...sections.basicInformation }}>
            <CommunityBasicInfoForm
              initialValues={data}
              onSubmit={handleUpdate}
              {...sections.basicInformation}
            />
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.description }}>
            <CommunityDescriptionForm
              initialValues={data}
              onSubmit={handleUpdate}
              {...sections.description}
            />
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.startingPrices }}>
            <CommunityPricingForm
              startingPrices={data.startingPrices || []}
              onSubmit={(values) =>
                handleUpdate({
                  startingPrices: values.map((v) => ({ name: v.id, price: v.price || 0 })),
                })
              }
              type="communityStartingPrice"
              {...sections.startingPrices}
            />
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.lotmap }}>
            <Grid item direction="row" container spacing={2}>
              <Grid item xs>
                <CommunityLotmapForm
                  initialValues={data}
                  onSubmit={handleUpdate}
                  {...sections.lotmap}
                />
              </Grid>
              <Grid item xs>
                <AssetForm
                  entity={{ ...data, __typename: 'Community' }}
                  entityContentId={data.id}
                  assets={data.assets}
                  attributeName="sitemapImage"
                  sectionConfig={{
                    title: 'Lotmap Image',
                    id: 'community-lotmap-image',
                  }}
                  contentSpaceId={contentSpaceId}
                />
              </Grid>
            </Grid>
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.thumbnail }}>
            <AssetForm
              entity={{ ...data, __typename: 'Community' }}
              onUploadCompleted={handleRefetch}
              attributeName="thumbnailImage"
              isDeletable={false}
              contentSpaceId={contentSpaceId}
              entityContentId={data.id}
              assets={data.assets}
              sectionConfig={sections.thumbnail}
            />
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.bannerImage }}>
            <AssetForm
              entity={{ ...data, __typename: 'Community' }}
              attributeName="bannerImage"
              isDeletable={false}
              contentSpaceId={contentSpaceId}
              entityContentId={data.id}
              assets={data.assets}
              sectionConfig={sections.bannerImage}
            />
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.logoImage }}>
            <AssetForm
              showCheckeredBackground={true}
              entity={{ ...data, __typename: 'Community' }}
              attributeName="logoImage"
              isDeletable={true}
              contentSpaceId={contentSpaceId}
              entityContentId={data.id}
              assets={data.assets}
              sectionConfig={sections.logoImage}
            />
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.sloganImage }}>
            <AssetForm
              showCheckeredBackground={true}
              entity={{ ...data, __typename: 'Community' }}
              attributeName="sloganImage"
              isDeletable={true}
              contentSpaceId={contentSpaceId}
              entityContentId={data.id}
              assets={data.assets}
              sectionConfig={sections.sloganImage}
            />
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.gallery }}>
            <AssetForm
              entity={{ ...data, __typename: 'Community' }}
              attributeName="galleryPhotos"
              isMultiple={true}
              contentSpaceId={contentSpaceId}
              entityContentId={data.id}
              assets={data.assets}
              sectionConfig={sections.gallery}
            />
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.banners }}>
            <ImageLinkMappingForm
              resourceName="banner"
              sourceContext="community"
              sourceId={data.id}
              sourceTypeName="Community"
              mapped={data.imageLinks}
              {...sections.banners}
            />
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.imageLinks }}>
            <ImageLinkMappingForm
              resourceName="imageLink"
              sourceContext="community"
              sourceId={data.id}
              sourceTypeName="Community"
              mapped={data.imageLinks}
              {...sections.imageLinks}
            />
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.openhouseWidgets }}>
            <CommunityOpenhouseWidgetsForm
              initialValues={data}
              onSubmit={handleUpdate}
              {...sections.openhouseWidgets}
            />
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.thirdPartyWidgets }}>
            <EmbeddingMappingForm
              resourceName="embedding"
              sourceContext="community"
              sourceId={data.id}
              mapped={data.embeddings}
              {...sections.thirdPartyWidgets}
            />
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.videos }}>
            <EmbeddingMappingForm
              resourceName="video"
              sourceContext="community"
              sourceId={data.id}
              mapped={data.embeddings}
              {...sections.videos}
            />
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.salesCentres }}>
            <CommunitySalesCentresForm
              entityContentId={data.id}
              contacts={contacts}
              salesCentres={data.salesCentres}
              {...sections.salesCentres}
            />
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.location }}>
            <LocationForm
              location={{
                lat: data.lat,
                lng: data.lng,
              }}
              onSubmit={(values) =>
                handleUpdate({
                  ...values,
                })
              }
              {...sections.location}
            />
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <DemostatsSection
            communityId={data.id}
            geopoint={
              (data.lat &&
                data.lng && {
                  lng: data.lng,
                  lat: data.lat,
                }) ||
              undefined
            }
            {...sections.demostats}
          />
        </Grid>
      </Grid>
    </DetailPageLayout>
  );
};
