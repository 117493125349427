import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

export type RegistrationHook = (key: symbol) => ({
  isRegistered: boolean,
  useLoadingBlind: (loading: boolean) => void,
});

export const noopRegistrationHook: RegistrationHook = (_: symbol) => ({
  isRegistered: false,
  useLoadingBlind: (__) => {}
});

const registrationState = atom<RegistrationHook>({
  key: 'LoadingBlockerRegistration',
  default: noopRegistrationHook,
});

export const useSetLoadingRegistration = (registration: RegistrationHook) => {
  return useSetRecoilState(registrationState)(registration);
};

export const useRegisterLoadingBlocker: RegistrationHook = (key: symbol) => {
  return useRecoilValue(registrationState)(key);
};
