import { gql } from '@apollo/client';
import { pascalCase } from '_lib/common/helpers';
import { camelCase } from 'lodash';

export const PublishProblemsFragment = `
  publishProblems {
    field
    problemCode
  }
`;

export const generateQueryStringWithContentSpace = (
  query: string,
  additionalVariables?: string,
) => {
  return (fragment?: string) => {
    return () => gql`
      ${fragment || ''}
      query contentSpace ($contentSpaceId: ID!${additionalVariables ? `, ${additionalVariables}` : ''}) {
        contentSpace (id: $contentSpaceId) {
          ${query}
        }
      }
    `;
  };
};

export const generateDeleteMutationString = (entityName: string) => gql`
  mutation delete${entityName}($input: Delete${entityName}Input) {
    delete${entityName}(input: $input) { deletedId }
  }
`;

export const generatePublishMutationString = (entityName: string, query: string) => {
  return (fragment?: string) => {
    return () => gql`
      ${fragment || ''}
      mutation publish${entityName}($input: Publish${entityName}Input) {
        publish${entityName}(input: $input) {
          ${query}
        }
      }
    `;
  };
};

export const generateUnpublishMutationString = (entityName: string, query: string) => {
  return (fragment?: string) => {
    return () => gql`
      ${fragment || ''}
      mutation unpublish${entityName}($input: Unpublish${entityName}Input) {
        unpublish${entityName}(input: $input) {
          ${query}
        }
      }
    `;
  };
};

export const generateCreateOneMutationStringCurried = (entityName: string, query: string) => {
  return (fragment?: string) => {
    return () => gql`
      ${fragment || ''}
      mutation create${entityName}($input: Create${entityName}Input) {
        create${entityName}(input: $input) {
          ${query}
        }
      }
    `;
  };
};

export const generateCreateOrUpdateString = (
  operation: 'create' | 'update',
  entityName: string,
  fragment: string,
) => {
  const camelCaseOperation = camelCase(operation);
  const pascalCaseOperation = pascalCase(operation);
  const camelCaseName = camelCase(entityName);
  const pascalCaseName = pascalCase(entityName);

  return gql`
    fragment All${pascalCaseName}Info on ${pascalCaseName} { ${fragment} }

    mutation ${camelCaseOperation}${pascalCaseName}($input: ${pascalCaseOperation}${pascalCaseName}Input) {
      ${camelCaseOperation}${pascalCaseName}(input: $input) {
        ${camelCaseName} { ...All${pascalCaseName}Info }
      }
    }
  `;
};

export const generateUpdateOneMutationString = (entityName: string, query: string) => {
  return (fragment?: string) => {
    return () => gql`
      ${fragment || ''}
        mutation update${entityName}($input: Update${entityName}Input) {
          update${entityName}(input: $input) {
            ${query}
          }
        }
    `;
  };
};

export const generateAddOrRemoveContentEntriesString = (
  operation: 'add' | 'remove',
  entityName: string,
  fragment: string,
) => {
  const camelCaseOperation = camelCase(operation);
  const pascalCaseOperation = pascalCase(operation);
  const camelCaseName = camelCase(entityName);
  const pascalCaseName = pascalCase(entityName);

  return gql`
    fragment ${camelCaseOperation}${pascalCaseName}ContentEntriesInfo on ${pascalCaseName} { ${fragment} }

    mutation ${camelCaseOperation}${pascalCaseName}ContentEntries($input: ${pascalCaseOperation}${pascalCaseName}ContentEntriesInput) {
      ${camelCaseOperation}${pascalCaseName}ContentEntries(input: $input) {
        ${camelCaseName} {
          ...${camelCaseOperation}${pascalCaseName}ContentEntriesInfo
        }
      }
    }
  `;
};
