import React from 'react';
import { atom, useRecoilState } from 'recoil';

const multiFormSemaphoreState = atom<string | undefined>({
  key: 'multiFormSemaphore',
  default: undefined,
});

export const useFormSemaphore = (formName: string, editing: boolean) => {
  const [activeForm, setActiveForm] = useRecoilState(multiFormSemaphoreState);
  React.useEffect(() => {
    if (editing && activeForm === undefined) {
      setActiveForm(formName);
    } else if (!editing && activeForm === formName) {
      setActiveForm(undefined);
    }
  });

  React.useEffect(() => {
    return () => {
      setActiveForm(undefined);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enabled = !activeForm || activeForm === formName;
  return {
    enabled,
  };
};
