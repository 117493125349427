import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton, Tooltip } from '@material-ui/core';
import { useBaseUiText } from '_lib/uiText';

interface Props {
  onEdit?: () => void;
  disabled?: boolean;
  small?: boolean;
}

export const EditIconButton = React.memo(({
  disabled,
  onEdit,
  small,
}: Props) => {
  const uiText = useBaseUiText();
  return (
    <Tooltip title={uiText.ACTION_EDIT_LABEL}>
      <div>
        <IconButton
          onClick={onEdit}
          disabled={disabled}
          size={small ? 'small' : undefined}
        >
          <EditIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
});
