import React from 'react';
import { Chip, Grid, Tooltip, Typography } from '@material-ui/core';
import { useUiText } from 'domain/uiText';
import { FeatureTag } from 'domain/api/featureTag';

interface Props {
  editing: boolean;
  featureTags: FeatureTag[];
  onDeleteTag: (tagId: string) => void;
}

export const FeatureTagsList = ({ editing, featureTags, onDeleteTag }: Props) => {
  const uiText = useUiText();
  return (
    <Grid container spacing={1}>
      {featureTags.length ? featureTags
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((featureTag) => (
          <Grid item key={featureTag.id}>
            <Tooltip title={featureTag.description || ''}>
              <Chip
                label={featureTag.name}
                onDelete={editing ? () => onDeleteTag(featureTag.id) : undefined}
              />
            </Tooltip>
          </Grid>
        )) : (
          <Grid item>
            <Typography color="textSecondary" variant="body1">
              {uiText.NO_FEATURE_TAGS}
            </Typography>
          </Grid>
        )
      }
    </Grid>
  );
}
