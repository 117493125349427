import React from 'react';
import { Grid } from '@material-ui/core';
import { useContentSpaceId } from '_lib/auth';
import { DeleteIconButton } from '_lib/components';
import { CardForm } from '_lib/form/variations';
import { LayoutRenderer } from 'lib';
import {
  SalesCentre,
  useUpdateOneSalesCentre,
  useDeleteOneSalesCentre,
} from 'domain/api/salesCentre';
import { Contact } from 'domain/api/contact';
import { useResourceFields } from 'system/resources/hooks/useResourceFields';
import { CommunitySalesCentreContacts } from './ComunitySalesCentreContacts';

type Props = {
  salesCentre: SalesCentre;
  contacts: Contact[];
  entityContentId: string;
  openForm: () => void;
  closeForm: () => void;
};

export const CommunitySalesCentre = ({
  salesCentre,
  contacts,
  openForm,
  closeForm,
  entityContentId,
}: Props) => {
  const contentSpaceId = useContentSpaceId() || '';
  const updateSalesCentre = useUpdateOneSalesCentre();
  const deleteSalesCentre = useDeleteOneSalesCentre(salesCentre.community);

  const form = useResourceFields('salesCentre', {
    hasReadonly: true,
    initialValues: salesCentre,
  });

  const handleEdit = () => {
    openForm();
    form.onEdit();
  };

  const handleCancel = () => {
    closeForm();
    form.onCancel();
  };

  const handleSubmit = () => {
    closeForm();
    form.onSubmit(
      () => {},
      (values) =>
        updateSalesCentre.execute({
          ...values,
          id: salesCentre.id,
          community: salesCentre.community,
          contentSpaceId,
        })
    );
  };

  const handleDeleteSalesCentre = () => deleteSalesCentre.execute({ id: salesCentre.id });

  return (
    <Grid item={true}>
      <CardForm
        title={salesCentre.name}
        editing={form.isEditing}
        submitDisabled={form.hasErrors}
        onEdit={handleEdit}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        additionalActions={
          <Grid item={true}>
            <DeleteIconButton onDelete={handleDeleteSalesCentre} />
          </Grid>
        }
        loading={updateSalesCentre.loading || deleteSalesCentre.loading}
      >
        <LayoutRenderer
          items={[
            {
              container: true,
              direction: 'column',
              spacing: 2,
              items: [
                {
                  container: true,
                  spacing: 2,
                  items: [
                    {
                      xl: 6,
                      renderContent: () => form.renderField('name'),
                    },
                    {
                      xl: 6,
                      renderContent: () => form.renderField('address'),
                    },
                  ],
                },
                {
                  container: true,
                  spacing: 2,
                  items: [
                    {
                      xl: 6,
                      renderContent: () => form.renderField('phone'),
                    },
                    {
                      xl: 6,
                      renderContent: () => form.renderField('hours'),
                    },
                  ],
                },
                {
                  container: true,
                  items: [
                    {
                      renderContent: () => (
                        <CommunitySalesCentreContacts
                          salesCentre={salesCentre}
                          allContacts={contacts}
                          entityContentId={entityContentId}
                        />
                      ),
                    },
                  ],
                },
              ],
            },
          ]}
        />
      </CardForm>
    </Grid>
  );
};
