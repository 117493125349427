import * as yup from 'yup';
import { isKeyOf } from '_lib/common/helpers';

import {
  ResourceConstraint, Resource,
  ResourceFields, ExternalResourceConfig, InternalResourceConfig,
  ResourceFieldsConstraint,
  ResourceAction,
} from './types';

type MakeResource<
TResource extends ResourceConstraint,
TResourceFields extends ResourceFieldsConstraint,
TResourceDataDisplay extends ResourceConstraint | undefined = undefined
> = {
  internalConfig: InternalResourceConfig<
  TResource,
  TResourceFields,
  TResourceDataDisplay
  >;
  externalConfig: ExternalResourceConfig;
};

export function makeResource<
TResource extends ResourceConstraint,
TResourceFields extends ResourceFieldsConstraint,
TResourceDataDisplay extends ResourceConstraint | undefined = undefined
>({
  internalConfig,
  externalConfig,
}: MakeResource<
TResource,
TResourceFields,
TResourceDataDisplay
>): Resource<TResource, TResourceFields, TResourceDataDisplay> {
  const {
    defaultResource, defaultResourceFields,
    fields, fieldsValidationSchema,
    enabledPages,
  } = internalConfig;

  const externalFields = externalConfig.fields;
  const externalActions = externalConfig.actions;

  // Create field validation function for each field using the provided schema
  // and disable the field based on the external config
  const transformedFields: ResourceFields<TResourceFields> = { ...fields };
  Object.keys(transformedFields).forEach(key => {
    if (isKeyOf(transformedFields, key)) {
      if (externalFields.allManual) transformedFields[key].disabled = false;
      if (externalFields.allAutomatic) transformedFields[key].disabled = true;
      if (externalFields.automatic && externalFields.automatic.includes(key)) {
        transformedFields[key].disabled = true;
      }
      if (externalFields.manual && externalFields.manual.includes(key)) {
        transformedFields[key].disabled = false;
      }

      // Create validation function for field
      if (isKeyOf(fieldsValidationSchema.fields, key)) {
        transformedFields[key].validate = value => {
          try {
            // yup will throw an error when the first validation error is encountered, but we want
            // to capture all validation errors and present them to the user so we set abortEarly to false
            fieldsValidationSchema.validateSync({ [key]: value }, { abortEarly: false });
            return [];
          } catch (_error) {
            // Making sure the error is actually a yup.ValidationError before continuing
            if (_error && _error.name && _error.name === 'ValidationError') {
              const error: yup.ValidationError = _error;
              return error.errors;
            }

            // This should be caught by an error boundary component in the future.
            throw new Error(`An unknown error occured when validating resource field ${_error}`);
          }
        };
      }
    }
  });


  // Get the list page link for this resource
  const getListPageLink = () => ({ label: externalConfig.aliases.plural, path: `/${externalConfig.aliases.path}` });

  // Get the single page link for this resource
  const getSinglePageLink = (id: string, label?: string) => ({
    label: label || externalConfig.aliases.singular,
    path: `/${externalConfig.aliases.path}/${id}`,
  });

  // Determine if an action is disabled from the external config.
  // Example: CREATE could be a disabled operation
  const isActionDisabled = (action: ResourceAction) => {
    if (externalActions.automatic && externalActions.automatic.includes(action)) return true;
    if (externalActions.manual && externalActions.manual.includes(action)) return false;
    if (externalActions.allManual) return false;
    if (externalActions.allAutomatic) return true;
    return true;
  };

  return {
    enabled: externalConfig.enabled,
    defaultResource,
    defaultResourceFields,
    fields: transformedFields,
    fieldsValidationSchema,
    dataDisplay: internalConfig.dataDisplay,
    routes: {
      list: enabledPages.includes('list') ? `/${externalConfig.aliases.path}` : undefined,
      single: enabledPages.includes('single') ? `/${externalConfig.aliases.path}/:id` : undefined,
    },
    helpers: {
      isActionDisabled,
      getListPageLink: internalConfig.enabledPages.includes('list') ? getListPageLink : undefined,
      getSinglePageLink: internalConfig.enabledPages.includes('single') ? getSinglePageLink : undefined,
    }
  };
}
