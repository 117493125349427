import React from 'react';
import classNames from 'classnames';
import { Card, CardContent, CardHeader, makeStyles, Theme } from '@material-ui/core';
import { DisabledFormOverlay } from './DisabledFormOverlay';
import { FormActions } from './FormActions';
import { FormLayoutProps } from './types';

export const CardFormLayout = ({
  icon,
  title,
  helpText,
  actions,
  editing,
  children,
  formDisabled,
  hasErrors,
  ...rest
}: FormLayoutProps) => {
  const classes = useStyles();
  return (
    <Card
      className={classNames(
        classes.card,
        { [classes.editing]: editing },
        { [classes.formError]: hasErrors }
      )}
    >
      {formDisabled && <DisabledFormOverlay />}
      <CardHeader
        avatar={React.cloneElement(icon, { color: 'action' })}
        title={title}
        subheader={helpText}
        titleTypographyProps={{ variant: 'h5' }}
        subheaderTypographyProps={{ variant: 'caption' }}
        action={actions || <FormActions editing={editing} formDisabled={formDisabled} {...rest} />}
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    position: 'relative',
    borderTop: '3px solid #00000000',
  },
  editing: {
    borderColor: theme.palette.primary.main,
  },
  formError: {
    borderColor: theme.palette.error.main,
  },
}));
