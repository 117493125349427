import React from 'react';
import classNames from 'classnames';
import { CardContent, makeStyles, Theme } from '@material-ui/core';

interface OwnProps {
  noPadding?: boolean;
}

type Props = OwnProps;
export const FormContent: React.FC<Props> = (props) => {
  const { children, noPadding } = props;
  const classes = useStyles();
  return (
    <CardContent className={classNames({ [classes.noPadding]: noPadding })}>
      {children}
    </CardContent>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  noPadding: {
    padding: '0px !important',
  },
}));
