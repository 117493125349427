import React from 'react';
import {
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import HomeIcon from '@material-ui/icons/Home';
import HouseIcon from '@material-ui/icons/House';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import { Community } from 'domain/api/community';
import { FloorPlan } from 'domain/api/floorPlan';
import { Inventory } from 'domain/api/inventory';
import { ShowHome } from 'domain/api/showhome';
import { Spinner } from '_lib/loading';
import { LandingPageNotification } from './LandingPageNotification';
import { LandingPageItem } from './LandingPageItem';

interface LandingPageProps {
  get: () => void;
  loading: boolean;
  communityTitle: string;
  floorPlanTitle: string;
  inventoryTitle: string;
  showHomeTitle: string;
  communities: Community[];
  floorPlans: FloorPlan[];
  inventories: Inventory[];
  showHomes: ShowHome[];
  communitiesLink: string;
  floorPlansLink: string;
  inventoriesLink: string;
  showHomesLink: string;
}

export const LandingPage: React.FunctionComponent<LandingPageProps> = ({
  get,
  loading,
  floorPlans,
  floorPlanTitle,
  communities,
  communityTitle,
  inventories,
  inventoryTitle,
  showHomeTitle,
  showHomes,
  communitiesLink,
  floorPlansLink,
  inventoriesLink,
  showHomesLink,
}) => {
  const classes = useStyles();

  React.useEffect(() => {
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.landingPageContainer}>
      {loading && <Spinner />}
      {!loading && (
        <Grid container direction="column" spacing={6}>
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper} elevation={2}>
                <Typography align="left" variant="h4" gutterBottom={true}>
                  Welcome to the OpenHouse.AI CMS
                </Typography>
                <LandingPageNotification hasCommunity={!!communities.length} />
              </Paper>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid container item spacing={3}>
            <LandingPageItem
              resource={floorPlans}
              title={floorPlanTitle}
              description={`In this section you can review, edit and create ${floorPlanTitle}.`}
              icon={<HomeIcon />}
              link={floorPlansLink}
            />
            <LandingPageItem
              resource={communities}
              title={communityTitle}
              description={`In this section you can review, edit and create ${communityTitle}.`}
              icon={<LocationCityIcon />}
              link={communitiesLink}
            />
            <LandingPageItem
              resource={inventories}
              title={inventoryTitle}
              description={`In this section you can review, edit and create ${inventoryTitle}.`}
              icon={<HouseIcon />}
              link={inventoriesLink}
            />
            <LandingPageItem
              resource={showHomes}
              title={showHomeTitle}
              description={`In this section you can review, edit and create ${showHomeTitle}.`}
              icon={<HomeWorkIcon />}
              link={showHomesLink}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: 20,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    landingPageContainer: {
      padding: 20,
      backgroundColor: theme.palette.grey[100],
      height: '100vh',
    },
  })
);
