import * as yup from 'yup';
import { UiText } from 'domain/uiText';
import { FeatureTagFields } from './types';

export const featureTagFieldsSchema = (uiText: UiText) =>
  yup
    .object<FeatureTagFields>({
      name: yup.string().defined().required().default('').label('Name'),
      featureTagCategory: yup.string().defined().required(),
    })
    .defined();
