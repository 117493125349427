import React from 'react';
import RemoveIcon from '@material-ui/icons/Remove';
import { IconButton, Tooltip } from '@material-ui/core';
import { useBaseUiText } from '_lib/uiText';

interface Props {
  onRemove?: () => void;
  disabled?: boolean;
  tooltip?: string;
  small?: boolean;
}

export const RemoveIconButton = React.memo(({
  disabled,
  tooltip,
  onRemove,
  small,
}: Props) => {
  const uiText = useBaseUiText();
  return (
    <Tooltip title={tooltip || uiText.ACTION_REMOVE_LABEL}>
      <div>
        <IconButton
          onClick={onRemove}
          disabled={disabled}
          size={small ? 'small' : undefined}
        >
          <RemoveIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
});
