import React from 'react';
import { LayoutRenderer } from 'lib';
import { Button } from '@material-ui/core';
import { ResourcesContextValue } from 'system/resources/types';
import { ImageLinkInput } from 'domain/api/imageLink/types';
import { useUiText } from 'domain/uiText';
import { EntityDialogActionForm } from '../EntityDialogActionForm';

type Props = {
  onSubmit: (values: ImageLinkInput, onClose: () => void, alsoPublish?: boolean) => void;
  submitting: boolean;
  submitted: boolean;
  disabled: boolean;
  resourceName: Extract<
  keyof Pick<ResourcesContextValue, 'imageLink' | 'banner'>, string
  >;
};

export const CreateImageLinkMappingForm = ({
  onSubmit,
  submitted,
  submitting,
  disabled,
  resourceName,
}: Props) => {
  const uiText = useUiText();
  const newLabel = resourceName === 'banner'
    ? uiText.ACTION_NEW_BANNER_LABEL
    : uiText.ACTION_NEW_IMAGE_LINK_LABEL;

  return (
    <EntityDialogActionForm
      resourceName="imageLink"
      onSubmit={onSubmit}
      title={newLabel}
      action={(onClick) => (
        <Button
          size="small"
          variant="outlined"
          style={{ textTransform: 'capitalize', marginRight: 8 }}
          onClick={onClick}
          disabled={disabled}
        >
          {newLabel}
        </Button>
      )}
      submitting={submitting}
      submitted={submitted}
      showCreateAndPublishAction={true}
    >
      {(renderField) => (
        <LayoutRenderer
          items={[
            {
              container: true, direction: 'column', spacing: 2,
              items: [
                {
                  renderContent: () => renderField('displayName'),
                },
                {
                  renderContent: () => renderField('url'),
                },
                {
                  renderContent: () => renderField('files'),
                },
              ],
            },
          ]}
        />
      )}
    </EntityDialogActionForm>
  );
};
