import { defaultUiText, UiText } from 'domain/uiText';
import { ExternalResourceConfig, InternalResourceConfig, makeResource } from 'lib/resource';
import {
  defaultImageLink,
  defaultImageLinkFields,
  ImageLink,
  imageLinkDataDisplayFields,
  ImageLinkFields,
  imageLinkFields,
} from './types';
import { defaultExternalResourceConfig } from '../shared';
import { imageLinkFieldsSchema } from './schemas';

export const imageLinkInternalResourceConfig = (uiText: UiText): InternalResourceConfig<
ImageLink,
ImageLinkFields
> => ({
  enabledPages: ['list'],
  defaultResource: defaultImageLink,
  defaultResourceFields: defaultImageLinkFields,
  fields: imageLinkFields(uiText),
  fieldsValidationSchema: imageLinkFieldsSchema(uiText),
  dataDisplay: imageLinkDataDisplayFields(uiText),
});

export const defaultImageLinkExternalResourceConfig: ExternalResourceConfig = {
  ...defaultExternalResourceConfig,
  aliases: {
    path: 'image-links',
    plural: 'Image Links',
    singular: 'Image Link',
  },
};

export const defaultImageLinkResource = makeResource({
  internalConfig: imageLinkInternalResourceConfig(defaultUiText),
  externalConfig: defaultImageLinkExternalResourceConfig,
});
