import { useAddOrRemoveContentEntries } from 'domain/api/common/adapters/useAddOrRemoveContentEntries';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { generateAddOrRemoveContentEntriesString } from 'domain/api/common/fragments';
import { ApiImageLink, ImageLink } from '../types';
import { transformApiImageLinkToImageLink } from '../transforms';
import { AllImageLinkInfo } from '../fragments';

type Input = {
  imageLinkId: string;
  contentSpaceId: string;
  contentEntries: string[];
};

type Response = {
  addImageLinkContentEntries: {
    imageLink: ApiImageLink;
  };
};

export const useAddImageLinkMappingsMutation = () => {
  const schemas = useApiSchemas();
  return useAddOrRemoveContentEntries<Input, Response, ImageLink>(
    generateAddOrRemoveContentEntriesString('add', 'ImageLink', AllImageLinkInfo),
    (data) => schemas?.imageLink.apiSchema ? transformApiImageLinkToImageLink(
      validateApiResponseEntity(
        data?.addImageLinkContentEntries?.imageLink,
        schemas.imageLink.apiSchema,
      ),
    ) : undefined,
  );
};
