import { UiText } from 'domain/uiText';
import { DataDisplay, Resource, ResourceFields } from 'lib/resource';
import { idCellRenderer } from '_lib/components/table/CellRenderers';
import { Asset } from 'domain/api/asset/types';
import { PublishProblem } from 'domain/api/common/types';
import { Embedding } from '../embedding';
import { ImageLink } from '../imageLink';

export type ShowHome = {
  id: string;
  builderId: string;
  published: boolean;
  displayName: string;

  homeType: string | null;
  bedrooms: number | null;
  bathrooms: number | null;
  halfBaths: number | null;
  floorSize: number | null;
  garage: string | null;

  address: {
    fullAddress: string | null;
    address: string | null;
    city: string | null;
    countrySubDivisionCode: string | null;
    country: string | null;
    postalCode: string | null;
  } | null;

  fullAddress: string | null;

  showMonthlyPaymentCalculator: boolean | null;
  showCommuteCalculation: boolean | null;

  locationVisualizerLat: number | null;
  locationVisualizerLon: number | null;

  seoTitleTag: string | null;
  seoMetaDescription: string | null;
  seoMetaKeywords: string[] | null;

  assets: Asset[];
  embeddings: Embedding[];
  imageLinks: ImageLink[];

  publishProblems: PublishProblem[];

  floorPlan: {
    id: string;
    publishProblems: PublishProblem[];
  } | null;
  community: {
    id: string;
    communityGeoPointLat: number;
    communityGeoPointLon: number;
  } | null;
};

export const defaultShowHome: ShowHome = {
  id: '',
  builderId: '',
  published: false,
  displayName: '',

  homeType: null,
  bedrooms: null,
  bathrooms: null,
  halfBaths: null,
  floorSize: null,
  garage: null,

  showMonthlyPaymentCalculator: false,
  showCommuteCalculation: true,

  locationVisualizerLat: null,
  locationVisualizerLon: null,

  seoTitleTag: null,
  seoMetaDescription: null,
  seoMetaKeywords: [],

  address: null,
  fullAddress: '',

  assets: [],
  embeddings: [],
  imageLinks: [],

  floorPlan: null,
  community: null,

  publishProblems: [],
};

export type ShowHomeFields = Partial<{
  displayName: string;
  homeType: string | null;
  bedrooms: number | null;
  bathrooms: number | null;
  halfBaths: number | null;
  floorSize: number | null;
  garage: string | null;

  showMonthlyPaymentCalculator: boolean | null;
  showCommuteCalculation: boolean | null;

  seoTitleTag: string | null;
  seoMetaKeywords: string[] | null;

  address: string | null;
  city: string | null;
  countrySubDivisionCode: string | null;
  country: string | null;
  postalCode: string | null;

  seoDescriptionBody: string;
  seoDescriptionBullets: string[];
  lat: number | null;
  lng: number | null;
  floorPlanId: string | null;
  communityId: string | null;
}> & { builderId: string };

export type ShowHomeResource = Resource<ShowHome, ShowHomeFields>;

export const defaultShowHomeFields: ShowHomeFields = {
  builderId: '',
  displayName: '',

  homeType: null,
  bedrooms: 0,
  bathrooms: 0,
  halfBaths: 0,
  floorSize: 0,
  garage: null,

  showMonthlyPaymentCalculator: false,
  showCommuteCalculation: true,

  lat: null,
  lng: null,

  seoTitleTag: null,
  seoDescriptionBody: '',
  seoDescriptionBullets: [],
  seoMetaKeywords: [],

  floorPlanId: '',
  communityId: '',
};

export const showHomeFields = (uiText: UiText): ResourceFields<ShowHomeFields> => ({
  builderId: {
    value: '',
    name: 'builderId',
    type: 'text',
    fieldConfig: {
      label: 'ID',
      required: true,
    },
  },
  displayName: {
    value: '',
    name: 'displayName',
    type: 'text',
    fieldConfig: {
      label: 'Name',
      required: true,
    },
  },
  homeType: {
    value: '',
    name: 'homeType',
    type: 'text',
    fieldConfig: {
      label: 'Home Type',
      required: true,
    },
  },
  bedrooms: {
    value: 0,
    name: 'bedrooms',
    type: 'number',
    fieldConfig: {
      label: 'Bedrooms',
      nullable: true,
    },
  },
  bathrooms: {
    value: 0,
    name: 'bathrooms',
    type: 'number',
    fieldConfig: {
      label: 'Bathrooms',
      nullable: true,
    },
  },
  halfBaths: {
    value: 0,
    name: 'halfBaths',
    type: 'number',
    fieldConfig: {
      label: 'Half Bathrooms',
      nullable: true,
    },
  },
  floorSize: {
    value: 0,
    name: 'floorSize',
    type: 'number',
    fieldConfig: {
      label: 'Floor Size',
      nullable: true,
    },
  },
  garage: {
    value: '',
    name: 'garage',
    type: 'text',
    fieldConfig: {
      label: 'Garage',
      nullable: true,
    },
  },
  showMonthlyPaymentCalculator: {
    value: false,
    name: 'showMonthlyPaymentCalculator',
    type: 'check-box',
    fieldConfig: {
      label: 'Monthly Payment Calculator',
    },
  },
  showCommuteCalculation: {
    value: false,
    name: 'showCommuteCalculation',
    type: 'check-box',
    fieldConfig: {
      label: 'Commute Calculator',
    },
  },
  lat: {
    value: null,
    name: 'lat',
    type: 'number',
    fieldConfig: {
      label: 'Latitude',
    },
  },
  lng: {
    value: null,
    name: 'lng',
    type: 'number',
    fieldConfig: {
      label: 'Longitude',
    },
  },
  seoTitleTag: {
    value: '',
    name: 'seoTitleTag',
    type: 'text',
    fieldConfig: {
      label: 'SEO Title',
      helperText: `This defaults to the ${uiText.SHOWHOME_SINGULAR.toLocaleLowerCase()} name and your company name.`,
    },
  },
  seoDescriptionBody: {
    value: '',
    name: 'seoDescriptionBody',
    type: 'text',
    fieldConfig: {
      label: 'Body Text',
      multiline: true,
    },
  },
  seoDescriptionBullets: {
    value: [],
    name: 'seoDescriptionBullets',
    type: 'text-list',
    fieldConfig: {
      label: 'Bullet Points',
      type: 'bullet',
    },
  },
  seoMetaKeywords: {
    value: [] as string[],
    name: 'seoMetaKeywords',
    type: 'text-list',
    fieldConfig: {
      label: 'SEO Keywords',
    },
  },
  floorPlanId: {
    value: '',
    name: 'floorPlanId',
    type: 'select',
    fieldConfig: {
      label: uiText.FLOOR_PLAN_SINGULAR,
    },
  },
  communityId: {
    value: '',
    name: 'communityId',
    type: 'select',
    fieldConfig: {
      label: uiText.COMMUNITY_SINGULAR,
    },
  },
  address: {
    value: '',
    name: 'address',
    type: 'text',
    fieldConfig: {
      label: 'Address',
      required: true,
    },
  },
  city: {
    value: '',
    name: 'city',
    type: 'text',
    fieldConfig: {
      label: 'City',
    },
  },
  countrySubDivisionCode: {
    value: '',
    name: 'countrySubDivisionCode',
    type: 'text',
    fieldConfig: {
      label: 'State / Province',
    },
  },
  country: {
    value: '',
    name: 'country',
    type: 'text',
    fieldConfig: {
      label: 'Country',
    },
  },
  postalCode: {
    value: '',
    name: 'postalCode',
    type: 'text',
    fieldConfig: {
      label: 'Postal / Zip Code',
    },
  },
});

export const showHomeDataDisplayFields = (uiText: UiText): DataDisplay<ShowHome> => ({
  fields: {
    displayName: {
      dataKey: 'displayName',
      label: 'Name',
    },
    builderId: {
      dataKey: 'builderId',
      label: 'ID',
      renderer: idCellRenderer,
    },
    homeType: {
      dataKey: 'homeType',
      label: 'Home Type',
    },
    fullAddress: {
      dataKey: 'fullAddress',
      label: 'Address',
    },
  },
});
