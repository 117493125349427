import * as yup from 'yup';

export const apiAssetValidationSchema = yup.object({
  __typename: yup.string(),
  id: yup.string().defined().default(''),
  url: yup.string().defined().default(''),
  contentSpaceId: yup.string().defined().default(''),
  displayName: yup.string().defined().default(''),
  description: yup.string().defined().default('').label('Description').max(250),
  attributeName: yup
    .string()
    .oneOf([
      'galleryPhotos',
      'sitemapImage',
      'sloganImage',
      'logoImage',
      'bannerImage',
      'thumbnailImage',
      'listingMainImage',
      'floorPlanImages',
      'attachmentFile',
      'attachmentImage',
      'displayImageNormal',
    ])
    .defined(),
  displayOrder: yup.number().integer().defined().nullable().default(null),
}).defined();
