import { UiText } from 'domain/uiText/uiText';
import * as yup from 'yup';
import { apiAssetValidationSchema } from '../asset/validation';
import { pickCommonApiValidationSchemas } from '../common/validation';
import { ApiImageLink, ApiImageLinkInput } from './types';

export const apiImageLinkInputValidationSchema = (
  uiText: UiText,
) => yup.object<ApiImageLinkInput, object>({
  ...pickCommonApiValidationSchemas(uiText)(['displayName']),
  url: yup.string().defined().default('').label('URL'),
  isLarge: yup.boolean().defined().default(false),
  files: yup.array().of(yup.object<Pick<File, 'name' | 'lastModified' | 'size' | 'type'>>({
    name: yup.string().defined().required(),
    lastModified: yup.number().defined().required(),
    size: yup.number().defined().required(),
    type: yup.string().defined().required(),
  }).defined()).defined(),
}).defined();

export const apiImageLinkValidationSchema = (
  uiText: UiText,
  hasParent?: boolean,
) => yup.object<ApiImageLink, object>({
  ...pickCommonApiValidationSchemas(uiText)(['displayName']),
  url: yup.string().defined().default('').label('URL'),
  isLarge: yup.boolean().defined().default(false),
  contentEntries: yup.array().of(yup.object({
    __typename: yup.string().defined().default(''),
    id: yup.string().defined().default(''),
    builderId: yup.string().defined().default(''),
    published: yup.boolean().defined().default(false),
  }).defined()).defined().default([]),
  assets: yup.array()
    .of(apiAssetValidationSchema)
    .test(
      'hasImage',
      'Image is required',
      value => hasParent
        ? true
        : !!value?.find(el => el.attributeName === 'displayImageNormal'),
    )
    .defined()
    .default([]),
}).defined();
