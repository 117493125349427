/* eslint-disable no-await-in-loop */
import { wait } from 'utilities/helpers';
import { getOneAsset } from '../getOne/getAsset';
import { Asset } from '../types';

const MAX_RETRIES = 5;
export const pollAsset = async (id: string, contentSpaceId: string): Promise<Asset | undefined> => {
  try {
    let retries = 0;

    while (retries <= MAX_RETRIES) {
      const asset = await getOneAsset(id, contentSpaceId);
      if (asset.data?.contentSpace.asset) {
        return asset.data.contentSpace.asset;
      }
      await wait(2000);
      retries += 1;
    }

    throw new Error('Failed to retrieve asset');
  } catch (error) {
    return error;
  }
};
