import React from 'react';
import classNames from 'classnames';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { APP_TOPBAR_HEIGHT } from 'domain/view/constants';

export interface Props {
  left?: React.ReactNode,
  right?: React.ReactNode,
  elevated?: boolean,
}

export const Topbar = ({ left, right, elevated }: Props) => {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      alignItems="center"
      className={classNames(classes.bar, { [classes.elevated]: elevated })}
    >
      <Grid item className={classes.left}>
        {left}
      </Grid>
      <Grid item>
        {right}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  bar: {
    minHeight: APP_TOPBAR_HEIGHT,
    padding: `${theme.spacing(2)}px 0px`,
    position: 'relative',
  },
  elevated: {
    boxShadow: theme.shadows[3],
    zIndex: theme.zIndex.drawer,
    backgroundColor: theme.palette.common.white,
  },
  left: {
    flexGrow: 2,
  },
}));
