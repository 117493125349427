import React from 'react';
import { ReadonlyField } from '../shared/ReadonlyField/ReadonlyField';
import { TextFieldControl } from './TextFieldControl';
import { TextFieldReadonly } from './TextFieldReadonly';
import { TextFieldProps } from './types';

export function TextField<TName extends string>(props: TextFieldProps<TName>) {
  const { readonly, ...rest } = props;
  if (readonly) {
    return (
      <ReadonlyField errors={rest.errors} label={rest.label}>
        <TextFieldReadonly value={rest.value || undefined} />
      </ReadonlyField>
    );
  }
  return <TextFieldControl {...rest} />;
}
