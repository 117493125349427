import React from 'react';
import { LayoutRenderer } from 'lib';
import { autoGenerateBuilderIdFromDisplayName } from 'system/resources/utilities/fieldModifiers';
import { useCheckFloorPlanDisplayName } from 'domain/api/floorPlan';
import { useDisplayNameAvailable } from 'system/resources/hooks/useDisplayNameAvailable';
import { FloorPlan } from 'domain/api/floorPlan/types';
import { EntityDialogActionForm } from 'domain/view/components/form/EntityDialogActionForm';
import { useUiText } from 'domain/uiText';

type Props = {
  onSubmit: (values: FloorPlan) => void;
};

export const CreateFloorPlanForm = ({ onSubmit }: Props) => {
  const uiText = useUiText();
  const { error, success, onChange, loading, reset } = useDisplayNameAvailable(
    useCheckFloorPlanDisplayName
  );

  return (
    <EntityDialogActionForm
      resourceName="floorPlan"
      onSubmit={onSubmit}
      submitDisabled={loading}
      externalFieldErrors={{
        displayName: error ? [error] : [],
      }}
      fieldModifiers={{
        displayName: autoGenerateBuilderIdFromDisplayName,
      }}
      title={uiText.ACTION_NEW_FLOOR_PLAN_LABEL}
      action={{
        type: 'create',
        label: uiText.ACTION_NEW_FLOOR_PLAN_LABEL,
      }}
      onClose={reset}
      includeUntouchedFields={true}
    >
      {(renderField) => (
        <LayoutRenderer
          items={[
            {
              container: true,
              direction: 'column',
              spacing: 2,
              items: [
                {
                  container: true,
                  item: true,
                  spacing: 2,
                  items: [
                    {
                      item: true,
                      xl: 6,
                      renderContent: () =>
                        renderField('displayName', {
                          onChangeCallback: onChange,
                          loading,
                          successText: success,
                        }),
                    },
                    {
                      item: true,
                      xl: 6,
                      renderContent: () => renderField('builderId'),
                    },
                    {
                      item: true,
                      xl: 6,
                      renderContent: () => renderField('homeType'),
                    },
                    {
                      item: true,
                      xl: 6,
                      renderContent: () => renderField('floorSize'),
                    },
                    {
                      item: true,
                      xl: 4,
                      sm: 6,
                      renderContent: () => renderField('bedrooms'),
                    },
                    {
                      item: true,
                      xl: 4,
                      sm: 6,
                      renderContent: () => renderField('bathrooms'),
                    },
                    {
                      item: true,
                      xl: 4,
                      sm: 6,
                      renderContent: () => renderField('halfBaths'),
                    },
                  ],
                },
              ],
            },
          ]}
        />
      )}
    </EntityDialogActionForm>
  );
};
