import React from 'react';
import { useContentSpaceId } from '_lib/auth';
import { AddIconButton } from '_lib/components';
import { FormDialog } from '_lib/form/formDialog/FormDialog';
import { useResourceFields } from 'system/resources/hooks/useResourceFields';
import { SalesCentreFields } from 'system/resources/salesCentre';
import { useToggle } from 'common/hooks/useToggle';
import { useCreateOneSalesCentre } from 'domain/api/salesCentre';
import { useResetableAdapterAction } from 'domain/api/common/hooks';
import { useUiText } from 'domain/uiText';

type Props = {
  entityContentId: string;
};

export const CreateSalesCentreDialog = ({ entityContentId }: Props) => {
  const contentSpaceId = useContentSpaceId();
  const uiText = useUiText();
  const dialogState = useToggle();
  const form = useResourceFields('salesCentre', { includeUntouchedFields: true });
  const createSalesCentre = useResetableAdapterAction(useCreateOneSalesCentre);

  const handleCreateSalesCentre = (values: SalesCentreFields) => {
    contentSpaceId &&
      createSalesCentre.execute({
        ...values,
        community: entityContentId,
        contentSpaceId,
      });
  };

  const handleSubmit = () => {
    form.onSubmit(
      () => {},
      (values) => handleCreateSalesCentre(values)
    );
  };

  React.useEffect(() => {
    if (createSalesCentre.called && !createSalesCentre.loading && dialogState.open) {
      dialogState.onClose();
      form.reset();
    }
  }, [createSalesCentre, dialogState, form]);

  return (
    <React.Fragment>
      <AddIconButton
        tooltip={uiText.ACTION_NEW_SALES_CENTRE_LABEL}
        onAdd={() => dialogState.onOpen()}
      />
      <FormDialog
        title={uiText.ACTION_NEW_SALES_CENTRE_LABEL}
        open={dialogState.open}
        submitDisabled={!form.canSubmit}
        onClose={dialogState.onClose}
        onSubmit={handleSubmit}
        submitting={createSalesCentre.loading}
        hasErrors={form.hasErrors && form.attemptedSubmit}
      >
        {form.renderField('name')}
        {form.renderField('address')}
        {form.renderField('phone')}
        {form.renderField('hours')}
      </FormDialog>
    </React.Fragment>
  );
};
