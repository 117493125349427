import { createFailedCreationMessage, createSuccessfulCreationMessage, useSetMessage } from '_lib/messages';
import { ValidatedEntity } from 'domain/api/common/types';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { updateCacheOnCreateOne } from 'domain/api/common/cache';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { generateCreateOrUpdateString } from 'domain/api/common/fragments';
import { useCreateOrUpdateOne } from 'domain/api/common/adapters/useCreateOrUpdateOne';
import { apolloClient } from 'domain/api';
import { ApiSalesCentre, ApiSalesCentreInput, SalesCentre } from '../types';
import { AllSalesCentreInfo } from '../fragments';
import { transformApiSalesCentreToSalesCentre, transformSalesCentreToApiSalesCentreInput } from '../transforms';

const CREATE_SALES_CENTRE = generateCreateOrUpdateString('create', 'salesCentre', AllSalesCentreInfo);

type Response = {
  createSalesCentre: {
    salesCentre: ApiSalesCentre | undefined;
  }
};

type Input<T> = T & {
  contentSpaceId: string;
};

export const useCreateOneSalesCentre = () => {
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  const { execute: executeMutation, ...rest } = useCreateOrUpdateOne<
  Input<ApiSalesCentreInput>,
  Response,
  ValidatedEntity<SalesCentre> | undefined
  >(
    CREATE_SALES_CENTRE,
    (data) => schemas?.salesCentre.apiSchema ? transformApiSalesCentreToSalesCentre(
      validateApiResponseEntity(
        data?.createSalesCentre.salesCentre,
        schemas.salesCentre.apiSchema,
      ),
    ) : undefined,
    {
      onSuccess: (res) => {
        const newSalesCentre = res;
        apolloClient.cache.modify({
          id: `Community:${newSalesCentre?.community}`,
          fields: {
            salesCentres(existing = []) {
              return [...existing, newSalesCentre];
            }
          }
        });
        setMessage(createSuccessfulCreationMessage(''));
      },
      onError: (err) => setMessage(createFailedCreationMessage('', err)),
      updateCache: (cache, data) => updateCacheOnCreateOne(
        cache,
        'salesCentres',
        data?.createSalesCentre.salesCentre,
        `fragment AllSalesCentreInfo on SalesCentre {
          ${AllSalesCentreInfo}
        }`,
      ),
    },
  );

  const execute = ({
    contentSpaceId,
    ...restData
  }: Input<Partial<SalesCentre>>) => executeMutation({
    contentSpaceId,
    ...transformSalesCentreToApiSalesCentreInput(restData),
  });

  return { execute, ...rest };
};
