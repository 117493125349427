import React from 'react';
import { Grid } from '@material-ui/core';
import { FormLayout } from '_lib/form/formSection/components/FormLayout';
import { useFormState } from '_lib/multiFormSemaphore';
import { PageSectionOptions } from '_lib/sectionedPageLayout';
import { FeatureTag } from 'domain/api/featureTag';
import { FeatureTagsList } from './FeatureTagsList';
import { FeatureTagsComboBox } from './FeatureTagsComboBox';

interface Props extends PageSectionOptions {
  featureTags: FeatureTag[]
  onSubmit: (featureTags: FeatureTag[]) => void;
}

export const FeatureTagsForm = ({ id, icon, title, helpText, featureTags, onSubmit }: Props) => {
  const { formId, editing, enabled, openForm, closeForm } = useFormState(id);
  const [draftTags, setDraftTags] = React.useState<FeatureTag[]>([]);
  const edit = () => {
    setDraftTags(featureTags);
    openForm();
  }
  const addTag = (tag: FeatureTag) => setDraftTags([...draftTags, tag]);
  const deleteTag = (tagId: string) => setDraftTags(draftTags.filter(tag => tag.id !== tagId));
  const submitForm = () => {
    onSubmit(draftTags);
    closeForm();
  }

  return (
    <FormLayout
      formId={formId}
      icon={icon}
      title={title}
      helpText={helpText}
      editing={editing}
      formDisabled={!enabled}
      onEdit={edit}
      onSubmit={submitForm}
      onCancel={closeForm}
    >
      <Grid container spacing={2} direction="column">
        <Grid item>
          <FeatureTagsList
            editing={editing}
            featureTags={editing ? draftTags : featureTags}
            onDeleteTag={deleteTag}
            />
        </Grid>
        {editing && (
          <Grid item>
            <FeatureTagsComboBox
              excludeOptions={draftTags}
              onAddTag={addTag}
            />
          </Grid>
        )}
      </Grid>
    </FormLayout>
  );
};
