import { defaultUiText, UiText } from 'domain/uiText';
import { ExternalResourceConfig, InternalResourceConfig, makeResource } from 'lib/resource';
import {
  defaultFeatureTag,
  defaultFeatureTagFields,
  FeatureTag,
  FeatureTagFields,
  featureTagFields,
  featureTagDataDisplayFields,
} from './types';
import { featureTagFieldsSchema } from './schemas';
import { defaultExternalResourceConfig } from '../shared';

export const featureTagInternalResourceConfig = (uiText: UiText): InternalResourceConfig<
FeatureTag,
FeatureTagFields
> => ({
  enabledPages: ['list'],
  defaultResource: defaultFeatureTag,
  defaultResourceFields: defaultFeatureTagFields,
  fields: featureTagFields(uiText),
  fieldsValidationSchema: featureTagFieldsSchema(uiText),
  dataDisplay: featureTagDataDisplayFields(uiText),
});

export const defaultFeatureTagExternalResourceConfig: ExternalResourceConfig = {
  ...defaultExternalResourceConfig,
  aliases: {
    path: 'features',
    plural: 'Features',
    singular: 'Feature',
  },
};

export const defaultFeatureTagResource = makeResource({
  internalConfig: featureTagInternalResourceConfig(defaultUiText),
  externalConfig: defaultFeatureTagExternalResourceConfig,
});
