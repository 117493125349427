import { gql } from '@apollo/client';
import { AllAssetInfo } from '../fragments';

export const UPDATE_ASSET = gql`
fragment AllAssetInfo on Asset {
  ${AllAssetInfo}
}
mutation updateAsset($input: UpdateAssetInput!) {
  updateAsset(input: $input) {
    asset {
      ...AllAssetInfo
    }
  }
}
`;
