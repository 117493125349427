import React from 'react';
import { useMutation } from '@apollo/client';
import { apolloClient } from 'domain/api/_apollo/client';
import { useContentSpaceId } from '_lib/auth';
import { updateCacheOnCreateOne } from 'domain/api/common/cache';
import { AllImageLinkInfo } from '../fragments';
import { ApiImageLink, ImageLinkInput } from '../types';
import { useCreateImageLinkAsset } from './useCreateImageLinkAsset';
import { CREATE_ONE_IMAGE_LINK, NEW_IMAGE_LINK_FRAGMENT } from './gql';
import { CreateImageLinkInput, CreateImageLinkResponse } from './types';

type Entity = { id: string, __typename: string, [x: string]: any };

const updateEntityInCache = (imageLink: ApiImageLink, entity: Entity) => {
  const { cache } = apolloClient;
  cache.modify({
    // Get the cache object of the entity
    id: cache.identify({ ...entity }),
    fields: {
      imageLinks(existing = []) {
        const newImageLink = { ...imageLink };
        const newImageLinkRef = cache.writeFragment({
          data: newImageLink,
          fragment: NEW_IMAGE_LINK_FRAGMENT,
        });
        return [...existing, newImageLinkRef];
      }
    }
  });
};

export const useCreateImageLink = (
  entity?: Entity,
  isLarge?: boolean,
)  => {
  const contentSpaceId = useContentSpaceId();
  // This was done to fix an issue with the combined loading states of
  // the mutations not result in a consistent loading state for this hook, which
  // caused issues like dialog forms closing early
  const [loading, setLoading] = React.useState(false);
  const createImageLinkAsset = useCreateImageLinkAsset();
  const [mutation, mutationResult] = useMutation<CreateImageLinkResponse, CreateImageLinkInput>(
    CREATE_ONE_IMAGE_LINK,
  );

  const execute = async (input: ImageLinkInput): Promise<ApiImageLink> => {
    try {
      if (!contentSpaceId) throw new Error('Missing contentSpaceId');
      const file = input.files && input.files.length > 0 ? input.files[0] : undefined;

      setLoading(true);
      const createImageLinkResult = await mutation({
        variables: {
          input: {
            contentSpaceId,
            displayName: input.displayName,
            url: input.url,
            isLarge: !!isLarge,
            contentEntries: [
              ...(input.communities || []),
              ...(input.floorPlans || []),
              ...(input.showHomes || []),
              ...(input.inventories || []),
              ...(input.contentEntries || []),
            ],
          },
        },
      });

      const imageLink = createImageLinkResult.data?.createImageLink.imageLink;

      if (!imageLink?.id) throw new Error('Failed to create');

      if (!file) {
        if (entity) updateEntityInCache(imageLink, entity);
        updateCacheOnCreateOne(
          apolloClient.cache,
          'imageLinks',
          imageLink,
          `fragment AllImageLinkInfo on ImageLink {
            ${AllImageLinkInfo}
          }`
        );
        setLoading(false);
        return imageLink;
      }

      const imageLinkWithAsset = await createImageLinkAsset.execute(imageLink, file);
      if (!imageLinkWithAsset) throw new Error('Failed to create');

      if (entity) updateEntityInCache(imageLinkWithAsset, entity);
      updateCacheOnCreateOne(
        apolloClient.cache,
        'imageLinks',
        imageLinkWithAsset,
        `fragment AllImageLinkInfo on ImageLink {
          ${AllImageLinkInfo}
        }`
      );
      setLoading(false);
      return imageLinkWithAsset;
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  return {
    execute,
    ...mutationResult,
    loading,
  };
};
