import { UiText } from 'domain/uiText';
import { Asset } from 'domain/api/asset/types';
import { DataDisplay, Resource, ResourceFields } from 'lib/resource';
import { FloorPlan } from 'system/resources/floorPlan';
import { idCellRenderer } from '_lib/components/table/CellRenderers';
import { Embedding } from '../embedding';
import { ImageLink } from '../imageLink';
import { SalesCentre } from '../salesCentre';

export type CommunityGroup = {
  name?: string;
};

export type FloorPlanPricingMapping = {
  floorPlan: Pick<FloorPlan, 'id' | 'displayName'>;
  price: number;
};

export type StartingPrice = {
  name: string;
  price: number;
};

export type Community = {
  id: string;
  builderId: string;
  published: boolean;
  communityGroup: CommunityGroup | null;
  displayName: string;

  showCommunityStats: boolean | null;
  showCommuteCalculation: boolean | null;

  communityType: string | null;

  displayPriority: number | null;
  startingPrices: StartingPrice[] | null;
  communityGeoPointLat: number | null;
  communityGeoPointLon: number | null;

  seoTitleTag: string | null;
  seoMetaDescription: string | null;
  seoMetaKeywords: string[] | null;

  lotmapLink: string | null;
  lotmapText: string | null;

  floorPlanMappings?: FloorPlanPricingMapping[];
  assets: Asset[];
  embeddings: Embedding[];
  imageLinks: ImageLink[];
  salesCentres: SalesCentre[];
};

export const defaultCommunity: Community = {
  id: '',
  builderId: '',
  published: false,
  communityGroup: null,
  displayName: '',

  showCommunityStats: false,
  showCommuteCalculation: true,

  communityType: '',

  displayPriority: null,
  startingPrices: [],
  communityGeoPointLat: null,
  communityGeoPointLon: null,

  seoTitleTag: '',
  seoMetaDescription: '',
  seoMetaKeywords: [],

  lotmapLink: '',
  lotmapText: '',

  floorPlanMappings: [],
  assets: [],
  embeddings: [],
  imageLinks: [],
  salesCentres: [],
};

export type CommunityFields = Partial<{
  displayName: string;
  communityGroup: string | null;
  communityType: string | null;
  displayPriority: number | null;
  startingPrices: StartingPrice[] | null;
  showCommunityStats: boolean | null;
  showCommuteCalculation: boolean | null;
  seoTitleTag: string | null;
  seoMetaKeywords: string[] | null;
  lotmapLink: string | null;
  lotmapText: string | null;
  seoDescriptionBody: string;
  seoDescriptionBullets: string[];
  lat: number | null;
  lng: number | null;
}> & { builderId: string };

export type CommunityResource = Resource<
Community,
CommunityFields
>;

export const defaultCommunityFields: CommunityFields = {
  builderId: '',
  displayName: '',
  communityGroup: '',
  communityType: '',
  displayPriority: 0,
  startingPrices: [],
  showCommunityStats: false,
  showCommuteCalculation: false,
  lat: null,
  lng: null,
  seoTitleTag: '',
  seoDescriptionBody: '',
  seoDescriptionBullets: [],
  seoMetaKeywords: [],
  lotmapLink: '',
  lotmapText: '',
};

export const communityFields = (
  uiText: UiText,
): ResourceFields<Omit<CommunityFields, 'startingPrices'>> =>  ({
  builderId: {
    value: '',
    name: 'builderId',
    type: 'text',
    fieldConfig: {
      label: 'ID',
      required: true,
    },
  },
  displayName: {
    value: '',
    name: 'displayName',
    type: 'text',
    fieldConfig: {
      label: 'Name',
      required: true,
    }
  },
  communityGroup: {
    value: '',
    name: 'communityGroup',
    type: 'text',
    fieldConfig: {
      label: `${uiText.COMMUNITY_SINGULAR} Group`,
    }
  },
  communityType: {
    value: '',
    name: 'communityType',
    type: 'text',
    fieldConfig: {
      label: `${uiText.COMMUNITY_SINGULAR} Type`,
      nullable: true,
    }
  },
  displayPriority: {
    value: null,
    name: 'displayPriority',
    type: 'number',
    fieldConfig: {
      label: 'Display Priority',
      nullable: true,
    }
  },
  lat: {
    value: null,
    name: 'lat',
    type: 'number',
    fieldConfig: {
      label: 'Latitude',
      nullable: true,
    }
  },
  lng: {
    value: null,
    name: 'lng',
    type: 'number',
    fieldConfig: {
      label: 'Longitude',
      nullable: true,
    }
  },
  showCommunityStats: {
    value: false,
    name: 'showCommunityStats',
    type: 'check-box',
    fieldConfig: {
      label: `${uiText.COMMUNITY_SINGULAR} Statistics`,
    }
  },
  showCommuteCalculation: {
    value: true,
    name: 'showCommuteCalculation',
    type: 'check-box',
    fieldConfig: {
      label: 'Commute Calculator',
    }
  },
  seoTitleTag: {
    value: '',
    name: 'seoTitleTag',
    type: 'text',
    fieldConfig: {
      label: 'SEO Title',
      helperText: `This defaults to the ${uiText.COMMUNITY_SINGULAR.toLocaleLowerCase()} name and your company name.`,
      nullable: true,
    }
  },
  seoDescriptionBody: {
    value: '',
    name: 'seoDescriptionBody',
    type: 'text',
    fieldConfig: {
      label: 'Body Text',
      multiline: true,
      nullable: true,
    }
  },
  seoDescriptionBullets: {
    value: [],
    name: 'seoDescriptionBullets',
    type: 'text-list',
    fieldConfig: {
      label: 'Bullet Points',
      type: 'bullet',
    }
  },
  seoMetaKeywords: {
    value: [],
    name: 'seoMetaKeywords',
    type: 'text-list',
    fieldConfig: {
      type: 'chip',
      label: 'SEO Keywords',
    }
  },
  lotmapLink: {
    value: '',
    name: 'lotmapLink',
    type: 'text',
    fieldConfig: {
      label: 'Lotmap Link',
      nullable: true,
    }
  },
  lotmapText: {
    value: '',
    name: 'lotmapText',
    type: 'text',
    fieldConfig: {
      label: 'Lotmap Text',
      nullable: true,
    }
  },
});

export const communityDataDisplayFields = (
  uiText: UiText,
): DataDisplay<Community> => ({
  fields: {
    displayName: {
      dataKey: 'displayName',
      label: 'Name',
    },
    builderId: {
      dataKey: 'builderId',
      label: 'ID',
      renderer: idCellRenderer,
    },
    communityType: {
      dataKey: 'communityType',
      label: `${uiText.COMMUNITY_PLURAL} Type`,
    },
  },
});
