import { useHistory } from 'react-router';
import {
  createFailedCreationMessage,
  createSuccessfulCreationMessage,
  useSetMessage,
} from '_lib/messages';
import { ValidatedEntity } from 'domain/api/common/types';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { updateCacheOnCreateOne } from 'domain/api/common/cache';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { generateCreateOrUpdateString } from 'domain/api/common/fragments';
import { useCreateOrUpdateOne } from 'domain/api/common/adapters/useCreateOrUpdateOne';
import { ApiShowHome, ApiShowHomeInput, ShowHome } from '../types';
import { AllShowHomeInfo } from '../fragments';
import { transformApiShowHomeToShowHome, transformShowHomeToApiShowHomeInput } from '../transforms';

const CREATE_ONE_INVENTORY = generateCreateOrUpdateString('create', 'showHome', AllShowHomeInfo);

type Response = {
  createShowHome: {
    showHome: ApiShowHome | undefined;
  }
};

type Input<T> = {
  contentSpaceId: string;
  builderId: string;
  showHome: T;
};

export const useCreateOneShowHome = (redirectPath?: string) => {
  const history = useHistory();
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  const { execute: executeMutation, ...rest } = useCreateOrUpdateOne<
  Input<ApiShowHomeInput>,
  Response,
  ValidatedEntity<ShowHome> | undefined
  >(
    CREATE_ONE_INVENTORY,
    (data) => schemas?.showHome.apiSchema ? transformApiShowHomeToShowHome(
      validateApiResponseEntity(
        data?.createShowHome.showHome,
        schemas.showHome.apiSchema,
      ),
    ) : undefined,
    {
      onSuccess: (result) => {
        setMessage(createSuccessfulCreationMessage(''));
        if (redirectPath && result) history.push(`/${redirectPath}/${result.id}`);
      },
      onError: (err) => setMessage(createFailedCreationMessage('', err)),
      updateCache: (cache, data) => updateCacheOnCreateOne(
        cache,
        'showHomes',
        data?.createShowHome.showHome,
        `fragment AllShowHomeInfo on ShowHome {
          ${AllShowHomeInfo}
        }`,
      ),
    },
  );

  const execute = (input: Input<Partial<ShowHome>>) => executeMutation({
    ...input,
    showHome: transformShowHomeToApiShowHomeInput(input.showHome),
  });

  return { execute, ...rest };
};
