import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { SectionedPageLayout } from '_lib/sectionedPageLayout';
import { Action, FieldError } from 'common/types';
import { DetailPageTopbar } from '../components/layout/topbar/DetailPageTopbar';
import { TopbarLayout } from '../components/layout/topbar/TopbarLayout';
import { DetailPageValidationErrors } from '../pages/DetailPage/ValidationErrors';

export interface Props {
  backTo: string;
  backLabel: string;
  thumbnail?: string;
  title: string;
  statusChip?: React.ReactNode;
  actions: Action[];
  children?: React.ReactNode;
  validationErrors?: FieldError[];
}

export const DetailPageLayout = React.memo(({ children, ...rest }: Props) => {
  const classes = useStyles();
  return (
    <TopbarLayout topbar={<DetailPageTopbar {...rest} />}>
      <SectionedPageLayout>
        <div className={classes.layout}>
          {rest.validationErrors && (
            <DetailPageValidationErrors errors={rest.validationErrors} />
          )}
          {children}
        </div>
      </SectionedPageLayout>
    </TopbarLayout>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    padding: theme.spacing(3),
  },
}));
