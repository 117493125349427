import React from 'react';
import { useContentSpaceId } from '_lib/auth';
import { useGetManyFeatureTags, useCreateOneFeatureTag, FeatureTag } from 'domain/api/featureTag';
import { useUiText } from 'domain/uiText';
import { ListPage } from 'domain/view/pages/ListPage/ListPage';
import { transformFeatureTagToApiFeatureTagInput } from 'domain/api/featureTag/transforms';
import { useUpdateOneFeatureTag } from 'domain/api/featureTag/updateOne/hook';
import { useDeleteOneFeatureTag } from 'domain/api/featureTag/deleteOne';
import { useResetableAdapterAction } from 'domain/api/common/hooks';
import { EditFeatureTagForm, CreateFeatureTagForm } from '../../forms';

export const FeatureTagsListPageContainer = () => {
  const uiText = useUiText();
  const contentSpaceId = useContentSpaceId();
  const getFeatureTags = useGetManyFeatureTags();

  const deleteFeatureTag = useDeleteOneFeatureTag();
  const createFeatureTag = useResetableAdapterAction(useCreateOneFeatureTag);
  const updateFeatureTag = useResetableAdapterAction(useUpdateOneFeatureTag);
  const get = () => {
    getFeatureTags.execute();
  };

  const handleCreate = ({
    name,
    description,
    featureTagCategory,
  }: {
    name: string;
    description: string;
    featureTagCategory: string;
  }) => {
    contentSpaceId &&
      createFeatureTag.execute({ name, description, featureTagCategoryId: featureTagCategory });
  };

  const handleUpdate = (
    values: Partial<FeatureTag> & { featureTagCategory: string; id: string }
  ) => {
    const transformed = transformFeatureTagToApiFeatureTagInput(values);
    contentSpaceId && updateFeatureTag.execute({ ...transformed, contentSpaceId });
  };

  return (
    <ListPage
      resourceName="featureTag"
      get={get}
      actions={{
        delete: (id: string) => deleteFeatureTag.execute({ id, contentSpaceId }),
        edit: (featureTag) => (
          <EditFeatureTagForm
            featureTag={featureTag}
            submitting={updateFeatureTag.loading}
            submitted={updateFeatureTag.called && !updateFeatureTag.loading}
            onSubmit={(values) => {
              return handleUpdate({
                ...values,
                featureTagCategory: values.featureTagCategory ?? featureTag.category.id,
                id: featureTag.id,
              });
            }}
          />
        ),
      }}
      loading={getFeatureTags.loading}
      actionLoading={deleteFeatureTag.loading}
      error={undefined}
      data={getFeatureTags.result}
      called={getFeatureTags.called}
      hasData={getFeatureTags.result.length > 0}
      noDataMessage={uiText.NO_FEATURE_TAGS}
      topbar={{
        title: uiText.FEATURE_TAG_PLURAL,
        createAction: (
          <CreateFeatureTagForm
            onSubmit={handleCreate}
            submitting={createFeatureTag.loading}
            submitted={createFeatureTag.called}
          />
        ),
      }}
    />
  );
};
