import React from 'react';
import { AdapterAction } from 'domain/api/common/adapters/types';

const QUERY_DELAY = 1000;

const ERROR_MESSAGE = 'That name is not available';
const SUCCESS_MESSAGE = 'That name is available!';

export const useDisplayNameAvailable = (
  adapterAction: () => AdapterAction<string, boolean | undefined>,
) => {
  const [available, setAvailable] = React.useState<boolean | undefined>(undefined);
  const timeoutRef = React.useRef<number | null>(null);
  const {
    execute, loading, called, result,
  } = React.useCallback(() => adapterAction(), [adapterAction])();

  const onChange = React.useCallback((value?: string | null) => {
    if (timeoutRef.current) window.clearTimeout(timeoutRef.current);

    if (value && value.length > 0) {
      timeoutRef.current = window.setTimeout(() => execute(value), QUERY_DELAY);
    }

    if (!value) setAvailable(undefined);
  }, [execute]);

  const onBlur = (value?: string) => value && value.length > 0 && execute(value);

  React.useEffect(() => setAvailable(() => result), [result]);

  return {
    isAvailable: available,
    error: available === false ? ERROR_MESSAGE : undefined,
    success: available === true ? SUCCESS_MESSAGE : undefined,
    loading,
    called,
    onChange,
    onBlur,
    reset: () => setAvailable(undefined),
  };
};
