import { useGetManyImageLinks } from 'domain/api/imageLink';
import { ImageLink } from 'domain/api/imageLink/types';
import React from 'react';
import { ResourcesContextValue } from 'system/resources/types';

type Props = {
  sourceId: string;
  mapped: ImageLink[];
  resourceName: Extract<
  keyof Pick<ResourcesContextValue, 'imageLink' | 'banner'>, string
  >;
};

const imageLinksWithStatus = (
  imageLinks: ImageLink[],
) => imageLinks.map(el => ({
  ...el,
  publishedStatus: el.publishedStatus,
}));

const filterImageLinksbySize = (imageLinks: ImageLink[], isLarge?: boolean) => {
  return imageLinks.filter((imageLink) => {
    if (!isLarge && imageLink.isLarge) return false;
    if (isLarge && !imageLink.isLarge) return false;
    return true;
  });
};

export const useImageLinkMappingsData = ({
  sourceId,
  mapped,
  resourceName,
}: Props) => {
  const isLarge = resourceName === 'banner';
  const getImageLinks = useGetManyImageLinks(isLarge);

  React.useEffect(() => {
    if (!getImageLinks.called) {
      getImageLinks.execute();
    }
  }, [getImageLinks]);

  const mappedValidatedAndTransformed = React.useMemo(() => {
    return imageLinksWithStatus(
      filterImageLinksbySize(mapped, isLarge),
    );
  }, [mapped, isLarge]);

  const unmappedTransformed = React.useMemo(() => {
    return imageLinksWithStatus(
      filterImageLinksbySize(
        getImageLinks.result.filter(el => (
          !el.contentEntries.find((entry) => entry === sourceId))
        ),
        isLarge,
      ),
    );
  }, [isLarge, getImageLinks.result, sourceId]);

  return {
    mapped: mappedValidatedAndTransformed,
    unmapped: unmappedTransformed,
  };
};
