import { UiText } from 'domain/uiText/uiText';
import * as yup from 'yup';
import { ApiContact, ApiContactInput } from './types';

const sharedSchemas = (uiText: UiText) => ({
  name: yup.string().defined().required().default('').label('Name'),
  email: yup.string().defined().default('').label('Email'),
  title: yup.string().defined().default('').label('Job Title'),
  phone: yup.string().defined().default('').label('Phone Number'),
});

export const apiContactInputValidationSchema = (
  uiText: UiText,
) => yup.object<ApiContactInput, object>({
  ...sharedSchemas(uiText),
  salesCentres: yup.array().of(yup.string().defined()).default([]).defined(),
}).defined();

export const apiContactValidationSchema = (
  uiText: UiText,
) => yup.object<ApiContact, object>({
  id: yup.string().required(),
  ...sharedSchemas(uiText),
  salesCentres: yup.array().of(yup.object({
    id: yup.string().required(),
    name: yup.string().required(),
  }).defined()).default([]).defined(),
}).defined();
