import React from 'react';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import { Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { RemoveIconButton } from '_lib/components/buttons/RemoveIconButton';
import { Contact } from 'domain/api/contact';
import { Spinner } from '_lib/loading';
import { useRemoveContactsFromSalesCentre } from 'domain/api/salesCentre';
import { EditContactDialog } from './dialogs';

type Props = {
  contact: Contact;
  salesCentreId: string;
};

export const CommunitySalesCentreContact = ({ contact, salesCentreId }: Props) => {
  const classes = useStyles();
  const removeContactFromSalesCentre = useRemoveContactsFromSalesCentre();

  const handleRemoveContact = () => {
    removeContactFromSalesCentre.execute({ id: salesCentreId, input: [contact.id] });
  };

  return (
    <Grid item={true}>
      <Paper className={classes.contactContainer} style={{ padding: 16 }} elevation={0}>
        <Grid container={true} alignItems="center" wrap="nowrap">
          <Grid item={true} style={{ flexGrow: 2 }}>
            <Typography className={classes.contactTitle} variant="caption">
              {contact.title}
            </Typography>
            <Typography>{contact.name}</Typography>
            {contact.email && (
              <Typography
                className={classes.contactEmail}
                component="div"
                color="textSecondary"
                variant="caption"
              >
                <EmailIcon className={classes.contactIcon} color="inherit" fontSize="inherit" />
                {contact.email}
              </Typography>
            )}
            {contact.phone && (
              <Typography
                className={classes.contactPhone}
                component="div"
                color="textSecondary"
                variant="caption"
              >
                <PhoneIcon className={classes.contactIcon} color="inherit" fontSize="inherit" />
                {contact.phone}
              </Typography>
            )}
          </Grid>
          <Grid item={true} container={true} wrap="nowrap" style={{ flexGrow: 0, width: 'auto' }}>
            <RemoveIconButton onRemove={handleRemoveContact} small />
            <EditContactDialog salesCentreId={salesCentreId} contact={contact} />
          </Grid>
        </Grid>
        {removeContactFromSalesCentre.loading && <Spinner withContent={true} />}
      </Paper>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  contactTitle: {},
  contactContainer: {
    border: `solid 2px ${theme.palette.grey[300]}`,
  },
  contactIcon: {
    marginRight: 4,
    color: theme.palette.grey[400],
  },
  contactEmail: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
  },
  contactPhone: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
  },
}));
