import { createFailedUpdateMessage, createSuccessfulUpdateMessage, useSetMessage } from '_lib/messages';
import { IdType, ValidatedEntity, WithClearList } from 'domain/api/common/types';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { useCreateOrUpdateOne } from 'domain/api/common/adapters/useCreateOrUpdateOne';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { generateCreateOrUpdateString } from 'domain/api/common/fragments';
import { transformClearableFieldsToClearList } from 'domain/api/common/transforms';
import { ApiShowHome, ApiShowHomeInput, CLEARABLE_SHOW_HOME_FIELDS, ShowHome } from '../types';
import { transformApiShowHomeToShowHome, transformShowHomeToApiShowHomeInput } from '../transforms';
import { AllShowHomeInfo } from '../fragments';

const UPDATE_ONE_SHOW_HOME = generateCreateOrUpdateString('update', 'showHome', AllShowHomeInfo);

type Response = {
  updateShowHome: {
    showHome: ApiShowHome | undefined;
  }
};

type Input<T> = {
  contentSpaceId: string;
  id: string;
  idType: IdType;
  showHome: T;
};

export const useUpdateOneShowHome = () => {
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  const { execute: executeMutation, ...rest } = useCreateOrUpdateOne<
  WithClearList<ApiShowHomeInput, Input<ApiShowHomeInput>>,
  Response,
  ValidatedEntity<ShowHome> | undefined
  >(
    UPDATE_ONE_SHOW_HOME,
    (data) => schemas?.showHome.apiSchema ? transformApiShowHomeToShowHome(
      validateApiResponseEntity(
        data?.updateShowHome.showHome,
        schemas.showHome.apiSchema,
      ),
    ) : undefined,
    {
      onSuccess: () => setMessage(createSuccessfulUpdateMessage('')),
      onError: (err) => setMessage(createFailedUpdateMessage('', err)),
    },
  );

  const execute = (input: Input<Partial<ShowHome>>) => {
    const transformed = transformShowHomeToApiShowHomeInput(input.showHome);
    executeMutation({
      ...input,
      showHome: transformed,
      clear: transformClearableFieldsToClearList(transformed, CLEARABLE_SHOW_HOME_FIELDS),
    });
  }

  return { execute, ...rest };
};
