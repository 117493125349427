import { useHistory } from 'react-router';
import {
  createFailedDeleteMessage,
  createSuccessfulDeleteMessage,
  useSetMessage,
} from '_lib/messages';
import { useIdInputMutation } from 'domain/api/common/adapters/useIdInputMutation';
import { updateCacheOnDeleteOne } from 'domain/api/common/cache';
import { DeleteOneAdapterAction } from 'domain/api/common/adapters/types';
import { DELETE_ONE_EMBEDDING } from './gql';

type Response = {
  deleteEmbedding: {
    deletedId: string | null | undefined;
  };
};

export const useDeleteOneEmbedding = (redirectPath?: string): DeleteOneAdapterAction => {
  const history = useHistory();
  const setMessage = useSetMessage();

  return useIdInputMutation<Response, string | undefined>(
    DELETE_ONE_EMBEDDING,
    (data) => data?.deleteEmbedding.deletedId || undefined,
    {
      updateCache: (cache, data) =>
        updateCacheOnDeleteOne(cache, 'embeddings', data?.deleteEmbedding.deletedId),
      onSuccess: () => {
        setMessage(createSuccessfulDeleteMessage(''));
        if (redirectPath) history.push(`/${redirectPath}`);
      },
      onError: (err) => setMessage(createFailedDeleteMessage('', err)),
    }
  );
};
