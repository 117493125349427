import { ApiFeatureTagCategory, FeatureTagCategory } from '../featureTagCategory/types';

export type ApiFeatureTag = {
  id: string;
  name: string;
  description: string;
  showFloorPlanViewer: boolean;
  featureTagCategory: ApiFeatureTagCategory;
};
export type ApiFeatureTagInput = {
  name?: string;
  featureTagCategoryId: string;
  description?: string;
  showFloorPlanViewer?: boolean;
};

export type FeatureTag = Pick<
  ApiFeatureTag,
  'id' | 'name' | 'description' | 'showFloorPlanViewer'
> & {
  category: FeatureTagCategory;
  featureTagCategory: FeatureTagCategory['displayName'];
};

export const CLEARABLE_FEATURE_TAG_FIELDS: (keyof ApiFeatureTagInput & string)[] = [
  'name',
  'description',
  'featureTagCategoryId',
];
