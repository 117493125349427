import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

type Props = {
  clientId: string;
  domain: string;
  redirectUri: string;
  children: React.ReactNode;
};

export const AuthProvider = ({ clientId, domain, redirectUri, children }: Props) => {
  return (
    <Auth0Provider
      clientId={clientId}
      domain={domain}
      redirectUri={redirectUri}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
