import React from 'react';
import { Grid } from '@material-ui/core';
import { Redirect, useParams } from 'react-router';
import { FieldError, Status } from 'common/types';
import { PublishedStatusChip } from '_lib/components/display/publishedStatus/PublishedStatusChip';
import { Spinner } from '_lib/loading';
import { Section } from '_lib/sectionedPageLayout';
import { SectionSeparator } from '_lib/components';
import { FloorPlan } from 'domain/api/floorPlan/types';
import { ValidatedEntity } from 'domain/api/common/types';
import { useUiText } from 'domain/uiText';
import { useLinks } from 'domain/routes/useLinks';
import { DetailPageLayout } from 'domain/view/containers/DetailPageLayout';
import { AssetForm } from 'domain/view/components/form/AssetForm/AssetForm';
import { ImageLinkMappingForm } from 'domain/view/components/form/ImageLinkMappingForm/ImageLinkMappingForm';
import { EmbeddingMappingForm } from 'domain/view/components/form/EmbeddingMappingForm/EmbeddingMappingForm';
import { useSections } from 'domain/sections/useSections';
import { TopbarConfig } from 'domain/view/components/layout/topbar/useResourceTopbarConfig';
import { Community } from 'domain/api/community';
import { CommunityPricingForm } from 'domain/view/components/form/CommunityPricingForm/CommunityPricingForm';
import { FeatureTagsForm } from 'domain/view/components/form/FeatureTagsForm/FeatureTagsForm';
import { FeatureTag, useGetManyFeatureTags } from 'domain/api/featureTag';
import {
  FloorPlanBasicInfoForm,
  FloorPlanDescriptionForm,
  FloorPlanOpenhouseWidgetsForm,
} from '../../forms';

interface FloorPlanDetailPageProps {
  get: (id: string) => void;
  handleUpdate: (values: Partial<FloorPlan>) => void;
  handleRefetch: () => void;
  data: ValidatedEntity<FloorPlan>;
  communities: ValidatedEntity<Community>[];
  validation?: {
    errors?: FieldError[] | undefined;
  };
  publishedStatus: Status;
  loading: boolean;
  called: boolean;
  topbar: TopbarConfig;
  contentSpaceId: string;
}

export const FloorPlanDetailPage: React.FunctionComponent<FloorPlanDetailPageProps> = ({
  get,
  handleUpdate,
  handleRefetch,
  data,
  publishedStatus,
  loading,
  called,
  topbar,
  contentSpaceId,
  communities,
  validation,
}) => {
  const params = useParams<{ id: string }>();
  const uiText = useUiText();
  const links = useLinks();
  const sections = useSections();

  // TODO: Remove when implementing full FloorPlanLayout support
  const floorPlanLayout = data?.floorPlanLayouts[0];
  const { result: allFeatureTags, execute } = useGetManyFeatureTags();

  React.useEffect(() => {
    if (allFeatureTags.length <= 0) {
      execute();
    }
    get(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  if ((!called && !data) || (!data && loading)) return <Spinner />;
  if (!data) {
    return <Redirect to={links.floorPlanList.path} />;
  }

  const setFeatureTags = (homeFeatures: FeatureTag[]) => {
    const allFeaturesIds = allFeatureTags.map((feature) => feature.id);
    const actualFeatureTags = homeFeatures.filter((feautureTag) =>
      allFeaturesIds.includes(feautureTag.id)
    );

    return homeFeatures.length !== actualFeatureTags.length ? actualFeatureTags : homeFeatures;
  };

  return (
    <DetailPageLayout
      backTo={topbar.backButtonPath}
      backLabel={uiText.FLOOR_PLAN_PLURAL}
      thumbnail={topbar.thumbnailUrl}
      title={topbar.title}
      statusChip={<PublishedStatusChip publishedStatus={publishedStatus} />}
      actions={topbar.actions}
      validationErrors={validation?.errors}
    >
      {loading && <Spinner withContent />}
      <Grid item container spacing={3} direction="column" wrap="nowrap">
        <Grid item>
          <Section options={{ ...sections.basicInformation }}>
            {(sectionOptions) => (
              <FloorPlanBasicInfoForm
                initialValues={data}
                onSubmit={handleUpdate}
                {...sectionOptions}
              />
            )}
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.description }}>
            {(sectionOptions) => (
              <FloorPlanDescriptionForm
                initialValues={data}
                onSubmit={handleUpdate}
                {...sections.description}
                {...sectionOptions}
              />
            )}
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.features }}>
            {(sectionOptions) => (
              <FeatureTagsForm
                featureTags={setFeatureTags(data.featureTags)}
                onSubmit={(values) => handleUpdate({ featureTags: values })}
                {...sectionOptions}
              />
            )}
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.mainImage }}>
            {(sectionOptions) => (
              <AssetForm
                entity={{ ...data, __typename: 'FloorPlan' }}
                onUploadCompleted={handleRefetch}
                attributeName="listingMainImage"
                isDeletable={false}
                contentSpaceId={contentSpaceId}
                entityContentId={data.id}
                assets={data.assets}
                sectionConfig={sections.mainImage}
                {...sectionOptions}
              />
            )}
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.communityPricing }}>
            {(sectionOptions) => (
              <CommunityPricingForm
                type="communityPricing"
                communities={communities}
                communityPricingMappings={data.communityPricing || []}
                onSubmit={(values) => handleUpdate({ communityPricing: values })}
                {...sectionOptions}
              />
            )}
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.gallery }}>
            {(sectionOptions) => (
              <AssetForm
                entity={{ ...data, __typename: 'FloorPlan' }}
                attributeName="galleryPhotos"
                isMultiple={true}
                contentSpaceId={contentSpaceId}
                entityContentId={data.id}
                assets={data.assets}
                sectionConfig={sections.gallery}
                {...sectionOptions}
              />
            )}
          </Section>
        </Grid>
        <SectionSeparator />
        {!!floorPlanLayout && (
          <>
            <Grid item>
              <Section options={{ ...sections.floorplans }}>
                {(sectionOptions) => (
                  <AssetForm
                    entity={{ ...floorPlanLayout, __typename: 'FloorPlanLayout' }}
                    attributeName="floorPlanImages"
                    isMultiple={true}
                    contentSpaceId={contentSpaceId}
                    entityContentId={floorPlanLayout.id}
                    assets={floorPlanLayout.assets}
                    sectionConfig={sections.floorplans}
                    {...sectionOptions}
                  />
                )}
              </Section>
            </Grid>
            <SectionSeparator />
          </>
        )}
        <Grid item container direction="row" spacing={2}>
          <Grid item xs>
            <Section options={{ ...sections.attachmentFile }}>
              {(sectionOptions) => (
                <AssetForm
                  entity={{ ...data, __typename: 'FloorPlan' }}
                  attributeName="attachmentFile"
                  contentSpaceId={contentSpaceId}
                  entityContentId={data.id}
                  assets={data.assets}
                  sectionConfig={sections.attachmentFile}
                  isPdf
                  isImage={false}
                  {...sectionOptions}
                />
              )}
            </Section>
          </Grid>
          <Grid item xs>
            <Section options={{ ...sections.attachmentImage }}>
              {(sectionOptions) => (
                <AssetForm
                  entity={{ ...data, __typename: 'FloorPlan' }}
                  attributeName="attachmentImage"
                  contentSpaceId={contentSpaceId}
                  entityContentId={data.id}
                  assets={data.assets}
                  sectionConfig={sections.attachmentImage}
                  {...sectionOptions}
                />
              )}
            </Section>
          </Grid>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.banners }}>
            {(sectionOptions) => (
              <ImageLinkMappingForm
                resourceName="banner"
                sourceContext="floorPlan"
                sourceId={data.id}
                sourceTypeName="FloorPlan"
                mapped={data.imageLinks as any}
                {...sections.banners}
                {...sectionOptions}
              />
            )}
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.imageLinks }}>
            {(sectionOptions) => (
              <ImageLinkMappingForm
                resourceName="imageLink"
                sourceContext="floorPlan"
                sourceId={data.id}
                sourceTypeName="FloorPlan"
                mapped={data.imageLinks as any}
                {...sections.imageLinks}
                {...sectionOptions}
              />
            )}
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.openhouseWidgets }}>
            {(sectionOptions) => (
              <FloorPlanOpenhouseWidgetsForm
                fields={data}
                onSubmit={handleUpdate}
                {...sections.openhouseWidgets}
                {...sectionOptions}
              />
            )}
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.thirdPartyWidgets }}>
            {(sectionOptions) => (
              <EmbeddingMappingForm
                resourceName="embedding"
                sourceContext="floorPlan"
                sourceId={data.id}
                mapped={data.embeddings}
                {...sections.thirdPartyWidgets}
                {...sectionOptions}
              />
            )}
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.videos }}>
            {(sectionOptions) => (
              <EmbeddingMappingForm
                resourceName="video"
                sourceContext="floorPlan"
                sourceId={data.id}
                mapped={data.embeddings}
                {...sections.videos}
                {...sectionOptions}
              />
            )}
          </Section>
        </Grid>
      </Grid>
    </DetailPageLayout>
  );
};
