import React from 'react';
import { Dialog, makeStyles, Theme } from '@material-ui/core';
import { useAssetFormContext } from '../AssetFormContext';
import { AssetFormUploading } from '../steps/uploading/AssetFormUploading';
import { AssetFormReordering } from '../steps/reordering/AssetFormReordering';

export const AssetFormDialog = () => {
  const classes = useStyles();
  const { step, updateContext } = useAssetFormContext();
  const isInDialog = !!(step === 'reordering' || step === 'uploading');

  const handleClose = () => updateContext({ step: 'readonly' });
  return (
    <Dialog
      open={isInDialog}
      onClose={handleClose}
      PaperProps={{ className: classes.dialog }}
    >
      {step === 'uploading' && <AssetFormUploading />}
      {step === 'reordering' && <AssetFormReordering />}
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    borderTop: `solid 4px ${theme.palette.primary.main}`,
    minHeight: 'calc(100vh - 64px)',
    maxWidth: '60vw',
    minWidth: '60vw',
    [theme.breakpoints.down('xl')]: {
      maxWidth: '80vw',
      minWidth: '80vw',
    },
  },
}));
