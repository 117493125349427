import React from 'react';
import { useGetManyFloorPlans } from 'domain/api/floorPlan';
import { useGetManyInventories } from 'domain/api/inventory';
import { useGetManyShowHomes } from 'domain/api/showhome';
import { useGetManyCommunities } from 'domain/api/community';
import { useLinks } from 'domain/routes/useLinks';
import { LandingPage } from './LandingPage';

export const LandingPageContainer = () => {
  const getCommunities = useGetManyCommunities();
  const getFloorPlans = useGetManyFloorPlans();
  const getInventories = useGetManyInventories();
  const getShowHomes = useGetManyShowHomes();
  const links = useLinks();

  const get = () => {
    getCommunities.execute();
    getFloorPlans.execute();
    getInventories.execute();
    getShowHomes.execute();
  };

  return (
    <LandingPage
      get={get}
      loading={
        getCommunities.loading ||
        getFloorPlans.loading ||
        getInventories.loading ||
        getShowHomes.loading
      }
      communityTitle={links.communityList.linkText}
      floorPlanTitle={links.floorPlanList.linkText}
      inventoryTitle={links.inventoryList.linkText}
      showHomeTitle={links.showHomeList.linkText}
      communities={getCommunities.result}
      floorPlans={getFloorPlans.result}
      inventories={getInventories.result}
      showHomes={getShowHomes.result}
      communitiesLink={links.communityList.path}
      floorPlansLink={links.floorPlanList.path}
      inventoriesLink={links.inventoryList.path}
      showHomesLink={links.showHomeList.path}
    />
  );
};
