import React from 'react';
import { Grid } from '@material-ui/core';
import { Redirect, useParams } from 'react-router';
import { FieldError, Status } from 'common/types';
import { PublishedStatusChip } from '_lib/components/display/publishedStatus/PublishedStatusChip';
import { Section } from '_lib/sectionedPageLayout';
import { Spinner } from '_lib/loading';
import { SectionSeparator } from '_lib/components';
import { useUiText } from 'domain/uiText';
import { useLinks } from 'domain/routes/useLinks';
import { AssetForm } from 'domain/view/components/form/AssetForm/AssetForm';
import { DetailPageLayout } from 'domain/view/containers/DetailPageLayout';
import { ImageLinkMappingForm } from 'domain/view/components/form/ImageLinkMappingForm/ImageLinkMappingForm';
import { EmbeddingMappingForm } from 'domain/view/components/form/EmbeddingMappingForm/EmbeddingMappingForm';
import { LocationForm } from 'domain/view/components/form/LocationForm/LocationForm';
import { FeatureTagsForm } from 'domain/view/components/form/FeatureTagsForm/FeatureTagsForm';
import { ValidatedEntity } from 'domain/api/common/types';
import { Inventory } from 'domain/api/inventory';
import { FloorPlan } from 'domain/api/floorPlan/types';
import { Community } from 'domain/api/community';
import { useSections } from 'domain/sections/useSections';
import { TopbarConfig } from 'domain/view/components/layout/topbar/useResourceTopbarConfig';
import {
  InventoryBasicInfoForm,
  InventoryDescriptionForm,
  InventoryOpenhouseWidgetsForm,
} from '../../forms';
import { InventoryPricingForm } from '../../forms/InventoryPricingForm';
import { InventoryAddressForm } from '../../forms/InventoryAddressForm';

interface InventoryDetailPageProps {
  get: (id: string) => void;
  handleUpdate: (values: Partial<Inventory>) => void;
  handleRefetch: () => void;
  data?: ValidatedEntity<Inventory>;
  validation?: {
    errors?: FieldError[] | undefined;
  };
  publishedStatus: Status;
  loading: boolean;
  called: boolean;
  topbar: TopbarConfig;
  contentSpaceId: string;
  floorPlans: FloorPlan[];
  communities: Community[];
}

export const InventoryDetailPage: React.FunctionComponent<InventoryDetailPageProps> = ({
  get,
  data,
  topbar,
  loading,
  validation,
  publishedStatus,
  handleUpdate,
  handleRefetch,
  contentSpaceId,
  called,
  floorPlans,
  communities,
}) => {
  const params = useParams<{ id: string }>();
  const uiText = useUiText();
  const links = useLinks();
  const sections = useSections();

  React.useEffect(() => {
    get(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  if ((!called && !data) || (!data && loading)) return <Spinner />;
  if (!data) {
    return <Redirect to={links.inventoryList.path} />;
  }

  return (
    <DetailPageLayout
      backTo={topbar.backButtonPath}
      backLabel={uiText.INVENTORY_PLURAL}
      thumbnail={topbar.thumbnailUrl}
      title={topbar.title}
      statusChip={<PublishedStatusChip publishedStatus={publishedStatus} />}
      actions={topbar.actions}
      validationErrors={validation?.errors}
    >
      {loading && <Spinner withContent />}
      <Grid item container spacing={3} direction="column" wrap="nowrap">
        <Grid item>
          <Section options={{ ...sections.basicInformation }}>
            {(sectionOptions) => (
              <InventoryBasicInfoForm
                initialValues={data}
                onSubmit={handleUpdate}
                floorPlans={floorPlans}
                communities={communities}
                {...sectionOptions}
              />
            )}
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.pricing }}>
            {(sectionOptions) => (
              <InventoryPricingForm
                initialValues={data}
                onSubmit={handleUpdate}
                {...sectionOptions}
              />
            )}
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.address }}>
            {(sectionOptions) => (
              <InventoryAddressForm
                initialValues={data}
                onSubmit={handleUpdate}
                {...sectionOptions}
              />
            )}
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.description }}>
            {(sectionOptions) => (
              <InventoryDescriptionForm
                initialValues={data}
                onSubmit={handleUpdate}
                {...sectionOptions}
              />
            )}
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.features }}>
            {(sectionOptions) => (
              <FeatureTagsForm
                featureTags={data.featureTags}
                onSubmit={(values) => handleUpdate({ featureTags: values })}
                {...sectionOptions}
              />
            )}
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.mainImage }}>
            {(sectionOptions) => (
              <AssetForm
                entity={{ ...data, __typename: 'Inventory' }}
                onUploadCompleted={handleRefetch}
                attributeName="listingMainImage"
                isDeletable={false}
                contentSpaceId={contentSpaceId}
                entityContentId={data.id}
                assets={data.assets}
                sectionConfig={sectionOptions}
              />
            )}
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.gallery }}>
            {(sectionOptions) => (
              <AssetForm
                entity={{ ...data, __typename: 'Inventory' }}
                attributeName="galleryPhotos"
                isMultiple={true}
                contentSpaceId={contentSpaceId}
                entityContentId={data.id}
                assets={data.assets}
                sectionConfig={sectionOptions}
              />
            )}
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.floorplans }}>
            {(sectionOptions) => (
              <AssetForm
                entity={{ ...data, __typename: 'Inventory' }}
                attributeName="floorPlanImages"
                isMultiple={true}
                contentSpaceId={contentSpaceId}
                entityContentId={data.id}
                assets={data.assets}
                sectionConfig={sectionOptions}
              />
            )}
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.attachmentFile }}>
            {(sectionOptions) => (
              <AssetForm
                entity={{ ...data, __typename: 'Inventory' }}
                attributeName="attachmentFile"
                isImage={false}
                isPdf={true}
                contentSpaceId={contentSpaceId}
                entityContentId={data.id}
                assets={data.assets}
                sectionConfig={sectionOptions}
              />
            )}
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.attachmentImage }}>
            {(sectionOptions) => (
              <AssetForm
                entity={{ ...data, __typename: 'Inventory' }}
                attributeName="attachmentImage"
                contentSpaceId={contentSpaceId}
                entityContentId={data.id}
                assets={data.assets}
                sectionConfig={sectionOptions}
              />
            )}
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.banners }}>
            <ImageLinkMappingForm
              resourceName="banner"
              sourceContext="inventory"
              sourceId={data.id}
              sourceTypeName="Inventory"
              mapped={data.imageLinks}
              {...sections.banners}
            />
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.imageLinks }}>
            <ImageLinkMappingForm
              resourceName="imageLink"
              sourceContext="inventory"
              sourceId={data.id}
              sourceTypeName="Inventory"
              mapped={data.imageLinks}
              {...sections.imageLinks}
            />
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.openhouseWidgets }}>
            <InventoryOpenhouseWidgetsForm
              initialValues={data}
              onSubmit={handleUpdate}
              {...sections.openhouseWidgets}
            />
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.thirdPartyWidgets }}>
            <EmbeddingMappingForm
              resourceName="embedding"
              sourceContext="inventory"
              sourceId={data.id}
              mapped={data.embeddings}
              {...sections.thirdPartyWidgets}
            />
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.videos }}>
            <EmbeddingMappingForm
              resourceName="video"
              sourceContext="inventory"
              sourceId={data.id}
              mapped={data.embeddings}
              {...sections.videos}
            />
          </Section>
        </Grid>
        <SectionSeparator />
        <Grid item>
          <Section options={{ ...sections.location }}>
            <LocationForm
              location={{
                lat: data.lat,
                lng: data.lng,
              }}
              onSubmit={(values) => handleUpdate({
                ...values,
              })}
              {...sections.location}
            />
          </Section>
        </Grid>
      </Grid>
    </DetailPageLayout>
  );
};
