import { compact } from 'lodash';
import { combineDescriptionBodyAndBullets, createPublishedStatus, splitSeoMetaDescription } from '../common/transforms';
import { ValidatedEntity } from '../common/types';
import { transformApiEmbeddingsToEmbeddings } from '../embedding/transforms';
import { transformApiImageLinksToImageLinks } from '../imageLink/transforms';
import { transformApiSalesCentreToSalesCentre } from '../salesCentre/transforms';
import { ApiCommunity, ApiCommunityInput, Community } from './types';

export const transformCommunityToApiCommunityInput = (
  data: Partial<Omit<Community, 'builderId'>>,
): ApiCommunityInput => ({
  displayName: data.displayName,
  displayPriority: data.displayPriority,
  seoMetaKeywords: data.seoMetaKeywords,
  seoTitleTag: data.seoTitleTag,
  showCommuteCalculation: data.showCommuteCalculation,
  showCommunityStats: data.showCommunityStats,
  lotmapText: data.lotmapText,
  lotmapLink: data.lotmapLink,
  communityGroup: data.communityGroup,
  communityType: data.communityType,
  communityGeoPointLat: data.lat,
  communityGeoPointLon: data.lng,
  startingPrices: data.startingPrices,
  ...combineDescriptionBodyAndBullets(data.seoDescriptionBody, data.seoDescriptionBullets),
});

export const transformApiCommunityToCommunity = (
  data?: ValidatedEntity<ApiCommunity>,
): ValidatedEntity<Community> | undefined => data ? ({
  id: data.id,
  builderId: data.builderId,
  published: data.published,
  displayName: data.displayName,
  displayPriority: data.displayPriority,
  seoMetaKeywords: data.seoMetaKeywords,
  seoTitleTag: data.seoTitleTag,
  showCommuteCalculation: data.showCommuteCalculation,
  showCommunityStats: data.showCommunityStats,
  lotmapText: data.lotmapText,
  lotmapLink: data.lotmapLink,
  communityGroup: data.communityGroup?.name || null,
  communityType: data.communityType,
  lat: data.communityGeoPointLat,
  lng: data.communityGeoPointLon,
  startingPrices: data.startingPrices,
  floorPlans: (data.floorPlanMappings || []).map(el => ({
    id: el.floorPlan.id,
    displayName: el.floorPlan.displayName,
    price: el.price,
  })),
  publishedStatus: createPublishedStatus(data),
  ...splitSeoMetaDescription(data.seoMetaDescription),
  validationErrors: data.validationErrors,

  assets: data.assets,
  embeddings: transformApiEmbeddingsToEmbeddings(data.embeddings),
  imageLinks: transformApiImageLinksToImageLinks(data.imageLinks),

  salesCentres: compact(
    data.salesCentres.map(
      salesCentre => transformApiSalesCentreToSalesCentre(salesCentre),
    ),
  ),
}) : undefined;

export const transformApiCommunitiesToCommunities = (
  data?: ValidatedEntity<ApiCommunity>[],
) => data ? compact(data.map(el => transformApiCommunityToCommunity(el))) : [];
