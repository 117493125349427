import { useRecoilValue, useSetRecoilState } from 'recoil';
import { configState, entityFieldsSelectorFamily, entityActionsSelectorFamily } from './configState';
import { Config } from './types';

export const useConfig = () => useRecoilValue(configState);

export const useSetConfig = () => useSetRecoilState(configState);

export const useEntityFieldsConfig = (
  entity: keyof Config['resources'] & string,
) => useRecoilValue(entityFieldsSelectorFamily(entity));

export const useEntityActionsConfig = (
  entity: keyof Config['resources'] & string,
) => useRecoilValue(entityActionsSelectorFamily(entity));
