// This will need to be refactored in the near future to support
// builder aliases for entities/entity field names
export const uiText = {
  ENTITY_PUBLISHED_TOOLTIP: 'This entry is published',
  ENTITY_PUBLISH_ERROR_TOOLTIP:
    "This entry can't be published until the required fields are updated",
  ENTITY_PUBLISH_DRAFT_TOOLTIP: 'This entry is ready to be published',
  ENTITY_PUBLISHED_LABEL: 'Published',
  ENTITY_PUBLISH_DRAFT_LABEL: 'Draft',
  ENTITY_UNPUBLISH_LABEL: 'Unpublish',
  ENTITY_PUBLISH_LABEL: 'Publish',
  NO_RESULTS: 'No Results',
  DELETE_DIALOG_TITLE: 'Are you sure you want to delete this entry?',
  DELETE_DIALOG_DESCRIPTION: 'Once you delete this entry you will not be able to recover it',
  DELETE_DIALOG_CONFIRM: 'Yes, I understand',
  GENERIC_ENTITY: 'Entry',
  CANCEL: 'Cancel',
  SUBMIT: 'Submit',
  UPLOAD: 'Upload',
  UPLOAD_ASSETS_TOOLTIP: 'Upload assets',
  EDIT_TOOLTIP: 'Edit',
  DELETE_TOOLTIP: 'Delete',
  DELETE_ASSETS: 'Delete Selected Assets',
  DELETE_ASSETS_TOOLTIP: 'Delete selected assets',
  NO_DESCRIPTION: 'No description',
  NO_ASSETS_CREATED: 'No assets have been created',
  REORDER_ASSETS: 'Reorder Assets',
  REORDER_ASSETS_TOOLTIP: 'Manage the display order of assets',
  SELECT_FILES: 'Select Files',
  SELECT_FILE: 'Select a file',
  ASSET_UPLOAD_HELP_TEXT_SINGLE: 'Drag and drop a file here',
  ASSET_UPLOAD_HELP_TEXT_MULTIPLE: 'Drag and drop files here',
  SELECT_ENTRIES_TO_REMOVE: 'Select entries to remove',
  NO_ENTRIES_ADDED: 'No entries have been added',
  ADD_EXISTING_ENTRY_TOOLTIP: "You don't have any existing entries",
  CREATE_NEW: 'Create New',
  ADD_EXISTING: 'Add Existing',
  SELECT_ALL_ENTRIES_TOOLTIP: 'Select all entries',
  UNABLE_TO_PUBLISH: 'Unable to publish',
  TABLE_FILTERS_TOOLTIP: 'Filters',
  ADD_BUTTON_TOOLTIP: 'Create new entry',
  ADD_ENTRY_TOOLTIP: 'Add new entry',
  ADD_LIST_ITEM_TOOLTIP: 'Add list item',
  COLLAPSE_MENU_TOOLTIP: 'Collapse Menu',
  EXPAND_MENU_TOOLTIP: 'Expand Menu',
  MAIN_IMAGE_RECOMMENDATIONS:
    'Aspect Ratio: 3:2, Minimum: 1200px x 800px, Maximum 2400px x 1600px, Recommended: 1800px x 1200px',
  GALLERY_IMAGE_RECOMMENDATIONS:
    'Aspect Ratio: 3:2, Minimum: 1200px x 800px, Maximum 2400px 1600px, Recommended: 1800px x 1200px',
  FLOOR_PLAN_IMAGE_RECOMMENDATIONS:
    'Aspect Ratio: Tightly Cropped, Minimum: as large as possible, Maximum Size: 3000px x 3000px',
  LOT_MAP_IMAGE_RECOMMENDATIONS:
    'Aspect Ratio: Any, Minimum: 800px x 800px, Maximum: 3000px x 3000px',
  THUMBNAIL_IMAGE_RECOMMENDATIONS:
    'Aspect Ratio 2:1, Minimum: 600px x 300px, Maximum: 2400px x 1200px, Recommended: 1000px x 500px',
  LOGO_IMAGE_RECOMMENDATIONS: 'Aspect Ratio: Any, Height: 80px exactly, Max Width: 850px',
  SLOGAN_IMAGE_RECOMMENDATIONS: 'Aspect Ratio: Any, Height: 100px exactly, Max Width: 900px',
  BANNER_IMAGE_RECOMMENDATIONS: 
    'Aspect Ratio: 2:1, Minimum: 900px x 450px, Maximum: 2000px x 1000px, Recommended: 1000px x 500px',
  ATTACHMENT_IMAGE_RECOMMENDATIONS: 'Aspect Ratio: 1:1, Exactly 200px x 200px',
  MAX_UPLOAD_SIZE_ERROR: 'File size is larger than 10MB.',
  INVALID_UPLOAD_TYPE_ERROR: 'Invalid file type.',
};
