import { Resource, ResourceFields } from 'lib/resource';

export type Vendor = {
  id: string;
  type: string;
  displayName: string;
  icon: string;
  allowedRegex: string;
  urlHelperText: string;
};

export type VendorFields = {};

export type VendorResource = Resource<
Vendor,
VendorFields
>;

export const defaultVendor: Vendor = {
  id: '',
  type: '',
  displayName: '',
  icon: '',
  allowedRegex: '',
  urlHelperText: '',
};

export const defaultVendorFields: VendorFields = {};

export const vendorFields: ResourceFields<VendorFields> = {};
