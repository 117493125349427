import { createFailedPublishMessage, createSuccessfulPublishMessage, useSetMessage } from '_lib/messages';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { ValidatedEntity } from 'domain/api/common/types';
import { useIdInputMutation } from 'domain/api/common/adapters/useIdInputMutation';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { PUBLISH_ONE_IMAGE_LINK } from './gql';
import { ApiImageLink, ImageLink } from '../types';
import { transformApiImageLinkToImageLink } from '../transforms';

type Response = {
  publishImageLink: {
    imageLink?: ApiImageLink;
  };
};

export const usePublishOneImageLink = () => {
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();
  return useIdInputMutation<Response, ValidatedEntity<ImageLink> | undefined>(
    PUBLISH_ONE_IMAGE_LINK,
    (data) => schemas?.imageLink.apiSchema ? transformApiImageLinkToImageLink(
      validateApiResponseEntity(
        data?.publishImageLink.imageLink,
        schemas.imageLink.apiSchema,
      ),
    ) : undefined,
    {
      onSuccess: () => setMessage(createSuccessfulPublishMessage('')),
      onError: (err) => setMessage(createFailedPublishMessage('', err)),
    },
  );
};
