import { ExternalResourceConfig, Resource } from 'lib/resource/types';
import { defaultFloorPlanResource, FloorPlan, FloorPlanFields } from './floorPlan';
import { defaultCommunityResource, Community, CommunityFields } from './community';
import { defaultEmbeddingResource, defaultVideoResource, Embedding, EmbeddingDataDisplayFields, EmbeddingFields } from './embedding';
import { defaultVendorResource, Vendor, VendorFields } from './vendor';
import { defaultBannerResource, defaultImageLinkResource, ImageLink, ImageLinkFields } from './imageLink';
import { defaultInventoryResource, Inventory, InventoryFields } from './inventory';
import { defaultShowHomeResource, ShowHome, ShowHomeFields } from './showHome';
import { defaultContactResource, Contact, ContactFields } from './contact';
import { defaultSalesCentreResource, SalesCentre, SalesCentreFields } from './salesCentre';
import { defaultFeatureTagResource } from './featureTag/featureTagResource';
import { FeatureTag, FeatureTagFields } from './featureTag/types';

// This is be accessible globally via hooks like 'useResource'
export type ResourcesContextValue = {
  floorPlan: Resource<FloorPlan, FloorPlanFields>;
  community: Resource<Community, CommunityFields>;
  inventory: Resource<Inventory, InventoryFields>;
  showHome: Resource<ShowHome, ShowHomeFields>;
  imageLink: Resource<ImageLink, ImageLinkFields>;
  banner: Resource<ImageLink, ImageLinkFields>;
  embedding: Resource<Embedding, EmbeddingFields, EmbeddingDataDisplayFields>;
  vendor: Resource<Vendor, VendorFields>;
  contact: Resource<Contact, ContactFields>;
  video: Resource<Embedding, EmbeddingFields, EmbeddingDataDisplayFields>;
  salesCentre: Resource<SalesCentre, SalesCentreFields>;
  featureTag: Resource<FeatureTag, FeatureTagFields>
};

// We need to provide a default value to React.context in order to maintain static typings for it.
export const DefaultResourcesContextValue: ResourcesContextValue = {
  floorPlan: defaultFloorPlanResource,
  community: defaultCommunityResource,
  inventory: defaultInventoryResource,
  showHome: defaultShowHomeResource,
  imageLink: defaultImageLinkResource,
  banner: defaultBannerResource,
  embedding: defaultEmbeddingResource,
  // Vendor is omitted from the external config since it has no operations that
  // are configurable per builder, but it is still a resource that is used in the app.
  vendor: defaultVendorResource,
  video: defaultVideoResource,
  contact: defaultContactResource,
  salesCentre: defaultSalesCentreResource,
  featureTag: defaultFeatureTagResource,
};

export type ExternalResourcesConfig = {
  floorPlan: ExternalResourceConfig;
  community: ExternalResourceConfig;
  inventory: ExternalResourceConfig;
  showHome: ExternalResourceConfig;
  imageLink: ExternalResourceConfig;
  banner: ExternalResourceConfig;
  embedding: ExternalResourceConfig;
  video: ExternalResourceConfig;
  contact: ExternalResourceConfig;
  salesCentre: ExternalResourceConfig;
  featureTag: ExternalResourceConfig
};
