import { useLazyQuery } from '@apollo/client';
import { GetManyAdapterAction } from 'domain/api/common/adapters/types';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import {
  GetManyVariables,
  ResponseWithContentSpace,
  ValidatedEntity,
} from 'domain/api/common/types';
import { validateApiResponseEntities } from 'domain/api/common/validation';
import { useContentSpaceId } from '_lib/auth';
import { transformApiInventoriesToInventories } from '../transforms';
import { ApiShowHome, ShowHome } from '../types';
import { GET_MANY_SHOW_HOMES } from './gql';

type Response = ResponseWithContentSpace<{ showHomes: ApiShowHome[] }>;
type Variables = GetManyVariables;

export const useGetManyShowHomes: () => GetManyAdapterAction<ValidatedEntity<ShowHome>> = () => {
  const contentSpaceId = useContentSpaceId();
  const schemas = useApiSchemas();
  const [query, queryResult] = useLazyQuery<Response, Variables>(GET_MANY_SHOW_HOMES);

  const execute = () => {
    contentSpaceId && query({ variables: { contentSpaceId } });
  };

  const validated = schemas?.showHome.apiSchema
    ? validateApiResponseEntities(
      queryResult.data?.contentSpace.showHomes,
      schemas.showHome.apiSchema
    )
    : undefined;

  return {
    execute,
    result: transformApiInventoriesToInventories(validated),
    loading: queryResult.loading,
    called: queryResult.called,
    error: queryResult.error?.message,
  };
};
