import { PureQueryOptions } from '@apollo/client';
import { ValidatedEntity } from 'domain/api/common/types';
import { validateApiResponseEntity } from 'domain/api/common/validation';
import { updateCacheOnCreateOne } from 'domain/api/common/cache';
import { generateCreateOrUpdateString } from 'domain/api/common/fragments';
import { useApiSchemas } from 'domain/api/ApiProvider/hooks';
import { useCreateOrUpdateOne } from 'domain/api/common/adapters/useCreateOrUpdateOne';
import { useContentSpaceId } from '_lib/auth';
import {
  createFailedCreationMessage,
  createSuccessfulCreationMessage,
  useSetMessage,
} from '_lib/messages';
import { ApiEmbedding, ApiEmbeddingInput, Embedding, EmbeddingInput } from '../types';
import { AllEmbeddingInfo } from '../fragments';
import { transformApiEmbeddingToEmbedding } from '../transforms';

const CREATE_ONE_EMBEDDING = generateCreateOrUpdateString('create', 'embedding', AllEmbeddingInfo);

type Response = {
  createEmbedding: {
    embedding: ApiEmbedding | undefined;
  };
};

type Input<T> = T & {
  contentSpaceId: string;
};

export const useCreateOneEmbedding = (refetchQueries?: PureQueryOptions[]) => {
  const contentSpaceId = useContentSpaceId();
  const schemas = useApiSchemas();
  const setMessage = useSetMessage();

  const { execute: executeMutation, ...rest } = useCreateOrUpdateOne<
  Input<ApiEmbeddingInput>,
  Response,
  ValidatedEntity<Embedding> | undefined
  >(
    CREATE_ONE_EMBEDDING,
    (data) =>
      schemas?.embedding.apiSchema
        ? transformApiEmbeddingToEmbedding(
          validateApiResponseEntity(data?.createEmbedding.embedding, schemas.embedding.apiSchema)
        )
        : undefined,
    {
      onSuccess: () => {
        setMessage(createSuccessfulCreationMessage(''));
      },
      onError: (err) => setMessage(createFailedCreationMessage('', err)),
      updateCache: (cache, data) =>
        updateCacheOnCreateOne(
          cache,
          'embeddings',
          data?.createEmbedding.embedding,
          `fragment AllEmbeddingInfo on Embedding {
            ${AllEmbeddingInfo}
          }`
        ),
      refetchQueries,
    }
  );

  const execute = (input: EmbeddingInput) => {
    if (!contentSpaceId) return;
    executeMutation({
      displayName: input.displayName,
      iframeUrl: input.iframeUrl,
      vendorId: input.vendorId,
      contentSpaceId,
      contentEntries: [
        ...(input?.communities || []),
        ...(input?.floorPlans || []),
        ...(input?.showHomes || []),
        ...(input?.inventories || []),
        ...(input?.contentEntries || []),
      ],
    });
  };

  return { execute, ...rest };
};
