import { Action, Status } from 'common/types';
import { useBaseUiText } from '_lib/uiText';

export const useStatusToolTip = (publishedStatus: Status) => {
  const uiText = useBaseUiText();
  switch (publishedStatus) {
    case 'published':
      return uiText.PUBLISHED_STATUS_PUBLISHED_NOTE;
    case 'error':
      return uiText.PUBLISHED_STATUS_DRAFT_NOTE_PROBLEMS;
    case 'draft':
    default:
      return uiText.PUBLISHED_STATUS_DRAFT_LABEL;
  }
};

export const useStatusLabel = (publishedStatus: Status) => {
  const uiText = useBaseUiText();
  switch (publishedStatus) {
    case 'published':
      return uiText.PUBLISHED_STATUS_PUBLISHED_LABEL;
    case 'error':
    case 'draft':
    default:
      return uiText.PUBLISHED_STATUS_DRAFT_LABEL;
  }
};

export const usePublishActionState = (publishedStatus: Status): Action => {
  const uiText = useBaseUiText();
  switch (publishedStatus) {
    case 'error':
      return { type: 'publish', label: uiText.ACTION_PUBLISH_LABEL, disabled: true };
    case 'draft':
      return { type: 'publish', label: uiText.ACTION_PUBLISH_LABEL };
    case 'published':
    default:
      return { type: 'unpublish', label: uiText.ACTION_UNPUBLISH_LABEL };
  }
};
