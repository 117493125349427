import { apolloClient } from 'domain/api/_apollo/client';
import { GET_ONE_ASSET } from './gql';
import { GetOneAssetResponse } from './types';

export const getOneAsset = async (id: string, contentSpaceId: string) => {
  return apolloClient.query<GetOneAssetResponse>({
    variables: { id, contentSpaceId },
    query: GET_ONE_ASSET,
    fetchPolicy: 'network-only',
  });
};
