import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import arrayMove from 'array-move';
import { SortEnd, SortableContainer } from 'react-sortable-hoc';
import { Asset, useOrderAssets } from 'domain/api/asset';
import { useUiText } from 'domain/uiText';
import { Spinner } from '_lib/loading';
import { useAssetFormContext } from '../../AssetFormContext';
import { AssetFormReorderingItem } from './AssetFormReorderingItem';

const SortableAssetsContainer = SortableContainer(({ assets }: { assets: Asset[] }) => {
  const renderAsset = (asset: Asset, index: number) => (
    <AssetFormReorderingItem key={`${asset.id}-reorderable`} index={index} {...asset} />
  );

  return (
    <Grid container={true} spacing={2} style={{ position: 'relative' }}>
      {assets.map(renderAsset)}
    </Grid>
  );
});

export const AssetFormReordering = () => {
  const uiText = useUiText();
  const { updateContext, entity, ...context } = useAssetFormContext();
  const orderAssets = useOrderAssets(context.attributeName, entity);
  const [state, setState] = React.useState<Asset[]>(context.assets);

  const handleClose = () => updateContext({ step: 'readonly' });

  const handleSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    setState((prev) => [...arrayMove(prev, oldIndex, newIndex)]);
  };

  const handleSubmit = () => {
    context.entityContentId &&
      orderAssets.order(
        context.entityContentId,
        state.map((asset) => asset.id)
      );
  };

  React.useEffect(() => {
    if (!orderAssets.loading && orderAssets.result) {
      handleClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderAssets]);

  return (
    <React.Fragment>
      <DialogTitle>
        <Typography variant="h4">{context.sectionConfig.title}</Typography>
        <Typography color="textSecondary">Manage Display Order</Typography>
      </DialogTitle>
      <DialogContent>
        <SortableAssetsContainer axis="xy" assets={state} onSortEnd={handleSortEnd} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} disabled={orderAssets.loading}>
          {uiText.ACTION_CANCEL_LABEL}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleSubmit}
          disabled={orderAssets.loading}
        >
          {uiText.ACTION_SAVE_LABEL}
        </Button>
      </DialogActions>
      {orderAssets.loading && <Spinner withContent={true} />}
    </React.Fragment>
  );
};
