import React from 'react';
import { Button } from '@material-ui/core';
import { Table } from '_lib/components';
import { useAddContactsToSalesCentre } from 'domain/api/salesCentre';
import { Contact } from 'domain/api/contact';
import { useToggle } from 'common/hooks/useToggle';
import { useUiText } from 'domain/uiText';
import { FormDialog } from '_lib/form/formDialog/FormDialog';
import { useResetableAdapterAction } from 'domain/api/common/hooks';

type Props = {
  contacts: Contact[];
  salesCentreId: string;
};

export const AddExistingContactsDialog = ({ contacts, salesCentreId }: Props) => {
  const uiText = useUiText();
  const dialogState = useToggle();
  const [state, setState] = React.useState<string[]>([]);
  const addContactsToSalesCentre = useResetableAdapterAction(useAddContactsToSalesCentre);

  const handleSelect = (ids: string[]) => setState(() => ids);

  const handleSubmit = () => {
    if (state.length > 0)
      addContactsToSalesCentre.execute({
        id: salesCentreId,
        input: [...state],
      });
  };

  React.useEffect(() => {
    if (
      addContactsToSalesCentre.called &&
      !addContactsToSalesCentre.loading &&
      state.length > 0 &&
      dialogState.open
    ) {
      dialogState.onClose();
      setState([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addContactsToSalesCentre]);

  return (
    <React.Fragment>
      <Button
        size="small"
        variant="outlined"
        style={{ textTransform: 'capitalize', marginRight: 8 }}
        onClick={dialogState.onOpen}
      >
        {uiText.ACTION_ADD_EXISTING_CONTACT_LABEL}
      </Button>
      <FormDialog
        title={uiText.ACTION_ADD_EXISTING_CONTACT_LABEL}
        open={dialogState.open}
        submitDisabled={state.length <= 0}
        onClose={dialogState.onClose}
        onSubmit={handleSubmit}
        submitting={addContactsToSalesCentre.loading}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Table
            resourceName="contact"
            data={contacts}
            noDataMessage={uiText.NO_CONTACT}
            actions={{}}
            variant="flat"
            onSelect={handleSelect}
            selectedRows={state}
          />
        </div>
      </FormDialog>
    </React.Fragment>
  );
};
