import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, Grid, makeStyles, Theme } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Action } from 'common/types';
import { Actions } from '_lib/components';
import { Topbar } from './Topbar';
import { TopbarTitle } from './TopbarTitle';

export interface Props {
  backTo: string;
  backLabel: string;
  thumbnail?: string;
  title: string;
  statusChip?: React.ReactNode;
  actions: Action[];
}

export const DetailPageTopbar = ({
  backTo,
  backLabel,
  thumbnail,
  title,
  statusChip,
  actions,
}: Props) => {
  const classes = useStyles();
  return (
    <Topbar
      elevated
      left={
        <Grid item container alignItems="center" wrap="nowrap" spacing={2}>
          <Grid item>
            <Avatar className={classes.avatar} src={thumbnail} />
          </Grid>
          <Grid item container direction="column">
            <Button
              component={Link}
              to={backTo}
              variant="outlined"
              startIcon={<ArrowBackIosIcon />}
              size="small"
              className={classes.backButton}
            >
              {backLabel}
            </Button>
            <div className={classes.titlePart}>
              <TopbarTitle title={title} />
              <span style={{ alignSelf: 'center' }}>{statusChip}</span>
            </div>
          </Grid>
        </Grid>
      }
      right={
        <div className={classes.actionsMargin}>
          <Actions actions={actions} />
        </div>
      }
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  backButton: {
    textTransform: 'none',
    maxWidth: 150,
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  titlePart: {
    display: 'flex',
    alignItems: 'baseline',
  },
  actionsMargin: {
    marginRight: theme.spacing(4),
  },
}));
