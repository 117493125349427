import * as yup from 'yup';
import { UiText } from 'domain/uiText';
import { InventoryFields } from './types';
import { millisecondsToSeconds } from 'utilities/helpers';

// Schemas used in both the resource and fields
const sharedSchemas = (uiText: UiText) => ({
  builderId: yup.string().defined().required().default('').label('ID'),
  displayName: yup.string().defined().required().default('').label('Name'),
  homeType: yup.string().defined().required().default('').label('Home Type'),

  price: yup.number().defined().default(null).label('Price'),
  priceReduced: yup.boolean().defined().default(false).label('Price Reduced'),
  priceReducedFrom: yup
    .number()
    .defined()
    .nullable()
    .default(null)
    .when(['price', 'priceReduced'], (price: number = 0, priceReduced: boolean, schema: yup.NumberSchema<number, object>) => {
      if (!priceReduced) return schema;
      return schema.min(price).default(0);
    })
    .label('Price Reduced From'),

  bedrooms: yup.number().defined().default(null).label('Bedrooms'),
  bathrooms: yup.number().defined().default(null).label('Bathrooms'),
  halfBaths: yup.number().defined().default(null).label('Half Bathrooms'),
  floorSize: yup.number().defined().default(null).label('Floor Size'),
  garage: yup.string().defined().default(''),

  possessionDateSec: yup.number()
    .nullable(true)
    .defined()
    .default(null)
    .min(millisecondsToSeconds(new Date().valueOf()), 'Please select a date in the future.')
    .typeError('Please select a date in the future.')
    .label('Possession Date'),

  showMonthlyPaymentCalculator: yup.boolean().defined().default(true).label('Monthly Payment' +
                                                                           ' Calculator'),
  showCommuteCalculation: yup.boolean().defined().default(true).label('Commute Calculator'),

  seoTitleTag: yup.string().defined().default('').label('SEO Title'),
  seoMetaKeywords: yup.array().of(yup.string().defined()).defined().compact().default([]).label('SEO Keywords'),
});

const sharedInputAndFieldSchemas = (uiText: UiText) => ({
  lat: yup.number().defined().default(null).nullable().min(-90).max(90).label('Latitude'),
  lng: yup.number().defined().default(null).nullable().min(-180).max(180).label('Longitude'),
  seoDescriptionBullets:
    yup.array().of(yup.string().defined()).defined().compact().default([]).label('Bullet Points'),
  seoDescriptionBody: yup.string().defined().default('').label('Body Text'),
  floorPlanId:
    yup.string()
      .defined()
      .nullable()
      .default(null)
      .label(uiText.FLOOR_PLAN_SINGULAR),
  communityId:
    yup.string()
      .defined()
      .nullable()
      .default(null)
      .label(uiText.COMMUNITY_SINGULAR),
  address: yup.string().defined().default('').label('Address'),
  city: yup.string().defined().default('').label('City'),
  countrySubDivisionCode: yup.string().defined().nullable(true).default(null).label('State / Province'),
  country: yup.string().defined().default('').label('Country'),
  postalCode: yup.string().defined().nullable(true).default(null).label('Postal / Zip Code'),
});

export const inventoryFieldsSchema = (uiText: UiText) => yup.object<InventoryFields>({
  ...sharedSchemas(uiText),
  ...sharedInputAndFieldSchemas(uiText),
  status: yup.string().defined().nullable().default(null),
}).defined();
