import { Divider, Grid } from '@material-ui/core';
import React from 'react';

export const SectionSeparator = () => {
  return (
    <Grid item={true}>
      <Divider style={{ height: 2 }} light={true} />
    </Grid>
  );
};
