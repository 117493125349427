import React from 'react';
import { LayoutRenderer } from 'lib';
import { useUiText } from 'domain/uiText';
import { EntityDialogActionForm } from 'domain/view/components/form/EntityDialogActionForm';
import { useAllFeatureTagCategories } from 'domain/view/components/form/FeatureTagsForm/hooks';
import { FeatureTag } from 'domain/api/featureTag';

type Props = {
  featureTag: FeatureTag;
  submitting: boolean;
  submitted: boolean;
  onSubmit: (values: Partial<FeatureTag>) => void;
};

export const EditFeatureTagForm = ({ onSubmit, featureTag, submitted, submitting }: Props) => {
  const uiText = useUiText();
  const { allCategories } = useAllFeatureTagCategories();
  return (
    <EntityDialogActionForm
      resourceName="featureTag"
      onSubmit={(values) => onSubmit(values)}
      title={uiText.ACTION_EDIT_FEATURE_TAG_LABEL}
      action={{
        type: 'edit',
        label: uiText.ACTION_EDIT_LABEL,
        isIcon: true,
        isSmall: true,
      }}
      initialValues={{
        ...featureTag,
        featureTagCategory: featureTag.category.id,
      }}
      submitted={submitted}
      submitting={submitting}
    >
      {(renderField) => (
        <LayoutRenderer
          items={[
            {
              container: true,
              direction: 'column',
              spacing: 2,
              items: [
                {
                  item: true,
                  renderContent: () =>
                    renderField('featureTagCategory', {
                      isFullWidth: true,
                      required: true,
                      options: allCategories.map((category) => ({
                        label: category.displayName,
                        value: category.id,
                      })),
                    }),
                },
                {
                  renderContent: () => renderField('name'),
                },
                {
                  renderContent: () => renderField('description'),
                },
              ],
            },
          ]}
        />
      )}
    </EntityDialogActionForm>
  );
};
