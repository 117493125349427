import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { bulletInputId, SortableTextListFieldBullet } from './TextListFieldBullet';

type Props = {
  items: string[];
  disabled?: boolean;
  className: string;
  readonly?: boolean;
  onChange: (value: string, index: number) => void;
  onDelete: (index: number) => void;
  focusPrev: (index: number) => void;
  focusNext: (index: number) => void;
  mergePrev: (index: number) => void;
  mergeNext: (index: number) => void;
};

export const TextListFieldSortableBullets = SortableContainer(({
  items,
  className,
  readonly,
  disabled,
  onDelete,
  onChange,
  focusPrev,
  focusNext,
  mergePrev,
  mergeNext,
}: Props) => (
  <div className={className}>
    {items.map((value, i) => (
      <SortableTextListFieldBullet
        key={bulletInputId(i)}
        index={i}
        _index={i}
        value={value}
        readonly={readonly}
        disabled={disabled}
        onChange={onChange}
        onDelete={onDelete}
        focusPrev={focusPrev}
        focusNext={focusNext}
        mergePrev={mergePrev}
        mergeNext={mergeNext}
      />
    ))}
  </div>
));
