import React from 'react';
import { LayoutRenderer } from 'lib/layout/LayoutRenderer';
import { FloorPlan } from 'domain/api/floorPlan/types';
import { EntitySectionForm } from 'domain/view/components/form/EntitySectionForm';
import { PageSectionOptions } from '_lib/sectionedPageLayout';

type Props = PageSectionOptions & {
  fields: FloorPlan;
  onSubmit: (values: FloorPlan) => void;
};

export const FloorPlanOpenhouseWidgetsForm = ({ fields, onSubmit, ...rest }: Props) => {
  return (
    <EntitySectionForm
      resourceName="floorPlan"
      initialValues={fields}
      onUpdate={onSubmit}
      {...rest}
    >
      {(renderField) => (
        <LayoutRenderer
          items={[
            {
              container: true,
              direction: 'column',
              spacing: 2,
              items: [
                {
                  container: true,
                  item: true,
                  spacing: 2,
                  items: [
                    {
                      item: true,
                      renderContent: () => renderField('showMonthlyPaymentCalculator'),
                    },
                  ],
                },
              ],
            },
          ]}
        />
      )}
    </EntitySectionForm>
  );
};
