import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { assetsLink, httpLink } from './links';

const link = ApolloLink.from([
  assetsLink,
  httpLink,
]);

const cache = new InMemoryCache({
  typePolicies: {
    ContentSpace: {
      queryType: true,
    },
  },
});

export const apolloClient = new ApolloClient({
  link,
  credentials: 'include',
  cache,
});
