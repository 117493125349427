import { useUiText } from 'domain/uiText';
import { LinksOptions } from './types';

export const useLinks = (): LinksOptions => {
  const uiText = useUiText();

  return {
    landing: {
      path: '/',
    },
    communityList: {
      path: `/${uiText.COMMUNITY_PATH}`,
      linkText: uiText.COMMUNITY_PLURAL,
    },
    communityDetail: {
      path: `/${uiText.COMMUNITY_PATH}/:id`,
    },
    floorPlanList: {
      path: `/${uiText.FLOOR_PLAN_PATH}`,
      linkText: uiText.FLOOR_PLAN_PLURAL,
    },
    floorPlanDetail: {
      path: `/${uiText.FLOOR_PLAN_PATH}/:id`,
    },
    inventoryList: {
      path: `/${uiText.INVENTORY_PATH}`,
      linkText: uiText.INVENTORY_PLURAL,
    },
    inventoryDetail: {
      path: `/${uiText.INVENTORY_PATH}/:id`,
    },
    showHomeList: {
      path: `/${uiText.SHOWHOME_PATH}`,
      linkText: uiText.SHOWHOME_PLURAL,
    },
    showHomeDetail: {
      path: `/${uiText.SHOWHOME_PATH}/:id`,
    },
    imageLinkList: {
      path: `/${uiText.IMAGE_LINK_PATH}`,
      linkText: uiText.IMAGE_LINK_PLURAL,
    },
    bannerList: {
      path: `/${uiText.BANNER_PATH}`,
      linkText: uiText.BANNER_PLURAL,
    },
    videoList: {
      path: `/${uiText.VIDEO_PATH}`,
      linkText: uiText.VIDEO_PLURAL,
    },
    thirdParyWidgetList: {
      path: `/${uiText.THIRD_PARTY_PLUGIN_PATH}`,
      linkText: uiText.THIRD_PARTY_PLUGIN_PLURAL,
    },
    contactList: {
      path: `/${uiText.CONTACT_PATH}`,
      linkText: uiText.CONTACT_PLURAL,
    },
    featureTags: {
      path: '/features',
      linkText: 'Features',
    }
  };
};
