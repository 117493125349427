import React from 'react';
import classNames from 'classnames';
import { CardHeader, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

interface OwnProps {
  title: string;
  icon?: JSX.Element;
  actions?: JSX.Element;
  noPadding?: boolean;
}

type Props = OwnProps;

export const FormHeader = (props: Props) => {
  const { title, icon, actions, noPadding } = props;
  const classes = useStyles();

  return (
    <CardHeader
      className={classNames(classes.header, { [classes.noPadding]: noPadding })}
      title={(
        <React.Fragment>
          <Grid container={true} alignItems="center" spacing={2}>
            {icon && (
              <Grid item={true}>
                <Typography style={{ display: 'flex' }} className={classes.icon}>
                  {icon}
                </Typography>
              </Grid>
            )}
            <Grid item={true}>{title}</Grid>
          </Grid>
        </React.Fragment>
      )}
      classes={{ action: classes.actions }}
      action={actions && (
        <Grid style={{ margin: 0 }} container={true} alignItems="center">{actions}</Grid>
      )}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    margin: 0,
  },
  noPadding: {
    padding: 0,
    marginBottom: theme.spacing(2),
  },
  header: {
  },
  icon: {
    color: theme.palette.grey[400],
  },
}));
