import { Contact } from '../contact/types';

export type ApiSalesCentre = {
  id: string;
  name: string;
  phone: string;
  address: string;
  hours: string[];
  email: string;
  contacts: Contact[];
  community: { id: string };
};

export type ApiSalesCentreInput = Partial<Pick<ApiSalesCentre, 'name' | 'phone' | 'address' | 'hours' | 'email'> & {
  community: string;
}>;

export const CLEARABLE_SALES_CENTRE_FIELDS: (keyof ApiSalesCentreInput & string)[] = [
  'phone',
  'address',
  'hours',
  'email',
];

export type SalesCentre = Pick<ApiSalesCentre, 'id' | 'name' | 'phone' | 'address' | 'hours' |'email' | 'contacts'> & {
  community: string;
};
