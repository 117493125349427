import { Community } from 'domain/api/community';
import { StartingPrice } from 'system/resources/community';
import { PageSectionOptions } from '_lib/sectionedPageLayout';

export type CommunityPricingMapping = {
    id: string;
    displayName: string;
    price: number | null;
  };

  export interface ICommunityPricingState extends Array<CommunityPricingMapping>{};

  export const defaultCommunityPricingItem: CommunityPricingMapping = {
    id: '',
    price: 0,
    displayName: '',
  };

  export interface ICommunityPricingProps extends PageSectionOptions {
    communities?: Partial<Community>[];
    communityPricingMappings?: CommunityPricingMapping[];
    startingPrices?: StartingPrice[];
    onSubmit: (values: CommunityPricingMapping[]) => void;
    type?: 'communityPricing' | 'communityStartingPrice'
  }
