import React from 'react';
import { LayoutRenderer } from 'lib';
import { Contact } from 'domain/api/contact/types';
import { useUiText } from 'domain/uiText';
import { EntityDialogActionForm } from 'domain/view/components/form/EntityDialogActionForm';

type Props = {
  contact: Contact;
  submitting: boolean;
  submitted: boolean;
  onSubmit: (values: Contact) => void,
};

export const EditContactForm = ({ onSubmit, contact, submitted, submitting }: Props) => {
  const uiText = useUiText();

  return (
    <EntityDialogActionForm
      resourceName="contact"
      onSubmit={onSubmit}
      title={uiText.ACTION_EDIT_CONTACT_LABEL}
      action={{
        type: 'edit',
        label: uiText.ACTION_EDIT_LABEL,
        isIcon: true,
        isSmall: true,
      }}
      initialValues={contact}
      submitted={submitted}
      submitting={submitting}
    >
      {renderField => (
        <LayoutRenderer
          items={[
            {
              container: true, direction: 'column', spacing: 2,
              items: [
                {
                  renderContent: () => renderField('name')
                },
                {
                  renderContent: () => renderField('title')
                },
                {
                  renderContent: () => renderField('email')
                },
                {
                  renderContent: () => renderField('phone')
                },
              ],
            }
          ]}
        />
      )}
    </EntityDialogActionForm>
  );
};
