import { compact } from 'lodash';
import { ApiContact, ApiContactInput, Contact } from './types';

export const transformContactToApiContactInput = (
  data: Partial<Contact>,
): ApiContactInput => ({
  name: data.name,
  email: data.email,
  title: data.title,
  phone: data.phone,
  salesCentres: data.salesCentres?.map(salesCentre => salesCentre.id),
});

export const transformApiContactToContact = (
  data?: ApiContact,
): Contact | undefined => data ? ({
  id: data.id,
  name: data.name,
  email: data.email,
  title: data.title,
  phone: data.phone,
  salesCentres: data.salesCentres,
}) : undefined;

export const transformApiInventoriesToInventories = (
  data?: ApiContact[],
) => data ? compact(data.map(el => transformApiContactToContact(el))) : [];
