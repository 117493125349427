import React from 'react';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useFormState } from '_lib/multiFormSemaphore';
import { PageSectionOptions, Section } from '_lib/sectionedPageLayout';
import { FormLayout } from '_lib/form/formSection/components/FormLayout';
import { useConfig } from 'domain/config';
import { useUiText } from 'domain/uiText';
import { useDemostatsState } from './hooks';
import { DemostatsActions } from './DemostatsActions';
import { DemostatsCharts } from './DemostatsCharts';
import { DemostatsBoundaryMap } from './DemostatsBoundaryMap';

interface Props extends PageSectionOptions {
  communityId: string,
  geopoint?: google.maps.LatLngLiteral,
}

export const DemostatsSection = ({ id, title, icon, communityId, geopoint }: Props) => {
  const uiText = useUiText();
  const defaultGeoPoint = useConfig()?.defaultGeoPoint;
  const center = geopoint || defaultGeoPoint;
  const {
    editing,
    enabled,
    formId,
    openForm,
    closeForm
  } = useFormState(id);
  const formDisabled = !geopoint || !enabled;
  const {
    calculating,
    previewing,
    boundary,
    draftBoundary,
    demostats,
    setDraftBoundary,
    onCancel,
    onPreview,
    clearPreview,
    clearDraftBoundary,
    onSave,
  } = useDemostatsState(communityId, editing, closeForm);

  return (
    <Section options={{ id, title, icon }}>
      <FormLayout
        formId={formId}
        title={title}
        icon={icon}
        formDisabled={!enabled}
        editing={editing}
        actions={<DemostatsActions
          previewing={previewing}
          calculating={calculating}
          formOpen={editing}
          formDisabled={formDisabled}
          onEdit={openForm}
          onCancel={onCancel}
          onPreview={onPreview}
          previewDisabled={!draftBoundary}
          onReEdit={clearPreview}
          onClear={clearDraftBoundary}
          onSave={onSave}
        />}
      >
        {!geopoint && 
          <Alert severity="warning" style={{ marginBottom: 16, }}>
            {uiText.WARNING_BOUNDARY_LOCATION_PREREQUISITE}
          </Alert>}
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item xs={(!editing || previewing) ? 6 : 12}>
            {center && <DemostatsBoundaryMap
              drawing={editing && !previewing}
              calculating={calculating}
              boundary={boundary}
              draftBoundary={draftBoundary}
              setDraftBoundary={setDraftBoundary}
              center={center}
            />}
          </Grid>
          {
            (!editing || previewing) &&
            <Grid item xs={6}>
              <DemostatsCharts demostats={demostats} />
            </Grid>
          }
        </Grid>
      </FormLayout>
    </Section>
  );
};
