import { gql } from '@apollo/client';
import { AllImageLinkInfo } from '../fragments';

export const ADD_IMAGE_LINK_CONTENT_ENTRIES = gql`
fragment AddImageLinkContentEntriesInfo on ImageLink {
  ${AllImageLinkInfo}
}

mutation addImageLinkContentEntries($input: AddImageLinkContentEntriesInput) {
  addImageLinkContentEntries(input: $input) {
    imageLink {
      ...AddImageLinkContentEntriesInfo
    }
  }
}
`;
