import React from 'react';
import { Grid } from '@material-ui/core';
import { SaveButton } from '_lib/components/buttons/SaveButton';
import { useQueuedJobs } from 'common/hooks/useQueuedJobs';
import { useAssetFormContext } from '../../AssetFormContext';
import { AssetFormPageSectionItems } from '../../components/AssetFormPageSectionItems';
import { AssetFormPageSectionHeader } from '../../components/AssetFormPageSectionHeader';
import { AssetFormEditingItem } from './AssetFormEditingItem';
import { useDescriptionValidation } from './useDescriptionValidation';

interface Props {
  showCheckeredBackground?: boolean;
}

export const AssetFormEditing = ({ showCheckeredBackground }: Props) => {
  const [description, setDescription] = React.useState<string>('');
  const { descriptionFieldError } = useDescriptionValidation(description);
  const { assets, attributeName, entityContentId, updateContext, isPdf } = useAssetFormContext();
  const {
    isCurrentJob,
    addSelected,
    start,
    onJobSuccess,
    isSelected,
    editSelected,
    getSelectedItem,
    isRunning,
  } = useQueuedJobs({
    onCompleted: () => updateContext({ step: 'readonly' }),
  });

  const handleDescriptionChange = (index: number, value: string) => {
    const { id } = assets[index];

    setDescription(value);

    if (isSelected(id)) {
      editSelected(id, value);
    } else {
      addSelected(id, value);
    }
  };

  const handleSubmit = () => start();

  const handleSuccess = (id: string) => onJobSuccess(id);

  return (
    <React.Fragment>
      <AssetFormPageSectionHeader
        actionsDisabled={isRunning}
        additionalActions={
          <Grid item={true}>
            <SaveButton disabled={isRunning || !!descriptionFieldError} onSave={handleSubmit} />
          </Grid>
        }
      />
      <AssetFormPageSectionItems>
        {(renderAssetItem) => (
          <React.Fragment>
            {assets.map((asset, i) =>
              renderAssetItem(
                <AssetFormEditingItem
                  index={i}
                  url={asset.url}
                  id={asset.id}
                  isPdf={isPdf}
                  displayName={asset.displayName}
                  description={
                    isSelected(asset.id) ? getSelectedItem(asset.id)?.data : asset.description
                  }
                  key={`${asset.id}-readonly-${entityContentId}-${attributeName}`}
                  onDescriptionChange={handleDescriptionChange}
                  onSuccess={() => handleSuccess(asset.id)}
                  startUpdate={isCurrentJob(asset.id)}
                  descriptionFieldError={descriptionFieldError}
                  showCheckeredBackground={showCheckeredBackground}
                />,
                asset.id
              )
            )}
          </React.Fragment>
        )}
      </AssetFormPageSectionItems>
    </React.Fragment>
  );
};
