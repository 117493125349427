import { defaultUiText, UiText } from 'domain/uiText';
import { ExternalResourceConfig, InternalResourceConfig, makeResource } from 'lib/resource';
import { defaultImageLink, ImageLink, ImageLinkFields } from './types';
import { defaultImageLinkExternalResourceConfig, imageLinkInternalResourceConfig } from './imageLinkResource';

export const bannerInternalResourceConfig = (uiText: UiText): InternalResourceConfig<
ImageLink,
ImageLinkFields
> => ({
  ...imageLinkInternalResourceConfig(uiText),
  defaultResource: { ...defaultImageLink, isLarge: true },
});

export const defaultBannerExternalResourceConfig: ExternalResourceConfig = {
  ...defaultImageLinkExternalResourceConfig,
  enabled: true,
  aliases: {
    path: 'banners',
    plural: 'Banners',
    singular: 'Banner',
  },
};

export const defaultBannerResource = makeResource({
  internalConfig: bannerInternalResourceConfig(defaultUiText),
  externalConfig: defaultBannerExternalResourceConfig,
});
